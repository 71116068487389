// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import {
  DB_TABLE_USER,
  improvedRequestGetEntryListAll,
} from "../../../service/browser/BackendRequestService";
import ProfileUserListingSuperPane from "./ProfileUserListingSuperPane";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import TextBodySmall from "../../component/shared/text/TextBodySmall";
import { dateConvertToObject } from "../../../service/browser/DateService";
import { listToObjectByKey } from "../../../service/browser/ObjectService";

export const makeProfileUserListingExploreSuperPaneThemeDesignTokens = (
  mode
) => ({});

export function makeProfileUserListingExploreSuperPaneTheme(mode) {
  return {
    ...makeProfileUserListingExploreSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;

export default function ProfileUserListingExploreSuperPane({
  targetUserUuid,
  listMaxHeight,
  onChangeUserCount,
}) {
  const profileUserListingExploreSuperPaneTheme = createTheme(
    makeProfileUserListingExploreSuperPaneTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);

  const [selectedUserUuidList, setSelectedUserUuidList] = useState([]);
  const [dateBySelectedUserUuidTable, setDateBySelectedUserUuidTable] =
    useState([]);

  const initData = async () => {
    const getAllUserResponse = await improvedRequestGetEntryListAll(
      DB_TABLE_USER
    );

    const userDataList =
      getAllUserResponse &&
      getAllUserResponse.data &&
      getAllUserResponse.data["content"]
        ? getAllUserResponse.data["content"]
        : [];
    const filteredUserDataList = userDataList.filter(
      (userData) =>
        userData["uuid"] !== targetUserUuid &&
        userData["completed"] == true &&
        userData["soft_deleted"] == false
    );
    const newSelectedUserUuidList = filteredUserDataList.map(
      (userData) => userData["uuid"]
    );
    const newDateBySelectedUserUuidTable = {};
    filteredUserDataList.forEach((userData) => {
      newDateBySelectedUserUuidTable[userData["uuid"]] = dateConvertToObject(
        userData["creation_date_time"]
      );
    });

    onChangeUserCount?.(newSelectedUserUuidList.length);
    setSelectedUserUuidList(newSelectedUserUuidList);
    setDateBySelectedUserUuidTable(newDateBySelectedUserUuidTable);
  };

  useEffect(() => {
    initData()
      .then(() => {
        setAssetLoading(false);

        setTimeout(() => {
          setAssetVisible(true);
        }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
      })
      .catch((error) => {
        setAssetLoadingError(true);
      });
  }, []);

  return (
    <ThemeProvider theme={profileUserListingExploreSuperPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError
          name={"ProfileUserListingExploreSuperPane"}
        />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          <ProfileUserListingSuperPane
            targetUserUuid={targetUserUuid}
            selectedUserUuidList={selectedUserUuidList}
            listMaxHeight={listMaxHeight}
            dateBySelectedUserUuidTable={dateBySelectedUserUuidTable}
            dateTextPrefix={"Joined"}
          />
        </Box>
      )}
    </ThemeProvider>
  );
}
