// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import {
  DB_TABLE_COURSE,
  DB_TABLE_COURSE_AND_USER,
  requestGetEntryList,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import ButtonCheckBox from "../../component/shared/button/ButtonCheckBox";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";

export const makeCalendarFilterPaneThemeDesignTokens = (mode) => ({
  typography: {
    label: {
      fontFamily: "PT Sans",
      fontSize: 15,
      fontWeight: 400,
      color: purple[900],
    },
  },
});

export function makeCalendarFilterPaneTheme(mode) {
  return {
    ...makeCalendarFilterPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarFilterPane({
  presentUserUuid,
  onUpdateFilters,
}) {
  const calendarFilterPaneTheme = createTheme(
    makeCalendarFilterPaneTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [courseDataByCourseUuidTable, setCourseDataByCourseUuidTable] =
    useState({});
  const [filteredCourseTable, setFilteredCourseTable] = useState({});

  const filterLoad = () => {
    if (!presentUserUuid) {
      setAssetLoadingError(true);
      return;
    }
    requestSearchEntry(
      DB_TABLE_COURSE_AND_USER,
      {
        user_uuid: presentUserUuid,
      },
      (searchResponseData) => {
        if (searchResponseData && searchResponseData["content"]) {
          const courseAndUserDataList = searchResponseData["content"];
          const courseUuidList = courseAndUserDataList.map(
            (courseAndUserData) =>
              courseAndUserData["course_and_user_id"]["course_uuid"]
          );

          const newFilteredCourseTable = {};

          courseAndUserDataList.forEach((courseAndUserData) => {
            newFilteredCourseTable[
              courseAndUserData["course_and_user_id"]["course_uuid"]
            ] = {
              included: true,
              color: `#${courseAndUserData["color"]}`,
            };
          });

          setFilteredCourseTable(newFilteredCourseTable);

          requestGetEntryList(
            DB_TABLE_COURSE,
            courseUuidList,
            (courseDataList) => {
              const newCourseDataByCourseUuidTable = {};
              courseDataList.forEach((courseData) => {
                newCourseDataByCourseUuidTable[courseData["uuid"]] = courseData;
              });
              setCourseDataByCourseUuidTable(newCourseDataByCourseUuidTable);
              onUpdateFilters?.(newFilteredCourseTable);
              setAssetLoading(false);
            },
            (error) => {
              setAssetLoadingError(true);
            }
          );
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  };

  useEffect(() => {
    filterLoad();
  }, []);

  const toggleCourseIncludedStatus = (courseUuid) => {
    const copyFilteredCourseTable = { ...filteredCourseTable };
    const courseFilterInfo = copyFilteredCourseTable[courseUuid];
    courseFilterInfo["included"] = !courseFilterInfo["included"];
    setFilteredCourseTable(copyFilteredCourseTable);

    onUpdateFilters?.(copyFilteredCourseTable);
  };

  const handleCheckBoxClick = (courseUuid) => {
    toggleCourseIncludedStatus(courseUuid);
  };

  return (
    <ThemeProvider theme={calendarFilterPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name={"CalendarFilterPane"} />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            gap: "5px",
          }}
        >
          {courseDataByCourseUuidTable &&
            Object.keys(courseDataByCourseUuidTable).map((courseUuid) => (
              <Box
                key={courseUuid}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <ButtonCheckBox
                  checked={filteredCourseTable[courseUuid]["included"]}
                  onClick={() => handleCheckBoxClick(courseUuid)}
                  boxWidth={26}
                  outlineEnabled={false}
                  fillEnabled={true}
                  fillColor={alpha(
                    filteredCourseTable[courseUuid]["color"],
                    0.7
                  )}
                />
                <Typography variant="label">{`${courseDataByCourseUuidTable[courseUuid]["code"]} ${courseDataByCourseUuidTable[courseUuid]["number"]} [${courseDataByCourseUuidTable[courseUuid]["section_number"]}]`}</Typography>
              </Box>
            ))}
        </Box>
      )}
    </ThemeProvider>
  );
}
