// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Button, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, blue, purple, red, gray, green } from "../../../style/AppTheme";

export const makeButtonRectangleLabelDesignTokens = (mode) => ({
  typography: {
    regular: {
      fontFamily: "PT Sans",
      fontSize: "16px",
      fontStyle: "italic",
      fontWeight: 400,
    },
    small: {
      fontFamily: "PT Sans",
      fontSize: "13px",
      fontWeight: 400,
    },
  },
});

export function makeButtonRectangleLabelTheme(mode) {
  return {
    ...makeButtonRectangleLabelDesignTokens(mode),
    components: {
      MuiButtonBase: {
        defaultProps: {
          // Uncomment to disable the default Google-like ripple
          // disableTouchRipple: true,
          // disableRipple: true,
        },
        styleOverrides: {
          root: {
            boxSizing: "border-box",
            transition: "all 100ms ease-in",
            "&:focus-visible": {
              outlineOffset: "2px",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            paddingInline: "12px",
            paddingBlock: "6px",

            textTransform: "none",

            // adjust style
            // color: gray[700],

            color: gray[50],
            ...(ownerState.toggled
              ? { backgroundColor: alpha(purple[900], 0.8) }
              : { backgroundColor: alpha(purple[900], 0.5) }),
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",

            "&:hover": {
              backgroundColor: alpha(purple[800], 0.7),
            },
            ...(ownerState.variant === "critical" && {
              color: purple[900],
              ...(ownerState.toggled
                ? { backgroundColor: alpha(red[900], 0.8) }
                : { backgroundColor: alpha(red[900], 0.5) }),

              "&:hover": {
                backgroundColor: alpha(red[800], 0.7),
              },
            }),
            ...(ownerState.variant === "success" && {
              color: purple[900],
              ...(ownerState.toggled
                ? { backgroundColor: alpha(green[600], 0.8) }
                : { backgroundColor: alpha(green[600], 0.5) }),

              "&:hover": {
                backgroundColor: alpha(green[700], 0.7),
              },
            }),
            ...(ownerState.variant === "neutral" && {
              color: gray[700],
              ...(ownerState.toggled
                ? { backgroundColor: alpha(blue[100], 1) }
                : { backgroundColor: alpha(gray[200], 0.5) }),

              "&:hover": {
                backgroundColor: alpha(blue[100], 0.8),
              },
            }),
          }),
        },
      },
    },
  };
}

export default function ButtonRectangleLabel({
  variant = "neutral",
  textVariant = "regular",
  label,
  onClick,
  toggled = false,
  disabled = false,
}) {
  const buttonRectangleLabelTheme = createTheme(makeButtonRectangleLabelTheme("light"));

  const handleClick = () => {
    if (!disabled) {
      onClick?.(label);
    }
  };

  return (
    <ThemeProvider theme={buttonRectangleLabelTheme}>
      <Button variant={variant} onClick={handleClick} toggled={toggled.toString()} sx={{
        opacity: disabled ? 0.2 : 1.0
      }}>
        <Typography variant={textVariant}>{label}</Typography>
      </Button>
    </ThemeProvider>
  );
}
