// import logo from './logo.svg';
import "./App.css";
import Root from "./controller/special/Root";
import { UserContextProvider } from "./context/UserContext";

function App() {
  return (
    <UserContextProvider>
      <Root />
    </UserContextProvider>
  );
}

export default App;
