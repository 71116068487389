// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import SettingsPrivacyPolicyPane from "../../pane/settings/SettingsPrivacyPolicyPane";
import { componentHeightCalculate, componentHeightSet } from "../../../service/browser/ComponentService";

export const makeSettingsPrivacyPolicySuperPaneThemeDesignTokens = (
  mode
) => ({});

export function makeSettingsPrivacyPolicySuperPaneTheme(mode) {
  return {
    ...makeSettingsPrivacyPolicySuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function SettingsPrivacyPolicySuperPane({ presentUserUuid }) {
  const settingsPrivacyPolicySuperPaneTheme = createTheme(
    makeSettingsPrivacyPolicySuperPaneTheme("light")
  );

  const contentBoxRef = useRef(null);
  const [contentBoxHeight, setContentBoxHeight] = useState("250px");

  const updateHeight = () => {
    componentHeightSet(
      setContentBoxHeight,
      componentHeightCalculate(contentBoxRef, -100, 250)
    );
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(contentBoxRef.current);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <ThemeProvider theme={settingsPrivacyPolicySuperPaneTheme}>
      <Box
        ref={contentBoxRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "12px",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            width: "60%",
            height: contentBoxHeight,
          }}
        >
          <SettingsPrivacyPolicyPane targetUserUuid={presentUserUuid} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
