// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// DEBUG AND TEMPORARY
import tempProfilePictureA from "../../asset/image/placeholder/profile_picture/sample-pfp-jack.png";

// React
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import ProfilePortraitPane from "../../pane/profile/ProfilePortraitPane";
import { UserContext } from "../../../context/UserContext";
import {
  DB_TABLE_USER,
  DB_TABLE_USER_ENDORSEMENT,
  DB_TABLE_USER_REACTION,
  requestGetEntry,
  requestIdentityMapping,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import { parse, format } from "date-fns";
import {
  DATE_FRONTEND_FORMAT_LONG,
  dateConvertToObject,
  dateConvertToString,
} from "../../../service/browser/DateService";
import SurroundingControls from "../../special/SurroundingControls";
import ProfileReactionPane from "../../pane/profile/ProfileReactionPane";
import ProfileEndorsementPane from "../../pane/profile/ProfileEndorsementPane";
import Separator from "../../component/shared/other/Separator";
import ProfileInfoPane from "../../pane/profile/ProfileInfoPane";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import ProfileSuperPane from "../../super_pane/profile/ProfileSuperPane";

export const makeProfilePrimaryPageThemeDesignTokens = (mode) => ({});

export function makeProfilePrimaryPageTheme(mode) {
  return {
    ...makeProfilePrimaryPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function ProfilePrimaryPage({}) {
  const profilePrimaryPageTheme = createTheme(
    makeProfilePrimaryPageTheme("light")
  );
  const {
    presentUserUuid,
  } = useContext(UserContext);

  return (
    <ThemeProvider theme={profilePrimaryPageTheme}>
        <SurroundingControls>
          <ProfileSuperPane presentUserUuid={presentUserUuid} targetUserUuid={presentUserUuid} />
        </SurroundingControls>
    </ThemeProvider>
  );
}
