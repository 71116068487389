// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import SettingsDisplayColorPickerPane from "../../pane/settings/SettingsDisplayColorPickerPane";
import TextHeadingLarge from "../../component/shared/text/TextHeadingLarge";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";

export const makeSettingsDisplaySuperPaneThemeDesignTokens = (mode) => ({});

export function makeSettingsDisplaySuperPaneTheme(mode) {
  return {
    ...makeSettingsDisplaySuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function SettingsDisplaySuperPane({ presentUserUuid }) {
  const settingsDisplaySuperPaneTheme = createTheme(
    makeSettingsDisplaySuperPaneTheme("light")
  );

  return (
    <ThemeProvider theme={settingsDisplaySuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
          padding: "10px"
        }}
      >
        <TextHeadingMediumA label="Course Colors" />
        <SettingsDisplayColorPickerPane targetUserUuid={presentUserUuid} />
      </Box>
    </ThemeProvider>
  );
}
