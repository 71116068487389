// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Box, Typography } from "@mui/material";

// Custom Theme and Style
import {
  colorBg,
  purple,
  blue,
  red,
  gray,
  green,
} from "../../../style/AppTheme";

// Extra
import * as React from "react";

// StudiousCat Components
// ...

export const makeButtonRectangleNarrowLabelThemeDesignTokens = (mode) => ({
  typography: {
    label: {
      fontFamily: "PT Sans",
      fontWeight: 400,
    },
    number: {
      fontFamily: "PT Sans",
      fontSize: "20px",
      fontWeight: 400,
    },
  },
});

export function makeButtonRectangleNarrowLabelTheme(mode) {
  return {
    ...makeButtonRectangleNarrowLabelThemeDesignTokens(mode),
    components: {},
  };
}

/*
NOTES:
(1) the notification number is aligned right
(2) as the notification box increases in size, the label will try to be in the middle
    of the space between the left side of the box and the notification number
*/
export default function ButtonRectangleNarrowLabel({
  label,
  labelFontSize = "22px",
  labelVariant = "regular",
  labelFontColor = purple[900],
  variant = "regular",
  number = 0,
  showNumber = false,
  selected = false,
  onClick,
  roundedCornerLeft = true,
  roundedCornerRight = true,
  fullWidth = true,
  boxHorizontalPadding = "12px",
}) {
  const buttonRectangleNarrowLabelTheme = createTheme(
    makeButtonRectangleNarrowLabelTheme("light")
  );

  const handleClick = () => {
    onClick?.(label);
  };

  switch (variant) {
    case "regular":
      break;
  }

  const VARIANT_COLOR_TABLE = {
    regular: {
      background: selected ? alpha(blue[100], 1) : alpha(gray[200], 0.5),
      backgroundHover: alpha(blue[100], 0.7),
      text: alpha(gray[700], 0.7),
      textNumber: alpha(purple[900], 0.4),
    },
    inverted: {
      background: selected ? alpha(purple[800], 0.6) : alpha(purple[900], 0.8),
      backgroundHover: alpha(purple[900], 0.6),
      text: gray[100],
      textNumber: gray[100],
    },
  };

  const COLOR_DATA =
    variant in VARIANT_COLOR_TABLE
      ? VARIANT_COLOR_TABLE[variant]
      : VARIANT_COLOR_TABLE["regular"];
  const BACKGROUND_COLOR = COLOR_DATA.background;
  const BACKGROUND_HOVER_COLOR = COLOR_DATA.backgroundHover;
  const TEXT_COLOR = COLOR_DATA.text;
  const TEXT_NUMBER_COLOR = COLOR_DATA.textNumber;
  const BORDER_RADIUS = "3px";

  return (
    <ThemeProvider theme={buttonRectangleNarrowLabelTheme}>
      <Box
        onClick={handleClick}
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          boxSizing: "border-box",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
          borderTopLeftRadius: `${roundedCornerLeft ? BORDER_RADIUS : "0px"}`,
          borderBottomLeftRadius: `${
            roundedCornerLeft ? BORDER_RADIUS : "0px"
          }`,
          borderTopRightRadius: `${roundedCornerRight ? BORDER_RADIUS : "0px"}`,
          borderBottomRightRadius: `${
            roundedCornerRight ? BORDER_RADIUS : "0px"
          }`,
          borderBottom: "1px solid",
          borderColor: gray[200],
          backgroundColor: BACKGROUND_COLOR,
          paddingInline: boxHorizontalPadding,
          paddingBlock: "2px",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "6px",
          ...(fullWidth && { width: "100%" }),
          transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            backgroundColor: BACKGROUND_HOVER_COLOR,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: "1",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="label"
            sx={{
              fontSize: labelFontSize,
              color: labelFontColor,
              ...(labelVariant === "italic" && { fontStyle: "italic" }),
              userSelect: "none",
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              pointerEvents: "none",
              color: TEXT_COLOR,
            }}
          >
            {label}
          </Typography>
        </Box>
        {showNumber && (
          <Typography
            variant="number"
            sx={{
              color: TEXT_NUMBER_COLOR,
            }}
          >
            {number}
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
}
