// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import ProfilePortraitPane from "../../pane/profile/ProfilePortraitPane";
import ProfileReactionPane from "../../pane/profile/ProfileReactionPane";
import ProfileEndorsementPane from "../../pane/profile/ProfileEndorsementPane";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import ProfileInfoPane, {
  ProfileInfoPreset,
} from "../../pane/profile/ProfileInfoPane";
import {
  DB_TABLE_USER_FRIEND,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import {
  componentHeightCalculate,
  componentHeightSet,
} from "../../../service/browser/ComponentService";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import { useNavigate } from "react-router";
import { userSessionEnd } from "../../../service/user/UserService";
import { UserContext } from "../../../context/UserContext";
import ProfileCriticalControlPane from "../../pane/profile/ProfileCriticalControlPane";
import ProfileUserListingFriendsSuperPane from "./ProfileUserListingFriendsSuperPane";
import ProfileUserListingExploreSuperPane from "./ProfileUserListingExploreSuperPane";

export const makeProfileSuperPaneThemeDesignTokens = (mode) => ({});

export function makeProfileSuperPaneTheme(mode) {
  return {
    ...makeProfileSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

const InfoTabViewType = {
  INFO: "INFO",
  FRIENDS: "FRIENDS",
  EXPLORE: "EXPLORE",
};

export default function ProfileSuperPane({ presentUserUuid, targetUserUuid }) {
  const profileSuperPaneTheme = createTheme(makeProfileSuperPaneTheme("light"));

  const viewingOwnProfile = presentUserUuid === targetUserUuid;

  const [infoTabView, setInfoTabView] = useState(InfoTabViewType.INFO);
  const [endorsementPaneHeight, setEndorsementPaneHeight] = useState("100px");
  const [friendPaneHeight, setFriendPaneHeight] = useState("100px");
  const [explorePaneHeight, setExplorePaneHeight] = useState("100px");
  const [infoPaneHeight, setInfoPaneHeight] = useState("100px");
  const [friendCount, setFriendCount] = useState(0);
  const [exploreUserCount, setExploreUserCount] = useState(0);

  const endorsementPaneRef = useRef(null);
  const friendPaneRef = useRef(null);
  const explorePaneRef = useRef(null);
  const infoPaneRef = useRef(null);

  const updateHeight = () => {
    componentHeightSet(
      setEndorsementPaneHeight,
      componentHeightCalculate(endorsementPaneRef, -90, 100)
    );
    componentHeightSet(
      setFriendPaneHeight,
      componentHeightCalculate(friendPaneRef, -90, 100)
    );
    componentHeightSet(
      setExplorePaneHeight,
      componentHeightCalculate(explorePaneRef, -90, 100)
    );
    componentHeightSet(
      setInfoPaneHeight,
      componentHeightCalculate(infoPaneRef, -60, 100)
    );
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(endorsementPaneRef.current);
    resizeObserver.observe(friendPaneRef.current);
    resizeObserver.observe(infoPaneRef.current);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <ThemeProvider theme={profileSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: "60px",
          paddingTop: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "275px",
            }}
          >
            <ProfilePortraitPane targetUserUuid={targetUserUuid} />
          </Box>
          <Box
            sx={{
              width: "380px",
              paddingTop: "24px",
            }}
          >
            <ProfileReactionPane
              presentUserUuid={presentUserUuid}
              targetUserUuid={targetUserUuid}
            />
          </Box>
          <Box
            ref={endorsementPaneRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "380px",
              paddingTop: "24px",
            }}
          >
            <ProfileEndorsementPane
              presentUserUuid={presentUserUuid}
              targetUserUuid={targetUserUuid}
              endorsementListMaxHeight={endorsementPaneHeight}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingLeft: "32px",
            paddingBottom: "16px",
          }}
        >
          <Box
            sx={{
              borderRight: `1px solid ${gray[200]}`,
              height: endorsementPaneHeight,
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            flexGrow: 1,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: viewingOwnProfile ? "450px" : "300px",
            }}
          >
            <ButtonRectangleNarrowLabel
              label="Info"
              labelVariant="italic"
              onClick={() => setInfoTabView(InfoTabViewType.INFO)}
              selected={infoTabView === InfoTabViewType.INFO}
              roundedCornerLeft={true}
              roundedCornerRight={false}
              fullWidth={true}
            />
            <ButtonRectangleNarrowLabel
              label="Friends"
              labelVariant="italic"
              number={friendCount}
              showNumber={true}
              onClick={() => setInfoTabView(InfoTabViewType.FRIENDS)}
              selected={infoTabView === InfoTabViewType.FRIENDS}
              roundedCornerLeft={false}
              roundedCornerRight={false}
              fullWidth={true}
            />
            {viewingOwnProfile && (
              <ButtonRectangleNarrowLabel
                label="Explore"
                labelVariant="italic"
                number={exploreUserCount}
                showNumber={true}
                onClick={() => setInfoTabView(InfoTabViewType.EXPLORE)}
                selected={infoTabView === InfoTabViewType.EXPLORE}
                roundedCornerLeft={false}
                roundedCornerRight={true}
                fullWidth={true}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              ref={infoPaneRef}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "48px",
                width: "100%",
                height: infoPaneHeight,
                paddingTop: "32px",
                ...(infoTabView !== InfoTabViewType.INFO && {
                  display: "none",
                }),
              }}
            >
              <ProfileInfoPane
                targetUserUuid={targetUserUuid}
                preset={
                  viewingOwnProfile
                    ? ProfileInfoPreset.PRIMARY_PROFILE
                    : ProfileInfoPreset.ALTERNATE_PROFILE
                }
              />
              <ProfileCriticalControlPane
                presentUserUuid={presentUserUuid}
                targetUserUuid={targetUserUuid}
              />
            </Box>
            <Box
              ref={friendPaneRef}
              sx={{
                width: "75%",
                ...(infoTabView !== InfoTabViewType.FRIENDS && {
                  display: "none",
                }),
              }}
            >
              <ProfileUserListingFriendsSuperPane
                targetUserUuid={targetUserUuid}
                listMaxHeight={friendPaneHeight}
                onChangeUserCount={(newCount) => setFriendCount(newCount)}
              />
            </Box>
            {viewingOwnProfile && (
              <Box
                ref={explorePaneRef}
                sx={{
                  width: "75%",
                  ...(infoTabView !== InfoTabViewType.EXPLORE && {
                    display: "none",
                  }),
                }}
              >
                <ProfileUserListingExploreSuperPane
                  targetUserUuid={targetUserUuid}
                  listMaxHeight={explorePaneHeight}
                  onChangeUserCount={(newCount) =>
                    setExploreUserCount(newCount)
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
