import {
  DB_TABLE_MEETING,
  DB_TABLE_MEETING_AND_USER,
  DB_TABLE_USER_ENDORSEMENT,
  DB_TABLE_USER_FRIEND,
  DB_TABLE_USER_NOTIFICATION,
  improvedRequestCreateEntry,
  improvedRequestDeleteEntryAssociation,
  improvedRequestGetEntry,
  improvedRequestGetEntryAssociation,
  improvedRequestSearchEntry,
  improvedRequestUpdateEntry,
  improvedRequestUpdateEntryAssociation,
  requestUpdateEntry,
} from "../browser/BackendRequestService";
import {
  meetingLeaderParticipantJoinRequestAccept,
  meetingLeaderParticipantJoinRequestDeny,
  meetingParticipantLeaderInviteRequestAccept,
  meetingParticipantLeaderInviteRequestDeny,
} from "../meeting/MeetingService";
import { notificationCreate } from "./NotificationService";
import {
  userEndorsementRequestAccept,
  userEndorsementRequestDeny,
  userFriendRequestAccept,
  userFriendRequestDeny,
} from "./UserService";

export const NotificationActionType = {
  ACCEPT_DENY: "accept-deny",
  NONE: "",
};

export const NotificationActionResponseAcceptDeny = {
  ACCEPT: "accept",
  DENY: "deny",
};

export const NOTIFICATION_ACTION_TYPE_BY_NOTIFICATION_TYPE_TABLE = {
  "user-friend-request": NotificationActionType.ACCEPT_DENY,
  "user-endorsement-request": NotificationActionType.ACCEPT_DENY,
  "meeting-participant-leader-invite-request":
    NotificationActionType.ACCEPT_DENY,
  "meeting-leader-participant-join-request": NotificationActionType.ACCEPT_DENY,
};

// Pure async function, can throw errors.
export const notificationMarkActionCompleted = async (userNotificationUuid) => {
  const getResponse = await improvedRequestGetEntry(
    DB_TABLE_USER_NOTIFICATION,
    userNotificationUuid
  );
  const userNotificationData = getResponse.data;

  if (userNotificationData["action_completed"] == false) {
    const newUserNotificationData = { ...userNotificationData };
    newUserNotificationData["action_completed"] = true;
    const updateResponse = await improvedRequestUpdateEntry(
      DB_TABLE_USER_NOTIFICATION,
      userNotificationUuid,
      newUserNotificationData
    );
    return updateResponse.data;
  }
  return userNotificationData;
};

// Pure async function, can throw errors.
export const notificationActionUserFriendRequest = async (
  userNotificationData,
  resourceDataList,
  actionLabel
) => {
  const friendingUserUuid = resourceDataList[0]["uuid"];
  const friendedUserUuid = userNotificationData["user_uuid"];

  if (actionLabel === NotificationActionResponseAcceptDeny.ACCEPT) {
    await userFriendRequestAccept(friendingUserUuid, friendedUserUuid);
  } else {
    await userFriendRequestDeny(friendingUserUuid, friendedUserUuid);
  }
};

// Pure async function, can throw errors.
export const notificationActionUserEndorsementRequest = async (
  userNotificationData,
  resourceDataList,
  actionLabel
) => {
  const endorsingUserUuid = resourceDataList[0]["uuid"];
  const endorsedUserUuid = userNotificationData["user_uuid"];
  const userEndorsementUuid = resourceDataList[1]["uuid"];

  if (actionLabel === NotificationActionResponseAcceptDeny.ACCEPT) {
    await userEndorsementRequestAccept(
      endorsingUserUuid,
      endorsedUserUuid,
      userEndorsementUuid
    );
  } else {
    await userEndorsementRequestDeny(
      endorsingUserUuid,
      endorsedUserUuid,
      userEndorsementUuid
    );
  }
};

// Pure async function, can throw errors.
export const notificationActionMeetingParticipantLeaderInviteRequest = async (
  userNotificationData,
  resourceDataList,
  actionLabel
) => {
  const participantUserUuid = userNotificationData["user_uuid"];
  const meetingLeaderUserUuid = resourceDataList[0]["uuid"];

  const cachedMeetingData = resourceDataList[1];
  const meetingUuid = cachedMeetingData["uuid"];

  if (actionLabel === NotificationActionResponseAcceptDeny.ACCEPT) {
    await meetingParticipantLeaderInviteRequestAccept(
      participantUserUuid,
      meetingLeaderUserUuid,
      meetingUuid
    );
  } else {
    await meetingParticipantLeaderInviteRequestDeny(
      participantUserUuid,
      meetingLeaderUserUuid,
      meetingUuid
    );
  }
};

// Pure async function, can throw errors.
export const notificationActionMeetingLeaderParticipantJoinRequest = async (
  userNotificationData,
  resourceDataList,
  actionLabel
) => {
  const participantUserUuid = resourceDataList[0]["uuid"];
  const meetingLeaderUserUuid = userNotificationData["user_uuid"];

  const cachedMeetingData = resourceDataList[1];
  const meetingUuid = cachedMeetingData["uuid"];

  // CHECK:
  // Using the latest DB copy of the meeting and its meeting-and-users:
  //  (1) The current number of meeting-and-users (whether attending or not) is LESS THAN the max participant count of the meeting.
  //  (2) The current meeting, (a) completed is true, (b) soft_deleted is false
  //  (3) There is NOT already a meeting-and-user for the incoming user.
  if (actionLabel === NotificationActionResponseAcceptDeny.ACCEPT) {
    await meetingLeaderParticipantJoinRequestAccept(
      participantUserUuid,
      meetingLeaderUserUuid,
      meetingUuid
    );
  } else {
    await meetingLeaderParticipantJoinRequestDeny(
      participantUserUuid,
      meetingLeaderUserUuid,
      meetingUuid
    );
  }
};

export const NOTIFICATION_ACTION_FUNCTION_BY_NOTIFICATION_TYPE_TABLE = {
  "user-friend-request": notificationActionUserFriendRequest,
  "user-endorsement-request": notificationActionUserEndorsementRequest,
  "meeting-participant-leader-invite-request":
    notificationActionMeetingParticipantLeaderInviteRequest,
  "meeting-leader-participant-join-request":
    notificationActionMeetingLeaderParticipantJoinRequest,
};
