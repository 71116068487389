// Theme and Style
import { createTheme, IconButton, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green, blue } from "../../style/AppTheme";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";

// React
import * as React from "react";
import { useState } from "react";

export const makeStatusMessageThemeDesignTokens = (mode) => ({
  typography: {
    statusMessageText: {
      fontFamily: "PT Sans",
      fontSize: 14,
      fontWeight: 600,
    },
  },
});

export function makeStatusMessageTheme(mode) {
  return {
    ...makeStatusMessageThemeDesignTokens(mode),
    components: {},
  };
}

export const StatusMessageType = {
  INFO: "INFO",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export default function StatusMessage({
  message = "Message",
  type = StatusMessageType.INFO,
  onClose,
  width,
  height,
}) {
  const statusMessageTheme = createTheme(makeStatusMessageTheme("light"));

  const ICON_SIZE = "32px";
  const BORDER_COLOR_OPACITY = 0.5;
  const FILL_COLOR_OPACITY = 0.5;

  const ColorByStatusMessageType = {
    INFO: {
      border: alpha(blue[600], BORDER_COLOR_OPACITY),
      fill: alpha(blue[400], FILL_COLOR_OPACITY),
      text: gray[900],
    },
    SUCCESS: {
      border: alpha(green[600], BORDER_COLOR_OPACITY),
      fill: alpha(green[400], FILL_COLOR_OPACITY),
      text: gray[900],
    },
    ERROR: {
      border: alpha(red[600], BORDER_COLOR_OPACITY),
      fill: alpha(red[400], FILL_COLOR_OPACITY),
      text: gray[900],
    },
  };

  const statusColor = ColorByStatusMessageType[type];

  const generateIcon = () => {
    switch (type) {
      case StatusMessageType.INFO:
        return (
          <InfoIcon
            sx={{
              width: ICON_SIZE,
              height: ICON_SIZE,
            }}
          />
        );
      case StatusMessageType.SUCCESS:
        return (
          <CheckCircleIcon
            sx={{
              width: ICON_SIZE,
              height: ICON_SIZE,
            }}
          />
        );
      case StatusMessageType.ERROR:
        return (
          <WarningIcon
            sx={{
              width: ICON_SIZE,
              height: ICON_SIZE,
            }}
          />
        );
      default:
        return (
          <InfoIcon
            sx={{
              width: ICON_SIZE,
              height: ICON_SIZE,
            }}
          />
        );
    }
  };

  const handleCloseButtonClick = () => {
    onClose?.()
  }

  const generateCloseButton = (placeholder) => {
    if (placeholder || !onClose || onClose === undefined) {
      return (
        <IconButton
          disabled={true}
          size="small"
          sx={{
            opacity: 0,
          }}
        >
          <CancelIcon fontSize="inherit" />
        </IconButton>
      );
    }

    return (
      <IconButton size="small" onClick={handleCloseButtonClick} >
        <CancelIcon fontSize="inherit" />
      </IconButton>
    );
  };

  return (
    <ThemeProvider theme={statusMessageTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",

          ...(width ? { width: width } : { width: "100%" }),
          ...(height && { height: height }),

          padding: "8px",

          border: `2px solid ${statusColor.border}`,
          borderRadius: "6px",
          backgroundColor: statusColor.fill,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {generateCloseButton(true)}
          {generateIcon()}
          {generateCloseButton(false)}
        </Box>
        <Typography
          variant="statusMessageText"
          textAlign="center"
          sx={{
            color: statusColor.text,
          }}
        >
          {message}
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
