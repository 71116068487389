// Pure async function, can throw errors.

import {
    DB_TABLE_MEETING,
    DB_TABLE_MEETING_AND_USER,
  improvedRequestCreateEntry,
  improvedRequestDeleteEntryAssociation,
  improvedRequestGetEntry,
  improvedRequestGetEntryAssociation,
  improvedRequestSearchEntry,
  improvedRequestUpdateEntryAssociation,
} from "../browser/BackendRequestService";
import {
  notificationCreationTemplateMeetingLeaderParticipantInviteAccept,
  notificationCreationTemplateMeetingLeaderParticipantInviteDeny,
  notificationCreationTemplateMeetingParticipantLeaderJoinAccept,
  notificationCreationTemplateMeetingParticipantLeaderJoinDeny,
} from "../user/NotificationCreationTemplateService";
import {
  notificationCreate,
  notificationSetActionCompleted,
  NotificationType,
} from "../user/NotificationService";

// NOTE (9/1/2024): based on code originally from NotificationActionService
export const meetingParticipantLeaderInviteRequestAccept = async (
  participantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid
) => {
  // NOTE: when a meeting creator invites another user, a meeting-and-user is CREATED (so we can assume this already exists).
  const [getMeetingAndUserResponse, getMeetingResponse] = await Promise.all([
    improvedRequestGetEntryAssociation(
      DB_TABLE_MEETING_AND_USER,
      meetingUuid,
      participantUserUuid
    ),
    improvedRequestGetEntry(DB_TABLE_MEETING, meetingUuid),
  ]);

  const meetingAndUserData = getMeetingAndUserResponse.data;
  const meetingData = getMeetingResponse.data;

  // NOTE: Update the meeting-and-user only if the present user is not already attending the meeting AND if the meeting was not soft-deleted.
  if (
    meetingAndUserData &&
    meetingData &&
    meetingAndUserData["attending"] == false &&
    meetingData["soft_deleted"] == false
  ) {
    await improvedRequestUpdateEntryAssociation(
      DB_TABLE_MEETING_AND_USER,
      meetingUuid,
      participantUserUuid,
      {
        ...meetingAndUserData["meeting_and_user_id"],
        attending: true,
      }
    );
    await notificationCreate(
      notificationCreationTemplateMeetingLeaderParticipantInviteAccept(
        participantUserUuid,
        meetingLeaderUserUuid,
        meetingUuid,
        ""
      )
    );
    await Promise.all([
      notificationSetActionCompleted({
        user_uuid: participantUserUuid,
        second_resource_uuid: meetingUuid,
        type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
      }),
      notificationSetActionCompleted({
        user_uuid: meetingLeaderUserUuid,
        first_resource_uuid: participantUserUuid,
        second_resource_uuid: meetingUuid,
        type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
      }),
    ]);
  }
};

// Pure async function, can throw errors.
// NOTE (9/1/2024): based on code originally from NotificationActionService
export const meetingParticipantLeaderInviteRequestDeny = async (
  participantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid
) => {
  await improvedRequestDeleteEntryAssociation(
    DB_TABLE_MEETING_AND_USER,
    meetingUuid,
    participantUserUuid
  );
  await notificationCreate(
    notificationCreationTemplateMeetingLeaderParticipantInviteDeny(
      participantUserUuid,
      meetingLeaderUserUuid,
      meetingUuid,
      ""
    )
  );
  await Promise.all([
    notificationSetActionCompleted({
      user_uuid: participantUserUuid,
      second_resource_uuid: meetingUuid,
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
    }),
    notificationSetActionCompleted({
      user_uuid: meetingLeaderUserUuid,
      first_resource_uuid: participantUserUuid,
      second_resource_uuid: meetingUuid,
      type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
    }),
  ]);
};

// Pure async function, can throw errors.
// NOTE (9/1/2024): based on code originally from NotificationActionService
export const meetingLeaderParticipantJoinRequestAccept = async (
  participantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid
) => {
  const [getMeetingResponse, searchMeetingAndUserSearchResponse] =
    await Promise.all([
      improvedRequestGetEntry(DB_TABLE_MEETING, meetingUuid),
      improvedRequestSearchEntry(DB_TABLE_MEETING_AND_USER, {
        meeting_uuid: meetingUuid,
      }),
    ]);
  if (getMeetingResponse.data && searchMeetingAndUserSearchResponse.data) {
    const meetingData = getMeetingResponse.data;
    const meetingAndUserDataList =
      searchMeetingAndUserSearchResponse.data["content"];
    const userUuidList = meetingAndUserDataList.map(
      (meetingAndUserData) =>
        meetingAndUserData["meeting_and_user_id"]["user_uuid"]
    );
    const participantAlreadyAdded = userUuidList.includes(participantUserUuid);
    
    // NOTE: even if the meeting was changed to public (meaning request-join is no longer a necessity for users),
    // STILL allow the request-join to be accepted (intuitively makes sense).

    if (
      meetingData["completed"] == true &&
      meetingData["soft_deleted"] == false &&
      meetingAndUserDataList.length < meetingData["user_limit"] &&
      !participantAlreadyAdded
    ) {
      await improvedRequestCreateEntry(DB_TABLE_MEETING_AND_USER, {
        user_uuid: participantUserUuid,
        meeting_uuid: meetingUuid,
        attending: true,
      });
      await notificationCreate(
        notificationCreationTemplateMeetingParticipantLeaderJoinAccept(
          [participantUserUuid],
          meetingLeaderUserUuid,
          meetingUuid,
          ""
        )
      );
      await Promise.all([
        notificationSetActionCompleted({
          user_uuid: participantUserUuid,
          second_resource_uuid: meetingUuid,
          type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
        }),
        notificationSetActionCompleted({
          user_uuid: meetingLeaderUserUuid,
          first_resource_uuid: participantUserUuid,
          second_resource_uuid: meetingUuid,
          type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
        }),
      ]);
    }
  }
};

// Pure async function, can throw errors.
// NOTE (9/1/2024): based on code originally from NotificationActionService
export const meetingLeaderParticipantJoinRequestDeny = async (
  participantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid
) => {
  await notificationCreate(
    notificationCreationTemplateMeetingParticipantLeaderJoinDeny(
      [participantUserUuid],
      meetingLeaderUserUuid,
      meetingUuid,
      ""
    )
  );
  await Promise.all([
    notificationSetActionCompleted({
      user_uuid: participantUserUuid,
      second_resource_uuid: meetingUuid,
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
    }),
    notificationSetActionCompleted({
      user_uuid: meetingLeaderUserUuid,
      first_resource_uuid: participantUserUuid,
      second_resource_uuid: meetingUuid,
      type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
    }),
  ]);
};
