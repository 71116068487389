// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useCallback } from "react";
import { CalendarMeetingInfoPreset } from "../../super_pane/calendar_meeting_info/CalendarMeetingInfoSuperPane";
import { debounce } from "lodash";
import {
  CalendarMeetingInfoRowItemWrapper,
  CalendarMeetingInfoRowWrapper,
} from "../../component/calendar_meeting_info/CalendarMeetingInfoComponentCollection";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";

export const makeCalendarMeetingInfoInputControlsMinorPaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputControlsMinorPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputControlsMinorPaneThemeDesignTokens(mode),
    components: {},
  };
}

const DEBOUNCE_ACTION_TIMEOUT_DURATION_MS = 400;

export const CalendarMeetingInfoControlsMinorActionType = {
  CLOSE: "CLOSE",
  SHARE_MEETING: "SHARE_MEETING",
};

export default function CalendarMeetingInfoInputControlsMinorPane({
  meetingInfoPreset = CalendarMeetingInfoPreset.VIEW,
  onControlsMinorAction,
  meetingLinkCopiedClipboard,
}) {
  const calendarMeetingInfoInputControlsMinorPaneTheme = createTheme(
    makeCalendarMeetingInfoInputControlsMinorPaneTheme("light")
  );

  const processAction = (actionType) => {
    onControlsMinorAction?.(actionType);
  };

  const debounceAction = useCallback(
    debounce((actionType) => {
      processAction(actionType);
    }, DEBOUNCE_ACTION_TIMEOUT_DURATION_MS),
    [processAction]
  );

  const handleAction = useCallback(
    (actionType) => {
      debounceAction(actionType);
    },
    [debounceAction]
  );

  return (
    <ThemeProvider theme={calendarMeetingInfoInputControlsMinorPaneTheme}>
      <CalendarMeetingInfoRowWrapper>
        <CalendarMeetingInfoRowItemWrapper>
          <ButtonRectangleNarrowLabel
            label={
              meetingInfoPreset === CalendarMeetingInfoPreset.CREATE
                ? "Cancel & Go Back"
                : "Go to Calendar"
            }
            variant="inverted"
            showNumber={false}
            selected={false}
            onClick={() =>
              handleAction(CalendarMeetingInfoControlsMinorActionType.CLOSE)
            }
            labelFontSize={"18px"}
            fullWidth={false}
          />
          {meetingInfoPreset !== CalendarMeetingInfoPreset.CREATE && (
            <ButtonRectangleNarrowLabel
              label={
                meetingLinkCopiedClipboard ? "Copied!" : "Copy Meeting Link"
              }
              variant="inverted"
              showNumber={false}
              selected={false}
              onClick={() =>
                handleAction(
                  CalendarMeetingInfoControlsMinorActionType.SHARE_MEETING
                )
              }
              labelFontSize={"18px"}
              fullWidth={false}
            />
          )}
        </CalendarMeetingInfoRowItemWrapper>
      </CalendarMeetingInfoRowWrapper>
    </ThemeProvider>
  );
}
