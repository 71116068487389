// Theme and Style
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { gray, purple } from "../../style/AppTheme";

// Extra
import * as React from "react";
import { useState, useEffect } from "react";

// StudiousCat Components
import InputText from "../../component/shared/input/InputText";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";

import { validateUrlLinkedInProfile } from "../../../service/validation/ValidateUrl";
import SurroundingControls from "../../special/SurroundingControls";
import {
  DB_TABLE_COURSE,
  DB_TABLE_COURSE_AND_USER,
  DB_TABLE_MAJOR,
  DB_TABLE_MAJOR_AND_USER,
  DB_TABLE_USER,
  requestCreateEntry,
  requestDeleteEntry,
  requestDeleteEntryAssociation,
  requestGetEntry,
  requestGetEntryListAll,
  requestSearchEntry,
  requestUpdateEntry,
} from "../../../service/browser/BackendRequestService";
import {
  formatPhoneNumber,
  formatPhoneNumberDigitsOnly,
  validatePhoneNumber,
} from "../../../service/validation/ValidatePhoneNumber";
import {
  listToObjectByKey,
  randomColorHexString,
  stateListAddItem,
  stateListRemoveItemByValue,
  varListAddItem,
  varListRemoveItemByValue,
} from "../../../service/browser/ObjectService";
import DropDownBoxContainer from "../../component/shared/drop_down_box/DropDownBoxContainer";
import DropDownComboSearch from "../../component/shared/drop_down_combo/DropDownComboSearch";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";

export const makeProfileInfoPaneThemeDesignTokens = (mode) => ({
  typography: {
    bigUserName: {
      fontFamily: "Source Serif Pro",
      color: purple[900],
      fontWeight: 600,
      fontSize: 64,
      textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
      lineHeight: 0.9,
    },
  },
});

export function makeProfileInfoPaneTheme(mode) {
  return {
    ...makeProfileInfoPaneThemeDesignTokens(mode),
  };
}

const COLUMN_SPACING_FOR_GROUPED_ITEM_IN_ROW = 2;
const COLUMN_SPACING_OUTSIDE_GROUPS_IN_ROW = 3;
const ROW_ITEM_SPACING = 2;
const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;
const MAX_MAJOR_COUNT = 3;

export const ProfileInfoPreset = {
  PRIMARY_PROFILE: "PRIMARY_PROFILE",
  ALTERNATE_PROFILE: "ALTERNATE_PROFILE",
  CREATE_NEW_PROFILE: "CREATE_NEW_PROFILE",
};

const generateSingleLabel = (labelText = "label", labelOpacity = 1.0) => {
  return (
    <Grid
      item
      xs={2}
      textAlign="right"
      sx={{
        opacity: labelOpacity,
      }}
    >
      <TextHeadingMediumA label={labelText} />
    </Grid>
  );
};

function ProfileInfoTextGeneric({
  initialValue,
  onChangeValue,
  placeholderValue,
  maxCharCount,
  lineCount = 1,
  rowLabel,
  centerLabel = true,
  valueValidator,
  valueFilter,
  modifiable = false,
  required = false,
}) {
  const [textValue, setTextValue] = useState(initialValue);
  const textValueValid = valueValidator ? valueValidator(textValue) : true;

  const handleUnfocusName = () => {
    if (textValueValid) {
      onChangeValue(textValue);
    }
  };

  const handleSetValue = (newValue) => {
    if (valueFilter) {
      setTextValue(valueFilter(newValue));
    } else {
      setTextValue(newValue);
    }
  };

  useEffect(() => {
    setTextValue(initialValue);
  }, [initialValue]);

  return (
    <Grid
      item
      container
      xs
      alignItems={centerLabel ? "center" : undefined}
      columnSpacing={COLUMN_SPACING_OUTSIDE_GROUPS_IN_ROW}
    >
      {generateSingleLabel(rowLabel, 1.0)}
      <Grid item xs>
        <InputText
          required={required}
          numLines={lineCount}
          value={textValue}
          setValue={modifiable ? handleSetValue : undefined}
          onUnfocus={handleUnfocusName}
          error={!textValueValid}
          // disabled={!modifiable}
          maxCharCount={modifiable ? maxCharCount : undefined}
          placeholderValue={placeholderValue}
        />
      </Grid>
      {generateSingleLabel(rowLabel, 0.0)}
    </Grid>
  );
}

// onChange => selectedCourseListByUuid
function ProfileInfoClass({ targetUserUuid, modifiable, required, onChange }) {
  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [courseByUuidTable, setCourseByUuidTable] = useState({});
  const [selectedCourseListByUuid, setSelectedCourseListByUuid] = useState([]);
  const ROW_LABEL = "Classes:";

  const handleCourseDropDownAddItem = (courseUuid) => {
    requestCreateEntry(
      DB_TABLE_COURSE_AND_USER,
      {
        user_uuid: targetUserUuid,
        course_uuid: courseUuid,
        color: randomColorHexString(),
      },
      (responseData) => {
        if (responseData) {
          const newSelectedCourseListByUuid = varListAddItem(
            courseUuid,
            selectedCourseListByUuid
          );
          onChange?.(newSelectedCourseListByUuid);
          setSelectedCourseListByUuid(newSelectedCourseListByUuid);
        }
      }
    );
  };

  const handleCourseDropDownRemoveItem = (courseUuid) => {
    requestDeleteEntryAssociation(
      DB_TABLE_COURSE_AND_USER,
      courseUuid,
      targetUserUuid,
      (responseData) => {
        if (responseData) {
          const newSelectedCourseListByUuid = varListRemoveItemByValue(
            courseUuid,
            selectedCourseListByUuid
          );
          onChange?.(newSelectedCourseListByUuid);
          setSelectedCourseListByUuid(newSelectedCourseListByUuid);
        }
      }
    );
  };

  useEffect(() => {
    if (targetUserUuid) {
      const courseAllOperation = requestGetEntryListAll(
        DB_TABLE_COURSE,
        (courseResponseData) => {
          if (courseResponseData) {
            setCourseByUuidTable(
              listToObjectByKey(courseResponseData["content"], "uuid")
            );
          } else {
            setAssetLoadingError(true);
          }
        },
        (error) => {
          setAssetLoadingError(true);
        }
      );
      const courseUserOperation = requestSearchEntry(
        DB_TABLE_COURSE_AND_USER,
        {
          user_uuid: targetUserUuid,
        },
        (courseResponseData) => {
          if (courseResponseData) {
            const newSelectedCourseListByUuid = courseResponseData[
              "content"
            ].map((data) => data["course_and_user_id"]["course_uuid"]);
            onChange?.(newSelectedCourseListByUuid);
            setSelectedCourseListByUuid(newSelectedCourseListByUuid);
          } else {
            setAssetLoadingError(true);
          }
        },
        (error) => {
          setAssetLoadingError(true);
        }
      );
      Promise.all([courseAllOperation, courseUserOperation]).then(() => {
        setAssetLoading(false);
      });
    }
  }, [targetUserUuid]);

  return (
    <Grid
      item
      container
      xs
      alignItems="center"
      columnSpacing={COLUMN_SPACING_OUTSIDE_GROUPS_IN_ROW}
    >
      {generateSingleLabel(ROW_LABEL, 1.0)}
      <Grid item xs>
        {assetLoading || assetLoadingError ? (
          <DropDownComboSearch
            required={required}
            modifiable={false}
            fullWidth={true}
          />
        ) : (
          <DropDownComboSearch
            required={required}
            modifiable={modifiable}
            removable={selectedCourseListByUuid.length > 1}
            fullWidth={true}
            itemKeyId="uuid"
            itemKeySortBy="code"
            itemKeyListFilterBy={["name", "code", "number", "section_number"]}
            itemKeyListHeading={["code", "number", "section_number"]}
            itemKeyListDescription={["name"]}
            searchableItemById={courseByUuidTable}
            selectedItemIdList={selectedCourseListByUuid}
            onAddItemById={handleCourseDropDownAddItem}
            onRemoveItemById={handleCourseDropDownRemoveItem}
          />
        )}
      </Grid>
      {generateSingleLabel(ROW_LABEL, 0.0)}
    </Grid>
  );
}

// onChange => selectedMajorListByUuid
function ProfileInfoMajor({ targetUserUuid, modifiable, required, onChange }) {
  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [majorByUuidTable, setMajorByUuidTable] = useState({});
  const [selectedMajorListByUuid, setSelectedMajorListByUuid] = useState([]);
  const ROW_LABEL = "Majors:";

  const handleMajorDropDownAddItem = (majorUuid) => {
    requestCreateEntry(
      DB_TABLE_MAJOR_AND_USER,
      {
        user_uuid: targetUserUuid,
        major_uuid: majorUuid,
      },
      (responseData) => {
        if (responseData) {
          const newSelectedMajorListByUuid = varListAddItem(
            majorUuid,
            selectedMajorListByUuid
          );
          onChange?.(newSelectedMajorListByUuid);
          setSelectedMajorListByUuid(newSelectedMajorListByUuid);
        }
      }
    );
  };

  const handleMajorDropDownRemoveItem = (majorUuid) => {
    requestDeleteEntryAssociation(
      DB_TABLE_MAJOR_AND_USER,
      majorUuid,
      targetUserUuid,
      (responseData) => {
        if (responseData) {
          const newSelectedMajorListByUuid = varListRemoveItemByValue(
            majorUuid,
            selectedMajorListByUuid
          );
          onChange?.(newSelectedMajorListByUuid);
          setSelectedMajorListByUuid(newSelectedMajorListByUuid);
        }
      }
    );
  };

  useEffect(() => {
    if (targetUserUuid) {
      const majorAllOperation = requestGetEntryListAll(
        DB_TABLE_MAJOR,
        (majorResponseData) => {
          if (majorResponseData) {
            setMajorByUuidTable(
              listToObjectByKey(majorResponseData["content"], "uuid")
            );
          } else {
            setAssetLoadingError(true);
          }
        },
        (error) => {
          setAssetLoadingError(true);
        }
      );
      const majorUserOperation = requestSearchEntry(
        DB_TABLE_MAJOR_AND_USER,
        {
          user_uuid: targetUserUuid,
        },
        (majorResponseData) => {
          if (majorResponseData) {
            const newSelectedMajorListByUuid = majorResponseData["content"].map(
              (data) => data["major_and_user_id"]["major_uuid"]
            );
            onChange?.(newSelectedMajorListByUuid);
            setSelectedMajorListByUuid(newSelectedMajorListByUuid);
          } else {
            setAssetLoadingError(true);
          }
        },
        (error) => {
          setAssetLoadingError(true);
        }
      );
      Promise.all([majorAllOperation, majorUserOperation]).then(() => {
        setAssetLoading(false);
      });
    }
  }, [targetUserUuid]);

  return (
    <Grid
      item
      container
      xs
      alignItems="center"
      columnSpacing={COLUMN_SPACING_OUTSIDE_GROUPS_IN_ROW}
    >
      {generateSingleLabel(ROW_LABEL, 1.0)}
      <Grid item xs>
        {assetLoading || assetLoadingError ? (
          <DropDownComboSearch
            required={required}
            modifiable={false}
            fullWidth={true}
          />
        ) : (
          <DropDownComboSearch
            required={required}
            modifiable={modifiable}
            removable={selectedMajorListByUuid.length > 1}
            searchable={selectedMajorListByUuid.length < MAX_MAJOR_COUNT}
            fullWidth={true}
            itemKeyId="uuid"
            itemKeySortBy="name"
            itemKeyListFilterBy={["name"]}
            itemKeyListHeading={["name"]}
            searchableItemById={majorByUuidTable}
            selectedItemIdList={selectedMajorListByUuid}
            onAddItemById={handleMajorDropDownAddItem}
            onRemoveItemById={handleMajorDropDownRemoveItem}
          />
        )}
      </Grid>
      {generateSingleLabel(ROW_LABEL, 0.0)}
    </Grid>
  );
}

export default function ProfileInfoPane({
  targetUserUuid,
  preset,
  onChangeUserData,
  onChangeCourse,
  onChangeMajor,
}) {
  const profileInfoPaneTheme = createTheme(makeProfileInfoPaneTheme("light"));

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});

  const userPropertyUpdate = (userUuid, key, value) => {
    requestGetEntry(DB_TABLE_USER, userUuid, (userResponseData) => {
      if (userResponseData) {
        const newUserResponseData = userResponseData;
        newUserResponseData[key] = value;
        onChangeUserData?.(newUserResponseData);
        requestUpdateEntry(DB_TABLE_USER, userUuid, newUserResponseData);
      }
    });
  };

  useEffect(() => {
    if (!targetUserUuid) {
      setAssetLoadingError(true);
      return;
    }
    const userOperation = requestGetEntry(
      DB_TABLE_USER,
      targetUserUuid,
      (userResponseData) => {
        if (userResponseData) {
          onChangeUserData?.(userResponseData)
          setCurrentUserData(userResponseData);
          setAssetLoading(false);

          setTimeout(() => {
            setAssetVisible(true);
          }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
        } else {
          setAssetLoadingError(true);
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
    setAssetLoading(false);
  }, []);

  const infoModifiable =
    preset == ProfileInfoPreset.PRIMARY_PROFILE ||
    preset == ProfileInfoPreset.CREATE_NEW_PROFILE;
  const infoRequired = preset == ProfileInfoPreset.CREATE_NEW_PROFILE;

  return (
    <ThemeProvider theme={profileInfoPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name={"ProfileInfoPane"} />
      )}
      {!assetLoading && !assetLoadingError && (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          rowSpacing={ROW_ITEM_SPACING}
          sx={{
            paddingTop: "8px",
            paddingBottom: "8px",
            width: "100%",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          <ProfileInfoTextGeneric
            initialValue={currentUserData["full_name"]}
            onChangeValue={(newValue) =>
              userPropertyUpdate(targetUserUuid, "full_name", newValue)
            }
            placeholderValue="John Doe"
            maxCharCount={32}
            rowLabel="Name:"
            valueValidator={(value) => value && value.length > 0}
            modifiable={false}
            required={infoRequired}
          />
          <ProfileInfoClass
            targetUserUuid={targetUserUuid}
            modifiable={infoModifiable}
            required={infoRequired}
            onChange={(courseUuidList) => onChangeCourse?.(courseUuidList)}
          />
          <ProfileInfoMajor
            targetUserUuid={targetUserUuid}
            modifiable={infoModifiable}
            required={infoRequired}
            onChange={(majorUuidList) => onChangeMajor?.(majorUuidList)}
          />
          <ProfileInfoTextGeneric
            initialValue={currentUserData["bio"]}
            onChangeValue={(newValue) =>
              userPropertyUpdate(targetUserUuid, "bio", newValue)
            }
            placeholderValue="Tell everyone about yourself! How are you involved on campus? Are you in any student organizations or leadership positions? This information helps other students to get to know you and open up to studying with you!"
            maxCharCount={200}
            lineCount={3}
            rowLabel="Bio:"
            centerLabel={false}
            modifiable={infoModifiable}
            required={false}
          />
          <ProfileInfoTextGeneric
            initialValue={formatPhoneNumber(currentUserData["phone_number"])}
            onChangeValue={(newValue) =>
              userPropertyUpdate(
                targetUserUuid,
                "phone_number",
                formatPhoneNumberDigitsOnly(newValue)
              )
            }
            placeholderValue="(123) 456-7890"
            rowLabel="Phone:"
            valueValidator={(value) =>
              value.length === 0 || validatePhoneNumber(value, true)
            }
            valueFilter={(value) => formatPhoneNumber(value)}
            modifiable={infoModifiable}
            required={false}
          />
          <ProfileInfoTextGeneric
            initialValue={currentUserData["website_url"]}
            onChangeValue={(newValue) =>
              userPropertyUpdate(targetUserUuid, "website_url", newValue)
            }
            placeholderValue="https://www.linkedin.com/in/john-doe"
            maxCharCount={64}
            rowLabel="LinkedIn:"
            valueValidator={(value) =>
              (value !== undefined && value.length === 0) ||
              validateUrlLinkedInProfile(value, true)
            }
            modifiable={infoModifiable}
            required={false}
          />
        </Grid>
      )}
    </ThemeProvider>
  );
}
