// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";

// Interactive Assets
import { MuiColorInput } from "mui-color-input";

// Custom Theme and Style
// ...

// Extra
import * as React from "react";

// StudiousCat Components
// ...

export const makeInputColorSelectorThemeDesignTokens = (mode) => ({});

export function makeInputColorSelectorTheme(mode) {
  return {
    ...makeInputColorSelectorThemeDesignTokens(mode),
    components: {},
  };
}

export default function InputColorSelector({
  colorValue,
  onChangeColorValue,
  boxWidth = 70,
}) {
  const inputColorSelectorTheme = createTheme(
    makeInputColorSelectorTheme("light")
  );

  const convertRgbStringToHex = (rgbString) => {
    // Extract the numbers from the rgb string
    const rgbValues = rgbString.match(/\d+/g);

    // Convert each value to a hex string and pad with zeros if necessary
    const hexValues = rgbValues.map((value) => {
      const hex = parseInt(value).toString(16);
      return hex.length === 1 ? "0" + hex : hex; // Pad with zero if needed
    });

    // Join the hex values and prepend with '#'
    return `#${hexValues.join("").toLowerCase()}`;
  };

  const handleChange = (rgbString) => {
    onChangeColorValue?.(convertRgbStringToHex(rgbString));
  };

  return (
    <ThemeProvider theme={inputColorSelectorTheme}>
      <MuiColorInput
        value={colorValue}
        onChange={handleChange}
        sx={{
          "& .MuiInputBase-root": {
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            boxSizing: "border-box",
            aspectRatio: "1",
            minWidth: boxWidth,
            height: "100%",

            padding: "0px",
            margin: "0px",

            // Hides all of the other components of the MuiColorInput
            // except for the MuiInputAdornment and the MuiButton.
            // Normally the MuiColorInput has a TextInput component
            // that is not part of the SC Figma at this moment (7/14/2024).
            "& > input": {
              display: "none",
            },
          },
          "& .MuiInputAdornment-root": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
            height: "100%",

            padding: "0px",
            margin: "0px",

            maxHeight: "none",
          },
          "& .MuiButton-root": {
            display: "flex",

            width: "100%",
            height: "100%",
          },
        }}
      />
    </ThemeProvider>
  );
}
