// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import AboutSuperPane from "../../super_pane/about/AboutSuperPane";
import SurroundingControls from "../../special/SurroundingControls";

export const makeAboutPageThemeDesignTokens = (mode) => ({});

export function makeAboutPageTheme(mode) {
  return {
    ...makeAboutPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function AboutPage({}) {
  const aboutPageTheme = createTheme(makeAboutPageTheme("light"));

  return (
    <ThemeProvider theme={aboutPageTheme}>
      <SurroundingControls>
        <AboutSuperPane />
      </SurroundingControls>
    </ThemeProvider>
  );
}
