// Theme and Style
import { createTheme, Popover, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useRef, useEffect } from "react";

import CalendarFilterPane from "../../pane/calendar/CalendarFilterPane";
import CalendarControlsPane, {
  CalendarViewType,
} from "../../pane/calendar/CalendarControlsPane";
import CalendarViewPane from "../../pane/calendar/CalendarViewPane";
import ButtonCheckBox from "../../component/shared/button/ButtonCheckBox";
import CalendarDatePane from "../../pane/calendar/CalendarDatePane";
import CalendarSearchPane from "../../pane/calendar/CalendarSearchPane";

import { CalendarContext } from "../../../context/CalendarContext";
import {
  DATE_BACKEND_FORMAT,
  dateConvertToString,
  dateShiftDay,
  dateShiftMonth,
  dateShiftWeek,
} from "../../../service/browser/DateService";
import {
  componentHeightCalculate,
  componentHeightSet,
} from "../../../service/browser/ComponentService";
import CalendarMeetingInfoPlaceholderPane from "../../pane/calendar/CalendarMeetingInfoPlaceholderPane";
import {
  DB_TABLE_COURSE,
  DB_TABLE_COURSE_AND_USER,
  DB_TABLE_MEETING,
  DB_TABLE_MEETING_AND_USER,
  DB_TABLE_USER,
  improvedRequestGetEntryListAll,
  improvedRequestSearchEntry,
  requestCreateEntry,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import CalendarMeetingQuickViewPane, {
  CalendarMeetingQuickViewPreset,
} from "../../pane/calendar/CalendarMeetingQuickViewPane";
import { listToObjectByKey } from "../../../service/browser/ObjectService";
import { useNavigate } from "react-router";
import CalendarMeetingInfoSuperPane from "../calendar_meeting_info/CalendarMeetingInfoSuperPane";

export const makeCalendarSuperPaneThemeDesignTokens = (mode) => ({});

export function makeCalendarSuperPaneTheme(mode) {
  return {
    ...makeCalendarSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export const CalendarTopMostView = {
  CALENDAR: "CALENDAR",
  CREATE_MEETING: "CREATE_MEETING",
};

export default function CalendarSuperPane({ presentUserUuid }) {
  const calendarSuperPaneTheme = createTheme(
    makeCalendarSuperPaneTheme("light")
  );

  const CALENDAR_UPDATE_HEIGHT_DELAY_DURATION_MS = 25;
  const CALENDAR_RELOAD_DELAY_DURATION_MS = 25;
  const CALENDAR_VIEW_MOUNT_DELAY_DURATION_MS = 75;
  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 400;
  const MEETING_USER_LIMIT_DEFAULT = 6;

  const navigate = useNavigate();

  const [assetLoading, setAssetLoading] = useState(false);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);

  const calendarRef = useRef(null);
  const calendarBoxRef = useRef(null);
  const calendarQuickViewBoxRef = useRef(null);
  const [calendarBoxHeight, setCalendarBoxHeight] = useState("250px");
  const [calendarQuickViewBoxHeight, setCalendarQuickViewBoxHeight] =
    useState("250px");
  const [calendarViewMounted, setCalendarViewMounted] = useState(false);

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarView, setCalendarView] = useState(CalendarViewType.WEEK);
  const [calendarTopMostView, setCalendarTopMostView] = useState(
    CalendarTopMostView.CALENDAR
  );

  const [meetingDataByMeetingUuidTable, setMeetingDataByMeetingUuidTable] =
    useState({});
  const [userDataByUserUuidTable, setUserDataByUserUuidTable] = useState({});
  const [userUuidListByMeetingUuidTable, setUserUuidListByMeetingUuidTable] =
    useState({});
  const [courseDataByCourseUuidTable, setCourseDataByCourseUuidTable] =
    useState({});
  const [calendarFilteredCourseTable, setCalendarFilteredCourseTable] =
    useState({});

  const [calendarQuickViewPreset, setCalendarQuickViewPreset] = useState(
    CalendarMeetingQuickViewPreset.EMPTY_MEETING
  );
  const [selectedTimeSlotContent, setSelectedTimeSlotContent] = useState({});
  const [selectedExistingMeetingUuid, setSelectedExistingMeetingUuid] =
    useState(null);
  const [creatingMeetingUuid, setCreatingMeetingUuid] = useState(null);

  const initCalendarContent = async () => {
    return new Promise((resolve, reject) => {
      Promise.all([
        improvedRequestSearchEntry(DB_TABLE_MEETING, {
          completed: true,
          soft_deleted: false,
        }),
        improvedRequestGetEntryListAll(DB_TABLE_MEETING_AND_USER),
        improvedRequestGetEntryListAll(DB_TABLE_USER),
        improvedRequestGetEntryListAll(DB_TABLE_COURSE),
      ])
        .then(
          ([
            meetingResponse,
            meetingAndUserResponse,
            userResponse,
            courseResponse,
          ]) => {
            const meetingDataList = meetingResponse.data["content"];
            const meetingAndUserDataList =
              meetingAndUserResponse.data["content"];
            const userDataList = userResponse.data["content"];
            const courseDataList = courseResponse.data["content"];

            const newMeetingDataByMeetingUuidTable = listToObjectByKey(
              meetingDataList,
              "uuid"
            );
            const newCourseDataByCourseUuidTable = listToObjectByKey(
              courseDataList,
              "uuid"
            );
            const newUserDataByUserUuidTable = listToObjectByKey(
              userDataList,
              "uuid"
            );

            const newUserUuidListByMeetingUuidTable = {};
            meetingAndUserDataList.forEach((meetingAndUserData) => {
              const meetingUuid =
                meetingAndUserData["meeting_and_user_id"]["meeting_uuid"];
              const userUuid =
                meetingAndUserData["meeting_and_user_id"]["user_uuid"];

              if (meetingUuid in newUserUuidListByMeetingUuidTable) {
                newUserUuidListByMeetingUuidTable[meetingUuid].push(userUuid);
              } else {
                newUserUuidListByMeetingUuidTable[meetingUuid] = [userUuid];
              }
            });

            setMeetingDataByMeetingUuidTable(newMeetingDataByMeetingUuidTable);
            setUserDataByUserUuidTable(newUserDataByUserUuidTable);
            setUserUuidListByMeetingUuidTable(
              newUserUuidListByMeetingUuidTable
            );
            setCourseDataByCourseUuidTable(newCourseDataByCourseUuidTable);
            resolve();
          }
        )
        .catch((error) => {
          reject();
        });
    });
  };

  useEffect(() => {
    initCalendarContent()
      .then(() => {
        setAssetLoading(false);
      })
      .catch((error) => {
        setAssetLoadingError(true);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      componentUpdateHeight();

      setTimeout(() => {
        setCalendarViewMounted(true);
      }, CALENDAR_VIEW_MOUNT_DELAY_DURATION_MS);
    }, CALENDAR_UPDATE_HEIGHT_DELAY_DURATION_MS);

    setAssetVisible(false);

    setTimeout(() => {
      setAssetVisible(true);
    }, [ASSET_VISIBLE_TIMEOUT_DURATION_MS]);
  }, [calendarTopMostView]);

  const getCalendarInstance = () => {
    if (calendarRef && calendarRef.current) {
      return calendarRef.current.getInstance();
    } else {
      return undefined;
    }
  };

  const getCalendarDate = () => {
    const calendarInstance = getCalendarInstance();
    if (calendarInstance) {
      return calendarInstance.getDate().d.d;
    } else {
      return calendarDate;
    }
  };

  const componentUpdateHeight = () => {
    componentHeightSet(
      setCalendarBoxHeight,
      componentHeightCalculate(calendarBoxRef, -30, 250)
    );
    componentHeightCalculate(
      setCalendarQuickViewBoxHeight,
      componentHeightCalculate(calendarQuickViewBoxRef, -30, 250)
    );
  };

  useEffect(() => {
    if (!assetLoading) {
      window.addEventListener("resize", componentUpdateHeight);

      const resizeObserver = new ResizeObserver(componentUpdateHeight);
      resizeObserver.observe(calendarBoxRef.current);
      resizeObserver.observe(calendarQuickViewBoxRef.current);

      return () => {
        window.removeEventListener("resize", componentUpdateHeight);
      };
    }
  }, [assetLoading]);

  const handleFiltersUpdate = (filteredCourseTable) => {
    setCalendarFilteredCourseTable(filteredCourseTable);
  };

  const handleControlsViewUpdate = (newCalendarView) => {
    setCalendarView(newCalendarView);
  };

  const handleControlsViewLeft = () => {
    const calendarInstance = getCalendarInstance();
    calendarInstance?.prev();
    setCalendarDate(getCalendarDate());
  };

  const handleControlsViewRight = () => {
    const calendarInstance = getCalendarInstance();
    calendarInstance?.next();
    setCalendarDate(getCalendarDate());
  };

  const handleControlsViewToday = () => {
    const calendarInstance = getCalendarInstance();
    const newDate = new Date();
    calendarInstance?.setDate(newDate);
    setCalendarDate(newDate);
  };

  const handleCalendarStartCreateMeeting = () => {
    const newMeetingStartDate = selectedTimeSlotContent.startDate;
    const newMeetingEndDate = selectedTimeSlotContent.endDate;
    const newMeetingStartDateText = dateConvertToString(
      newMeetingStartDate,
      DATE_BACKEND_FORMAT
    );
    const newMeetingEndDateText = dateConvertToString(
      newMeetingEndDate,
      DATE_BACKEND_FORMAT
    );

    requestSearchEntry(
      DB_TABLE_COURSE_AND_USER,
      {
        user_uuid: presentUserUuid,
      },
      (courseAndUserSearchResponseData) => {
        if (
          courseAndUserSearchResponseData &&
          courseAndUserSearchResponseData["total_elements"] > 0
        ) {
          const firstCourseAndUserData =
            courseAndUserSearchResponseData["content"][0];
          const firstCourseUuid =
            firstCourseAndUserData["course_and_user_id"]["course_uuid"];

          requestCreateEntry(
            DB_TABLE_MEETING,
            {
              name: "",
              description: "",
              address: "",
              creator_user_uuid: presentUserUuid,
              start_date_time: newMeetingStartDateText,
              end_date_time: newMeetingEndDateText,
              scheduled_year: newMeetingStartDate.getFullYear(),
              scheduled_month: newMeetingStartDate.getMonth() + 1,
              scheduled_day: newMeetingStartDate.getDay(),
              course_uuid: firstCourseUuid,
              user_limit: MEETING_USER_LIMIT_DEFAULT,
              recurring: false,
              recurring_repeat_every_amount: 0,
              recurring_repeat_every_type: "",
              recurring_repeat_on_week_day: "",
              recurring_ends: false,
              recurring_ends_type: "",
              recurring_ends_on_date_time: newMeetingEndDateText,
              recurring_ends_after_occurrence_amount: 0,
              mode_in_person: true,
              mode_online: false,
              mode_public: true,
              completed: false,
              soft_deleted: false,
            },
            (creatingMeetingData) => {
              const newCreatingMeetingUuid = creatingMeetingData["uuid"];
              setCreatingMeetingUuid(newCreatingMeetingUuid);
              setCalendarTopMostView(CalendarTopMostView.CREATE_MEETING);
            }
          );
        }
      }
    );
  };

  const handleCalendarFinishCreateMeeting = () => {
    setCalendarViewMounted(false);

    setTimeout(() => {
      initCalendarContent()
        .then(() => {
          setCalendarQuickViewPreset(
            CalendarMeetingQuickViewPreset.EMPTY_MEETING
          );
          setCalendarTopMostView(CalendarTopMostView.CALENDAR);
        })
        .catch((error) => {
          setAssetLoadingError(true);
        });
    }, CALENDAR_RELOAD_DELAY_DURATION_MS);
  };

  const handleCalendarViewSelectedExistingMeeting = () => {
    if (selectedExistingMeetingUuid && selectedExistingMeetingUuid.length > 0) {
      navigate(`/meeting/${selectedExistingMeetingUuid}`, { replace: true });
    }
  };

  const handleCalendarSelectedTimeSlot = (startDate, endDate) => {
    setSelectedTimeSlotContent({
      startDate: startDate,
      endDate: endDate,
    });
    setCalendarQuickViewPreset(CalendarMeetingQuickViewPreset.NEW_MEETING);
  };

  const handleCalendarSelectedExistingMeeting = (newMeetingUuid) => {
    setSelectedExistingMeetingUuid(newMeetingUuid);
    setCalendarQuickViewPreset(CalendarMeetingQuickViewPreset.EXISTING_MEETING);
  };

  const generateNewMeetingComponent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CalendarMeetingInfoSuperPane
          presentUserUuid={presentUserUuid}
          targetMeetingUuid={creatingMeetingUuid}
          creatingMeeting={true}
          onClose={() => handleCalendarFinishCreateMeeting()}
        />
      </Box>
    );
  };

  // TODO: useEffect for newly created meeting for reloading whole calendar

  const generateEntireCalendarComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "10px",
          paddingBottom: "40px",
          transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          opacity: assetVisible ? 1 : 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "20px",
            paddingBottom: "5px",
          }}
        >
          <Box
            sx={{
              width: "180px",
            }}
          >
            <CalendarControlsPane
              onUpdateView={handleControlsViewUpdate}
              onViewLeft={handleControlsViewLeft}
              onViewRight={handleControlsViewRight}
              onViewToday={handleControlsViewToday}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexGrow: 1,
              gap: "45px",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <CalendarDatePane date={calendarDate} />
            <CalendarSearchPane />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              width: "240px",
              paddingTop: "40px",
            }}
          >
            <CalendarFilterPane
              presentUserUuid={presentUserUuid}
              onUpdateFilters={handleFiltersUpdate}
            />
            <Box
              ref={calendarQuickViewBoxRef}
              sx={{
                width: "220px",
                height: calendarQuickViewBoxHeight,
                flexGrow: 1,
                paddingRight: "20px",
                paddingTop: "20px",
              }}
            >
              <CalendarMeetingQuickViewPane
                preset={calendarQuickViewPreset}
                meetingDataByMeetingUuidTable={meetingDataByMeetingUuidTable}
                userDataByUserUuidTable={userDataByUserUuidTable}
                userUuidListByMeetingUuidTable={userUuidListByMeetingUuidTable}
                existingMeetingUuid={selectedExistingMeetingUuid}
                newMeetingTimeSlotContent={selectedTimeSlotContent}
                onCreateNewMeeting={() => handleCalendarStartCreateMeeting()}
                onViewExistingMeeting={() =>
                  handleCalendarViewSelectedExistingMeeting()
                }
              />
            </Box>
          </Box>
          <Box
            ref={calendarBoxRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            {calendarViewMounted && (
              <CalendarViewPane
                view={calendarView}
                calendarQuickViewPreset={calendarQuickViewPreset}
                presentUserUuid={presentUserUuid}
                filteredCourseTable={calendarFilteredCourseTable}
                meetingDataByMeetingUuidTable={meetingDataByMeetingUuidTable}
                userUuidListByMeetingUuidTable={userUuidListByMeetingUuidTable}
                courseDataByCourseUuidTable={courseDataByCourseUuidTable}
                calendarHeight={calendarBoxHeight}
                onSelectedTimeSlot={handleCalendarSelectedTimeSlot}
                onSelectedExistingMeeting={
                  handleCalendarSelectedExistingMeeting
                }
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const generateMainComponent = () => {
    switch (calendarTopMostView) {
      case CalendarTopMostView.CALENDAR:
        return generateEntireCalendarComponent();
      case CalendarTopMostView.CREATE_MEETING:
        return generateNewMeetingComponent();
    }
  };

  return (
    <CalendarContext.Provider value={{ calendarRef }}>
      <ThemeProvider theme={calendarSuperPaneTheme}>
        {assetLoadingError && (
          <StatusMessageComponentLoadError name="CalendarSuperPane" />
        )}
        {!assetLoading && !assetLoadingError && generateMainComponent()}
      </ThemeProvider>
    </CalendarContext.Provider>
  );
}
