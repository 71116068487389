// Theme and Style
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green, blue } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import TextHeadingMediumA from "../shared/text/TextHeadingMediumA";

export const makeStatusComponentLoadThemeDesignTokens = (mode) => ({});

export function makeStatusComponentLoadTheme(mode) {
  return {
    ...makeStatusComponentLoadThemeDesignTokens(mode),
    components: {},
  };
}

export default function StatusComponentLoad({}) {
  const statusComponentLoadTheme = createTheme(
    makeStatusComponentLoadTheme("light")
  );

  return (
    <ThemeProvider theme={statusComponentLoadTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          width: "100%",
          height: "100%",

          gap: "5px",
          padding: "5px",
        }}
      >
        <TextHeadingMediumA label="Loading..." />
        <CircularProgress
          sx={{
            color: purple[900],
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
