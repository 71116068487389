// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import ProfileInfoPane, {
  ProfileInfoPreset,
} from "../../pane/profile/ProfileInfoPane";
import LobbyProfilePortraitPane from "../../pane/lobby/LobbyProfilePortraitPane";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import Separator from "../../component/shared/other/Separator";

export const makeLobbyProfileInfoSuperPaneThemeDesignTokens = (mode) => ({
  typography: {
    noteText: {
      fontFamily: "PT Sans",
      color: purple[900],
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: 20,
    },
    noteRedText: {
      fontFamily: "PT Sans",
      color: red[800],
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: 20,
    },
  },
});

export function makeLobbyProfileInfoSuperPaneTheme(mode) {
  return {
    ...makeLobbyProfileInfoSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function LobbyProfileInfoSuperPane({
  targetUserUuid,
  onCreate,
  onBack,
}) {
  const lobbyProfileInfoSuperPaneTheme = createTheme(
    makeLobbyProfileInfoSuperPaneTheme("light")
  );

  const [majorUuidList, setMajorUuidList] = useState([]);
  const [courseUuidList, setCourseUuidList] = useState([]);

  const profileReady = majorUuidList.length > 0 && courseUuidList.length > 0;

  return (
    <ThemeProvider theme={lobbyProfileInfoSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
          paddingTop: "24px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            gap: "24px",
          }}
        >
          <Box>
            <LobbyProfilePortraitPane
              targetUserUuid={targetUserUuid}
              scale={1.25}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
              width: "900px",
            }}
          >
            <ProfileInfoPane
              targetUserUuid={targetUserUuid}
              preset={ProfileInfoPreset.CREATE_NEW_PROFILE}
              onChangeMajor={(newMajorUuidList) =>
                setMajorUuidList(newMajorUuidList)
              }
              onChangeCourse={(newCourseUuidList) =>
                setCourseUuidList(newCourseUuidList)
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
            width: "900px",
          }}
        >
          <Box
            sx={{
              width: "600px",
              textAlign: "center",
            }}
          >
            <Typography variant="noteText">
              We highly recommend filling out as much information as possible as
              that will make other students more likely to study with you!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "24px",
            }}
          >
            <ButtonRectangleLabel
              variant="success"
              label="Let's do this!"
              onClick={() => onCreate?.()}
              disabled={!profileReady}
            />
            <ButtonRectangleLabel
              variant="critical"
              label="Cancel"
              onClick={() => onBack?.()}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
