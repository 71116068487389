// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Avatar, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green, blue } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import { shapeCircleFilled } from "../../util/AppUtil";
import {
  DATE_FRONTEND_FORMAT_LONG,
  DATE_FRONTEND_FORMAT_TIME_ONLY,
  dateConvertToString,
  dateConvertToStringRelative,
} from "../../../service/browser/DateService";
import IconAccept from "../../icon/IconAccept";
import IconDeny from "../../icon/IconDeny";
import { NotificationActionResponseAcceptDeny, NotificationActionType } from "../../../service/user/NotificationActionService";

export const makeInboxNotificationCollectionItemThemeDesignTokens = (mode) => ({
  typography: {
    subjectHeading: {
      fontFamily: "Source Serif Pro",
      fontSize: 32,
      fontWeight: 600,
      color: purple[900],
    },
    subjectBody: {
      fontFamily: "Source Serif Pro",
      fontSize: 32,
      fontWeight: 400,
      color: purple[900],
    },
    mainBody: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 400,
      color: purple[900],
    },
    mainBodyEmphasis: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 600,
      color: purple[900],
    },
    date: {
      fontFamily: "PT Sans",
      fontSize: 20,
      fontWeight: 800,
      color: alpha(purple[900], 0.4),
    },
  },
});

export function makeInboxNotificationCollectionItemTheme(mode) {
  return {
    ...makeInboxNotificationCollectionItemThemeDesignTokens(mode),
    components: {},
  };
}

// NOTE: will show the subject heading (i.e. name of the person involved in the notification) if subjectHeading not undefined
// NOTE: will show the action if the actionType is not undefined
// NOTE: date can be left undefined, OK
// NOTE: viewed can be left undefined, OK

// NOTE: will attempt to expand to fill the width of the parent (should be surrounded with a Box of a fixed width)
export default function InboxNotificationCollectionItem({
  pictureUrl,
  subjectHeading,
  subjectBody,
  mainBodyEmphasis,
  mainBody,
  date,
  actionType,
  onAction,
  actionCompleted,
  viewed,
}) {
  const inboxNotificationCollectionItemTheme = createTheme(
    makeInboxNotificationCollectionItemTheme("light")
  );

  const dateDayOnly = date ? dateConvertToStringRelative(date) : "";
  const dateTimeOnly = date
    ? dateConvertToString(date, DATE_FRONTEND_FORMAT_TIME_ONLY)
    : "";

  const getActionComponentAcceptDeny = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
        }}
      >
        <IconAccept
          size={32}
          opacity={0.5}
          onHoverOpacity={0.6}
          fill={purple[900]}
          onClick={() =>
            onAction?.(NotificationActionResponseAcceptDeny.ACCEPT)
          }
        />
        <IconDeny
          size={32}
          opacity={0.5}
          onHoverOpacity={0.6}
          fill={purple[900]}
          onClick={() => onAction?.(NotificationActionResponseAcceptDeny.DENY)}
        />
      </Box>
    );
  };

  const getActionComponentDefault = () => {
    return <Box></Box>;
  };

  const getActionComponent = () => {
    if (actionCompleted) {
      return getActionComponentDefault();
    }
    switch (actionType) {
      case NotificationActionType.ACCEPT_DENY:
        return getActionComponentAcceptDeny();
      default:
        return getActionComponentDefault();
    }
  };

  return (
    <ThemeProvider theme={inboxNotificationCollectionItemTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: "5px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {shapeCircleFilled(10, alpha(blue[100], viewed ? 0 : 0.7))}
            <Avatar
              alt={subjectHeading}
              src={pictureUrl}
              sx={{
                width: "80px",
                height: "80px",
              }}
            />
            {/* <Avatar
              sx={{
                width: "80px",
                height: "80px",
              }}
            >
              {subjectHeading.charAt(0)}
            </Avatar> */}
          </Box>
          {/* text */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              {subjectHeading && (
                <Typography variant="subjectHeading">
                  {subjectHeading}:
                </Typography>
              )}
              <Typography variant="subjectBody">{subjectBody}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              {mainBodyEmphasis && (
                <Typography variant="mainBodyEmphasis">
                  {mainBodyEmphasis}
                </Typography>
              )}
              <Typography variant="mainBody">{mainBody}</Typography>
            </Box>
          </Box>
        </Box>
        {/* date, action */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            minWidth: "100px",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "right",
            }}
          >
            <Typography variant="date">{dateTimeOnly}</Typography>
            <Typography variant="date">{dateDayOnly}</Typography>
          </Box>
          {getActionComponent()}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
