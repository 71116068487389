// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";
import TextHeadingLarge from "../../component/shared/text/TextHeadingLarge";

export const makeHelpFaqSuperPaneThemeDesignTokens = (mode) => ({});

export function makeHelpFaqSuperPaneTheme(mode) {
  return {
    ...makeHelpFaqSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function HelpFaqSuperPane({}) {
  const helpFaqSuperPaneTheme = createTheme(makeHelpFaqSuperPaneTheme("light"));

  return (
    <ThemeProvider theme={helpFaqSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "30px",
          padding: "20px",
          width: "100%",
        }}
      >
        <TextHeadingLarge label="Frequently Asked Questions" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextHeadingMediumA label="Coming Soon!" />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
