// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Chip, Avatar, Typography, Popover } from "@mui/material";

// Custom Theme and Style
import {
  colorBg,
  purple,
  blue,
  red,
  gray,
  green,
} from "../../../style/AppTheme";

// React
import * as React from "react";
import { useState, useCallback, useRef } from "react";

import InputText from "../../../component/shared/input/InputText";
import DropDownBoxContainer from "../drop_down_box/DropDownBoxContainer";
import { objectToListConcatenateByKeyList } from "../../../../service/browser/ObjectService";
import { debounce } from "lodash";

export const makeDropDownComboSearchThemeDesignTokens = (mode) => ({
  typography: {
    menuDescriptionText: {
      fontFamily: "PT Sans",
      color: purple[900],
      fontWeight: 400,
      fontSize: 16,
    },
    itemHeadingText: {
      fontFamily: "Source Serif Pro",
      color: purple[900],
      fontWeight: 600,
      fontSize: 20,
    },
    itemDescriptionText: {
      fontFamily: "PT Sans",
      color: purple[900],
      fontWeight: 400,
      fontSize: 16,
    },
  },
});

export function makeDropDownComboSearchTheme(mode) {
  return {
    ...makeDropDownComboSearchThemeDesignTokens(mode),
    components: {},
  };
}

const DEBOUNCE_TIMEOUT_DURATION_MS = 100;

function DropDownComboSearchChip({ label, onDelete }) {
  return (
    <Chip
      label={label}
      variant="outlined"
      onDelete={onDelete ? () => onDelete() : undefined}
      sx={{
        borderRadius: "3px",
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
      }}
    />
  );
}

function DropDownComboSearchItem({
  pictureUrl,
  headingText,
  descriptionText,
  onClick,
}) {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        borderRadius: "5px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: alpha(gray[400], 0.8),
        transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          backgroundColor: alpha(blue[500], 0.7),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
        }}
      >
        {pictureUrl && (
          <Avatar
            alt={headingText}
            src={pictureUrl}
            sx={{
              width: "40px",
              height: "40px",
            }}
          />
        )}
        <Box
          sx={{
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          {headingText && (
            <Typography variant="itemHeadingText">{headingText}</Typography>
          )}
        </Box>
        <Box
          sx={{
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          {descriptionText && (
            <Typography variant="itemDescriptionText">
              {descriptionText}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default function DropDownComboSearch({
  required = false,
  modifiable = true,
  removable = true,
  searchable = true,
  fixedWidth = "120px",
  useFixedWidth = false,
  fullWidth = false,
  menuMaxHeight = "300px",
  itemKeyId = "",
  itemKeySortBy = "",
  itemKeyListFilterBy = [],
  itemKeyPictureUrl = "",
  itemKeyListHeading = [],
  itemKeyListDescription = [],
  searchableItemById = {},
  selectedItemIdList = [],
  onAddItemById = undefined,
  onRemoveItemById = undefined,
  maxItemDisplayCount = 200,
}) {
  const dropDownComboSearchTheme = createTheme(
    makeDropDownComboSearchTheme("light")
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const dropDownBoxRef = useRef(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;
  const [searchBarText, setSearchBarText] = useState("");

  if (!searchable && popoverOpen) {
    setAnchorEl(null);
  }

  const processClickDropDownBox = (eventCurrentTarget) => {
    if (modifiable) {
      setAnchorEl(eventCurrentTarget);
    }
  };

  const debounceClickDropDownBox = useCallback(
    debounce((eventCurrentTarget) => {
      processClickDropDownBox(eventCurrentTarget);
    }, DEBOUNCE_TIMEOUT_DURATION_MS),
    [processClickDropDownBox]
  );

  const handleClickDropDownBox = useCallback(
    (eventCurrentTarget) => {
      debounceClickDropDownBox(eventCurrentTarget);
    },
    [debounceClickDropDownBox]
  );

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const searchFilter = (pattern) => {
    if (!searchableItemById) {
      return [];
    }
    const patternLower = pattern.toLowerCase();
    return Object.values(searchableItemById)
      .filter(
        (item) =>
          objectToListConcatenateByKeyList(item, itemKeyListFilterBy, " ")
            .toLowerCase()
            .includes(patternLower) &&
          !selectedItemIdList.includes(item[itemKeyId])
      )
      .sort((a, b) => {
        if (a[itemKeySortBy] < b[itemKeySortBy]) {
          return -1;
        }
        if (a[itemKeySortBy] > b[itemKeySortBy]) {
          return 1;
        }
        return 0;
      });
  };

  const getDropDownMenuContents = () => {
    const filteredItemList = searchFilter(searchBarText);
    const matchedItemCount = filteredItemList.length;
    const displayedItemCount = Math.min(maxItemDisplayCount, matchedItemCount);

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: alpha(gray[400], 0.6),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            padding: "10px",
          }}
        >
          <InputText
            placeholderValue={"Search..."}
            value={searchBarText}
            setValue={setSearchBarText}
          />
        </Box>
        <Box
          sx={{
            padding: "10px",
            userSelect: "none",
          }}
        >
          <Typography variant="menuDescriptionText">
            Showing {displayedItemCount} out of {matchedItemCount} Matches
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: menuMaxHeight,
            overflowY: "auto", // show scroll bar
          }}
        >
          {filteredItemList.slice(0, maxItemDisplayCount).map((item, index) => (
            <Box
              key={index}
              sx={{
                padding: "5px",
              }}
            >
              <DropDownComboSearchItem
                onClick={
                  searchable
                    ? () => onAddItemById?.(item[itemKeyId])
                    : undefined
                }
                pictureUrl={itemKeyPictureUrl && item[itemKeyPictureUrl]}
                headingText={
                  itemKeyListHeading &&
                  objectToListConcatenateByKeyList(
                    item,
                    itemKeyListHeading,
                    " "
                  )
                }
                descriptionText={
                  itemKeyListDescription &&
                  objectToListConcatenateByKeyList(
                    item,
                    itemKeyListDescription,
                    " "
                  )
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const getDropDownMenuPopover = () => {
    return (
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        <Box
          sx={{
            width: dropDownBoxRef.current
              ? dropDownBoxRef.current.offsetWidth
              : "auto",
          }}
        >
          {getDropDownMenuContents()}
        </Box>
      </Popover>
    );
  };

  const getDropDownBox = () => {
    return (
      <Box ref={dropDownBoxRef}>
        <DropDownBoxContainer
          onClick={
            searchable
              ? (event) => handleClickDropDownBox(event.currentTarget)
              : undefined
          }
          required={required}
          iconVisible={modifiable}
          open={popoverOpen}
          fixedWidth={fixedWidth}
          useFixedWidth={useFixedWidth}
          fullWidth={fullWidth}
        >
          <Grid container columnGap={1} rowGap={1}>
            {selectedItemIdList.map((itemId, index) => (
              <Grid item key={index}>
                <DropDownComboSearchChip
                  label={
                    searchableItemById[itemId]
                      ? objectToListConcatenateByKeyList(
                          searchableItemById[itemId],
                          itemKeyListHeading,
                          " "
                        )
                      : ""
                  }
                  onDelete={
                    modifiable && removable
                      ? () => onRemoveItemById?.(itemId)
                      : undefined
                  }
                />
              </Grid>
            ))}
          </Grid>
        </DropDownBoxContainer>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={dropDownComboSearchTheme}>
      <Box>
        {getDropDownBox()}
        {getDropDownMenuPopover()}
      </Box>
    </ThemeProvider>
  );
}
