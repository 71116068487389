// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import DropDownComboSearch from "../../component/shared/drop_down_combo/DropDownComboSearch";
import {
  DB_TABLE_COURSE,
  DB_TABLE_COURSE_AND_USER,
  requestGetEntryList,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import { listToObjectByKey } from "../../../service/browser/ObjectService";

export const makeCalendarMeetingInfoInputCoursePaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputCoursePaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputCoursePaneThemeDesignTokens(mode),
    components: {},
  };
}

// Assume that the user is enrolled in SOME COURSE
// Assume the initial value is a valid course uuid
// If it is blank, then assume the first course uuid of the user
export default function CalendarMeetingInfoInputCoursePane({
  initialValue,
  onChangeValue,
  targetUserUuid,
  modifiable,
  required,
}) {
  const calendarMeetingInfoInputCoursePaneTheme = createTheme(
    makeCalendarMeetingInfoInputCoursePaneTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [selectedCourseUuid, setSelectedCourseUuid] = useState(initialValue);
  const [courseDataByCourseUuidTable, setCourseDataByCourseUuidTable] =
    useState({});

  const handleCourseDropDownSelectItem = (courseUuid) => {
    setSelectedCourseUuid(courseUuid);
    onChangeValue?.(courseUuid);
  };

  useEffect(() => {
    if (targetUserUuid) {
      requestSearchEntry(
        DB_TABLE_COURSE_AND_USER,
        {
          user_uuid: targetUserUuid,
        },
        (responseData) => {
          if (responseData && responseData["content"]) {
            const courseUuidList = responseData["content"].map(
              (courseAndUserData) =>
                courseAndUserData["course_and_user_id"]["course_uuid"]
            );

            requestGetEntryList(
              DB_TABLE_COURSE,
              courseUuidList,
              (courseResponseData) => {
                const newCourseDataByCourseUuidTable = listToObjectByKey(
                  courseResponseData,
                  "uuid"
                );
                setCourseDataByCourseUuidTable(newCourseDataByCourseUuidTable);
                setAssetLoading(false);
              },
              (error) => {
                setAssetLoadingError(true);
              }
            );
          } else {
            setAssetLoadingError(true);
          }
        },
        (error) => {
          setAssetLoadingError(true);
        }
      );
    } else {
      setAssetLoadingError(true);
    }
  }, [targetUserUuid]);

  return (
    <ThemeProvider theme={calendarMeetingInfoInputCoursePaneTheme}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {assetLoading || assetLoadingError ? (
          <DropDownComboSearch
            required={required}
            modifiable={false}
            fullWidth={true}
          />
        ) : (
          <DropDownComboSearch
            required={required}
            modifiable={modifiable}
            removable={false}
            fullWidth={true}
            itemKeyId="uuid"
            itemKeySortBy="code"
            itemKeyListFilterBy={["name", "code", "number", "section_number"]}
            itemKeyListHeading={["code", "number", "section_number"]}
            itemKeyListDescription={["name"]}
            searchableItemById={courseDataByCourseUuidTable}
            selectedItemIdList={[selectedCourseUuid]}
            onAddItemById={handleCourseDropDownSelectItem}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}
