// NOTE:
// Fields that are NOT used will be filled in with an 'undefined', NOT with an empty keyword.
// There is NO NEED to check for undefined values notificationData fields, this will be handled by some other component.

export const NOTIFICATION_COMPONENT_TEMPLATE_KEY_LIST = [
  "notificationData",
  "resourceDataList",
  "subjectBody",
  "mainBody",
  "mainBodyEmphasis",
];

export const notificationComponentTemplateUserFriendRequest = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: "Sent you a friend request!",
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateUserFriendAccept = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: "Accepted your friend request!",
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateUserFriendDeny = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: "Declined your friend request.",
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateUserEndorsementRequest = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: "Sent you an endorsement! Add it to your profile?",
    mainBody: resourceDataList[1]["reason"],
    mainBodyEmphasis: "Endorsement Description",
  };
};
export const notificationComponentTemplateUserEndorsementAccept = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: "Accepted your endorsement!",
    mainBody: resourceDataList[1]["reason"],
    mainBodyEmphasis: "Endorsement Description:",
  };
};
export const notificationComponentTemplateUserEndorsementDeny = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: "Declined your endorsement.",
    mainBody: resourceDataList[1]["reason"],
    mainBodyEmphasis: "Endorsement Description:",
  };
};
export const notificationComponentTemplateMeetingParticipantLeaderInfoChange = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: `Changed the ${keywordList[0]} of meeting '${resourceDataList[1]["name"]}'.`,
    mainBody: notificationData["message"],
    mainBodyEmphasis: `Changed from '${keywordList[1]}' to '${keywordList[2]}'`,
  };
};
export const notificationComponentTemplateMeetingParticipantLeaderDeleteMeeting =
  (notificationData, resourceDataList, keywordList) => {
    return {
      notificationData: notificationData,
      resourceDataList: resourceDataList,
      subjectBody: `Deleted meeting '${resourceDataList[1]["name"]}'.`,
      mainBody: notificationData["message"],
      mainBodyEmphasis: undefined,
    };
  };
export const notificationComponentTemplateMeetingParticipantLeaderRemoveUser = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: `Removed you from meeting '${resourceDataList[1]["name"]}'.`,
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateMeetingParticipantLeaderAnnouncement =
  (notificationData, resourceDataList, keywordList) => {
    return {
      notificationData: notificationData,
      resourceDataList: resourceDataList,
      subjectBody: `Made an announcement in meeting '${resourceDataList[1]["name"]}'!`,
      mainBody: notificationData["message"],
      mainBodyEmphasis: undefined,
    };
  };
export const notificationComponentTemplateMeetingParticipantLeaderInviteRequest =
  (notificationData, resourceDataList, keywordList) => {
    return {
      notificationData: notificationData,
      resourceDataList: resourceDataList,
      subjectBody: `Invited you to meeting '${resourceDataList[1]["name"]}'!`,
      mainBody: notificationData["message"],
      mainBodyEmphasis: undefined,
    };
  };
export const notificationComponentTemplateMeetingParticipantLeaderInviteCancel =
  (notificationData, resourceDataList, keywordList) => {
    return {
      notificationData: notificationData,
      resourceDataList: resourceDataList,
      subjectBody: `Canceled your invite for meeting '${resourceDataList[1]["name"]}'.`,
      mainBody: notificationData["message"],
      mainBodyEmphasis: undefined,
    };
  };
export const notificationComponentTemplateMeetingParticipantLeaderJoinAccept = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: `Accepted your request to join meeting '${resourceDataList[1]["name"]}'!`,
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateMeetingParticipantLeaderJoinDeny = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: `Declined your request to join meeting '${resourceDataList[1]["name"]}'.`,
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateMeetingLeaderParticipantInviteAccept =
  (notificationData, resourceDataList, keywordList) => {
    return {
      notificationData: notificationData,
      resourceDataList: resourceDataList,
      subjectBody: `Accepted your invitation for meeting '${resourceDataList[1]["name"]}'!`,
      mainBody: notificationData["message"],
      mainBodyEmphasis: undefined,
    };
  };
export const notificationComponentTemplateMeetingLeaderParticipantInviteDeny = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: `Declined your invitation for meeting '${resourceDataList[1]["name"]}'.`,
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateMeetingLeaderParticipantJoinDirect = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: `Joined meeting '${resourceDataList[1]["name"]}'!`,
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};
export const notificationComponentTemplateMeetingLeaderParticipantJoinRequest =
  (notificationData, resourceDataList, keywordList) => {
    return {
      notificationData: notificationData,
      resourceDataList: resourceDataList,
      subjectBody: `Wants to join meeting '${resourceDataList[1]["name"]}'!`,
      mainBody: notificationData["message"],
      mainBodyEmphasis: undefined,
    };
  };
export const notificationComponentTemplateMeetingLeaderParticipantLeave = (
  notificationData,
  resourceDataList,
  keywordList
) => {
  return {
    notificationData: notificationData,
    resourceDataList: resourceDataList,
    subjectBody: `Left meeting '${resourceDataList[1]["name"]}'.`,
    mainBody: notificationData["message"],
    mainBodyEmphasis: undefined,
  };
};

export const NOTIFICATION_COMPONENT_TEMPLATE_BY_NOTIFICATION_TYPE_TABLE = {
  "user-friend-request": notificationComponentTemplateUserFriendRequest,
  "user-friend-accept": notificationComponentTemplateUserFriendAccept,
  "user-friend-deny": notificationComponentTemplateUserFriendDeny,
  "user-endorsement-request":
    notificationComponentTemplateUserEndorsementRequest,
  "user-endorsement-accept": notificationComponentTemplateUserEndorsementAccept,
  "user-endorsement-deny": notificationComponentTemplateUserEndorsementDeny,
  "meeting-participant-leader-info-change":
    notificationComponentTemplateMeetingParticipantLeaderInfoChange,
  "meeting-participant-leader-delete-meeting":
    notificationComponentTemplateMeetingParticipantLeaderDeleteMeeting,
  "meeting-participant-leader-remove-user":
    notificationComponentTemplateMeetingParticipantLeaderRemoveUser,
  "meeting-participant-leader-announcement":
    notificationComponentTemplateMeetingParticipantLeaderAnnouncement,
  "meeting-participant-leader-invite-request":
    notificationComponentTemplateMeetingParticipantLeaderInviteRequest,
  "meeting-participant-leader-invite-cancel":
    notificationComponentTemplateMeetingParticipantLeaderInviteCancel,
  "meeting-participant-leader-join-accept":
    notificationComponentTemplateMeetingParticipantLeaderJoinAccept,
  "meeting-participant-leader-join-deny":
    notificationComponentTemplateMeetingParticipantLeaderJoinDeny,
  "meeting-leader-participant-invite-accept":
    notificationComponentTemplateMeetingLeaderParticipantInviteAccept,
  "meeting-leader-participant-invite-deny":
    notificationComponentTemplateMeetingLeaderParticipantInviteDeny,
  "meeting-leader-participant-join-direct":
    notificationComponentTemplateMeetingLeaderParticipantJoinDirect,
  "meeting-leader-participant-join-request":
    notificationComponentTemplateMeetingLeaderParticipantJoinRequest,
  "meeting-leader-participant-leave":
    notificationComponentTemplateMeetingLeaderParticipantLeave,
};
