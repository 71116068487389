// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext } from "react";
import SettingsPrivacyPolicyPane from "../../pane/settings/SettingsPrivacyPolicyPane";
import SurroundingControls from "../../special/SurroundingControls";
import SettingsPrivacyPolicySuperPane from "../../super_pane/settings/SettingsPrivacyPolicySuperPane";
import { UserContext } from "../../../context/UserContext";

export const makeSettingsPrivacyPolicyPageThemeDesignTokens = (mode) => ({});

export function makeSettingsPrivacyPolicyPageTheme(mode) {
  return {
    ...makeSettingsPrivacyPolicyPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function SettingsPrivacyPolicyPage({}) {
  const settingsPrivacyPolicyPageTheme = createTheme(
    makeSettingsPrivacyPolicyPageTheme("light")
  );

  const { presentUserUuid } = useContext(UserContext);

  return (
    <ThemeProvider theme={settingsPrivacyPolicyPageTheme}>
      <SurroundingControls>
        <SettingsPrivacyPolicySuperPane presentUserUuid={presentUserUuid} />
      </SurroundingControls>
    </ThemeProvider>
  );
}
