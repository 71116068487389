import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function IconDropDown(props) {
  const { flipped, onClick, size, stroke, opacity, onHoverOpacity, ...other } = props;

  return (
    <SvgIcon
      {...other}
      sx={{
        fontSize: size,
        width: size,
        height: size,
        "&:hover path": {
          opacity: onHoverOpacity,
        },
        rotate: `${flipped ? "180deg" : "0deg"}`
      }}
      onClick={onClick}
    >
      <path
        d="M19 1L10 7L1 1"
        stroke={stroke}
        opacity={opacity}
        fill="none"
        // The transform-translate is required to center the <path/> in the center of <SvgIcon/>.
        transform="translate(2, 8)"
      />
    </SvgIcon>
  );
}

export default IconDropDown;
