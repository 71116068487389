// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useMemo, useCallback } from "react";
import CalendarMeetingInfoInputSuperPane from "./CalendarMeetingInfoInputSuperPane";
import {
  CalendarMeetingInfoValidationStatus,
  validatorMeetingLocation,
  validatorMeetingName,
  validatorMeetingTime,
} from "../../component/calendar_meeting_info/CalendarMeetingInfoRowComponentCollection";
import {
  DATE_FRONTEND_FORMAT_LONG,
  DATE_FRONTEND_FORMAT_TIME_ONLY,
  dateCheckDayEqual,
  dateCheckTimeEqual,
  dateConvertToObject,
  dateConvertToString,
} from "../../../service/browser/DateService";
import {
  DB_TABLE_MEETING,
  DB_TABLE_MEETING_AND_USER,
  DB_TABLE_USER,
  DB_TABLE_USER_NOTIFICATION,
  improvedRequestCreateEntry,
  improvedRequestDeleteEntryAssociation,
  improvedRequestGetEntry,
  improvedRequestSearchEntry,
  improvedRequestUpdateEntry,
} from "../../../service/browser/BackendRequestService";
import {
  notificationCreate,
  notificationSetActionCompleted,
  notificationSetActionCompletedGivenUserUuidList,
  NotificationType,
} from "../../../service/user/NotificationService";
import { CalendarMeetingInfoControlsActionType } from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputControlsPane";
import {
  stateObjectDeleteItem,
  stateObjectSetItem,
} from "../../../service/browser/ObjectService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import { debounce } from "lodash";
import {
  notificationCreationTemplateMeetingLeaderParticipantJoinDirect,
  notificationCreationTemplateMeetingLeaderParticipantJoinRequest,
  notificationCreationTemplateMeetingLeaderParticipantLeave,
  notificationCreationTemplateMeetingParticipantLeaderDeleteMeeting,
  notificationCreationTemplateMeetingParticipantLeaderInfoChange,
  notificationCreationTemplateMeetingParticipantLeaderInviteRequest,
} from "../../../service/user/NotificationCreationTemplateService";
import {
  meetingParticipantLeaderInviteRequestAccept,
  meetingParticipantLeaderInviteRequestDeny,
} from "../../../service/meeting/MeetingService";
import { CalendarMeetingInfoControlsMinorActionType } from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputControlsMinorPane";

const CHECK_BOX_BUTTON_WIDTH = 40;

const BUSY_TIMEOUT_DURATION_MS = 150;
const DEBOUNCE_PROPERTY_TIMEOUT_DURATION_MS = 400;
const DEBOUNCE_TEXT_UPDATE_TIMEOUT_DURATION_MS = 750;
const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 400;
const MEETING_DATA_UPDATE_DELAY_DURATION_MS = 750;

const MeetingInfoKeyType = {
  STRING: "STRING",
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
  TIME: "TIME",
};

const MEETING_INFO_VALUE_FORMATTER = {
  STRING: (value) => value,
  BOOLEAN: (value) => (value ? "yes" : "no"),
  DATE: (value) => dateConvertToString(value, DATE_FRONTEND_FORMAT_LONG),
  TIME: (value) => dateConvertToString(value, DATE_FRONTEND_FORMAT_TIME_ONLY),
};

const MEETING_KEY_INFO_CHANGE_NOTIFICATION = {
  name: {
    label: "name",
    type: MeetingInfoKeyType.STRING,
  },
  address: {
    label: "address",
    type: MeetingInfoKeyType.STRING,
  },
  // CUSTOM, DOES NOT EXACTLY ALIGN WITH MEETING TABLE
  date: {
    label: "date",
    type: MeetingInfoKeyType.DATE,
  },
  // CUSTOM, DOES NOT EXACTLY ALIGN WITH MEETING TABLE
  start_time: {
    label: "start time",
    type: MeetingInfoKeyType.TIME,
  },
  // CUSTOM, DOES NOT EXACTLY ALIGN WITH MEETING TABLE
  end_time: {
    label: "end time",
    type: MeetingInfoKeyType.TIME,
  },
  user_limit: {
    label: "participant limit",
    type: MeetingInfoKeyType.STRING,
  },
  mode_in_person: {
    label: "in-person status",
    type: MeetingInfoKeyType.BOOLEAN,
  },
  mode_online: {
    label: "online status",
    type: MeetingInfoKeyType.BOOLEAN,
  },
  mode_public: {
    label: "public status",
    type: MeetingInfoKeyType.BOOLEAN,
  },
};

export const CalendarMeetingInfoPreset = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

// Pure Async Function: can throw errors
// "valid" => meeting not deleted (soft_deleted = false)
export const calendarMeetingInfoCheckpointMeetingValid = async (
  meetingUuid
) => {
  const getResponse = await improvedRequestGetEntry(
    DB_TABLE_MEETING,
    meetingUuid
  );
  const meetingData = getResponse.data;

  // For safety, if the meeting data is undefined or null, assume the meeting is no longer valid (info screen should be closed).
  if (!meetingData || meetingData["soft_deleted"] == true) {
    return false;
  }

  return true;
};

export const calendarMeetingInfoCheckpointMeetingData = async (meetingUuid) => {
  const getResponse = await improvedRequestGetEntry(
    DB_TABLE_MEETING,
    meetingUuid
  );
  return getResponse.data;
};

// Pure Async Function: can throw errors
export const calendarMeetingInfoCheckpointUserAttending = async (
  userUuid,
  meetingUuid
) => {
  const searchResponse = await improvedRequestSearchEntry(
    DB_TABLE_MEETING_AND_USER,
    {
      user_uuid: userUuid,
      meeting_uuid: meetingUuid,
      attending: true,
    }
  );
  return (
    searchResponse &&
    searchResponse.data &&
    searchResponse.data["total_elements"] > 0
  );
};

// Pure Async Function: can throw errors
export const calendarMeetingInfoCheckpointUserRequestedJoin = async (
  userUuid,
  meetingUuid
) => {
  // The receiver of the notification (creator of the meeting) does NOT need to be specified.
  const searchResponse = await improvedRequestSearchEntry(
    DB_TABLE_USER_NOTIFICATION,
    {
      first_resource_uuid: userUuid,
      second_resource_uuid: meetingUuid,
      type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
      action_completed: false,
    }
  );
  return (
    searchResponse &&
    searchResponse.data &&
    searchResponse.data["total_elements"] > 0
  );
};

// Pure Async Function: can throw errors
export const calendarMeetingInfoCheckpointUserInvited = async (
  userUuid,
  meetingUuid
) => {
  // The receiver of the notification (creator of the meeting) does NOT need to be specified.
  const searchResponse = await improvedRequestSearchEntry(
    DB_TABLE_USER_NOTIFICATION,
    {
      user_uuid: userUuid,
      second_resource_uuid: meetingUuid,
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
      action_completed: false,
    }
  );
  return (
    searchResponse &&
    searchResponse.data &&
    searchResponse.data["total_elements"] > 0
  );
};

export const makeCalendarMeetingInfoSuperPaneThemeDesignTokens = (mode) => ({});

export function makeCalendarMeetingInfoSuperPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarMeetingInfoSuperPane({
  presentUserUuid,
  targetMeetingUuid,
  creatingMeeting = true,
  onClose,
}) {
  const calendarMeetingInfoSuperPaneTheme = createTheme(
    makeCalendarMeetingInfoSuperPaneTheme("light")
  );

  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===
  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===
  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);

  // Applies to BOTH "major" and "minor" controls.
  const [actionControlsGeneralHandling, setActionControlsGeneralHandling] =
    useState(false);

  const [presentUserAttendingMeeting, setPresentUserAttendingMeeting] =
    useState(false);
  const [presentUserRequestedJoin, setPresentUserRequestedJoin] =
    useState(false);
  const [presentUserInvited, setPresentUserInvited] = useState(false);

  const [meetingInfoPreset, setMeetingInfoPreset] = useState(
    CalendarMeetingInfoPreset.VIEW
  );
  const [meetingDataInitial, setMeetingDataInitial] = useState({});
  const [meetingDataLatest, setMeetingDataLatest] = useState({});
  const [meetingDataBuffer, setMeetingDataBuffer] = useState({});
  const [meetingChunkBuffer, setMeetingChunkBuffer] = useState({});

  const [meetingLinkCopiedClipboard, setMeetingLinkCopiedClipboard] =
    useState(false);
  const [meetingDataUpdateFinished, setMeetingDataUpdateFinished] =
    useState(true);
  const [meetingSubmissionReady, setMeetingSubmissionReady] = useState(false);
  const [errorMessageByRowKeyTable, setErrorMessageByRowKeyTable] = useState(
    {}
  );

  const meetingErrorMessageSet = (rowKey, message) => {
    stateObjectSetItem(
      rowKey,
      message,
      errorMessageByRowKeyTable,
      setErrorMessageByRowKeyTable
    );
  };

  const meetingErrorMessageRemove = (rowKey) => {
    stateObjectDeleteItem(
      rowKey,
      errorMessageByRowKeyTable,
      setErrorMessageByRowKeyTable
    );
  };

  const meetingSubmissionStatusCheck = () => {
    if (!meetingDataUpdateFinished || assetLoading) {
      return false;
    }

    if (Object.keys(errorMessageByRowKeyTable).length > 0) {
      return false;
    }

    const validationResultList = [
      validatorMeetingName(meetingDataLatest["name"]),
      validatorMeetingTime([
        meetingDataLatest["start_date_time"],
        meetingDataLatest["end_date_time"],
      ]),
      validatorMeetingLocation(meetingDataLatest["address"]),
    ];

    for (const result of validationResultList) {
      if (result.status !== CalendarMeetingInfoValidationStatus.VALID) {
        return false;
      }
    }

    return true;
  };

  // Pure Async Function: can throw errors
  const meetingDataUpdateNotificationSend = async (
    previousMeetingData,
    chunk
  ) => {
    const chunkItemCount = Object.keys(chunk).length;

    // NOTE: this function ASSUMES that there IS a difference between the values in chunk and previousMeetingData, it will not check if they are actually different
    // WARNING: any updates made involving 3+ items will NOT send a notification update.

    var matchedMeetingKey = undefined;
    var matchedMeetingValuePrevious = undefined;
    var matchedMeetingValueNew = undefined;

    switch (chunkItemCount) {
      case 1:
        const [chunkKey, chunkValue] = Object.entries(chunk)[0];
        if (chunkKey in MEETING_KEY_INFO_CHANGE_NOTIFICATION) {
          matchedMeetingKey = chunkKey;
        }
        matchedMeetingValuePrevious = previousMeetingData[chunkKey];
        matchedMeetingValueNew = chunkValue;
        break;
      case 2:
        if ("start_date_time" in chunk && "end_date_time" in chunk) {
          const chunkStartDateTime = dateConvertToObject(
            chunk["start_date_time"]
          );
          const chunkEndDateTime = dateConvertToObject(chunk["end_date_time"]);
          const previousStartDateTime = dateConvertToObject(
            previousMeetingData["start_date_time"]
          );
          const previousEndDateTime = dateConvertToObject(
            previousMeetingData["end_date_time"]
          );

          // CHECK: did the meeting DAY change.
          if (
            !dateCheckDayEqual(chunkStartDateTime, previousStartDateTime) &&
            !dateCheckDayEqual(chunkEndDateTime, previousEndDateTime)
          ) {
            matchedMeetingKey = "date";

            // Either "start_date_time" or "end_date_time" works
            matchedMeetingValuePrevious =
              previousMeetingData["start_date_time"];
            matchedMeetingValueNew = chunk["start_date_time"];
          }
          // CHECK: did the meeting START TIME change.
          else if (
            !dateCheckTimeEqual(chunkStartDateTime, previousStartDateTime) &&
            dateCheckTimeEqual(chunkEndDateTime, previousEndDateTime)
          ) {
            matchedMeetingKey = "start_time";
            matchedMeetingValuePrevious =
              previousMeetingData["start_date_time"];
            matchedMeetingValueNew = chunk["start_date_time"];
          }
          // CHECK: did the meeting END TIME change.
          else if (
            dateCheckTimeEqual(chunkStartDateTime, previousStartDateTime) &&
            !dateCheckTimeEqual(chunkEndDateTime, previousEndDateTime)
          ) {
            matchedMeetingKey = "end_time";
            matchedMeetingValuePrevious = previousMeetingData["end_date_time"];
            matchedMeetingValueNew = chunk["end_date_time"];
          }
        }
        break;
      default:
        break;
    }

    // NOTE: it is guaranteed that matchedMeetingKey matches to something in MEETING_KEY_INFO_CHANGE_NOTIFICATION if it is not undefined.
    if (matchedMeetingKey) {
      const changedInfoData =
        MEETING_KEY_INFO_CHANGE_NOTIFICATION[matchedMeetingKey];
      const changedInfoLabel = changedInfoData["label"];
      const changedInfoType = changedInfoData["type"];

      const changedInfoFormatter =
        MEETING_INFO_VALUE_FORMATTER[changedInfoType];

      const changedInfoValuePrevious = changedInfoFormatter(
        matchedMeetingValuePrevious
      );
      const changedInfoValueNew = changedInfoFormatter(matchedMeetingValueNew);

      // Only currently attending users can receive updates about the meeting info.
      const searchResponse = await improvedRequestSearchEntry(
        DB_TABLE_MEETING_AND_USER,
        {
          meeting_uuid: targetMeetingUuid,
          attending: true,
        }
      );
      if (
        searchResponse &&
        searchResponse.data &&
        searchResponse.data["content"]
      ) {
        const meetingAndUserDataList = searchResponse.data["content"];
        const userUuidList = meetingAndUserDataList
          .map(
            (meetingAndUserData) =>
              meetingAndUserData["meeting_and_user_id"]["user_uuid"]
          )
          .filter((userUuid) => userUuid !== presentUserUuid);

        await notificationCreate(
          notificationCreationTemplateMeetingParticipantLeaderInfoChange(
            userUuidList,
            presentUserUuid,
            targetMeetingUuid,
            "",
            changedInfoLabel,
            changedInfoValuePrevious,
            changedInfoValueNew
          )
        );
      }
    }
  };

  const meetingDataDifferenceCheck = (previousMeetingData, chunk) => {
    for (const [chunkKey, chunkValue] of Object.entries(chunk)) {
      if (previousMeetingData[chunkKey] !== chunkValue) {
        return true;
      }
    }
    return false;
  };

  // Pure Async Function: can throw errors
  const meetingDataSyncBuffer = async () => {
    const meetingDataChangeDetected = meetingDataDifferenceCheck(
      meetingDataLatest,
      meetingDataBuffer
    );

    if (!meetingDataChangeDetected) {
      return;
    }
    
    const previousMeetingData = { ...meetingDataLatest };

    const meetingUpdateResponse = await improvedRequestUpdateEntry(
      DB_TABLE_MEETING,
      targetMeetingUuid,
      meetingDataBuffer
    );
    if (meetingUpdateResponse && meetingUpdateResponse.data) {
      const retrievedUpdatedMeetingData = meetingUpdateResponse.data;
      setMeetingDataLatest(retrievedUpdatedMeetingData);

      if (meetingInfoPreset === CalendarMeetingInfoPreset.EDIT) {
        await meetingDataUpdateNotificationSend(
          previousMeetingData,
          meetingChunkBuffer
        );
      }
    }
  };

  // Pure Async Function: can throw errors
  const meetingDataUpdate = async (chunk) => {
    const meetingDataChangeDetected = meetingDataDifferenceCheck(
      meetingDataLatest,
      chunk
    );

    if (!meetingDataChangeDetected) {
      return;
    }

    const previousMeetingData = { ...meetingDataLatest };

    const meetingGetResponse = await improvedRequestGetEntry(
      DB_TABLE_MEETING,
      targetMeetingUuid
    );
    if (meetingGetResponse && meetingGetResponse.data) {
      const retrievedMeetingData = meetingGetResponse.data;

      const updatedMeetingData = retrievedMeetingData;
      Object.entries(chunk).forEach(([key, value]) => {
        updatedMeetingData[key] = value;
      });

      const meetingUpdateResponse = await improvedRequestUpdateEntry(
        DB_TABLE_MEETING,
        targetMeetingUuid,
        updatedMeetingData
      );
      const retrievedUpdatedMeetingData = meetingUpdateResponse.data;

      setMeetingDataLatest(retrievedUpdatedMeetingData);

      if (meetingInfoPreset === CalendarMeetingInfoPreset.EDIT) {
        await meetingDataUpdateNotificationSend(previousMeetingData, chunk);
      }
    }
  };

  const meetingDataUpdateBuffer = (chunk) => {
    const meetingDataChangeDetected = meetingDataDifferenceCheck(
      meetingDataBuffer,
      chunk
    );

    if (!meetingDataChangeDetected) {
      return;
    }

    const newMeetingDataBuffer = { ...meetingDataBuffer };
    Object.entries(chunk).forEach(([key, value]) => {
      newMeetingDataBuffer[key] = value;
    });
    setMeetingDataBuffer(newMeetingDataBuffer);
  };

  // Pure Async Function: can throw errors
  // WARNING: this function will NOT update the assetLoading or assetLoadingError state!
  const meetingDataInit = async () => {
    const getResponse = await improvedRequestGetEntry(
      DB_TABLE_MEETING,
      targetMeetingUuid
    );
    const meetingData = getResponse.data;

    if (meetingData) {
      setMeetingDataLatest(meetingData);
      setMeetingDataBuffer(meetingData);
      setMeetingDataInitial(meetingData);

      if (presentUserUuid === meetingData["creator_user_uuid"]) {
        if (creatingMeeting) {
          setMeetingInfoPreset(CalendarMeetingInfoPreset.CREATE);
        } else {
          setMeetingInfoPreset(CalendarMeetingInfoPreset.EDIT);
        }
        // If the present user is the creator of the meeting, then they CANNOT request to join it (they should by default be a part of it).
      } else {
        const newPresentUserRequestedJoin =
          await calendarMeetingInfoCheckpointUserRequestedJoin(
            presentUserUuid,
            targetMeetingUuid
          );
        setPresentUserRequestedJoin(newPresentUserRequestedJoin);

        const newPresentUserInvited =
          await calendarMeetingInfoCheckpointUserInvited(
            presentUserUuid,
            targetMeetingUuid
          );
        setPresentUserInvited(newPresentUserInvited);
      }

      // Important: even though logically, the creator should already be in the meeting, this is NOT the case when the "dummy meeting" (meeting when 'completed' is false).
      // WHen the meeting is created, it is only then that the creator is added to the meeting.
      // This state is checked for that.
      const newPresentUserAttendingMeeting =
        await calendarMeetingInfoCheckpointUserAttending(
          presentUserUuid,
          targetMeetingUuid
        );
      setPresentUserAttendingMeeting(newPresentUserAttendingMeeting);
    }
  };

  useEffect(() => {
    setMeetingSubmissionReady(meetingSubmissionStatusCheck());
  }, [assetLoading, meetingDataUpdateFinished, errorMessageByRowKeyTable]);

  useEffect(() => {
    meetingDataInit()
      .then(() => {
        setAssetLoading(false);
        setTimeout(() => {
          setAssetVisible(true);
        }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
      })
      .catch((error) => {
        setAssetLoadingError(true);
      });
  }, []);

  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===
  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===
  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===

  // Pure Async Function: can throw errors
  const meetingHandleControlsCreate = async () => {
    const recentMeetingData = await calendarMeetingInfoCheckpointMeetingData(
      targetMeetingUuid
    );
    const recentPresentUserCreatorMatched =
      presentUserUuid === recentMeetingData["creator_user_uuid"];

    if (
      recentMeetingData["completed"] == false &&
      recentPresentUserCreatorMatched
    ) {

      const meetingAndUserSearchResponse = await improvedRequestSearchEntry(
        DB_TABLE_MEETING_AND_USER,
        {
          meeting_uuid: targetMeetingUuid,
          attending: false,
        }
      );

      if (meetingAndUserSearchResponse.data) {
        const meetingAndUserDataList =
          meetingAndUserSearchResponse.data["content"];
        const userUuidList = meetingAndUserDataList.map(
          (meetingAndUserData) =>
            meetingAndUserData["meeting_and_user_id"]["user_uuid"]
        );

        await Promise.all([
          meetingDataUpdate({
            completed: true,
          }),
          improvedRequestCreateEntry(DB_TABLE_MEETING_AND_USER, {
            user_uuid: presentUserUuid,
            meeting_uuid: targetMeetingUuid,
            attending: true,
          }),
        ])

        // NOTE: make sure that userUuidList does NOT contain the present user (creator of the meeting)!
        // The present user should NOT receive an invite to the meeting they created.
        await notificationCreate(
          notificationCreationTemplateMeetingParticipantLeaderInviteRequest(
            userUuidList,
            presentUserUuid,
            targetMeetingUuid,
            ""
          )
        );
        // NOTE: if the creation was NOT successful, it will NOT close the meeting.
        onClose?.();
      }
    }
  };

  // Pure Async Function: can throw errors
  const meetingHandleControlsDelete = async () => {
    const recentMeetingValid = await calendarMeetingInfoCheckpointMeetingValid(
      targetMeetingUuid
    );
    const recentMeetingData = await calendarMeetingInfoCheckpointMeetingData(
      targetMeetingUuid
    );
    const recentPresentUserCreatorMatched =
      presentUserUuid === recentMeetingData["creator_user_uuid"];

    // NOTE: deleting the meeting will NOT change the meeting-and-user entires associated with the meeting.
    // This is to PRESERVE the attendees in case the meeting is referenced later somehow.

    if (recentMeetingValid && recentPresentUserCreatorMatched) {
      // NOTE (9/1/2024): when using meetingDataUpdate(), strange errors occurred.
      // We will manually update the meeting data without meetingDataUpdate() to avoid the risk of components reacting poorly because the meeting was deleted.
      // Note that the components will react if meetingDataUpdate() is used, but not with this more manual method.

      const newMeetingData = { ...recentMeetingData };
      newMeetingData["soft_deleted"] = true;

      await improvedRequestUpdateEntry(
        DB_TABLE_MEETING,
        targetMeetingUuid,
        newMeetingData
      );

      // Target ATTENDING and NON-ATTENDING users and the MEETING LEADER.
      const searchMeetingAndUserResponse = await improvedRequestSearchEntry(
        DB_TABLE_MEETING_AND_USER,
        {
          meeting_uuid: targetMeetingUuid,
        }
      );

      const meetingAndUserDataList = searchMeetingAndUserResponse.data
        ? searchMeetingAndUserResponse.data["content"]
        : [];
      const userUuidList = meetingAndUserDataList.map(
        (meetingAndUserData) =>
          meetingAndUserData["meeting_and_user_id"]["user_uuid"]
      );
      const userUuidListExcludeMeetingCreator = userUuidList.filter(
        (userUuid) => userUuid !== recentMeetingData["creator_user_uuid"]
      );

      await Promise.all([
        notificationSetActionCompletedGivenUserUuidList(userUuidList, {
          second_resource_uuid: targetMeetingUuid,
          type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
        }),
        notificationSetActionCompleted({
          user_uuid: recentMeetingData["creator_user_uuid"],
          second_resource_uuid: targetMeetingUuid,
          type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
        }),
      ]);

      await notificationCreate(
        notificationCreationTemplateMeetingParticipantLeaderDeleteMeeting(
          userUuidListExcludeMeetingCreator,
          presentUserUuid,
          targetMeetingUuid,
          ""
        )
      );
    }
    // NOTE: will close regardless if the user successfully deleted the meeting.
    onClose?.();
  };

  // Pure Async Function: can throw errors
  const meetingHandleControlsRequestJoin = async () => {
    const recentMeetingValid = await calendarMeetingInfoCheckpointMeetingValid(
      targetMeetingUuid
    );

    const recentMeetingData = await calendarMeetingInfoCheckpointMeetingData(
      targetMeetingUuid
    );
    const recentPresentUserCreatorMatched =
      presentUserUuid === recentMeetingData["creator_user_uuid"];

    const recentPresentUserAttendingMeeting =
      await calendarMeetingInfoCheckpointUserAttending(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserAttendingMeeting(recentPresentUserAttendingMeeting);

    const recentPresentUserInvited =
      await calendarMeetingInfoCheckpointUserInvited(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserInvited(recentPresentUserInvited);

    const recentPresentUserRequestedJoin =
      await calendarMeetingInfoCheckpointUserRequestedJoin(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserRequestedJoin(recentPresentUserRequestedJoin);

    // NOTE: only NON-PUBLIC meetings require users to request to join.
    if (
      recentMeetingValid &&
      !recentPresentUserCreatorMatched &&
      !recentPresentUserAttendingMeeting &&
      !recentPresentUserInvited &&
      !recentPresentUserRequestedJoin &&
      recentMeetingData["mode_public"] == false
    ) {
      if (!recentPresentUserRequestedJoin) {
        setPresentUserRequestedJoin(true);
      }

      await notificationCreate(
        notificationCreationTemplateMeetingLeaderParticipantJoinRequest(
          presentUserUuid,
          recentMeetingData["creator_user_uuid"],
          targetMeetingUuid,
          ""
        )
      );
    }
  };

  // Pure Async Function: can throw errors
  const meetingHandleControlsDirectlyJoin = async () => {
    const recentMeetingValid = await calendarMeetingInfoCheckpointMeetingValid(
      targetMeetingUuid
    );

    const recentMeetingData = await calendarMeetingInfoCheckpointMeetingData(
      targetMeetingUuid
    );
    const recentPresentUserCreatorMatched =
      presentUserUuid === recentMeetingData["creator_user_uuid"];

    const recentPresentUserAttendingMeeting =
      await calendarMeetingInfoCheckpointUserAttending(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserAttendingMeeting(recentPresentUserAttendingMeeting);

    // NOTE: only PUBLIC meetings allow users to directly join.
    if (
      recentMeetingValid &&
      !recentPresentUserCreatorMatched &&
      !recentPresentUserAttendingMeeting &&
      recentMeetingData["mode_public"] == true
    ) {
      await improvedRequestCreateEntry(DB_TABLE_MEETING_AND_USER, {
        user_uuid: presentUserUuid,
        meeting_uuid: targetMeetingUuid,
        attending: true,
      });
      if (!recentPresentUserAttendingMeeting) {
        setPresentUserAttendingMeeting(true);
      }

      await notificationCreate(
        notificationCreationTemplateMeetingLeaderParticipantJoinDirect(
          presentUserUuid,
          recentMeetingData["creator_user_uuid"],
          targetMeetingUuid,
          ""
        )
      );
      await Promise.all([
        notificationSetActionCompleted({
          user_uuid: presentUserUuid,
          second_resource_uuid: targetMeetingUuid,
          type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
        }),
        notificationSetActionCompleted({
          user_uuid: recentMeetingData["creator_user_uuid"],
          first_resource_uuid: presentUserUuid,
          second_resource_uuid: targetMeetingUuid,
          type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
        }),
      ]);
    }
  };

  // Pure Async Function: can throw errors
  const meetingHandleControlsLeave = async () => {
    const recentMeetingValid = await calendarMeetingInfoCheckpointMeetingValid(
      targetMeetingUuid
    );
    const recentMeetingData = await calendarMeetingInfoCheckpointMeetingData(
      targetMeetingUuid
    );
    const recentPresentUserCreatorMatched =
      presentUserUuid === recentMeetingData["creator_user_uuid"];
    const recentPresentUserAttendingMeeting =
      await calendarMeetingInfoCheckpointUserAttending(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserAttendingMeeting(recentPresentUserAttendingMeeting);

    if (
      recentMeetingValid &&
      !recentPresentUserCreatorMatched &&
      recentPresentUserAttendingMeeting
    ) {
      const deleteResponse = await improvedRequestDeleteEntryAssociation(
        DB_TABLE_MEETING_AND_USER,
        targetMeetingUuid,
        presentUserUuid
      );
      if (deleteResponse && deleteResponse.data) {
        if (recentPresentUserAttendingMeeting) {
          setPresentUserAttendingMeeting(false);
        }
        await notificationCreate(
          notificationCreationTemplateMeetingLeaderParticipantLeave(
            presentUserUuid,
            recentMeetingData["creator_user_uuid"],
            targetMeetingUuid,
            ""
          )
        );
        await Promise.all([
          notificationSetActionCompleted({
            user_uuid: presentUserUuid,
            second_resource_uuid: targetMeetingUuid,
            type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
          }),
          notificationSetActionCompleted({
            user_uuid: recentMeetingData["creator_user_uuid"],
            first_resource_uuid: presentUserUuid,
            second_resource_uuid: targetMeetingUuid,
            type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
          }),
        ]);
      }
    }
  };

  // Pure Async Function: can throw errors
  const meetingHandleControlsInviteAccept = async () => {
    const recentMeetingValid = await calendarMeetingInfoCheckpointMeetingValid(
      targetMeetingUuid
    );
    const recentMeetingData = await calendarMeetingInfoCheckpointMeetingData(
      targetMeetingUuid
    );

    const recentPresentUserCreatorMatched =
      presentUserUuid === recentMeetingData["creator_user_uuid"];

    const recentPresentUserAttendingMeeting =
      await calendarMeetingInfoCheckpointUserAttending(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserAttendingMeeting(recentPresentUserAttendingMeeting);

    const recentPresentUserInvited =
      await calendarMeetingInfoCheckpointUserInvited(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserInvited(recentPresentUserInvited);

    if (
      recentMeetingValid &&
      !recentPresentUserCreatorMatched &&
      !recentPresentUserAttendingMeeting &&
      recentPresentUserInvited
    ) {
      await meetingParticipantLeaderInviteRequestAccept(
        presentUserUuid,
        recentMeetingData["creator_user_uuid"],
        targetMeetingUuid
      );

      setPresentUserRequestedJoin(false);
      if (recentPresentUserInvited) {
        setPresentUserInvited(false);
      }
      if (!presentUserAttendingMeeting) {
        setPresentUserAttendingMeeting(true);
      }
    }
  };

  // Pure Async Function: can throw errors
  const meetingHandleControlsInviteDeny = async () => {
    const recentMeetingValid = await calendarMeetingInfoCheckpointMeetingValid(
      targetMeetingUuid
    );
    const recentMeetingData = await calendarMeetingInfoCheckpointMeetingData(
      targetMeetingUuid
    );

    const recentPresentUserCreatorMatched =
      presentUserUuid === recentMeetingData["creator_user_uuid"];

    const recentPresentUserAttendingMeeting =
      await calendarMeetingInfoCheckpointUserAttending(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserAttendingMeeting(recentPresentUserAttendingMeeting);

    const recentPresentUserInvited =
      await calendarMeetingInfoCheckpointUserInvited(
        presentUserUuid,
        targetMeetingUuid
      );
    setPresentUserInvited(recentPresentUserInvited);

    if (
      recentMeetingValid &&
      !recentPresentUserCreatorMatched &&
      !recentPresentUserAttendingMeeting &&
      recentPresentUserInvited
    ) {
      await meetingParticipantLeaderInviteRequestDeny(
        presentUserUuid,
        recentMeetingData["creator_user_uuid"],
        targetMeetingUuid
      );

      setPresentUserRequestedJoin(false);
      if (recentPresentUserInvited) {
        setPresentUserInvited(false);
      }
    }
  };

  const CONTROL_HANDLER_TABLE = {};
  CONTROL_HANDLER_TABLE[CalendarMeetingInfoControlsActionType.CREATE] =
    meetingHandleControlsCreate;
  CONTROL_HANDLER_TABLE[CalendarMeetingInfoControlsActionType.DELETE] =
    meetingHandleControlsDelete;
  CONTROL_HANDLER_TABLE[CalendarMeetingInfoControlsActionType.LEAVE] =
    meetingHandleControlsLeave;
  CONTROL_HANDLER_TABLE[CalendarMeetingInfoControlsActionType.DIRECTLY_JOIN] =
    meetingHandleControlsDirectlyJoin;
  CONTROL_HANDLER_TABLE[CalendarMeetingInfoControlsActionType.REQUEST_JOIN] =
    meetingHandleControlsRequestJoin;
  CONTROL_HANDLER_TABLE[CalendarMeetingInfoControlsActionType.ACCEPT_INVITE] =
    meetingHandleControlsInviteAccept;
  CONTROL_HANDLER_TABLE[CalendarMeetingInfoControlsActionType.DENY_INVITE] =
    meetingHandleControlsInviteDeny;

  const meetingHandleControlsAction = (controlsActionType) => {
    if (actionControlsGeneralHandling) {
      return;
    }
    setActionControlsGeneralHandling(true);

    const asyncHandlerFunction = CONTROL_HANDLER_TABLE[controlsActionType];
    asyncHandlerFunction()
      .then(() => {
        setActionControlsGeneralHandling(false);
      })
      .catch((error) => {
        setActionControlsGeneralHandling(false);
      });
  };

  // NOTE (9/2/2024): make this more scalable like meetingHandleControlsAction later
  const meetingHandleControlsMinorAction = (controlsMinorActionType) => {
    switch (controlsMinorActionType) {
      case CalendarMeetingInfoControlsMinorActionType.CLOSE:
        onClose?.();
        break;
      case CalendarMeetingInfoControlsMinorActionType.SHARE_MEETING:
        if (!meetingLinkCopiedClipboard) {
          const meetingUrl = `${window.location.origin}/meeting/${targetMeetingUuid}`;
          navigator.clipboard
            .writeText(meetingUrl)
            .then(() => {
              setMeetingLinkCopiedClipboard(true);
            })
            .catch((error) => {});
        }
        break;
    }
  };

  const meetingProcessUpdateMeetingData = useCallback(() => {
    meetingDataSyncBuffer()
      .then(() => {
        setMeetingDataUpdateFinished(true);
      })
      .catch((error) => {
        setMeetingDataUpdateFinished(true);
      });
  }, [meetingDataBuffer]);

  const meetingDebounceUpdateMeetingData = useMemo(
    () =>
      debounce(
        meetingProcessUpdateMeetingData,
        DEBOUNCE_PROPERTY_TIMEOUT_DURATION_MS
      ),
    [meetingProcessUpdateMeetingData]
  );

  useEffect(() => {
    meetingDebounceUpdateMeetingData();

    return () => {
      meetingDebounceUpdateMeetingData.cancel();
    };
  }, [meetingDataBuffer]);

  const meetingHandleUpdateMeetingData = (chunk) => {
    meetingDataUpdateBuffer(chunk);
    setMeetingChunkBuffer(chunk);
    setMeetingDataUpdateFinished(false);
  };

  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===
  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===
  // ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===  ===

  return (
    <ThemeProvider theme={calendarMeetingInfoSuperPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name="CalendarMeetingInfoSuperPane" />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          <CalendarMeetingInfoInputSuperPane
            meetingInfoPreset={meetingInfoPreset}
            meetingData={meetingDataBuffer}
            meetingDataInitial={meetingDataInitial}
            presentUserUuid={presentUserUuid}
            targetMeetingUuid={targetMeetingUuid}
            presentUserAttendingMeeting={presentUserAttendingMeeting}
            presentUserRequestedJoin={presentUserRequestedJoin}
            presentUserInvited={presentUserInvited}
            meetingSubmissionReady={meetingSubmissionReady}
            errorMessageByRowKeyTable={errorMessageByRowKeyTable}
            onControlsAction={(controlsActionType) =>
              meetingHandleControlsAction(controlsActionType)
            }
            onControlsMinorAction={(controlsMinorActionType) =>
              meetingHandleControlsMinorAction(controlsMinorActionType)
            }
            onUpdateMeetingData={meetingHandleUpdateMeetingData}
            onSetErrorMessage={meetingErrorMessageSet}
            onRemoveErrorMessage={meetingErrorMessageRemove}
            meetingLinkCopiedClipboard={meetingLinkCopiedClipboard}
          />
        </Box>
      )}
    </ThemeProvider>
  );
}
