// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import {
  DATE_FRONTEND_FORMAT_MONTH_ONLY,
  DATE_FRONTEND_FORMAT_YEAR_ONLY,
  dateConvertToString,
} from "../../../service/browser/DateService";

export const makeCalendarDatePaneThemeDesignTokens = (mode) => ({
  typography: {
    bold: {
      fontFamily: "Source Serif Pro",
      fontSize: 48,
      fontWeight: 600,
      color: purple[900],
    },
    regular: {
      fontFamily: "Source Serif Pro",
      fontSize: 48,
      fontWeight: 400,
      color: purple[900],
    },
  },
});

export function makeCalendarDatePaneTheme(mode) {
  return {
    ...makeCalendarDatePaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarDatePane({ date }) {
  const calendarDatePaneTheme = createTheme(makeCalendarDatePaneTheme("light"));

  const HARD_CODE_MONTH = dateConvertToString(
    date,
    DATE_FRONTEND_FORMAT_MONTH_ONLY
  );
  const HARD_CODE_YEAR = dateConvertToString(
    date,
    DATE_FRONTEND_FORMAT_YEAR_ONLY
  );

  return (
    <ThemeProvider theme={calendarDatePaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <Typography variant="bold">{HARD_CODE_MONTH}</Typography>
        <Typography variant="regular">{HARD_CODE_YEAR}</Typography>
      </Box>
    </ThemeProvider>
  );
}
