// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext } from "react";

import SurroundingControls from "../../special/SurroundingControls";
import InboxSuperPane from "../../super_pane/inbox/InboxSuperPane";
import { UserContext } from "../../../context/UserContext";

export const makeInboxPageThemeDesignTokens = (mode) => ({});

export function makeInboxPageTheme(mode) {
  return {
    ...makeInboxPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function InboxPage({}) {
  const inboxPageTheme = createTheme(makeInboxPageTheme("light"));
  const { presentUserUuid } = useContext(UserContext);

  return (
    <ThemeProvider theme={inboxPageTheme}>
      <SurroundingControls>
        <InboxSuperPane presentUserUuid={presentUserUuid} />
      </SurroundingControls>
    </ThemeProvider>
  );
}
