// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import ButtonRectangleNarrowContainer from "../../component/shared/button/ButtonRectangleNarrowContainer";
import IconNavigationArrowThin from "../../icon/IconNavigationArrowThin";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";

export const makeCalendarControlsPaneThemeDesignTokens = (mode) => ({});

export function makeCalendarControlsPaneTheme(mode) {
  return {
    ...makeCalendarControlsPaneThemeDesignTokens(mode),
    components: {},
  };
}

export const CalendarViewType = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  AGENDA: "Agenda"
}

export default function CalendarControlsPane({
  onUpdateView,
  onViewLeft,
  onViewRight,
  onViewToday,
}) {
  const calendarControlsPaneTheme = createTheme(
    makeCalendarControlsPaneTheme("light")
  );

  const [selectedTimeViewName, setSelectedTimeViewName] = useState(CalendarViewType.WEEK);

  const relativeControlsShowing = selectedTimeViewName !== CalendarViewType.AGENDA;

  const handleTimeViewButtonClick = (newTimeViewName) => {
    setSelectedTimeViewName(newTimeViewName);
    onUpdateView?.(newTimeViewName);
  };

  const handleLeftButtonClick = () => {
    onViewLeft?.();
  };

  const handleRightButtonClick = () => {
    onViewRight?.();
  };

  const handleTodayButtonClick = () => {
    onViewToday?.();
  };

  const generateArrowIcon = (flippedRight) => {
    return (
      <IconNavigationArrowThin
        flippedRight={flippedRight}
        width={"12px"}
        height={"24px"}
        stroke={gray[900]}
        strokeWidth={"1px"}
        opacity={0.7}
        onHoverOpacity={0.7}
      />
    );
  };

  const BUTTON_FONT_COLOR = alpha(gray[900], 0.7);

  const generateArrowButton = (flippedRight) => {
    const handleClick = flippedRight
      ? handleRightButtonClick
      : handleLeftButtonClick;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <ButtonRectangleNarrowContainer
          roundedCornerLeft={!flippedRight}
          roundedCornerRight={flippedRight}
          onClick={handleClick}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {generateArrowIcon(flippedRight)}
          </Box>
        </ButtonRectangleNarrowContainer>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={calendarControlsPaneTheme}>
      <Grid container direction={"column"} rowGap={"4px"}>
        {/* Top Row */}
        <Grid container item>
          {Object.values(CalendarViewType).map((timeViewName, index) => (
            timeViewName !== CalendarViewType.AGENDA && <Grid item xs key={timeViewName}>
              <ButtonRectangleNarrowLabel
                label={timeViewName}
                labelFontSize="12px"
                labelFontColor={BUTTON_FONT_COLOR}
                showNumber={false}
                selected={timeViewName === selectedTimeViewName}
                onClick={() => handleTimeViewButtonClick(timeViewName)}
                roundedCornerLeft={index === 0}
                roundedCornerRight={index === Object.values(CalendarViewType).length - 1}
                fullWidth={true}
                boxHorizontalPadding="4px"
              />
            </Grid>
          ))}
        </Grid>

        {/* Bottom Row */}
        <Grid
          container
          item
          direction={"row"}
          alignItems={"center"}
          sx={{
            ...(!relativeControlsShowing && {
              opacity: 0,
              pointerEvents: "none",
            }),
          }}
        >
          <Grid item xs={2}>
            {generateArrowButton(false)}
          </Grid>
          <Grid item xs>
            <ButtonRectangleNarrowLabel
              label={"Today"}
              labelFontSize="16px"
              labelFontColor={BUTTON_FONT_COLOR}
              showNumber={false}
              selected={false}
              onClick={() => handleTodayButtonClick()}
              roundedCornerLeft={false}
              roundedCornerRight={false}
              fullWidth={true}
              boxHorizontalPadding="4px"
            />
          </Grid>
          <Grid item xs={2}>
            {generateArrowButton(true)}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
