// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext } from "react";
import SurroundingControls from "../../special/SurroundingControls";
import CalendarSuperPane from "../../super_pane/calendar/CalendarSuperPane";
import { UserContext } from "../../../context/UserContext";

export const makeCalendarPageThemeDesignTokens = (mode) => ({});

export function makeCalendarPageTheme(mode) {
  return {
    ...makeCalendarPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarPage({}) {
  const calendarPageTheme = createTheme(makeCalendarPageTheme("light"));

  const {
    authenticated,
    setAuthenticated,
    presentSessionUuid,
    setPresentSessionUuid,
    presentUserUuid,
    setPresentUserUuid,
  } = useContext(UserContext);

  return (
    <ThemeProvider theme={calendarPageTheme}>
      <SurroundingControls>
        <CalendarSuperPane presentUserUuid={presentUserUuid} />
      </SurroundingControls>
    </ThemeProvider>
  );
}
