// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
  CalendarMeetingInfoRowClassAndRecurring,
  CalendarMeetingInfoRowControls,
  CalendarMeetingInfoRowControlsMinor,
  CalendarMeetingInfoRowDescription,
  CalendarMeetingInfoRowExtraInfo,
  CalendarMeetingInfoRowInviteFriends,
  CalendarMeetingInfoRowLocation,
  CalendarMeetingInfoRowName,
  CalendarMeetingInfoRowParticipantCount,
  CalendarMeetingInfoRowTime,
} from "../../component/calendar_meeting_info/CalendarMeetingInfoRowComponentCollection";
import { MEETING_INFO_PANE_INNER_CENTER_ROW_WIDTH } from "../../component/calendar_meeting_info/CalendarMeetingInfoComponentCollection";
import TextHeadingLarge from "../../component/shared/text/TextHeadingLarge";
import {
  componentWidthCalculate,
  componentWidthSet,
} from "../../../service/browser/ComponentService";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";
import LobbyProfilePortraitPane from "../../pane/lobby/LobbyProfilePortraitPane";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import { CalendarMeetingInfoPreset } from "./CalendarMeetingInfoSuperPane";

export const makeCalendarMeetingInfoInputSuperPaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputSuperPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarMeetingInfoInputSuperPane({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  presentUserUuid,
  targetMeetingUuid,
  presentUserAttendingMeeting,
  presentUserRequestedJoin,
  presentUserInvited,
  meetingSubmissionReady,
  errorMessageByRowKeyTable,
  onUpdateMeetingData,
  onControlsAction,
  onControlsMinorAction,
  onSetErrorMessage,
  onRemoveErrorMessage,
  meetingLinkCopiedClipboard
}) {
  const calendarMeetingInfoInputSuperPaneTheme = createTheme(
    makeCalendarMeetingInfoInputSuperPaneTheme("light")
  );

  const mainBoxRef = useRef(null);
  const [mainBoxWidth, setMainBoxWidth] = useState("250px");

  const componentUpdateWidth = () => {
    componentWidthSet(
      setMainBoxWidth,
      componentWidthCalculate(mainBoxRef, -30, 250)
    );
  };

  useEffect(() => {
    window.addEventListener("resize", componentUpdateWidth);

    const resizeObserver = new ResizeObserver(componentUpdateWidth);
    resizeObserver.observe(mainBoxRef.current);

    return () => {
      window.removeEventListener("resize", componentUpdateWidth);
    };
  }, []);

  return (
    <ThemeProvider theme={calendarMeetingInfoInputSuperPaneTheme}>
      <Box
        ref={mainBoxRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: mainBoxWidth,
          height: "100%",
          paddingTop: "20px",
          gap: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              gap: "5px",
            }}
          >
            <CalendarMeetingInfoRowControlsMinor
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              onControlsMinorAction={onControlsMinorAction}
              meetingLinkCopiedClipboard={meetingLinkCopiedClipboard}
            />
            <TextHeadingLarge label="Study Group" />
            <TextHeadingMediumA label="Group Leader" />
            <LobbyProfilePortraitPane
              targetUserUuid={meetingData["creator_user_uuid"]}
              scale={0.5}
              userNameLinked={true}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              paddingBottom: "20px",
              gap: "20px",
            }}
          >
            <CalendarMeetingInfoRowName
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              errorMessageByRowKeyTable={errorMessageByRowKeyTable}
              onUpdateMeetingData={onUpdateMeetingData}
              onSetErrorMessage={onSetErrorMessage}
              onRemoveErrorMessage={onRemoveErrorMessage}
            />
            <CalendarMeetingInfoRowTime
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              errorMessageByRowKeyTable={errorMessageByRowKeyTable}
              onUpdateMeetingData={onUpdateMeetingData}
              onSetErrorMessage={onSetErrorMessage}
              onRemoveErrorMessage={onRemoveErrorMessage}
            />
            <CalendarMeetingInfoRowClassAndRecurring
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              presentUserUuid={presentUserUuid}
              onUpdateMeetingData={onUpdateMeetingData}
            />
            <CalendarMeetingInfoRowLocation
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              errorMessageByRowKeyTable={errorMessageByRowKeyTable}
              onUpdateMeetingData={onUpdateMeetingData}
              onSetErrorMessage={onSetErrorMessage}
              onRemoveErrorMessage={onRemoveErrorMessage}
            />
            <CalendarMeetingInfoRowDescription
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              onUpdateMeetingData={onUpdateMeetingData}
            />
            <CalendarMeetingInfoRowExtraInfo
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              onUpdateMeetingData={onUpdateMeetingData}
            />
            <CalendarMeetingInfoRowInviteFriends
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              presentUserUuid={presentUserUuid}
              targetMeetingUuid={targetMeetingUuid}
            />
            <CalendarMeetingInfoRowParticipantCount
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              onUpdateMeetingData={onUpdateMeetingData}
            />
            <CalendarMeetingInfoRowControls
              meetingInfoPreset={meetingInfoPreset}
              meetingData={meetingData}
              meetingDataInitial={meetingDataInitial}
              onControlsAction={onControlsAction}
              presentUserAttendingMeeting={presentUserAttendingMeeting}
              presentUserRequestedJoin={presentUserRequestedJoin}
              presentUserInvited={presentUserInvited}
              meetingSubmissionReady={meetingSubmissionReady}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
