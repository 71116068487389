// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useRef, useEffect } from "react";
import SettingsPrivacyPolicyPane from "../../pane/settings/SettingsPrivacyPolicyPane";
import {
  componentHeightCalculate,
  componentHeightSet,
} from "../../../service/browser/ComponentService";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";

export const makeLobbyProfilePrivacyPolicySuperPaneThemeDesignTokens = (
  mode
) => ({});

export function makeLobbyProfilePrivacyPolicySuperPaneTheme(mode) {
  return {
    ...makeLobbyProfilePrivacyPolicySuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function LobbyProfilePrivacyPolicySuperPane({
  targetUserUuid,
  onDecline,
  onAccept,
}) {
  const lobbyProfilePrivacyPolicySuperPaneTheme = createTheme(
    makeLobbyProfilePrivacyPolicySuperPaneTheme("light")
  );

  const contentBoxRef = useRef(null);
  const [contentBoxHeight, setContentBoxHeight] = useState("250px");

  const updateHeight = () => {
    componentHeightSet(
      setContentBoxHeight,
      componentHeightCalculate(contentBoxRef, -100, 250)
    );
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(contentBoxRef.current);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <ThemeProvider theme={lobbyProfilePrivacyPolicySuperPaneTheme}>
      <Box
        ref={contentBoxRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "12px",
          gap: "12px"
        }}
      >
        <Box
          sx={{
            width: "60%",
            height: contentBoxHeight,
          }}
        >
          <SettingsPrivacyPolicyPane targetUserUuid={targetUserUuid} />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "24px",
          }}
        >
          <ButtonRectangleLabel
            variant="success"
            label="Accept"
            onClick={() => onAccept?.()}
          />
          <ButtonRectangleLabel
            variant="critical"
            label="Decline"
            onClick={() => onDecline?.()}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
