// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import ButtonCheckBox from "../../component/shared/button/ButtonCheckBox";

export const makeCalendarMeetingInfoInputCheckBoxPaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputCheckBoxPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputCheckBoxPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarMeetingInfoInputCheckBoxPane({
  initialValue,
  onChangeValue,
  modifiable,
  width,
}) {
  const calendarMeetingInfoInputCheckBoxPaneTheme = createTheme(
    makeCalendarMeetingInfoInputCheckBoxPaneTheme("light")
  );

  const [checked, setChecked] = useState(initialValue ? true : false);

  const handleButtonClicked = () => {
    if (modifiable) {
      onChangeValue?.(!checked);
      setChecked(!checked);
    }
  };

  return (
    <ThemeProvider theme={calendarMeetingInfoInputCheckBoxPaneTheme}>
      <ButtonCheckBox
        boxWidth={width}
        outlineEnabled={true}
        fillEnabled={false}
        checked={checked}
        onClick={handleButtonClicked}
      />
    </ThemeProvider>
  );
}
