// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";

// Interactive Assets
import { Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../../style/AppTheme"

// Extra
import * as React from "react";

// StudiousCat Components
// ...

export const makeSeparatorThemeDesignTokens = (mode) => ({});

export function makeSeparatorTheme(mode) {
  return {
    ...makeSeparatorThemeDesignTokens(mode),
    components: {},
  };
}

export default function Separator({
  horizontal = true,
  fullLength = true,
  length = "10px",
  color = gray[200],
}) {
  const separatorTheme = createTheme(makeSeparatorTheme("light"));

  const thickness = horizontal ? "1px" : "2px"

  return (
    <ThemeProvider theme={separatorTheme}>
      {horizontal && (
        <Box
          sx={{
            display: 'block',
            width: length,
            ...(fullLength && { width: "100%" }),
            minHeight: thickness,
            height: thickness,
            backgroundColor: color,
          }}
        ></Box>
      )}
      {!horizontal && (
        <Box
          sx={{
            display: 'block',
            width: thickness,
            ...(!fullLength && { height: length }),
            backgroundColor: color,
          }}
        ></Box>
      )}
    </ThemeProvider>
  );
}
