// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import InboxNotificationPane from "../../pane/inbox/InboxNotificationPane";

export const makeInboxSuperPaneThemeDesignTokens = (mode) => ({});

export function makeInboxSuperPaneTheme(mode) {
  return {
    ...makeInboxSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function InboxSuperPane({ presentUserUuid }) {
  const inboxSuperPaneTheme = createTheme(makeInboxSuperPaneTheme("light"));

  return (
    <ThemeProvider theme={inboxSuperPaneTheme}>
      <InboxNotificationPane presentUserUuid={presentUserUuid} />
    </ThemeProvider>
  );
}
