// Theme and Style
import { createTheme, Popover, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import TextHeadingMediumA from "../shared/text/TextHeadingMediumA";
import TextBodySmall from "../shared/text/TextBodySmall";
import StatusMessage from "../other/StatusMessage";

export const MEETING_INFO_PANE_INNER_CENTER_ROW_WIDTH = "800px";
export const MEETING_INFO_PANE_LABEL_COLUMN_WIDTH = "230px";

export function CalendarMeetingInfoPopupBackground({
  children,
  anchorEl,
  onClose,
}) {
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  const handleClosePopover = () => {
    onClose?.();
  };

  return (
    <Popover
      id={popoverId}
      open={popoverOpen}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      {children}
    </Popover>
  );
}

export function CalendarMeetingInfoLabelColumn({ label, opacity = 1.0 }) {
  return (
    <Box
      sx={{
        minWidth: MEETING_INFO_PANE_LABEL_COLUMN_WIDTH,
        opacity: opacity,
        textAlign: "right",
      }}
    >
      <TextHeadingMediumA label={label} />
    </Box>
  );
}

export function CalendarMeetingInfoLabelRow({ label }) {
  return (
    <Box
      sx={{
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "right",
      }}
    >
      <TextHeadingMediumA label={label} />
    </Box>
  );
}

export function CalendarMeetingInfoRowWrapper({
  children,
  rowLabel,
  rowLabelCounterBalanced,
  rowLabelCentered,
  fixedWidth = true,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: rowLabelCentered ? "center" : "flex-start",
        gap: "15px",
        width: "100%",
      }}
    >
      {rowLabel && (
        <CalendarMeetingInfoLabelColumn label={rowLabel} opacity={1.0} />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          ...(fixedWidth && { minWidth: MEETING_INFO_PANE_INNER_CENTER_ROW_WIDTH }),
          ...(fixedWidth && { maxWidth: MEETING_INFO_PANE_INNER_CENTER_ROW_WIDTH }),
          gap: "15px",
        }}
      >
        {children}
      </Box>
      {rowLabel && rowLabelCounterBalanced && (
        <CalendarMeetingInfoLabelColumn label={rowLabel} opacity={0.0} />
      )}
    </Box>
  );
}
export function CalendarMeetingInfoRowItemWrapper({ children, flexGrow = 1 }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        flexGrow: flexGrow,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}

export function CalendarMeetingInfoStatusMessageWrapper({
  children,
  noteMessage,
  statusType,
  statusMessage,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        width: "100%",
      }}
    >
      {children}
      {noteMessage && (
        <Box
          sx={{
            width: MEETING_INFO_PANE_INNER_CENTER_ROW_WIDTH,
            textAlign: "center",
          }}
        >
          <TextBodySmall label={noteMessage} />
        </Box>
      )}
      {statusMessage && (
        <Box
          sx={{
            width: MEETING_INFO_PANE_INNER_CENTER_ROW_WIDTH,
          }}
        >
          <StatusMessage type={statusType} message={statusMessage} />
        </Box>
      )}
    </Box>
  );
}
