// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import SurroundingControls from "../../special/SurroundingControls";
import HelpSupportAndFeedbackSuperPane from "../../super_pane/help/HelpSupportAndFeedbackSuperPane";

export const makeHelpSupportAndFeedbackPageThemeDesignTokens = (mode) => ({});

export function makeHelpSupportAndFeedbackPageTheme(mode) {
  return {
    ...makeHelpSupportAndFeedbackPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function HelpSupportAndFeedbackPage({}) {
  const helpSupportAndFeedbackPageTheme = createTheme(
    makeHelpSupportAndFeedbackPageTheme("light")
  );

  return (
    <ThemeProvider theme={helpSupportAndFeedbackPageTheme}>
      <SurroundingControls>
        <HelpSupportAndFeedbackSuperPane />
      </SurroundingControls>
    </ThemeProvider>
  );
}
