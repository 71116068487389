export const listToObjectByKey = (list, key) => {
  const objectByKey = {};
  list.forEach((item) => {
    if (!(item[key] in objectByKey)) {
      objectByKey[item[key]] = item;
    }
  });
  return objectByKey;
};


export const varListAddItem = (item, list) => {
  const copyList = [...list];
  copyList.push(item);
  return copyList
};

export const varListRemoveItemByValue = (
  itemValue,
  list
) => {
  const copyList = [...list];
  copyList.splice(copyList.indexOf(itemValue), 1);
  return copyList;
};


export const stateListAddItem = (item, listState, setListState) => {
  const copyList = [...listState];
  copyList.push(item);
  setListState(copyList);
};

export const stateListRemoveItemByValue = (
  itemValue,
  listState,
  setListState
) => {
  const copyList = [...listState];
  copyList.splice(copyList.indexOf(itemValue), 1);
  setListState(copyList);
};

export const stateObjectSetItem = (key, value, objectState, setObjectState) => {
  const copyObject = {...objectState}
  copyObject[key] = value
  setObjectState(copyObject);
}

export const stateObjectDeleteItem = (key, objectState, setObjectState) => {
  const copyObject = {...objectState}
  if(key in copyObject) {
    delete copyObject[key]
  }
  setObjectState(copyObject);
}

export const objectToListConcatenateByKeyList = (object, keyList, separator) => {
    return keyList.map(key => object[key]).join(separator)
}

export const randomColorHexString = () => {
    // Generate a random number between 0 and 16777215 (0xFFFFFF)
    const randomColor = Math.floor(Math.random() * 16777215);
    // Convert to hex and pad with leading zeros if necessary
    const hexColor = randomColor.toString(16).padStart(6, '0');
    return hexColor;
}