// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green, blue } from "../../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";

export const makeButtonRectangleNarrowContainerThemeDesignTokens = (
  mode
) => ({});

export function makeButtonRectangleNarrowContainerTheme(mode) {
  return {
    ...makeButtonRectangleNarrowContainerThemeDesignTokens(mode),
    components: {},
  };
}

export default function ButtonRectangleNarrowContainer({
  selected = false,
  onClick,
  roundedCornerLeft = true,
  roundedCornerRight = true,
  fullWidth = true,
  boxHorizontalPadding = "12px",
  children
}) {
  const buttonRectangleNarrowContainerTheme = createTheme(
    makeButtonRectangleNarrowContainerTheme("light")
  );

  const handleClick = () => {
      onClick?.();
  };

  var color = alpha(gray[200], 0.5);
  if (selected) {
    color = alpha(blue[100], 1);
  }

  const BORDER_RADIUS = "3px";

  return (
    <ThemeProvider theme={buttonRectangleNarrowContainerTheme}>
      <Box
        onClick={handleClick}
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          boxSizing: "border-box",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
          borderTopLeftRadius: `${roundedCornerLeft ? BORDER_RADIUS : "0px"}`,
          borderBottomLeftRadius: `${roundedCornerLeft ? BORDER_RADIUS : "0px"}`,
          borderTopRightRadius: `${roundedCornerRight ? BORDER_RADIUS : "0px"}`,
          borderBottomRightRadius: `${
            roundedCornerRight ? BORDER_RADIUS : "0px"
          }`,
          borderBottom: "1px solid",
          borderColor: gray[200],
          backgroundColor: color,
          paddingInline: boxHorizontalPadding,
          paddingBlock: "2px",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "6px",
          ...(fullWidth && { width: "100%" }),
          transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            backgroundColor: alpha(blue[100], 0.5),
          },
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
}
