// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import HelpTutorialSuperPane from "../../super_pane/help/HelpTutorialSuperPane";
import SurroundingControls from "../../special/SurroundingControls";

export const makeHelpTutorialPageThemeDesignTokens = (mode) => ({});

export function makeHelpTutorialPageTheme(mode) {
  return {
    ...makeHelpTutorialPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function HelpTutorialPage({}) {
  const helpTutorialPageTheme = createTheme(makeHelpTutorialPageTheme("light"));

  return (
    <ThemeProvider theme={helpTutorialPageTheme}>
      <SurroundingControls>
        <HelpTutorialSuperPane />
      </SurroundingControls>
    </ThemeProvider>
  );
}
