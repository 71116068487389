// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Fab } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, blue, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ProfileEndorsementCollectionItem from "../../component/profile/ProfileEndorsementCollectionItem";
import Separator from "../../component/shared/other/Separator";
import {
  DB_TABLE_USER,
  DB_TABLE_USER_ENDORSEMENT,
  DB_TABLE_USER_NOTIFICATION,
  DB_TABLE_USER_REACTION,
  improvedRequestCreateEntry,
  improvedRequestSearchEntry,
  requestCreateEntry,
  requestGetEntryList,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import tempProfilePictureA from "../../asset/image/placeholder/profile_picture/sample-pfp-amir.png";

import { debounce } from "lodash";
import {
  DATE_BACKEND_FORMAT,
  DATE_FRONTEND_FORMAT_LONG,
  DATE_FRONTEND_FORMAT_SHORT,
  dateConvertToObject,
} from "../../../service/browser/DateService";
import InputText from "../../component/shared/input/InputText";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import {
  notificationCreate,
  NotificationType,
} from "../../../service/user/NotificationService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import { notificationCreationTemplateUserEndorsementRequest } from "../../../service/user/NotificationCreationTemplateService";

export const makeProfileEndorsementPaneThemeDesignTokens = (mode) => ({
  typography: {
    statusMessageText: {
      fontFamily: "PT Sans",
      fontSize: 14,
      fontWeight: 600,
    },
  },
});

export function makeProfileEndorsementPaneTheme(mode) {
  return {
    ...makeProfileEndorsementPaneThemeDesignTokens(mode),
    components: {},
  };
}

function ProfileButtonEndorsementAdd({
  length = "32px",
  showClose = false,
  onClick,
}) {
  return (
    <Fab
      onClick={() => onClick?.()}
      size="small"
      sx={{
        minWidth: length,
        maxWidth: length,
        minHeight: length,
        maxHeight: length,
        border: "1px solid black",
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: alpha(blue[200], 0.2),
        },
      }}
    >
      {showClose ? <AddIcon /> : <CloseIcon />}
    </Fab>
  );
}

export default function ProfileEndorsementPane({
  presentUserUuid,
  targetUserUuid,
  endorsementListMaxHeight,
}) {
  const profileEndorsementPaneTheme = createTheme(
    makeProfileEndorsementPaneTheme("light")
  );

  const modifiable = presentUserUuid != targetUserUuid;

  const UserSubmissionStatus = {
    SUBMITTED_AND_VISIBLE: "SUBMITTED_AND_VISIBLE",
    SUBMITTED_AND_AWAITING_APPROVAL: "SUBMITTED_AND_AWAITING_APPROVAL",
    NO_SUBMISSION: "NO_SUBMISSION",
    ERROR: "ERROR",
  };

  const StatusMessageType = {
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
  };

  const ColorByStatusMessageType = {
    ERROR: {
      border: alpha(red[600], 0.8),
      fill: alpha(red[400], 0.8),
      text: gray[900],
    },
    SUCCESS: {
      border: alpha(green[600], 0.8),
      fill: alpha(green[400], 0.8),
      text: gray[900],
    },
  };

  const sortUserEndorsementDataListByDate = (givenUserEndorsementDataList) => {
    return givenUserEndorsementDataList.sort(
      (userEndorsementDataA, userEndorsementDataB) => {
        const dateA = dateConvertToObject(
          userEndorsementDataA["creation_date_time"],
          DATE_BACKEND_FORMAT
        );
        const dateB = dateConvertToObject(
          userEndorsementDataB["creation_date_time"],
          DATE_BACKEND_FORMAT
        );
        return dateB.getTime() - dateA.getTime();
      }
    );
  };

  const BUSY_TIMEOUT_DURATION_MS = 150;
  const DEBOUNCE_TIMEOUT_DURATION_MS = 400;
  const ENDORSEMENT_MESSAGE_MAX_CHAR_COUNT = 250;
  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 300;

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [assetBusy, setAssetBusy] = useState(false);
  const [userDataByUserUuidTable, setUserDataByUserUuidTable] = useState({});
  const [userEndorsementDataList, setUserEndorsementDataList] = useState([]);

  const [statusMessageType, setStatusMessageType] = useState(
    StatusMessageType.ERROR
  );
  const [statusMessage, setStatusMessage] = useState("");
  const [newEndorsementMessage, setNewEndorsementMessage] = useState("");
  const [submitBoxVisible, setSubmitBoxVisible] = useState(false);
  const [submitBoxErrorGlowEnabled, setSubmitBoxErrorGlowEnabled] =
    useState(false);
  const sortedUserEndorsementDataList = sortUserEndorsementDataListByDate(
    userEndorsementDataList
  );

  const submitBoxHide = () => {
    setNewEndorsementMessage("");
    setSubmitBoxVisible(false);
    setSubmitBoxErrorGlowEnabled(false);
  };

  const submitBoxShow = () => {
    setNewEndorsementMessage("");
    setSubmitBoxVisible(true);
    setSubmitBoxErrorGlowEnabled(false);
  };

  const endorsementLoad = async () => {
    if (!presentUserUuid || !targetUserUuid) {
      setAssetLoadingError(true);
      return;
    }

    await requestSearchEntry(
      DB_TABLE_USER_ENDORSEMENT,
      {
        endorsed_user_uuid: targetUserUuid,
        visible: true,
      },
      (endorsementResponseData) => {
        if (endorsementResponseData && endorsementResponseData["content"]) {
          const newUserEndorsementDataList = endorsementResponseData["content"];
          setUserEndorsementDataList(newUserEndorsementDataList);
          const userUuidList = [];

          newUserEndorsementDataList.forEach((userEndorsementData) => {
            const userUuid = userEndorsementData["user_uuid"];
            if (!userUuidList.includes(userUuid)) {
              userUuidList.push(userUuid);
            }
          });

          requestGetEntryList(
            DB_TABLE_USER,
            userUuidList,
            (userDataList) => {
              if (userDataList) {
                const newUserDataByUserUuidTable = {};
                userDataList.forEach((userData) => {
                  newUserDataByUserUuidTable[userData["uuid"]] = userData;
                });
                setUserDataByUserUuidTable(newUserDataByUserUuidTable);
                setAssetLoading(false);

                setTimeout(() => {
                  setAssetVisible(true);
                }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
              } else {
                setAssetLoadingError(true);
              }
            },
            (error) => {
              setAssetLoadingError(true);
            }
          );
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  };

  useEffect(() => {
    endorsementLoad();
  }, []);

  const endorsementCheckUserSubmission = async (
    endorsingUserUuid,
    endorsedUserUuid
  ) => {
    return new Promise((resolve, reject) => {
      Promise.all([
        improvedRequestSearchEntry(DB_TABLE_USER_NOTIFICATION, {
          user_uuid: endorsedUserUuid,
          first_resource_uuid: endorsingUserUuid,
          type: NotificationType.USER_ENDORSEMENT_REQUEST,
          action_completed: false,
        }),
        improvedRequestSearchEntry(DB_TABLE_USER_ENDORSEMENT, {
          user_uuid: endorsingUserUuid,
          endorsed_user_uuid: endorsedUserUuid,
          visible: true,
          soft_deleted: false,
        }),
      ])
        .then(
          ([userNotificationSearchResponse, userEndorsementSearchResponse]) => {
            const userNotificationDataList =
              userNotificationSearchResponse.data["content"];
            const userEndorsementDataList =
              userEndorsementSearchResponse.data["content"];

            if (userNotificationDataList.length > 0) {
              resolve(UserSubmissionStatus.SUBMITTED_AND_AWAITING_APPROVAL);
              return;
            }
            if (userEndorsementDataList.length > 0) {
              resolve(UserSubmissionStatus.SUBMITTED_AND_VISIBLE);
              return;
            }
            resolve(UserSubmissionStatus.NO_SUBMISSION);
          }
        )
        .catch((error) => {
          resolve(UserSubmissionStatus.ERROR);
        });
    });
  };

  const endorsementSubmit = async () => {
    return new Promise((resolve, reject) => {
      Promise.all([
        improvedRequestSearchEntry(DB_TABLE_USER_NOTIFICATION, {
          user_uuid: targetUserUuid,
          first_resource_uuid: presentUserUuid,
          type: NotificationType.USER_ENDORSEMENT_REQUEST,
          action_completed: false,
        }),
        improvedRequestSearchEntry(DB_TABLE_USER_ENDORSEMENT, {
          user_uuid: presentUserUuid,
          endorsed_user_uuid: targetUserUuid,
          visible: true,
          soft_deleted: false,
        }),
      ])
        .then(
          ([userNotificationSearchResponse, userEndorsementSearchResponse]) => {
            const userNotificationDataList =
              userNotificationSearchResponse.data["content"];
            const userEndorsementDataList =
              userEndorsementSearchResponse.data["content"];

            if (userNotificationDataList.length > 0) {
              setStatusMessageType(StatusMessageType.ERROR);
              setStatusMessage(
                "You have already submitted an endorsement that is awaiting approval!"
              );
              resolve();
              return;
            }
            if (userEndorsementDataList.length > 0) {
              setStatusMessageType(StatusMessageType.ERROR);
              setStatusMessage("You have already endorsed this user!");
              resolve();
              return;
            }

            improvedRequestCreateEntry(DB_TABLE_USER_ENDORSEMENT, {
              user_uuid: presentUserUuid,
              endorsed_user_uuid: targetUserUuid,
              reason: newEndorsementMessage,
              visible: false,
              soft_deleted: false,
            })
              .then((createdUserEndorsementResponse) => {
                const createdUserEndorsementData =
                  createdUserEndorsementResponse.data;
                const createdUserEndorsementUuid = createdUserEndorsementData
                  ? createdUserEndorsementData["uuid"]
                  : undefined;

                if (createdUserEndorsementUuid) {
                  notificationCreate(notificationCreationTemplateUserEndorsementRequest(presentUserUuid, targetUserUuid, createdUserEndorsementUuid))
                  .then(() => {
                    setStatusMessageType(StatusMessageType.SUCCESS);
                      setStatusMessage(
                        "Success! The user has received a notification to accept your endorsement."
                      );
                      submitBoxHide();
                      resolve();
                      return;
                  })
                  .catch((error) => {
                    setStatusMessageType(StatusMessageType.ERROR);
                      setStatusMessage("An error occurred, please try again.");
                      resolve();
                      return;
                  })
                } else {
                  setStatusMessageType(StatusMessageType.ERROR);
                  setStatusMessage("An error occurred, please try again.");
                  resolve();
                  return;
                }
              })
              .catch((error) => {
                setStatusMessageType(StatusMessageType.ERROR);
                setStatusMessage("An error occurred, please try again.");
                resolve();
                return;
              });
          }
        )
        .catch((error) => {
          setStatusMessageType(StatusMessageType.ERROR);
          setStatusMessage("An error occurred, please try again.");
        });
    });
  };

  const processSubmitEndorsementButtonClick = async () => {
    if (newEndorsementMessage.length === 0) {
      setStatusMessageType(StatusMessageType.ERROR);
      setStatusMessage("Your endorsement is empty.");
      setSubmitBoxErrorGlowEnabled(true);
      return;
    }

    const presentUserSubmissionStatus = await endorsementCheckUserSubmission(
      presentUserUuid,
      targetUserUuid
    );

    switch (presentUserSubmissionStatus) {
      case UserSubmissionStatus.NO_SUBMISSION:
        await endorsementSubmit();
        break;
      case UserSubmissionStatus.SUBMITTED_AND_AWAITING_APPROVAL:
        setStatusMessageType(StatusMessageType.ERROR);
        setStatusMessage(
          "You have already submitted an endorsement that is awaiting approval!"
        );
        break;
      case UserSubmissionStatus.SUBMITTED_AND_VISIBLE:
        setStatusMessageType(StatusMessageType.ERROR);
        setStatusMessage("You have already endorsed this user!");
        break;
      case UserSubmissionStatus.ERROR:
        setStatusMessageType(StatusMessageType.ERROR);
        setStatusMessage("An error occurred, please try again later.");
        break;
      default:
        break;
    }
  };

  const debounceSubmitEndorsementButtonClick = useCallback(
    debounce(() => {
      if (!assetBusy) {
        setAssetBusy(true);
        processSubmitEndorsementButtonClick();
        setTimeout(() => {
          setAssetBusy(false);
        }, BUSY_TIMEOUT_DURATION_MS);
      }
    }, DEBOUNCE_TIMEOUT_DURATION_MS),
    [processSubmitEndorsementButtonClick]
  );

  const handleSubmitEndorsementButtonClick = useCallback(() => {
    debounceSubmitEndorsementButtonClick();
  }, [debounceSubmitEndorsementButtonClick]);

  const processNewEndorsementButtonClick = async () => {
    if (submitBoxVisible) {
      submitBoxHide();
      return;
    }

    const presentUserSubmissionStatus = await endorsementCheckUserSubmission(
      presentUserUuid,
      targetUserUuid
    );

    switch (presentUserSubmissionStatus) {
      case UserSubmissionStatus.NO_SUBMISSION:
        setStatusMessage("");
        submitBoxShow();
        break;
      case UserSubmissionStatus.SUBMITTED_AND_AWAITING_APPROVAL:
        setStatusMessageType(StatusMessageType.ERROR);
        setStatusMessage(
          "You have already submitted an endorsement that is awaiting approval!"
        );
        break;
      case UserSubmissionStatus.SUBMITTED_AND_VISIBLE:
        setStatusMessageType(StatusMessageType.ERROR);
        setStatusMessage("You have already endorsed this user!");
        break;
      case UserSubmissionStatus.ERROR:
        setStatusMessageType(StatusMessageType.ERROR);
        setStatusMessage("An error occurred, please try again later.");
        break;
      default:
        break;
    }
  };

  const debounceNewEndorsementButtonClick = useCallback(
    debounce(() => {
      if (!assetBusy) {
        setAssetBusy(true);
        processNewEndorsementButtonClick();
        setTimeout(() => {
          setAssetBusy(false);
        }, BUSY_TIMEOUT_DURATION_MS);
      }
    }, DEBOUNCE_TIMEOUT_DURATION_MS),
    [processNewEndorsementButtonClick]
  );

  const handleNewEndorsementButtonClick = useCallback(() => {
    debounceNewEndorsementButtonClick();
  }, [debounceNewEndorsementButtonClick]);

  const generateEndorsementComponent = (userEndorsementData) => {
    const userUuid = userEndorsementData["user_uuid"];
    const userData = userDataByUserUuidTable[userUuid];
    const date = dateConvertToObject(
      userEndorsementData["creation_date_time"],
      DATE_FRONTEND_FORMAT_SHORT
    );

    return (
      <ProfileEndorsementCollectionItem
        profilePictureUrl={userData["profile_picture_url"]}
        userName={userData["user_name"]}
        body={userEndorsementData["reason"]}
        date={date}
      />
    );
  };

  const handleEndorsementInputTextUnfocus = () => {
    setSubmitBoxErrorGlowEnabled(true);
  };

  const generateSubmitBoxComponent = () => {
    const inputTextEmpty = newEndorsementMessage.length === 0;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          gap: "10px",
          padding: "8px",
          width: "100%",
        }}
      >
        <InputText
          numLines={4}
          placeholderValue={"Write your endorsement..."}
          value={newEndorsementMessage}
          setValue={setNewEndorsementMessage}
          onUnfocus={handleEndorsementInputTextUnfocus}
          error={inputTextEmpty && submitBoxErrorGlowEnabled}
          maxCharCount={ENDORSEMENT_MESSAGE_MAX_CHAR_COUNT}
        />
        <ButtonRectangleLabel
          variant="success"
          label="Submit"
          onClick={handleSubmitEndorsementButtonClick}
        />
      </Box>
    );
  };

  const generateStatusMessageComponent = () => {
    const statusColor = ColorByStatusMessageType[statusMessageType];

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",

          width: "100%",
          padding: "8px",

          border: `2px solid ${statusColor.border}`,
          borderRadius: "6px",
          backgroundColor: statusColor.fill,
        }}
      >
        <Typography
          variant="statusMessageText"
          textAlign="center"
          sx={{
            color: statusColor.text,
          }}
        >
          {statusMessage}
        </Typography>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={profileEndorsementPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name={"ProfileEndorsementPane"} />
      )}
      {!assetLoadingError && !assetLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
            width: "100%",

            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          {/* Heading */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextHeadingMediumA label="Endorsements:" />

            {modifiable && (
              <ProfileButtonEndorsementAdd
                onClick={handleNewEndorsementButtonClick}
                showClose={!submitBoxVisible}
              />
            )}
          </Box>

          <Separator />

          {/* Status Message */}
          {statusMessage.length > 0 && generateStatusMessageComponent()}

          {/* List */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              paddingRight: "16px",
              overflowY: "auto",
              ...(endorsementListMaxHeight && {
                maxHeight: endorsementListMaxHeight,
              }),
            }}
          >
            {submitBoxVisible && generateSubmitBoxComponent()}
            {sortedUserEndorsementDataList.map((userEndorsementData, index) => (
              <Box
                key={index}
                sx={{
                  paddingTop: "5px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    paddingBottom: "5px",
                    width: "100%",
                  }}
                >
                  {generateEndorsementComponent(userEndorsementData)}
                </Box>
                <Separator />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}
