// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../../style/AppTheme"

// Extra
import * as React from "react";

// StudiousCat Components
// ...

export const makeTextRequiredAsteriskThemeDesignTokens = (mode) => ({
  typography: {
    required: {
      fontFamily: "PT Sans",
      fontSize: "32px",
      fontWeight: 400,
      color: alpha(red[900], 0.7),
    },
  },
});

export function makeTextRequiredAsteriskTheme(mode) {
  return {
    ...makeTextRequiredAsteriskThemeDesignTokens(mode),
    components: {},
  };
}

export default function TextRequiredAsterisk({
  height = "8px",
  useCustomHeight = true,
}) {
  const textRequiredAsteriskTheme = createTheme(
    makeTextRequiredAsteriskTheme("light")
  );

  return (
    <ThemeProvider theme={textRequiredAsteriskTheme}>
      <Typography
        variant="required"
        sx={{
          ...(useCustomHeight && { height: height }),
        }}
      >
        *
      </Typography>
    </ThemeProvider>
  );
}
