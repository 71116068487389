import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export default function AuthenticatedRoute({ children }) {
  const { authenticated } = useContext(UserContext);

  if (!authenticated) {
    return <Navigate replace to="/" />;
  }

  return children;
}
