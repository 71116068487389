// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import InboxNotificationCollectionItem, {
  NotificationActionResponseAcceptDeny,
  NotificationActionType,
} from "../../component/inbox/InboxNotificationCollectionItem";
import tempProfilePictureA from "../../asset/image/placeholder/profile_picture/sample-pfp-amir.png";
import {
  dateConvertToObject,
  dateConvertToStringRelative,
} from "../../../service/browser/DateService";
import {
  DB_TABLE_USER_NOTIFICATION,
  requestGetEntry,
  requestSearchEntryPaged,
  requestUpdateEntryList,
} from "../../../service/browser/BackendRequestService";
import {
  notificationExtractDataKeywordList,
  notificationExtractDataResourceUuidList,
  NOTIFICATION_RESOURCE_TABLE_BY_TYPE_MAP,
} from "../../../service/user/NotificationService";
import Separator from "../../component/shared/other/Separator";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import { debounce } from "lodash";
import {
  NOTIFICATION_COMPONENT_TEMPLATE_BY_NOTIFICATION_TYPE_TABLE,
  NOTIFICATION_COMPONENT_TEMPLATE_KEY_LIST,
} from "../../../service/user/NotificationComponentService";
import {
  NOTIFICATION_ACTION_FUNCTION_BY_NOTIFICATION_TYPE_TABLE,
  NOTIFICATION_ACTION_TYPE_BY_NOTIFICATION_TYPE_TABLE,
  notificationMarkActionCompleted,
} from "../../../service/user/NotificationActionService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";

export const makeInboxNotificationPaneThemeDesignTokens = (mode) => ({});

export function makeInboxNotificationPaneTheme(mode) {
  return {
    ...makeInboxNotificationPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function InboxNotificationPane({ presentUserUuid }) {
  const inboxNotificationPaneTheme = createTheme(
    makeInboxNotificationPaneTheme("light")
  );

  const RESOURCE_DATA_OCCUPIED_MARK = "OCCUPIED";
  const RESOURCE_DATA_ERROR_MARK = "ERROR";
  const NOTIFICATION_SEARCH_PAGE_SIZE = 20;
  const BUSY_TIMEOUT_DURATION_MS = 150;
  const DEBOUNCE_TIMEOUT_DURATION_MS = 400;

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetBusy, setAssetBusy] = useState(false);
  const [notificationDataList, setNotificationDataList] = useState([]);
  const [resourceDataByUuidByTable, setResourceDataByUuidByTable] = useState(
    {}
  );
  const [notificationSearchFirstRequest, setNotificationSearchFirstRequest] =
    useState(true);
  const [notificationTotalCount, setNotificationTotalCount] = useState(0);
  const [notificationSearchPageNumber, setNotificationSearchPageNumber] =
    useState(0);

  // === === === === === === === === === === === === === === === === === === ===
  // === === RESOURCE HANDLING (PENDING REFACTORING, 9/1/2024)
  // === === === === === === === === === === === === === === === === === === ===

  const resourceDataCheckErrorForCopy = (
    resourceUuid,
    resourceTableName,
    copyResourceDataByUuidByTable
  ) => {
    const resourceUuidMap = copyResourceDataByUuidByTable[resourceTableName];
    if (
      resourceUuidMap &&
      resourceUuid in resourceUuidMap &&
      resourceUuidMap[resourceUuid] === RESOURCE_DATA_ERROR_MARK
    ) {
      return true;
    }
    return false;
  };

  // Considered occupied when ANYTHING (that is not undefined/emptiness) is set as the resource EXCEPT the ERROR MARK.
  const resourceDataCheckOccupiedForCopy = (
    resourceUuid,
    resourceTableName,
    copyResourceDataByUuidByTable
  ) => {
    const resourceUuidMap = copyResourceDataByUuidByTable[resourceTableName];
    if (
      resourceUuidMap &&
      resourceUuid in resourceUuidMap &&
      resourceUuidMap[resourceUuid] !== RESOURCE_DATA_ERROR_MARK
    ) {
      return true;
    }
    return false;
  };

  const resourceDataCheckError = (resourceUuid, resourceTableName) => {
    return resourceDataCheckErrorForCopy(
      resourceUuid,
      resourceTableName,
      resourceDataByUuidByTable
    );
  };

  // Considered occupied when ANYTHING (that is not undefined/emptiness) is set as the resource EXCEPT the ERROR MARK.
  const resourceDataCheckOccupied = (resourceUuid, resourceTableName) => {
    return resourceDataCheckOccupiedForCopy(
      resourceUuid,
      resourceTableName,
      resourceDataByUuidByTable
    );
  };

  const notificationCheckResourceReadyForCopy = (
    notificationData,
    copyResourceDataByUuidByTable
  ) => {
    const resourceUuidList =
      notificationExtractDataResourceUuidList(notificationData);
    resourceUuidList.forEach((resourceUuid) => {
      if (
        !resourceDataCheckOccupiedForCopy(
          resourceUuid,
          copyResourceDataByUuidByTable
        ) ||
        resourceDataCheckErrorForCopy(
          resourceUuid,
          copyResourceDataByUuidByTable
        )
      ) {
        return false;
      }
    });
    return true;
  };

  const notificationCheckResourceReady = (notificationData) => {
    return notificationCheckResourceReadyForCopy(resourceDataByUuidByTable);
  };

  const resourceDataSetForCopy = (
    resourceUuid,
    resourceTableName,
    value,
    copyResourceDataByUuidByTable
  ) => {
    var resourceUuidMap = copyResourceDataByUuidByTable[resourceTableName];
    if (!resourceUuidMap) {
      copyResourceDataByUuidByTable[resourceTableName] = {};
      resourceUuidMap = copyResourceDataByUuidByTable[resourceTableName];
    }
    resourceUuidMap[resourceUuid] = value;
  };

  const resourceDataSet = (resourceUuid, resourceTableName, value) => {
    const copyResourceDataByUuidByTable = { ...resourceDataByUuidByTable };
    resourceDataSetForCopy(
      resourceUuid,
      resourceTableName,
      value,
      copyResourceDataByUuidByTable
    );
    setResourceDataByUuidByTable(copyResourceDataByUuidByTable);
  };

  const resourceDataGet = (resourceUuid, resourceTableName) => {
    const resourceUuidMap = resourceDataByUuidByTable[resourceTableName];
    if (resourceUuidMap) {
      return resourceUuidMap[resourceUuid];
    }
    return undefined;
  };

  const notificationGetResourceDataList = (notificationData) => {
    if (!notificationCheckResourceReady(notificationData)) {
      return [];
    }
    const resourceDataList = [];
    const resourceUuidList =
      notificationExtractDataResourceUuidList(notificationData);
    const resourceTableNameList =
      NOTIFICATION_RESOURCE_TABLE_BY_TYPE_MAP[notificationData["type"]];

    for (let i = 0; i < resourceUuidList.length; i++) {
      const resourceUuid = resourceUuidList[i];
      const resourceTableName = resourceTableNameList[i];
      resourceDataList.push(resourceDataGet(resourceUuid, resourceTableName));
    }
    return resourceDataList;
  };

  const notificationSortDataListForCopy = (notificationDataListCopy) => {
    return notificationDataListCopy.sort((notificationA, notificationB) => {
      const dateNotificationA = dateConvertToObject(
        notificationA["creation_date_time"]
      );
      const dateNotificationB = dateConvertToObject(
        notificationB["creation_date_time"]
      );
      return dateNotificationB - dateNotificationA;
    });
  };

  const notificationRequestMarkViewed = (
    givenNotificationDataList,
    onFinished,
    onError
  ) => {
    const updateNotificationDataList = [];

    givenNotificationDataList.forEach((notificationData) => {
      if (notificationData["viewed"] == false) {
        const newNotificationData = { ...notificationData };
        newNotificationData["viewed"] = true;
        updateNotificationDataList.push(newNotificationData);
      }
    });

    const updateRequestBody = updateNotificationDataList.map(
      (notificationData) => ({
        uuid: notificationData["uuid"],
        content: notificationData,
      })
    );

    if (updateNotificationDataList) {
      requestUpdateEntryList(
        DB_TABLE_USER_NOTIFICATION,
        updateRequestBody,
        (responseData) => {
          onFinished?.();
        },
        (error) => {
          onError?.();
        }
      );
    }
  };

  const notificationLoadResources = (
    givenNotificationDataList,
    givenResourceDataByUuidByTable,
    onFinished,
    onError
  ) => {
    const updatedState = {
      newResourceDataByUuidByTable: givenResourceDataByUuidByTable,
    };

    const resourceRequestOperationList = [];

    givenNotificationDataList.forEach((notificationData) => {
      const resourceUuidList =
        notificationExtractDataResourceUuidList(notificationData);

      const resourceTableNameList =
        NOTIFICATION_RESOURCE_TABLE_BY_TYPE_MAP[notificationData["type"]];

      for (let i = 0; i < resourceUuidList.length; i++) {
        const resourceUuid = resourceUuidList[i];
        const resourceTableName = resourceTableNameList[i];

        if (
          !resourceDataCheckOccupiedForCopy(
            resourceUuid,
            resourceTableName,
            updatedState.newResourceDataByUuidByTable
          )
        ) {
          resourceDataSetForCopy(
            resourceUuid,
            resourceTableName,
            RESOURCE_DATA_OCCUPIED_MARK,
            updatedState.newResourceDataByUuidByTable
          );
          const requestOperation = requestGetEntry(
            resourceTableName,
            resourceUuid,
            (responseData) => {
              if (responseData) {
                resourceDataSetForCopy(
                  resourceUuid,
                  resourceTableName,
                  responseData,
                  updatedState.newResourceDataByUuidByTable
                );
              } else {
                resourceDataSetForCopy(
                  resourceUuid,
                  resourceTableName,
                  RESOURCE_DATA_ERROR_MARK,
                  updatedState.newResourceDataByUuidByTable
                );
              }
            },
            (error) => {
              resourceDataSetForCopy(
                resourceUuid,
                resourceTableName,
                RESOURCE_DATA_ERROR_MARK,
                updatedState.newResourceDataByUuidByTable
              );
            }
          );
          resourceRequestOperationList.push(requestOperation);
        }
      }
    });

    if (resourceRequestOperationList) {
      Promise.all(resourceRequestOperationList)
        .then((result) => {
          setResourceDataByUuidByTable(
            updatedState.newResourceDataByUuidByTable
          );
          onFinished?.();
        })
        .catch((error) => {
          onError?.();
        });
    }
  };

  const notificationLoadPage = (
    givenPageNumber,
    givenPageSize,
    givenNotificationSearchFirstRequest,
    givenNotificationTotalCount,
    givenNotificationDataList,
    onFinished,
    onError
  ) => {
    requestSearchEntryPaged(
      DB_TABLE_USER_NOTIFICATION,
      {
        user_uuid: presentUserUuid,
      },
      givenPageNumber,
      givenPageSize,
      "creationDateTime,desc",
      (notificationResponseData) => {
        if (notificationResponseData) {
          const totalCountResponse = notificationResponseData["total_elements"];
          const updatedState = {
            newNotificationSearchFirstRequest: false,
            newNotificationTotalCount: totalCountResponse,
          };

          if (
            !givenNotificationSearchFirstRequest &&
            givenNotificationTotalCount !== totalCountResponse
          ) {
            const coveringPageSize =
              (givenPageNumber + 1) * NOTIFICATION_SEARCH_PAGE_SIZE;
            notificationLoadPage(
              0,
              coveringPageSize,
              true,
              0,
              [],
              onFinished,
              onError
            );
          } else {
            updatedState.newNotificationSearchPageNumber =
              givenPageNumber + givenPageSize / NOTIFICATION_SEARCH_PAGE_SIZE;

            const respondedNotificationDataList =
              notificationResponseData["content"];

            updatedState.newNotificationDataList =
              notificationSortDataListForCopy(
                givenNotificationDataList.concat(respondedNotificationDataList)
              );

            const providedResourceDataByUuidByTable =
              givenNotificationSearchFirstRequest
                ? {}
                : resourceDataByUuidByTable;

            setNotificationSearchFirstRequest(
              updatedState.newNotificationSearchFirstRequest
            );
            setNotificationTotalCount(updatedState.newNotificationTotalCount);
            setNotificationDataList(updatedState.newNotificationDataList);
            setNotificationSearchPageNumber(
              updatedState.newNotificationSearchPageNumber
            );

            notificationLoadResources(
              respondedNotificationDataList,
              providedResourceDataByUuidByTable,
              () => {
                onFinished?.();
              },
              () => {
                onError?.();
              }
            );

            notificationRequestMarkViewed(
              respondedNotificationDataList,
              () => {
                // Do Nothing.
              },
              () => {
                onError?.();
              }
            );
          }
        } else {
          onError?.();
        }
      },
      (error) => {
        onError?.();
      }
    );
  };

  const notificationLoad = () => {
    setAssetLoading(true);
    notificationLoadPage(
      notificationSearchPageNumber,
      NOTIFICATION_SEARCH_PAGE_SIZE,
      notificationSearchFirstRequest,
      notificationTotalCount,
      notificationDataList,
      () => {
        setAssetLoading(false);
      },
      () => {
        setAssetLoadingError(true);
      }
    );
  };

  const notificationCheckLoadMorePossible = () => {
    return (
      notificationSearchPageNumber * NOTIFICATION_SEARCH_PAGE_SIZE <
      notificationTotalCount
    );
  };

  useEffect(() => {
    notificationLoad();
  }, []);

  // === === === === === === === === === === === === === === === === === === ===
  // === === NOTIFICATION COMPONENT AND ACTION HANDLING (FINISHED REFACTORING, 9/1/2024)
  // === === === === === === === === === === === === === === === === === === ===

  // Pure async function, can throw errors.
  const processAction = async (
    notificationData,
    resourceDataList,
    actionLabel
  ) => {
    const notificationUuid = notificationData["uuid"];
    const notificationType = notificationData["type"];
    const actionFunction =
      NOTIFICATION_ACTION_FUNCTION_BY_NOTIFICATION_TYPE_TABLE[notificationType];

    // NOTE: Assume that the actionFunction is a pure async function.
    await actionFunction?.(notificationData, resourceDataList, actionLabel);

    const updatedNotificationData = await notificationMarkActionCompleted(
      notificationUuid
    );
    for (var index = 0; index < notificationDataList.length; index++) {
      const notificationDataAtIndex = notificationDataList[index];
      if (notificationUuid === notificationDataAtIndex["uuid"]) {
        const newNotificationDataList = [...notificationDataList];
        newNotificationDataList[index] = updatedNotificationData;
        setNotificationDataList(newNotificationDataList);
        break;
      }
    }
  };

  const debounceAction = useCallback(
    debounce((notificationData, resourceDataList, actionLabel) => {
      if (!assetBusy) {
        setAssetBusy(true);
        processAction(notificationData, resourceDataList, actionLabel).catch(
          (error) => {}
        );
        setTimeout(() => {
          setAssetBusy(false);
        }, BUSY_TIMEOUT_DURATION_MS);
      }
    }, DEBOUNCE_TIMEOUT_DURATION_MS),
    [processAction]
  );

  const handleAction = useCallback(
    (notificationData, resourceDataList, actionLabel) => {
      debounceAction(notificationData, resourceDataList, actionLabel);
    },
    [debounceAction]
  );

  const generateNotificationComponentFromTemplate = (
    notificationComponentTemplate
  ) => {
    const notificationData = notificationComponentTemplate.notificationData;
    const resourceDataList = notificationComponentTemplate.resourceDataList;
    const keywordList = notificationComponentTemplate.keywordList;
    const notificationType = notificationData["type"];
    const actionType =
      NOTIFICATION_ACTION_TYPE_BY_NOTIFICATION_TYPE_TABLE[notificationType];
    const actionHandler = actionType
      ? (actionLabel) =>
          handleAction(notificationData, resourceDataList, actionLabel)
      : undefined;

    return (
      <InboxNotificationCollectionItem
        pictureUrl={resourceDataList[0]["profile_picture_url"]}
        subjectHeading={resourceDataList[0]["user_name"]}
        subjectBody={notificationComponentTemplate.subjectBody}
        mainBody={notificationComponentTemplate.mainBody}
        mainBodyEmphasis={notificationComponentTemplate.mainBodyEmphasis}
        date={notificationData["creation_date_time"]}
        actionType={actionType}
        onAction={actionHandler}
        actionCompleted={notificationData["action_completed"]}
        viewed={notificationData["viewed"]}
      />
    );
  };

  const generateNotificationComponentError = () => {
    return (
      <InboxNotificationCollectionItem
        subjectHeading={"ERROR"}
        subjectBody={"An error occurred loading this notification."}
      />
    );
  };

  const generateNotificationComponent = (notificationData) => {
    try {
      if (!notificationCheckResourceReady(notificationData)) {
        return generateNotificationComponentError();
      }

      const notificationType = notificationData["type"];
      const resourceDataList =
        notificationGetResourceDataList(notificationData);
      const keywordList = notificationExtractDataKeywordList(notificationData);

      const componentTemplateFunction =
        NOTIFICATION_COMPONENT_TEMPLATE_BY_NOTIFICATION_TYPE_TABLE[
          notificationType
        ];
      const componentTemplate = componentTemplateFunction(
        notificationData,
        resourceDataList,
        keywordList
      );

      NOTIFICATION_COMPONENT_TEMPLATE_KEY_LIST.forEach(
        (componentTemplateKey) => {
          if (!Object.keys(componentTemplate).includes(componentTemplateKey)) {
            return generateNotificationComponentError();
          }
        }
      );

      return generateNotificationComponentFromTemplate(componentTemplate);
    } catch (error) {
      return generateNotificationComponentError();
    }
  };

  return (
    <ThemeProvider theme={inboxNotificationPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name="InboxNotificationPane" />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            padding: "20px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "180px",
            }}
          >
            <ButtonRectangleNarrowLabel
              label="Notifications"
              number={notificationTotalCount}
              showNumber={true}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              maxHeight: "65vh",
              overflowY: "auto",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            {notificationDataList.map((notificationData, index) => (
              <Box
                key={index}
                sx={{
                  paddingTop: "30px",
                }}
              >
                <Box
                  sx={{
                    paddingBottom: "30px",
                  }}
                >
                  {generateNotificationComponent(notificationData)}
                </Box>
                <Separator />
              </Box>
            ))}
            {notificationCheckLoadMorePossible() && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <ButtonRectangleLabel
                  variant="success"
                  label="Load More Notifications"
                  onClick={() => notificationLoad()}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}
