// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import ProfileUserListingSuperPane from "./ProfileUserListingSuperPane";
import {
  DB_TABLE_USER,
  DB_TABLE_USER_FRIEND,
  improvedRequestGetEntryList,
  improvedRequestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import { dateConvertToObject } from "../../../service/browser/DateService";
import { listToObjectByKey } from "../../../service/browser/ObjectService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";

export const makeProfileUserListingFriendsSuperPaneThemeDesignTokens = (
  mode
) => ({});

export function makeProfileUserListingFriendsSuperPaneTheme(mode) {
  return {
    ...makeProfileUserListingFriendsSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;

export default function ProfileUserListingFriendsSuperPane({
  targetUserUuid,
  listMaxHeight,
  onChangeUserCount,
}) {
  const profileUserListingFriendsSuperPaneTheme = createTheme(
    makeProfileUserListingFriendsSuperPaneTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);

  const [selectedUserUuidList, setSelectedUserUuidList] = useState([]);
  const [dateBySelectedUserUuidTable, setDateBySelectedUserUuidTable] =
    useState({});

  // NOTE: copy-pasted retrieveFriendedUserUuidList function from ProfileUserListingPane for now
  // TODO: make a standardized user friend finding function

  const retrieveFriendedUserUuidList = async (providedUserUuid) => {
    const [fromProvidedUserResponse, toProvidedUserResponse] =
      await Promise.all([
        improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
          user_uuid: providedUserUuid,
        }),
        improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
          friended_user_uuid: providedUserUuid,
        }),
      ]);
    const userFriendDataListFromProvided =
      fromProvidedUserResponse &&
      fromProvidedUserResponse.data &&
      fromProvidedUserResponse.data["content"]
        ? fromProvidedUserResponse.data["content"]
        : [];
    const userFriendDataListToProvided =
      toProvidedUserResponse &&
      toProvidedUserResponse.data &&
      toProvidedUserResponse.data["content"]
        ? toProvidedUserResponse.data["content"]
        : [];

    const friendedUserUuidListPackedFromProvided =
      userFriendDataListFromProvided.map((userFriendData) => ({
        userUuid: userFriendData["friended_user_uuid"],
        date: dateConvertToObject(userFriendData["creation_date_time"]),
      }));
    const friendedUserUuidListPackedToProvided =
      userFriendDataListToProvided.map((userFriendData) => ({
        userUuid: userFriendData["user_uuid"],
        date: dateConvertToObject(userFriendData["creation_date_time"]),
      }));
    const filteredPackedDataList = friendedUserUuidListPackedFromProvided
      .concat(friendedUserUuidListPackedToProvided)
      .filter(({ userUuid, date }) => userUuid !== providedUserUuid);

    const friendedUserUuidList = filteredPackedDataList.map(
      ({ userUuid, date }) => userUuid
    );
    const dateByFriendedUserUuidTable = {};
    filteredPackedDataList.forEach((packedData) => {
      dateByFriendedUserUuidTable[packedData["userUuid"]] = packedData["date"];
    });

    return [friendedUserUuidList, dateByFriendedUserUuidTable];
  };

  const initData = async () => {
    const [newSelectedUserUuidList, newDateBySelectedUserUuidTable] =
      await retrieveFriendedUserUuidList(targetUserUuid);

    onChangeUserCount?.(newSelectedUserUuidList.length);
    setSelectedUserUuidList(newSelectedUserUuidList);
    setDateBySelectedUserUuidTable(newDateBySelectedUserUuidTable);
  };

  useEffect(() => {
    initData()
      .then(() => {
        setAssetLoading(false);

        setTimeout(() => {
          setAssetVisible(true);
        }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
      })
      .catch((error) => {
        setAssetLoadingError(true);
      });
  }, []);

  return (
    <ThemeProvider theme={profileUserListingFriendsSuperPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError
          name={"ProfileUserListingFriendsSuperPane"}
        />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          <ProfileUserListingSuperPane
            targetUserUuid={targetUserUuid}
            selectedUserUuidList={selectedUserUuidList}
            dateBySelectedUserUuidTable={dateBySelectedUserUuidTable}
            dateTextPrefix={"Connected"}
            listMaxHeight={listMaxHeight}
          />
        </Box>
      )}
    </ThemeProvider>
  );
}
