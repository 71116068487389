// Theme and Style
import { Avatar, createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Link } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import {
  DATE_FRONTEND_FORMAT_TIME_ONLY,
  dateConvertToString,
} from "../../../service/browser/DateService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";

export const makeCalendarMeetingQuickViewPaneThemeDesignTokens = (mode) => ({
  typography: {
    headingText: {
      fontFamily: "PT Sans",
      fontWeight: 600,
      fontSize: 16,
      color: purple[500],
    },
    bodySectionText: {
      fontFamily: "PT Sans",
      textDecoration: "underline",
      fontSize: 16,
      color: purple[700],
    },
    bodyText: {
      fontFamily: "PT Sans",
      fontSize: 14,
      color: purple[700],
    },
    bodySmallText: {
      fontFamily: "PT Sans",
      fontStyle: "italic",
      fontSize: 13,
      color: purple[700],
    },
    statusHeadingText: {
      fontFamily: "PT Sans",
      fontWeight: 600,
      fontSize: 20,
      color: purple[900],
    },
    statusBodyText: {
      fontFamily: "PT Sans",
      fontWeight: 600,
      fontSize: 16,
      color: purple[800],
    },
  },
});

export function makeCalendarMeetingQuickViewPaneTheme(mode) {
  return {
    ...makeCalendarMeetingQuickViewPaneThemeDesignTokens(mode),
    components: {},
  };
}

export const CalendarMeetingQuickViewPreset = {
  NEW_MEETING: "NEW_MEETING",
  EXISTING_MEETING: "EXISTING_MEETING",
  EMPTY_MEETING: "EMPTY_MEETING",
};

const EVENT_MAX_DURATION_HOURS = 5;

export default function CalendarMeetingQuickViewPane({
  preset,
  meetingDataByMeetingUuidTable,
  userDataByUserUuidTable,
  userUuidListByMeetingUuidTable,
  existingMeetingUuid,
  newMeetingTimeSlotContent,
  onCreateNewMeeting,
  onViewExistingMeeting,
}) {
  const calendarMeetingQuickViewPaneTheme = createTheme(
    makeCalendarMeetingQuickViewPaneTheme("light")
  );

  const handleCreateNewMeeting = () => {
    onCreateNewMeeting?.();
  };

  const handleViewExistingMeeting = () => {
    onViewExistingMeeting?.();
  };

  const generateNewMeetingComponent = () => {
    if (!preset || !newMeetingTimeSlotContent) {
      return generateEmptyMeetingComponent();
    }
    if (
      !(
        "startDate" in newMeetingTimeSlotContent &&
        "endDate" in newMeetingTimeSlotContent
      )
    ) {
      return generateEmptyMeetingComponent();
    }

    const startDate = newMeetingTimeSlotContent.startDate;
    const endDate = newMeetingTimeSlotContent.endDate;

    const startDateText = dateConvertToString(
      startDate,
      DATE_FRONTEND_FORMAT_TIME_ONLY
    );
    const endDateText = dateConvertToString(
      endDate,
      DATE_FRONTEND_FORMAT_TIME_ONLY
    );

    var statusBody = "The selected time is not valid.";
    var timeSelectedValid = true;

    if (timeSelectedValid && endDate.getTime() <= startDate.getTime()) {
      statusBody = "Cannot have the start time before the end time.";
      timeSelectedValid = false;
    }

    if (timeSelectedValid && startDate.getDate() !== endDate.getDate()) {
      statusBody = "Cannot span across multiple days.";
      timeSelectedValid = false;
    }

    const hourDiff =
      Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);

    if (timeSelectedValid && hourDiff > EVENT_MAX_DURATION_HOURS) {
      statusBody = `Cannot be longer than ${EVENT_MAX_DURATION_HOURS} hours.`;
      timeSelectedValid = false;
    }

    const statusHeading = timeSelectedValid ? "AVAILABLE" : "NOT AVAILABLE";

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <Typography variant="headingText">Selected:</Typography>
          <Typography variant="headingText">
            {startDateText} to {endDateText}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          {timeSelectedValid ? <CheckCircleIcon /> : <WarningIcon />}
          <Typography variant="statusHeadingText">{statusHeading}</Typography>
          {!timeSelectedValid && (
            <Typography variant="statusBodyText">{statusBody}</Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "10px",
            width: "100%",
          }}
        >
          {timeSelectedValid && (
            <ButtonRectangleNarrowLabel
              label="Create"
              variant="inverted"
              showNumber={false}
              selected={false}
              onClick={() => handleCreateNewMeeting()}
              labelFontSize={"14px"}
              fullWidth={false}
            />
          )}
        </Box>
      </Box>
    );
  };

  const generateUser = (profilePictureUrl, userName) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          gap: "5px",
          leftPadding: "5px",
          rightPadding: "15px",
        }}
      >
        <Avatar
          src={profilePictureUrl}
          sx={{
            width: "32px",
            height: "32px",
          }}
        />
        <Link href={`/user/${userName}`} underline="always" color="inherit">
          <Typography
            sx={{
              fontFamily: "Source Serif Pro",
              fontSize: "14px",
              fontWeight: 600,
              color: alpha(purple[900], 0.8),
            }}
          >
            {userName}
          </Typography>
        </Link>
      </Box>
    );
  };

  const generateExistingMeetingComponent = () => {
    if (
      !preset ||
      !meetingDataByMeetingUuidTable ||
      !userDataByUserUuidTable ||
      !userUuidListByMeetingUuidTable ||
      !existingMeetingUuid
    ) {
      return generateEmptyMeetingComponent();
    }

    const meetingData = meetingDataByMeetingUuidTable[existingMeetingUuid];
    const meetingName = meetingData["name"];
    const meetingLocation = meetingData["address"];
    const meetingDescription = meetingData["description"];
    const meetingCreatorUserUuid = meetingData["creator_user_uuid"];
    const meetingCreatorUserData =
      userDataByUserUuidTable[meetingCreatorUserUuid];

    const userUuidList = userUuidListByMeetingUuidTable[existingMeetingUuid];
    const participantUserUuidList = userUuidList.filter(
      (userUuid) => userUuid !== meetingCreatorUserUuid
    );
    const participantUserDataList =
      participantUserUuidList && participantUserUuidList.length > 0
        ? participantUserUuidList.map(
            (userUuid) => userDataByUserUuidTable[userUuid]
          )
        : [];

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "10px",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              gap: "3px",
            }}
          >
            <Typography variant="headingText">Meeting Info</Typography>
            <Typography variant="bodySectionText">Name:</Typography>
            <Typography variant="bodyText">
              {meetingName ? meetingName : "None"}
            </Typography>
            <Typography variant="bodySectionText">Location:</Typography>
            <Typography variant="bodyText">
              {meetingLocation ? meetingLocation : "None"}
            </Typography>
            <Typography variant="bodySectionText">Description:</Typography>
            <Typography variant="bodySmallText">
              {meetingDescription ? meetingDescription : "None"}
            </Typography>
          </Box>
          {meetingCreatorUserUuid && meetingCreatorUserData && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                gap: "5px",
              }}
            >
              <Typography variant="headingText">Leader</Typography>
              {generateUser(
                meetingCreatorUserData["profile_picture_url"],
                meetingCreatorUserData["user_name"]
              )}
            </Box>
          )}
          {participantUserDataList && participantUserDataList.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                gap: "5px",
              }}
            >
              <Typography variant="headingText">Participants</Typography>
              {participantUserDataList.map((userData, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                  }}
                >
                  {generateUser(
                    userData["profile_picture_url"],
                    userData["user_name"]
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
            gap: "10px",
          }}
        >
          <ButtonRectangleNarrowLabel
            label="View"
            variant="inverted"
            showNumber={false}
            selected={false}
            onClick={() => handleViewExistingMeeting()}
            labelFontSize={"14px"}
            fullWidth={false}
          />
        </Box>
      </Box>
    );
  };

  const generateEmptyMeetingComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "70%",
            gap: "10px",
          }}
        >
          <Typography variant="headingText">Meeting Info Goes Here</Typography>
          <Typography variant="bodyText">
            Click and drag on the calendar to create a new meeting.
          </Typography>
          <Typography variant="bodyText">
            Click on an existing meeting in the calendar to view its details.
          </Typography>
        </Box>
      </Box>
    );
  };

  const generateMainComponent = () => {
    switch (preset) {
      case CalendarMeetingQuickViewPreset.NEW_MEETING:
        return generateNewMeetingComponent();
      case CalendarMeetingQuickViewPreset.EXISTING_MEETING:
        return generateExistingMeetingComponent();
      case CalendarMeetingQuickViewPreset.EMPTY_MEETING:
        return generateEmptyMeetingComponent();
      default:
        return generateEmptyMeetingComponent();
    }
  };

  return (
    <ThemeProvider theme={calendarMeetingQuickViewPaneTheme}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          border: `3px dashed ${purple[200]}`,
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        {generateMainComponent()}
      </Box>
    </ThemeProvider>
  );
}
