// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import {
  DB_TABLE_MEETING,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import SurroundingControls from "../../special/SurroundingControls";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import { useNavigate, useParams } from "react-router";
import CalendarMeetingInfoSuperPane from "../../super_pane/calendar_meeting_info/CalendarMeetingInfoSuperPane";

export const makeCalendarMeetingInfoPageThemeDesignTokens = (mode) => ({});

export function makeCalendarMeetingInfoPageTheme(mode) {
  return {
    ...makeCalendarMeetingInfoPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarMeetingInfoPage({}) {
  const calendarMeetingInfoPageTheme = createTheme(
    makeCalendarMeetingInfoPageTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [matchedMeetingData, setMatchedMeetingData] = useState({});

  const { presentUserUuid } = useContext(UserContext);
  const { targetMeetingUuid } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    requestSearchEntry(
      DB_TABLE_MEETING,
      {
        uuid: targetMeetingUuid,
        completed: true,
        soft_deleted: false,
      },
      (searchResponseData) => {
        if (searchResponseData && searchResponseData["content"]) {
          const meetingDataList = searchResponseData["content"];

          if (
            meetingDataList.length === 1 &&
            meetingDataList[0]["uuid"] === targetMeetingUuid
          ) {
            const meetingData = meetingDataList[0];
            setMatchedMeetingData(meetingData);
            setAssetLoading(false);
          } else {
            setAssetLoadingError(true);
          }
        } else {
          setAssetLoadingError(true);
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  }, [targetMeetingUuid]);

  const handleMeetingInfoClose = () => {
    navigate("/calendar", { replace: true });
  };

  return (
    <ThemeProvider theme={calendarMeetingInfoPageTheme}>
      <SurroundingControls>
        {assetLoadingError && (
          <StatusMessageComponentLoadError
            message={`Meeting Not Found: ${targetMeetingUuid}`}
          />
        )}
        {!assetLoading && !assetLoadingError && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <CalendarMeetingInfoSuperPane
              presentUserUuid={presentUserUuid}
              targetMeetingUuid={matchedMeetingData["uuid"]}
              creatingMeeting={false}
              onClose={() => handleMeetingInfoClose()}
            />
          </Box>
        )}
      </SurroundingControls>
    </ThemeProvider>
  );
}
