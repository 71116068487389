// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";

// Interactive Assets
import { CssBaseline, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, blue, purple, red, green, gray } from "../style/AppTheme";

// Extra
import * as React from "react";

// StudiousCat Components
import Sidebar from "./Sidebar";
import Heading from "./Heading";

// DEBUG
import NetDebugControls from "../component/debug/NetDebugControls";

export const SHOW_DEBUG_INFO = false;

export const makeSurroundingControlsThemeDesignTokens = (mode) => ({});

export function makeSurroundingControlsTheme(mode) {
  return {
    ...makeSurroundingControlsThemeDesignTokens(mode),
    components: {},
  };
}

export const SIDEBAR_ITEM_LIST_DEFAULT = [
  {
    label: "Calendar",
    id: "calendar",
    parent: "root",
  },
  {
    label: "Settings",
    id: "settings",
    parent: "root",
  },
  {
    label: "Profile",
    id: "profile",
    parent: "settings",
  },
  {
    label: "Display",
    id: "display",
    parent: "settings",
  },
  {
    label: "Privacy",
    id: "privacy",
    parent: "settings",
  },
  {
    label: "Inbox",
    id: "inbox",
    parent: "root",
  },
  {
    label: "Help",
    id: "help",
    parent: "root",
  },
  {
    label: "FAQ",
    id: "faq",
    parent: "help",
  },
  {
    label: "Tutorial",
    id: "tutorial",
    parent: "help",
  },
  {
    label: "Support & Feedback",
    id: "support-and-feedback",
    parent: "help",
  },
  {
    label: "About",
    id: "about",
    parent: "root",
  },
];

export const sidebarNavigatorDefault = (sidebarItemId) => {
  switch (sidebarItemId) {
    case "calendar":
      return "/calendar";
    case "profile":
      return "/settings/my-profile";
    case "display":
      return "/settings/display";
    case "privacy":
      return "/settings/privacy-policy";
    case "inbox":
      return "/inbox";
    case "faq":
      return "/help/faq";
    case "tutorial":
      return "/help/tutorial";
    case "support-and-feedback":
      return "/help/support-and-feedback";
    case "about":
      return "/about";
  }
  return "";
};

export const sidebarNavigatorEmpty = (sidebarItemId) => {
  return "";
};

export default function SurroundingControls({
  sidebarItemList = SIDEBAR_ITEM_LIST_DEFAULT,
  sidebarOnClickItem,
  sidebarNavigator = sidebarNavigatorDefault,
  sidebarProfilePictureVisible = true,
  headingSubtitle,
  children,
}) {
  const surroundingControlsTheme = createTheme(
    makeSurroundingControlsTheme("light")
  );

  return (
    <ThemeProvider theme={surroundingControlsTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: { xs: "100%", sm: "100dvh" },
          width: { xs: "100%", sm: "100dvw" },
          backgroundColor: colorBg,
        }}
      >
        <CssBaseline />
        <Sidebar
          itemList={sidebarItemList}
          onClickItem={sidebarOnClickItem}
          navigator={sidebarNavigator}
          profilePictureVisible={sidebarProfilePictureVisible}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {SHOW_DEBUG_INFO && <NetDebugControls />}
            <Heading subtitle={headingSubtitle} />
            <Box
              sx={{
                overflowY: "auto", // Enable vertical scrolling when content exceeds maxHeight
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
