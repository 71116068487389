import {
  DB_TABLE_MEETING,
  DB_TABLE_USER,
  DB_TABLE_USER_ENDORSEMENT,
  DB_TABLE_USER_NOTIFICATION,
  improvedRequestCreateEntryList,
  improvedRequestSearchEntry,
  improvedRequestUpdateEntryList,
} from "../browser/BackendRequestService";
import { NOTIFICATION_CREATION_TEMPLATE_KEY_LIST } from "./NotificationCreationTemplateService";

export const NOTIFICATION_NUMERICAL_KEY_LIST = [
  "first",
  "second",
  "third",
  "fourth",
];
export const NOTIFICATION_MAX_RESOURCE_COUNT = 4;
export const NOTIFICATION_MAX_KEYWORD_COUNT = 4;

// NOTE: BLOCKING will be implemented LATER!

export const NOTIFICATION_RESOURCE_TABLE_BY_TYPE_MAP = {
  "user-friend-request": [DB_TABLE_USER],
  "user-friend-accept": [DB_TABLE_USER],
  "user-friend-deny": [DB_TABLE_USER],
  "user-endorsement-request": [DB_TABLE_USER, DB_TABLE_USER_ENDORSEMENT],
  "user-endorsement-accept": [DB_TABLE_USER, DB_TABLE_USER_ENDORSEMENT],
  "user-endorsement-deny": [DB_TABLE_USER, DB_TABLE_USER_ENDORSEMENT],
  "meeting-participant-leader-info-change": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-participant-leader-delete-meeting": [
    DB_TABLE_USER,
    DB_TABLE_MEETING,
  ],
  "meeting-participant-leader-remove-user": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-participant-leader-announcement": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-participant-leader-invite-request": [
    DB_TABLE_USER,
    DB_TABLE_MEETING,
  ],
  "meeting-participant-leader-invite-cancel": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-participant-leader-join-accept": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-participant-leader-join-deny": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-leader-participant-invite-accept": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-leader-participant-invite-deny": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-leader-participant-join-direct": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-leader-participant-join-request": [DB_TABLE_USER, DB_TABLE_MEETING],
  "meeting-leader-participant-leave": [DB_TABLE_USER, DB_TABLE_MEETING],
};

export const NotificationType = {
  USER_FRIEND_REQUEST: "user-friend-request",
  USER_FRIEND_ACCEPT: "user-friend-accept",
  USER_FRIEND_DENY: "user-friend-deny",
  USER_ENDORSEMENT_REQUEST: "user-endorsement-request",
  USER_ENDORSEMENT_ACCEPT: "user-endorsement-accept",
  USER_ENDORSEMENT_DENY: "user-endorsement-deny",
  MEETING_PARTICIPANT_LEADER_INFO_CHANGE:
    "meeting-participant-leader-info-change",
  MEETING_PARTICIPANT_LEADER_DELETE_MEETING:
    "meeting-participant-leader-delete-meeting",
  MEETING_PARTICIPANT_LEADER_REMOVE_USER:
    "meeting-participant-leader-remove-user",
  MEETING_PARTICIPANT_LEADER_ANNOUNCEMENT:
    "meeting-participant-leader-announcement",
  MEETING_PARTICIPANT_LEADER_INVITE_REQUEST:
    "meeting-participant-leader-invite-request",
  MEETING_PARTICIPANT_LEADER_INVITE_CANCEL:
    "meeting-participant-leader-invite-cancel",
  MEETING_PARTICIPANT_LEADER_JOIN_ACCEPT:
    "meeting-participant-leader-join-accept",
  MEETING_PARTICIPANT_LEADER_JOIN_DENY: "meeting-participant-leader-join-deny",
  MEETING_LEADER_PARTICIPANT_INVITE_ACCEPT:
    "meeting-leader-participant-invite-accept",
  MEETING_LEADER_PARTICIPANT_INVITE_DENY:
    "meeting-leader-participant-invite-deny",
  MEETING_LEADER_PARTICIPANT_JOIN_DIRECT:
    "meeting-leader-participant-join-direct",
  MEETING_LEADER_PARTICIPANT_JOIN_REQUEST:
    "meeting-leader-participant-join-request",
  MEETING_LEADER_PARTICIPANT_LEAVE: "meeting-leader-participant-leave",
};

export const NOTIFICATION_DATA_OUTLINE = {
  user_uuid: "",
  first_resource_uuid: null,
  second_resource_uuid: null,
  third_resource_uuid: null,
  fourth_resource_uuid: null,
  first_keyword: "",
  second_keyword: "",
  third_keyword: "",
  fourth_keyword: "",
  message: "",
  type: "",
  viewed: false,
};

export const NOTIFICATION_USER_BLOCK_MESSAGE =
  "You will no longer be in contact with each other and their username will be displayed in red from here on out.";

export const notificationExtractDataResourceUuidList = (notificationData) => {
  if (!notificationData) {
    return [];
  }
  const resourceUuidList = [];
  for (let i = 0; i < NOTIFICATION_MAX_RESOURCE_COUNT; i++) {
    const number = NOTIFICATION_NUMERICAL_KEY_LIST[i];
    const resourceUuid = notificationData[`${number}_resource_uuid`];
    if (resourceUuid) {
      resourceUuidList.push(resourceUuid);
    }
  }
  return resourceUuidList;
};

export const notificationExtractDataKeywordList = (notificationData) => {
  if (!notificationData) {
    return [];
  }
  const keywordList = [];
  for (let i = 0; i < NOTIFICATION_MAX_KEYWORD_COUNT; i++) {
    const number = NOTIFICATION_NUMERICAL_KEY_LIST[i];
    const keyword = notificationData[`${number}_keyword`];
    if (keyword) {
      keywordList.push(keyword);
    }
  }
  return keywordList;
};

export const notificationPopulateResourceList = (
  userNotificationData,
  resourceUuidList
) => {
  // Any undefined resource UUID is excluded from the request altogether (assume nullable in database).
  for (let i = 0; i < NOTIFICATION_MAX_RESOURCE_COUNT; i++) {
    if (resourceUuidList[i]) {
      userNotificationData[
        `${NOTIFICATION_NUMERICAL_KEY_LIST[i]}_resource_uuid`
      ] = resourceUuidList[i];
    }
  }
};

export const notificationPopulateKeywordList = (
  userNotificationData,
  keywordList
) => {
  // Any undefined keyword is included as empty string (assume not nullable in database).
  for (let i = 0; i < NOTIFICATION_MAX_KEYWORD_COUNT; i++) {
    userNotificationData[`${NOTIFICATION_NUMERICAL_KEY_LIST[i]}_keyword`] =
      keywordList[i] ? keywordList[i] : "";
  }
};

// Pure async function: can throw errors.
// NOTE: the template MUST include all of the expected keys!
export const notificationCreate = async (creationTemplate) => {
  if (!creationTemplate) {
    return;
  }

  const creationTemplateKeyList = Object.keys(creationTemplate);

  NOTIFICATION_CREATION_TEMPLATE_KEY_LIST.forEach((creationTemplateKey) => {
    if (!creationTemplateKeyList.includes(creationTemplateKey)) {
      return;
    }
  });

  const possibleNotificationTypeList = Object.values(NotificationType);
  if (!possibleNotificationTypeList.includes(creationTemplate.type)) {
    return;
  }

  const createUserNotificationDataOutline = {
    type: creationTemplate.type,
    message: creationTemplate.message ? creationTemplate.message : "",
    action_completed: false,
    viewed: false,
  };

  notificationPopulateResourceList(
    createUserNotificationDataOutline,
    creationTemplate.resourceUuidList
  );
  notificationPopulateKeywordList(
    createUserNotificationDataOutline,
    creationTemplate.keywordList
  );

  const createUserNotificationDataList = [];
  creationTemplate.notifyingUserUuidList.forEach((notifyingUserUuid) => {
    const createUserNotificationData = { ...createUserNotificationDataOutline };
    createUserNotificationData["user_uuid"] = notifyingUserUuid;
    createUserNotificationDataList.push(createUserNotificationData);
  });

  const retrievedUserNotificationDataList =
    await improvedRequestCreateEntryList(
      DB_TABLE_USER_NOTIFICATION,
      createUserNotificationDataList
    );
  return retrievedUserNotificationDataList;
};

// Pure async function: can throw errors.
export const notificationSetActionCompleted = async (searchCriteria = {}) => {
  const searchResponse = await improvedRequestSearchEntry(
    DB_TABLE_USER_NOTIFICATION,
    {
      action_completed: false,
      ...searchCriteria,
    }
  );

  const userNotificationDataList = searchResponse.data["content"];

  if (userNotificationDataList && userNotificationDataList.length > 0) {
    const updateUserNotificationDataList = [];
    userNotificationDataList.forEach((userNotificationData) => {
      const newUserNotificationData = { ...userNotificationData };
      newUserNotificationData["action_completed"] = true;
      updateUserNotificationDataList.push({
        uuid: userNotificationData["uuid"],
        content: newUserNotificationData,
      });
    });
    const retrievedUserNotificationDataList =
      await improvedRequestUpdateEntryList(
        DB_TABLE_USER_NOTIFICATION,
        updateUserNotificationDataList
      );
    return retrievedUserNotificationDataList;
  }
  return [];
};

// Pure async function: can throw errors.
export const notificationSetActionCompletedGivenUserUuidList = async (
  notifyingUserUuidList,
  searchCriteria = {}
) => {
  const searchOperationList = notifyingUserUuidList.map((notifyingUserUuid) =>
    improvedRequestSearchEntry(DB_TABLE_USER_NOTIFICATION, {
      user_uuid: notifyingUserUuid,
      action_completed: false,
      ...searchCriteria,
    })
  );

  const searchResponseList = await Promise.all(searchOperationList);
  const updateUserNotificationDataList = [];

  searchResponseList.forEach((searchResponse) => {
    const userNotificationDataList = searchResponse.data["content"];

    if (userNotificationDataList && userNotificationDataList.length > 0) {
      userNotificationDataList.forEach((userNotificationData) => {
        const newUserNotificationData = { ...userNotificationData };
        newUserNotificationData["action_completed"] = true;
        updateUserNotificationDataList.push({
          uuid: userNotificationData["uuid"],
          content: newUserNotificationData,
        });
      });
    }
  });
  const retrievedUserNotificationDataList =
    await improvedRequestUpdateEntryList(
      DB_TABLE_USER_NOTIFICATION,
      updateUserNotificationDataList
    );
  return retrievedUserNotificationDataList;
};