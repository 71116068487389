// Theme and Style
import { createTheme, Fab, Popover, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import ProfileButtonReaction from "../../component/profile/ProfileButtonReaction";
import {
  DB_TABLE_USER_REACTION,
  requestCreateEntry,
  requestDeleteEntry,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import {
  stateListAddItem,
  stateListRemoveItemByValue,
  stateObjectDeleteItem,
  stateObjectSetItem,
} from "../../../service/browser/ObjectService";
import DropDownMenuIconLabelOption from "../../component/shared/drop_down_menu/DropDownMenuIconLabelOption";
import DropDownMenuProfileReactionOption from "../../component/shared/drop_down_menu/DropDownMenuProfileReactionOption";

import { debounce } from "lodash";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";
import Separator from "../../component/shared/other/Separator";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";

export const makeProfileReactionPaneThemeDesignTokens = (mode) => ({});

export function makeProfileReactionPaneTheme(mode) {
  return {
    ...makeProfileReactionPaneThemeDesignTokens(mode),
    components: {},
  };
}

function ProfileButtonReactionAdd({ length = "32px", onClickRaw }) {
  return (
    <Fab
      onClick={onClickRaw}
      size="small"
      sx={{
        minWidth: length,
        maxWidth: length,
        minHeight: length,
        maxHeight: length,
        border: "1px solid black",
        boxShadow: `0 0 3px ${gray[600]}`,
        backgroundColor: gray[100],
        "&:hover": {
          backgroundColor: gray[200],
        },
      }}
    >
      <AddIcon />
    </Fab>
  );
}

export default function ProfileReactionPane({
  presentUserUuid,
  targetUserUuid,
}) {
  const profileReactionPaneTheme = createTheme(
    makeProfileReactionPaneTheme("light")
  );

  const modifiable = presentUserUuid != targetUserUuid;

  // const USER_REACTION_LABEL_LIST = [
  //   "Focused",
  //   "Smart",
  //   "Teacher",
  //   "Leader",
  //   "Punctual",
  // ];

  const USER_REACTION_LABEL_LIST = [
    "Focused",
    "Smart",
    "Teacher",
    "Leader",
    "Punctual",
    "Helpful",
    "Kind",
    "Quick",
    "Slow-paced",
    "Detailed",
    "Techy",
    "Mathematician",
    "Physicist",
    "Biologist",
    "Chemist",
    "Listener",
    "Collaborator",
    "Writer",
    "Active",
  ];

  const BUSY_TIMEOUT_DURATION_MS = 150;
  const DEBOUNCE_TIMEOUT_DURATION_MS = 400;
  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [assetBusy, setAssetBusy] = useState(false);
  const [userReactionCountByLabelTable, setUserReactionCountByLabelTable] =
    useState({});
  const [userReactionLabelList, setUserReactionLabelList] = useState([]);
  const [userReactionDataByUuidTable, setUserReactionDataByUuidTable] =
    useState({});
  // const [userReactionDataList, setUserReactionDataList] = useState([]);

  const sortList = (list) => {
    return list.sort((itemA, itemB) => {
      if (itemA > itemB) {
        return 1;
      }
      if (itemA < itemB) {
        return -1;
      }
      return 0;
    });
  };

  const excludeList = (listPossible, listGiven) => {
    const listResult = [];
    listPossible.forEach((item) => {
      if (!listGiven.includes(item)) {
        listResult.push(item);
      }
    });
    return listResult;
  };

  const userReactionLabelUnusedList = excludeList(
    USER_REACTION_LABEL_LIST,
    userReactionLabelList
  );

  const dropDownMenuItemList = userReactionLabelUnusedList.map(
    (userReactionLabel) => ({
      id: userReactionLabel,
      label: userReactionLabel,
    })
  );

  const reactionCounterStateChangeBy = (userReactionLabel, amount) => {
    const copyUserReactionCountByLabelTable = {
      ...userReactionCountByLabelTable,
    };
    if (!(userReactionLabel in copyUserReactionCountByLabelTable)) {
      copyUserReactionCountByLabelTable[userReactionLabel] = 0;
    }
    copyUserReactionCountByLabelTable[userReactionLabel] += amount;
    setUserReactionCountByLabelTable(copyUserReactionCountByLabelTable);
  };

  const reactionDataSet = (userReactionData) => {
    stateObjectSetItem(
      userReactionData["uuid"],
      userReactionData,
      userReactionDataByUuidTable,
      setUserReactionDataByUuidTable
    );
  };

  const reactionDataRemove = (userReactionData) => {
    stateObjectDeleteItem(
      userReactionData["uuid"],
      userReactionDataByUuidTable,
      setUserReactionDataByUuidTable
    );
  };

  const userReactionLabelListAdd = (userReactionLabel) => {
    const copyUserReactionLabelList = [...userReactionLabelList];
    copyUserReactionLabelList.push(userReactionLabel);
    setUserReactionLabelList(sortList(copyUserReactionLabelList));
  };

  const userReactionLabelListRemove = (userReactionLabel) => {
    stateListRemoveItemByValue(
      userReactionLabel,
      userReactionLabelList,
      setUserReactionLabelList
    );
  };

  const reactionCounterIncrement = async (userReactionLabel) => {
    const requestBody = {
      user_uuid: presentUserUuid,
      targeted_user_uuid: targetUserUuid,
      type: userReactionLabel,
      soft_deleted: false,
    };

    await requestSearchEntry(
      DB_TABLE_USER_REACTION,
      requestBody,
      (searchResponseData) => {
        // Check that the reaction does not already exist.
        if (searchResponseData && searchResponseData["total_elements"] == 0) {
          requestCreateEntry(
            DB_TABLE_USER_REACTION,
            requestBody,
            (userReactionData) => {
              if (userReactionData) {
                reactionCounterStateChangeBy(userReactionLabel, 1);
                reactionDataSet(userReactionData);

                if (!userReactionLabelList.includes(userReactionLabel)) {
                  userReactionLabelListAdd(userReactionLabel);
                }
              }
            }
          );
        }
      }
    );
  };

  const reactionCounterDecrement = async (userReactionLabel) => {
    const requestBody = {
      user_uuid: presentUserUuid,
      targeted_user_uuid: targetUserUuid,
      type: userReactionLabel,
      soft_deleted: false,
    };

    await requestSearchEntry(
      DB_TABLE_USER_REACTION,
      requestBody,
      (searchResponseData) => {
        // Check that the reaction does already exist.
        if (searchResponseData && searchResponseData["total_elements"] >= 1) {
          const userReactionData = searchResponseData["content"][0];

          // Technically, we could set the soft_delete to true.
          // But for now, simply remove the reaction.
          requestDeleteEntry(
            DB_TABLE_USER_REACTION,
            userReactionData["uuid"],
            (deleteResponseData) => {
              if (deleteResponseData) {
                if (userReactionCountByLabelTable[userReactionLabel] == 1) {
                  userReactionLabelListRemove(userReactionLabel);
                }

                reactionCounterStateChangeBy(userReactionLabel, -1);
                reactionDataRemove(userReactionData);
              }
            }
          );
        }
      }
    );
  };

  const reactionLoad = async () => {
    if (!presentUserUuid || !targetUserUuid) {
      setAssetLoadingError(true);
      return;
    }

    await requestSearchEntry(
      DB_TABLE_USER_REACTION,
      {
        targeted_user_uuid: targetUserUuid,
        soft_deleted: false,
      },
      (searchResponseData) => {
        if (searchResponseData && searchResponseData["content"]) {
          const newUserReactionDataList = searchResponseData["content"];
          const newUserReactionLabelList = [];
          const newUserReactionCountByLabelTable = {};
          const newUserReactionDataByUuidTable = {};

          newUserReactionDataList.forEach((userReactionData) => {
            const userReactionUuid = userReactionData["uuid"];
            const userReactionLabel = userReactionData["type"];

            if (!newUserReactionLabelList.includes(userReactionLabel)) {
              newUserReactionLabelList.push(userReactionLabel);
              newUserReactionCountByLabelTable[userReactionLabel] = 0;
            }
            newUserReactionCountByLabelTable[userReactionLabel]++;
            newUserReactionDataByUuidTable[userReactionUuid] = userReactionData;
          });

          setUserReactionDataByUuidTable(newUserReactionDataByUuidTable);
          setUserReactionLabelList(sortList(newUserReactionLabelList));
          setUserReactionCountByLabelTable(newUserReactionCountByLabelTable);
          setAssetLoading(false);

          setTimeout(() => {
            setAssetVisible(true);
          }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  };

  useEffect(() => {
    reactionLoad();
  }, []);

  const checkPresentUserReacted = (userReactionLabel) => {
    var reacted = false;

    for (const userReactionData of Object.values(userReactionDataByUuidTable)) {
      if (
        userReactionLabel == userReactionData["type"] &&
        presentUserUuid == userReactionData["user_uuid"] &&
        targetUserUuid == userReactionData["targeted_user_uuid"]
      ) {
        reacted = true;
        break;
      }
    }

    return reacted;
  };

  const handleClickAddReactionButton = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const processReactionClick = (userReactionLabel) => {
    const reacted = checkPresentUserReacted(userReactionLabel);
    if (reacted) {
      reactionCounterDecrement(userReactionLabel);
    } else {
      reactionCounterIncrement(userReactionLabel);
    }
  };

  const debounceReactionClick = useCallback(
    debounce((userReactionLabel) => {
      if (!assetBusy) {
        setAssetBusy(true);
        processReactionClick(userReactionLabel);
        setTimeout(() => {
          setAssetBusy(false);
        }, BUSY_TIMEOUT_DURATION_MS);
      }
    }, DEBOUNCE_TIMEOUT_DURATION_MS),
    [processReactionClick]
  );

  const handleReactionClick = useCallback(
    (userReactionLabel) => {
      debounceReactionClick(userReactionLabel);
    },
    [debounceReactionClick]
  );

  const processDropDownMenuItemClick = (userReactionLabel) => {
    // Sanity Check: make sure that
    // (1) the label does not already exist
    // (2) the user has not already reacted to it
    const labelAlreadyExists =
      userReactionLabelList.includes(userReactionLabel);
    const labelAlreadyReacted = checkPresentUserReacted(userReactionLabel);

    if (!labelAlreadyExists && !labelAlreadyReacted) {
      reactionCounterIncrement(userReactionLabel);
    }
  };

  const debounceDropDownMenuItemClick = useCallback(
    debounce((userReactionLabel) => {
      if (!assetBusy) {
        setAssetBusy(true);
        processDropDownMenuItemClick(userReactionLabel);
        setTimeout(() => {
          setAssetBusy(false);
        }, BUSY_TIMEOUT_DURATION_MS);
      }
    }, DEBOUNCE_TIMEOUT_DURATION_MS),
    [processDropDownMenuItemClick]
  );

  const handleDropDownMenuItemClick = useCallback(
    (userReactionLabel) => {
      debounceDropDownMenuItemClick(userReactionLabel);
    },
    [debounceDropDownMenuItemClick]
  );

  if (popoverOpen && userReactionLabelUnusedList.length == 0) {
    handleClosePopover();
  }

  return (
    <ThemeProvider theme={profileReactionPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name={"ProfileReactionPane"} />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          <TextHeadingMediumA label="Reactions:" />

          <Separator />

          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {userReactionLabelList &&
              userReactionLabelList.map((userReactionLabel) => (
                <Grid item key={userReactionLabel}>
                  <ProfileButtonReaction
                    label={userReactionLabel}
                    count={userReactionCountByLabelTable[userReactionLabel]}
                    highlighted={checkPresentUserReacted(userReactionLabel)}
                    clickable={modifiable}
                    onClick={() => handleReactionClick(userReactionLabel)}
                  />
                </Grid>
              ))}
            {modifiable && userReactionLabelUnusedList.length > 0 && (
              <Grid item>
                <ProfileButtonReactionAdd
                  onClickRaw={handleClickAddReactionButton}
                />
              </Grid>
            )}
            <Popover
              id={popoverId}
              open={popoverOpen}
              anchorEl={popoverAnchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              sx={{
                ".MuiPopover-paper": {
                  backgroundColor: gray[100],
                  boxShadow: `0 0 3px ${gray[600]}`,
                  outline: "1px solid black",
                },
              }}
            >
              <DropDownMenuProfileReactionOption
                itemList={dropDownMenuItemList}
                onClickItem={handleDropDownMenuItemClick}
              />
            </Popover>
          </Grid>
        </Box>
      )}
    </ThemeProvider>
  );
}
