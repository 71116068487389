import React, { createContext, useState, useContext } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [presentSessionUuid, setPresentSessionUuid] = useState("");
  const [presentUserUuid, setPresentUserUuid] = useState("");

  return (
    <UserContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        presentSessionUuid,
        setPresentSessionUuid,
        presentUserUuid,
        setPresentUserUuid,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
