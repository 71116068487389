// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../../style/AppTheme";

// Extra
import * as React from "react";

// StudiousCat Components
import IconAccept from "../../../icon/IconAccept";

export const makeButtonCheckBoxThemeDesignTokens = (mode) => ({});

export function makeButtonCheckBoxTheme(mode) {
  return {
    ...makeButtonCheckBoxThemeDesignTokens(mode),
    components: {},
  };
}

export default function ButtonCheckBox({
  checked = false,
  onClick,
  boxWidth = 70,
  outlineEnabled = false,
  fillEnabled = true,
  fillColor = alpha(red[800], 0.5),
}) {
  const buttonCheckBoxTheme = createTheme(makeButtonCheckBoxTheme("light"));

  const handleButtonCheckBoxClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const getCheckedIcon = () => {
    return (
      <IconAccept
        size={boxWidth * 0.85}
        opacity={0.5}
        // onHoverOpacity={0.6}
        onHoverOpacity={0.5}
        fill={purple[900]}
      />
    );
  };

  return (
    <ThemeProvider theme={buttonCheckBoxTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          onClick={handleButtonCheckBoxClick}
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            boxSizing: "border-box",
            aspectRatio: "1",
            minWidth: boxWidth,
            height: "100%",

            borderRadius: "6px",

            ...(outlineEnabled
              ? { border: `3px solid ${alpha(purple[900], 0.8)}` }
              : {}),

            ...(fillEnabled ? { backgroundColor: fillColor } : {}),

            ...(!outlineEnabled && fillEnabled
              ? { boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.25)" }
              : {}),

            outline: "4px solid",
            outlineColor: "transparent",
            transition: "outline-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",

            "&:hover": {
              outlineColor: alpha(purple[600], 0.2),
            },
            "&.Mui-focused": {
              outlineColor: alpha(purple[600], 0.4),
            },
          }}
        >
          {checked && getCheckedIcon()}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
