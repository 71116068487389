// Theme and Style
import { createTheme, Popover, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Avatar, Link } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, blue, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useRef, useEffect, useContext } from "react";

import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import { CalendarContext } from "../../../context/CalendarContext";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import {
  DATE_BACKEND_FORMAT,
  DATE_FRONTEND_FORMAT_TIME_ONLY,
  dateConvertToString,
} from "../../../service/browser/DateService";
import { listToObjectByKey } from "../../../service/browser/ObjectService";
import { BorderColor } from "@mui/icons-material";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import { CalendarViewType } from "./CalendarControlsPane";
import { CalendarMeetingQuickViewPreset } from "./CalendarMeetingQuickViewPane";

export const makeCalendarViewPaneThemeDesignTokens = (mode) => ({
  typography: {
    popupHeadingText: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 600,
    },
    popupBodyText: {
      fontFamily: "PT Sans",
      fontSize: 14,
      fontWeight: 400,
    },
  },
});

export function makeCalendarViewPaneTheme(mode) {
  return {
    ...makeCalendarViewPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarViewPane({
  view,
  calendarQuickViewPreset,
  presentUserUuid,
  filteredCourseTable,
  meetingDataByMeetingUuidTable,
  userUuidListByMeetingUuidTable,
  courseDataByCourseUuidTable,
  calendarHeight,
  onSelectedTimeSlot,
  onSelectedExistingMeeting,
}) {
  const calendarViewPaneTheme = createTheme(makeCalendarViewPaneTheme("light"));
  const [calendarListenerInit, setCalendarListenerInit] = useState(false);
  const [
    calendarToastMeetingContentReady,
    setCalendarToastMeetingContentReady,
  ] = useState(false);

  const { calendarRef } = useContext(CalendarContext);
  const [toastCalendarMounted, setToastCalendarMounted] = useState(false);

  const TOAST_CALENDAR_MOUNT_DELAY_DURATION_MS = 50;
  const TOAST_CALENDAR_LISTENER_INIT_DELAY_DURATION_MS = 100;

  const [
    toastMeetingListByCourseUuidTable,
    setToastMeetingListByCourseUuidTable,
  ] = useState({});
  const [toastMeetingVisibleList, setToastMeetingVisibleList] = useState([]);

  useEffect(() => {
    setToastCalendarMounted(false);

    setTimeout(() => {
      setToastCalendarMounted(true);
    }, TOAST_CALENDAR_MOUNT_DELAY_DURATION_MS);
  }, [calendarToastMeetingContentReady]);

  const CALENDAR_MEETING_COLOR_DEFAULT = blue[500];

  const generateElementSpanRegular = (text) => {
    return `<span style="color: black;">${text}</span>`;
  };

  const generateElementSpanBold = (text, warning = false) => {
    if (warning) {
      return `<span style="font-weight: 600; font-style: italic; color: #520000;">${text}</span>`;
    } else {
      return `<span style="font-weight: 600; color: black;">${text}</span>`;
    }
  };

  const generateElementFlexColumn = (style, elementList) => {
    return `<div style="display: flex; flex-direction: column; ${style}">${elementList.join(
      " "
    )}</div>`;
  };

  const TOAST_CALENDAR_TEMPLATE = {
    time(event) {
      try {
        const meetingParticipantMaxReached =
          event.raw.currentParticipantCount === event.raw.maxParticipantCount;

        const startTimeText = dateConvertToString(
          event.start.d.d,
          DATE_FRONTEND_FORMAT_TIME_ONLY
        );
        const endTimeText = dateConvertToString(
          event.end.d.d,
          DATE_FRONTEND_FORMAT_TIME_ONLY
        );

        const meetingNameElement = generateElementSpanBold(
          `${event.title}`,
          meetingParticipantMaxReached
        );
        const meetingCourseElement = generateElementSpanRegular(
          `${event.raw.courseLabel}`
        );
        const meetingTimeElement = generateElementSpanBold(
          `${startTimeText} - ${endTimeText}`
        );
        const meetingLocationElement = generateElementSpanRegular(
          `${event.raw.location}`
        );
        const meetingParticipantCount = meetingParticipantMaxReached
          ? "FULL"
          : `${event.raw.currentParticipantCount}/${event.raw.maxParticipantCount}`;

        const meetingParticipantCountElement = generateElementSpanBold(
          meetingParticipantCount,
          meetingParticipantMaxReached
        );

        const flexColumnElement = generateElementFlexColumn("", [
          meetingNameElement,
          meetingTimeElement,
          meetingParticipantCountElement,
          meetingLocationElement,
          meetingCourseElement,
        ]);

        return flexColumnElement;
      } catch (error) {
        return `<span style="color: black;">${event.title}</span>`;
      }
    },
  };

  const capitalizeWords = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const convertMeetingDataToToastMeetingData = (
    meetingData,
    courseData,
    currentParticipantCount
  ) => {
    const meetingFilterData = filteredCourseTable[meetingData["course_uuid"]];
    const meetingColor = meetingFilterData
      ? meetingFilterData.color
      : CALENDAR_MEETING_COLOR_DEFAULT;
    const courseLabel = `${courseData["code"]} ${courseData["number"]}`;

    return {
      id: meetingData["uuid"],
      title: capitalizeWords(meetingData["name"]),
      start: meetingData["start_date_time"],
      end: meetingData["end_date_time"],
      backgroundColor: alpha(meetingColor, 0.5),
      dragBackgroundColor: alpha(meetingColor, 0.5),
      raw: {
        courseLabel: courseLabel,
        location: meetingData["address"],
        currentParticipantCount: currentParticipantCount,
        maxParticipantCount: meetingData["user_limit"],
      },
    };
  };

  const initToastCalendarMeetingContent = () => {
    const newToastMeetingListByCourseUuidTable = {};
    Object.entries(meetingDataByMeetingUuidTable).forEach(
      ([meetingUuid, meetingData]) => {
        const courseUuid = meetingData["course_uuid"];
        const courseData = courseDataByCourseUuidTable[courseUuid];
        const userUuidList = userUuidListByMeetingUuidTable[meetingUuid];

        const currentParticipantCount =
          !userUuidList || userUuidList === undefined ? 0 : userUuidList.length;
        const maxParticipantCount = meetingData["user_limit"];
        const presentUserIncluded =
          !userUuidList || userUuidList === undefined
            ? false
            : userUuidList.includes(presentUserUuid);

        // Rules for Hiding and Showing "Full" Meetings:
        //  If the meeting has reached the max number of participants...
        // (1) AND the present user is in the meeting, then SHOW the meeting.
        // (2) AND the present user is NOT in the meeting, then HIDE the meeting.

        if (
          currentParticipantCount < maxParticipantCount ||
          (currentParticipantCount >= maxParticipantCount &&
            presentUserIncluded)
        ) {
          const toastMeetingData = convertMeetingDataToToastMeetingData(
            meetingData,
            courseData,
            currentParticipantCount
          );

          if (courseUuid in newToastMeetingListByCourseUuidTable) {
            newToastMeetingListByCourseUuidTable[courseUuid].push(
              toastMeetingData
            );
          } else {
            newToastMeetingListByCourseUuidTable[courseUuid] = [
              toastMeetingData,
            ];
          }
        }
      }
    );
    return newToastMeetingListByCourseUuidTable;
  };

  const initToastMeetingListByCourseListing = (
    givenToastMeetingListByCourseUuidTable
  ) => {
    const newToastMeetingVisibleList = [];
    Object.entries(filteredCourseTable).forEach(([courseUuid, filterData]) => {
      const toastMeetingList =
        givenToastMeetingListByCourseUuidTable[courseUuid];
      if (
        filterData.included &&
        toastMeetingList &&
        toastMeetingList.length > 0
      ) {
        newToastMeetingVisibleList.push(...toastMeetingList);
      }
    });
    return newToastMeetingVisibleList;
  };

  const initToastCalendarListeners = () => {
    const calendarInstance = getCalendarInstance();
    if (calendarInstance) {
      calendarInstance.on("selectDateTime", (selectionData) => {
        const startDate = selectionData.start;
        const endDate = selectionData.end;
        onSelectedTimeSlot?.(startDate, endDate);
      });
      calendarInstance.on("clickEvent", (selectionData) => {
        const meetingUuid = selectionData.event.id;
        onSelectedExistingMeeting?.(meetingUuid);
      });
    }
  };

  useEffect(() => {
    if (
      !meetingDataByMeetingUuidTable ||
      !userUuidListByMeetingUuidTable ||
      !courseDataByCourseUuidTable ||
      !filteredCourseTable ||
      Object.keys(filteredCourseTable).length === 0
    ) {
      setCalendarToastMeetingContentReady(false);
      return;
    }

    const newToastMeetingListByCourseUuidTable =
      initToastCalendarMeetingContent();
    setToastMeetingListByCourseUuidTable(newToastMeetingListByCourseUuidTable);
    setCalendarToastMeetingContentReady(true);

    const newToastMeetingVisibleList = initToastMeetingListByCourseListing(
      newToastMeetingListByCourseUuidTable
    );
    setToastMeetingVisibleList(newToastMeetingVisibleList);
  }, [
    meetingDataByMeetingUuidTable,
    userUuidListByMeetingUuidTable,
    courseDataByCourseUuidTable,
    filteredCourseTable,
  ]);

  useEffect(() => {
    if (
      calendarToastMeetingContentReady &&
      toastMeetingListByCourseUuidTable &&
      filteredCourseTable &&
      Object.keys(filteredCourseTable).length > 0
    ) {
      const newToastMeetingVisibleList = initToastMeetingListByCourseListing(
        toastMeetingListByCourseUuidTable
      );
      setToastMeetingVisibleList(newToastMeetingVisibleList);
    } else {
      setToastMeetingVisibleList([]);
    }
  }, [
    calendarToastMeetingContentReady,
    toastMeetingListByCourseUuidTable,
    filteredCourseTable,
  ]);

  useEffect(() => {
    if (
      calendarListenerInit ||
      !calendarToastMeetingContentReady ||
      !toastCalendarMounted
    ) {
      return;
    }

    setTimeout(() => {
      setCalendarListenerInit(true);
      initToastCalendarListeners();
    }, TOAST_CALENDAR_LISTENER_INIT_DELAY_DURATION_MS);
  }, [calendarToastMeetingContentReady, toastCalendarMounted]);

  const getCalendarInstance = () => {
    if (calendarRef && calendarRef.current) {
      return calendarRef.current.getInstance();
    } else {
      return undefined;
    }
  };

  const clearCalendarSelection = () => {
    const calendarInstance = getCalendarInstance();
    calendarInstance?.clearGridSelections();
  };

  useEffect(() => {
    if (
      calendarQuickViewPreset !== CalendarMeetingQuickViewPreset.NEW_MEETING
    ) {
      clearCalendarSelection();
    }
  }, [calendarQuickViewPreset]);

  const toastCalendarView =
    view === CalendarViewType.AGENDA
      ? CalendarViewType.MONTH.toLowerCase()
      : view.toLowerCase();

  return (
    <ThemeProvider theme={calendarViewPaneTheme}>
      {calendarToastMeetingContentReady && (
        <Box
          sx={{
            width: "100%",
            border: `2px solid ${gray[200]}`,
          }}
        >
          {toastCalendarMounted && (
            <Calendar
              ref={calendarRef}
              usageStatistics={false}
              view={toastCalendarView}
              height={calendarHeight}
              events={toastMeetingVisibleList}
              template={TOAST_CALENDAR_TEMPLATE}
              month={{
                moreView: {
                  height: "300px",
                },
              }}
              week={{
                taskView: false,
                eventView: ["time"],
                dayGrid: {
                  height: "400px",
                  borderTop: "2px solid red",
                },
                timeGrid: {
                  height: "400px",
                  borderTop: "2px solid red",
                },
              }}
              theme={{
                week: {
                  dayName: {
                    borderBottom: `2px solid ${gray[200]}`,
                  },
                },
                common: {
                  backgroundColor: colorBg,
                },
              }}
            />
          )}
        </Box>
      )}
    </ThemeProvider>
  );
}
