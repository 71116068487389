// Theme and Style
import {
  Avatar,
  createTheme,
  Link,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import {
  DB_TABLE_USER,
  requestGetEntry,
} from "../../../service/browser/BackendRequestService";

export const makeLobbyProfilePortraitPaneThemeDesignTokens = (mode) => ({
  typography: {
    bigUserName: {
      fontFamily: "Source Serif Pro",
      color: purple[900],
      fontWeight: 600,
      //   fontSize: 40,
      textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
      textTransform: "none",
      lineHeight: 0.9,
    },
  },
});

export function makeLobbyProfilePortraitPaneTheme(mode) {
  return {
    ...makeLobbyProfilePortraitPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function LobbyProfilePortraitPane({
  targetUserUuid,
  scale = 1.0,
  userNameLinked = false,
}) {
  const lobbyProfilePortraitPaneTheme = createTheme(
    makeLobbyProfilePortraitPaneTheme("light")
  );

  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [targetUserData, setTargetUserData] = useState({});

  useEffect(() => {
    if (!targetUserUuid) {
      setAssetLoadingError(true);
    }

    requestGetEntry(
      DB_TABLE_USER,
      targetUserUuid,
      (userResponseData) => {
        if (userResponseData) {
          setTargetUserData(userResponseData);
          setAssetLoading(false);

          setTimeout(() => {
            setAssetVisible(true);
          }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
        } else {
          setAssetLoadingError(true);
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  }, [targetUserUuid]);

  const profilePictureUrl =
    assetLoading || assetLoadingError
      ? undefined
      : targetUserData["profile_picture_url"];
  const userName =
    assetLoading || assetLoadingError ? "user" : targetUserData["user_name"];

  const avatarLength = 180 * scale;
  const fontSize = 40 * scale;

  return (
    <ThemeProvider theme={lobbyProfilePortraitPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "12px",
          transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          opacity: assetVisible ? 1 : 0,
        }}
      >
        <Box
          sx={{
            width: `${avatarLength}px`,
            height: `${avatarLength}px`,
          }}
        >
          <Avatar
            src={profilePictureUrl}
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        {userNameLinked && (
          <Link href={`/user/${userName}`} underline="always" color="inherit">
            <Typography
              variant="bigUserName"
              sx={{
                fontSize: fontSize,
              }}
            >
              {userName}
            </Typography>
          </Link>
        )}
        {!userNameLinked && (
          <Typography
            variant="bigUserName"
            sx={{
              fontSize: fontSize,
            }}
          >
            {userName}
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
}
