// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Box, OutlinedInput, InputAdornment, Typography } from "@mui/material";

// Custom Theme and Style
import { purple, gray, red } from "../../../style/AppTheme";

// Extra
import * as React from "react";
import { useState, useRef } from "react";

// StudiousCat Components
import TextRequiredAsterisk from "../text/TextRequiredAsterisk";

export const makeInputTextDesignTokens = (mode) => ({});

export function makeInputTextTheme(mode) {
  return {
    ...makeInputTextDesignTokens(mode),
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: ({ theme, ownerState }) => ({
            border: "none",
          }),
          root: ({ theme, ownerState }) => ({
            "& .MuiInputBase-input": {
              "&::placeholder": {
                opacity: 0.7,
                color: gray[500],
              },
            },

            fontFamily: "PT Sans",
            fontSize: 16,

            boxSizing: "border-box",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",

            flexGrow: 1,
            height: "100%",
            maxHeight: "40px",

            borderRadius: "3px",
            borderBottom: "1px solid",
            borderColor: gray[200],

            backgroundColor: alpha(gray[100], 0.4),

            outline: "4px solid",
            outlineColor: "transparent",
            transition:
              "outline-color 300ms cubic-bezier(0.4, 0, 0.2, 1), background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",

            "&:hover": {
              outlineColor: alpha(purple[600], 0.2),
            },
            "&.Mui-focused": {
              outlineColor: alpha(purple[600], 0.4),
            },
            ...(ownerState.error && {
              backgroundColor: alpha(red[300], 0.4),
              outlineColor: alpha(red[600], 0.4),
            }),
          }),
        },
      },
    },
  };
}

export default function InputText({
  numLines = 1,
  placeholderValue,
  value,
  setValue,
  onUnfocus,
  required = false,
  error = false,
  disabled = false,
  maxCharCount,
}) {
  const inputTextTheme = createTheme(makeInputTextTheme("light"));
  const outlinedInputRef = useRef(null);

  const handleChange = (event) => {
    const newValue = event.target.value;

    if (maxCharCount && newValue.length < maxCharCount) {
      setValue?.(newValue);
    } else if (!maxCharCount) {
      setValue?.(newValue);
    }
  };

  const handleBlur = () => {
    onUnfocus?.();
  };

  return (
    <ThemeProvider theme={inputTextTheme}>
      <OutlinedInput
        ref={outlinedInputRef}
        fullWidth
        placeholder={placeholderValue}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        multiline={true}
        rows={numLines}
        error={error}
        disabled={disabled}
        sx={{
          maxHeight: `${40 * numLines}px`,
        }}
        startAdornment={
          required && (
            <InputAdornment position="start">
              <TextRequiredAsterisk />
            </InputAdornment>
          )
        }
      />
    </ThemeProvider>
  );
}
