import { NotificationType } from "./NotificationService";

export const NOTIFICATION_CREATION_TEMPLATE_KEY_LIST = [
    "notifyingUserUuidList",
    "type",
    "message",
    "resourceUuidList",
    "keywordList",
  ];

export const notificationCreationTemplateUserFriendRequest = (
  friendingUserUuid,
  friendedUserUuid,
  message
) => {
  return {
    notifyingUserUuidList: [friendedUserUuid],
    type: NotificationType.USER_FRIEND_REQUEST,
    message: message,
    resourceUuidList: [friendingUserUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateUserFriendAccept = (
  friendingUserUuid,
  friendedUserUuid,
  message
) => {
  return {
    notifyingUserUuidList: [friendingUserUuid],
    type: NotificationType.USER_FRIEND_ACCEPT,
    message: message,
    resourceUuidList: [friendedUserUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateUserFriendDeny = (
  friendingUserUuid,
  friendedUserUuid,
  message
) => {
  return {
    notifyingUserUuidList: [friendingUserUuid],
    type: NotificationType.USER_FRIEND_DENY,
    message: message,
    resourceUuidList: [friendedUserUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateUserEndorsementRequest = (
  endorsingUserUuid,
  endorsedUserUuid,
  userEndorsementUuid
) => {
  return {
    notifyingUserUuidList: [endorsedUserUuid],
    type: NotificationType.USER_ENDORSEMENT_REQUEST,
    message: "",
    resourceUuidList: [endorsingUserUuid, userEndorsementUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateUserEndorsementAccept = (
  endorsingUserUuid,
  endorsedUserUuid,
  userEndorsementUuid
) => {
  return {
    notifyingUserUuidList: [endorsingUserUuid],
    type: NotificationType.USER_ENDORSEMENT_ACCEPT,
    message: "",
    resourceUuidList: [endorsedUserUuid, userEndorsementUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateUserEndorsementDeny = (
  endorsingUserUuid,
  endorsedUserUuid,
  userEndorsementUuid
) => {
  return {
    notifyingUserUuidList: [endorsingUserUuid],
    type: NotificationType.USER_ENDORSEMENT_DENY,
    message: "",
    resourceUuidList: [endorsedUserUuid, userEndorsementUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateMeetingParticipantLeaderInfoChange = (
  participantUserUuidList,
  meetingLeaderUserUuid,
  meetingUuid,
  message,
  changedAspectName,
  changedAspectPreviousValue,
  changedAspectNewValue
) => {
  return {
    notifyingUserUuidList: [...participantUserUuidList],
    type: NotificationType.MEETING_PARTICIPANT_LEADER_INFO_CHANGE,
    message: message,
    resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
    keywordList: [
      changedAspectName,
      changedAspectPreviousValue,
      changedAspectNewValue,
    ],
  };
};
export const notificationCreationTemplateMeetingParticipantLeaderDeleteMeeting =
  (participantUserUuidList, meetingLeaderUserUuid, meetingUuid, message) => {
    return {
      notifyingUserUuidList: [...participantUserUuidList],
      type: NotificationType.MEETING_PARTICIPANT_LEADER_DELETE_MEETING,
      message: message,
      resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
      keywordList: [],
    };
  };
export const notificationCreationTemplateMeetingParticipantLeaderRemoveUser = (
  removedUserUuid,
  meetingLeaderUserUuid,
  meetingUuid,
  message
) => {
  return {
    notifyingUserUuidList: [removedUserUuid],
    type: NotificationType.MEETING_PARTICIPANT_LEADER_REMOVE_USER,
    message: message,
    resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateMeetingParticipantLeaderAnnouncement =
  (participantUserUuidList, meetingLeaderUserUuid, meetingUuid, message) => {
    return {
      notifyingUserUuidList: [...participantUserUuidList],
      type: NotificationType.MEETING_PARTICIPANT_LEADER_ANNOUNCEMENT,
      message: message,
      resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
      keywordList: [],
    };
  };
export const notificationCreationTemplateMeetingParticipantLeaderInviteRequest =
  (participantUserUuidList, meetingLeaderUserUuid, meetingUuid, message) => {
    return {
      notifyingUserUuidList: [...participantUserUuidList],
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
      message: message,
      resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
      keywordList: [],
    };
  };
export const notificationCreationTemplateMeetingParticipantLeaderInviteCancel =
  (participantUserUuidList, meetingLeaderUserUuid, meetingUuid, message) => {
    return {
      notifyingUserUuidList: [...participantUserUuidList],
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_CANCEL,
      message: message,
      resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
      keywordList: [],
    };
  };
export const notificationCreationTemplateMeetingParticipantLeaderJoinAccept = (
  participantUserUuidList,
  meetingLeaderUserUuid,
  meetingUuid,
  message
) => {
  return {
    notifyingUserUuidList: [...participantUserUuidList],
    type: NotificationType.MEETING_PARTICIPANT_LEADER_JOIN_ACCEPT,
    message: message,
    resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateMeetingParticipantLeaderJoinDeny = (
  participantUserUuidList,
  meetingLeaderUserUuid,
  meetingUuid,
  message
) => {
  return {
    notifyingUserUuidList: [...participantUserUuidList],
    type: NotificationType.MEETING_PARTICIPANT_LEADER_JOIN_DENY,
    message: message,
    resourceUuidList: [meetingLeaderUserUuid, meetingUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateMeetingLeaderParticipantInviteAccept =
  (invitedParticipantUserUuid, meetingLeaderUserUuid, meetingUuid, message) => {
    return {
      notifyingUserUuidList: [meetingLeaderUserUuid],
      type: NotificationType.MEETING_LEADER_PARTICIPANT_INVITE_ACCEPT,
      message: message,
      resourceUuidList: [invitedParticipantUserUuid, meetingUuid],
      keywordList: [],
    };
  };
export const notificationCreationTemplateMeetingLeaderParticipantInviteDeny = (
  invitedParticipantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid,
  message
) => {
  return {
    notifyingUserUuidList: [meetingLeaderUserUuid],
    type: NotificationType.MEETING_LEADER_PARTICIPANT_INVITE_DENY,
    message: message,
    resourceUuidList: [invitedParticipantUserUuid, meetingUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateMeetingLeaderParticipantJoinDirect = (
  participantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid,
  message
) => {
  return {
    notifyingUserUuidList: [meetingLeaderUserUuid],
    type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_DIRECT,
    message: message,
    resourceUuidList: [participantUserUuid, meetingUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateMeetingLeaderParticipantJoinRequest = (
  participantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid,
  message
) => {
  return {
    notifyingUserUuidList: [meetingLeaderUserUuid],
    type: NotificationType.MEETING_LEADER_PARTICIPANT_JOIN_REQUEST,
    message: message,
    resourceUuidList: [participantUserUuid, meetingUuid],
    keywordList: [],
  };
};
export const notificationCreationTemplateMeetingLeaderParticipantLeave = (
  participantUserUuid,
  meetingLeaderUserUuid,
  meetingUuid,
  message
) => {
  return {
    notifyingUserUuidList: [meetingLeaderUserUuid],
    type: NotificationType.MEETING_LEADER_PARTICIPANT_LEAVE,
    message: message,
    resourceUuidList: [participantUserUuid, meetingUuid],
    keywordList: [],
  };
};
