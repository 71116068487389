// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import LobbyProfilePortraitPane from "../../pane/lobby/LobbyProfilePortraitPane";

export const makeLobbyProfileWelcomeSuperPaneThemeDesignTokens = (mode) => ({
  typography: {
    titleText: {
      fontFamily: "PT Sans",
      fontSize: 72,
      fontWeight: 600,
      color: purple[900],
    },
    headingText: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 600,
      color: purple[900],
    },
  },
});

export function makeLobbyProfileWelcomeSuperPaneTheme(mode) {
  return {
    ...makeLobbyProfileWelcomeSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function LobbyProfileWelcomeSuperPane({
  targetUserUuid,
  onNext,
}) {
  const lobbyProfileWelcomeSuperPaneTheme = createTheme(
    makeLobbyProfileWelcomeSuperPaneTheme("light")
  );

  return (
    <ThemeProvider theme={lobbyProfileWelcomeSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "25px",
          paddingLeft: "50px",
          paddingRight: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="titleText">Welcome!</Typography>
          <Typography variant="headingText" textAlign="center">
            We are StudiousCat, a brand new service for empowering students to
            schedule engaging meetings for all things school, casual, and more.
          </Typography>
        </Box>
        <LobbyProfilePortraitPane targetUserUuid={targetUserUuid} scale={1.25} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography variant="headingText" textAlign="center">
            Click on the button below to create a new account!
          </Typography>
          <ButtonRectangleLabel
            variant="success"
            label="Start Your Studious Journey"
            onClick={() => onNext?.()}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
