// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Box, Grid } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../../style/AppTheme";

// Extra
import * as React from "react";

// StudiousCat Components
import TextHeadingMediumA from "../text/TextHeadingMediumA";
import IconDropDown from "../../../icon/IconDropDown";
import TextRequiredAsterisk from "../text/TextRequiredAsterisk";

export const makeDropDownBoxContainerThemeDesignTokens = (mode) => ({});

export function makeDropDownBoxContainerTheme(mode) {
  return {
    ...makeDropDownBoxContainerThemeDesignTokens(mode),
    components: {},
  };
}

export default function DropDownBoxContainer({
  label = "",
  labelWidth = "200px",
  onClick,
  required = false,
  iconVisible = true,
  open = false,
  fixedWidth = "120px",
  useFixedWidth = false,
  fullWidth = false,
  children,
  // NOTE: fullWidth will force the DropDownBoxContainer to occupy as much space in the parent container as possible.
  // NOTE: useFixedWidth will force the DropDownBoxContainer to have an exact width (regardless of whatever is inside the DropDownBoxContainer).
  // NOTE: when fullWidth and useFixedWidth are both false, then the DropDownBoxContainer will only occupy as much space as it needs based on its own contents.
  // NOTE: it makes sense to have both useFixedWidth and fullWidth false, but it does NOT make sense to have them both true.
}) {
  const dropDownBoxContainerTheme = createTheme(
    makeDropDownBoxContainerTheme("light")
  );

  return (
    <ThemeProvider theme={dropDownBoxContainerTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "right",
          gap: "20px",
        }}
      >
        {label && (
          <Box
            sx={{
              width: labelWidth,
            }}
          >
            <TextHeadingMediumA label={label} />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            ...(useFixedWidth && { width: fixedWidth }),
            ...(fullWidth && { width: "100%" }),
          }}
        >
          <Box
            onClick={onClick}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",

              minHeight: "40px",

              boxSizing: "border-box",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",

              ...((useFixedWidth || fullWidth) && { width: "100%" }),
              padding: "4px",
              gap: "8px",

              borderRadius: "3px",
              borderBottom: "1px solid",
              borderColor: gray[200],

              backgroundColor: alpha(gray[100], 0.4),

              outline: "4px solid",
              outlineColor: "transparent",
              transition: "outline-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",

              "&:hover": {
                outlineColor: alpha(purple[600], 0.2),
              },
              "&.Mui-focused": {
                outlineColor: alpha(purple[600], 0.4),
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                paddingLeft: "10px",
              }}
            >
              {required && <TextRequiredAsterisk height="32px" />}
              {children}
            </Box>
            {iconVisible && (
              <IconDropDown
                flipped={open}
                size={32}
                opacity={0.7}
                onHoverOpacity={0.7}
                stroke={purple[900]}
              />
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
