import "./AppStyle.css";

export const colorBg = "#eceff6";

export const purple = {
  50: "#e8e8f5",
  100: "#bbbbdf",
  200: "#aeaede",
  300: "#8e8ed0",
  400: "#7575c6",
  500: "#4a4aa9",
  600: "#33338f",
  700: "#232379",
  800: "#151560",
  900: "#06063c",
};

export const blue = {
  900: "#062e5b",
  800: "#0b417f",
  700: "#11529c",
  600: "#1c63b5",
  500: "#3173c0",
  400: "#4b85c8",
  300: "#719fd4",
  200: "#92b5de",
  100: "#aec7e4", // selected color
  50: "#c7d6e8"
}

export const red = {
  50: "#efd6d6",
  100: "#e1e1c4",
  200: "#e3b1b1",
  300: "#e1a1a1",
  400: "#e38e8e",
  500: "#df7676",
  600: "#e46464",
  700: "#e64848",
  800: "#e92727",
  900: "#ea1010",
};

export const gray = {
  50: "#fafafa",
  100: "#e1e1e1",
  200: "#c8c8c8", // background of numbered button tab
  300: "#afafaf",
  400: "#969696",
  500: "#7d7d7d",
  600: "#646464",
  700: "#4b4b4b",
  800: "#323232",
  900: "#191919",
};

export const green = {
  50: "#d3ebd2",
  100: "#c5ebc4",
  200: "#b4e7b3",
  300: "#9ddd9b",
  400: "#89d887",
  500: "#5adb58",
  600: "#30d82c",
  700: "#29BE26", // the "default" green
  800: "#19a516",
  900: "#0d860a",
};
