// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// Extra
import * as React from "react";
import { useState, useContext } from "react";
import { useNavigate } from "react-router";

// StudiousCat Components
import SurroundingControls, { sidebarNavigatorEmpty } from "../../special/SurroundingControls";

import { UserContext } from "../../../context/UserContext";
import LobbySignInButton from "../../component/lobby/LobbySignInButton";
import LobbyLandingSuperPane from "../../super_pane/lobby/LobbyLandingSuperPane";
import SettingsPrivacyPolicyPane from "../../pane/settings/SettingsPrivacyPolicyPane";
import LobbyProfilePrivacyPolicySuperPane from "../../super_pane/lobby/LobbyProfilePrivacyPolicySuperPane";
import LobbyProfileCreateSuperPane, {
  LOBBY_PROFILE_CREATE_VIEW_ORDER,
  LobbyProfileCreateView,
} from "../../super_pane/lobby/LobbyProfileCreateSuperPane";
import {
  DB_TABLE_SCHOOL,
  DB_TABLE_USER,
  requestCreateEntry,
  requestDeleteEntry,
  requestGetEntry,
  requestSearchEntry,
  requestUpdateEntry,
} from "../../../service/browser/BackendRequestService";
import StatusMessage, {
  StatusMessageType,
} from "../../component/other/StatusMessage";
import {
  userSessionBegin,
  userSessionSet,
} from "../../../service/user/UserService";

export const makeLobbyPageThemeDesignTokens = (mode) => ({});

export function makeLobbyPageTheme(mode) {
  return {
    ...makeLobbyPageThemeDesignTokens(mode),
    components: {},
  };
}

export const LobbyPageView = {
  LANDING: "LANDING",
  CREATE_PROFILE: "CREATE_PROFILE",
};

export default function LobbyPage({}) {
  const lobbyPageTheme = createTheme(makeLobbyPageTheme("light"));
  const navigate = useNavigate();

  const {
    authenticated,
    setAuthenticated,
    presentSessionUuid,
    setPresentSessionUuid,
    presentUserUuid,
    setPresentUserUuid,
  } = useContext(UserContext);

  const HEADING_SLOGAN = "Let's thrive as students together!";
  const REDIRECT_DELAY_DURATION_MS = 200;

  const [lobbyPageView, setLobbyPageView] = useState(LobbyPageView.LANDING);
  const [lobbyProfileCreateView, setLobbyProfileCreateView] = useState(
    LobbyProfileCreateView.WELCOME
  );
  const [creatingUserUuid, setCreatingUserUuid] = useState(null);
  const [signInError, setSignInError] = useState(false);

  const markSidebarContentsDisabled = (sidebarContents) => {
    const endIndex = LOBBY_PROFILE_CREATE_VIEW_ORDER.indexOf(
      lobbyProfileCreateView
    );
    if (endIndex >= 0 && endIndex < sidebarContents.length) {
      const newSidebarContents = [...sidebarContents];
      for (
        let index = sidebarContents.length - 1;
        index > endIndex + 1;
        index--
      ) {
        newSidebarContents[index].disabled = true;
      }

      return newSidebarContents;
    }

    return sidebarContents;
  };

  const rawSidebarContents =
    lobbyPageView !== LobbyPageView.CREATE_PROFILE
      ? []
      : [
          {
            label: "Cancel",
            id: "cancel",
            parent: "root",
          },
          {
            label: "Welcome",
            id: "welcome",
            parent: "root",
          },
          {
            label: "Privacy",
            id: "privacy",
            parent: "root",
          },
          {
            label: "Profile",
            id: "profile",
            parent: "root",
          },
        ];
  const sidebarContents = markSidebarContentsDisabled(rawSidebarContents);

  const handleNewUser = (googleMatchedUserData) => {
    requestSearchEntry(
      DB_TABLE_SCHOOL,
      {
        name: "University of Arizona",
      },
      (schoolResponseData) => {
        if (schoolResponseData && schoolResponseData["total_elements"] > 0) {
          const schoolUuid = schoolResponseData["content"][0]["uuid"];
          requestCreateEntry(
            DB_TABLE_USER,
            {
              google_id: googleMatchedUserData["google_id"],
              user_name: googleMatchedUserData["user_name"],
              full_name: googleMatchedUserData["full_name"],
              school_email: googleMatchedUserData["school_email"],
              phone_number: "",
              bio: "",
              website_url: "",
              profile_picture_url: googleMatchedUserData["profile_picture_url"],
              school_uuid: schoolUuid,
              disabled: false,
              dark_mode_activated: false,
              font_size: "medium",
              completed: false,
              soft_deleted: false,
            },
            (userResponseData) => {
              setCreatingUserUuid(userResponseData["uuid"]);
              setLobbyPageView(LobbyPageView.CREATE_PROFILE);
              setSignInError(false);
            },
            (error) => {
              setSignInError(true);
            }
          );
        } else {
          setSignInError(true);
        }
      },
      (error) => {
        setSignInError(true);
      }
    );
  };

  const handleExistingUser = () => {
    setSignInError(false);
    setTimeout(() => {
      navigate("/calendar", { replace: true });
    }, REDIRECT_DELAY_DURATION_MS);
  };

  const handleCreateProfileCancel = () => {
    if (creatingUserUuid) {
      requestDeleteEntry(DB_TABLE_USER, creatingUserUuid);
      setCreatingUserUuid(null);
    }
    setLobbyProfileCreateView(LobbyProfileCreateView.WELCOME);
    setLobbyPageView(LobbyPageView.LANDING);
  };

  const handleCreateProfileFinish = () => {
    requestGetEntry(
      DB_TABLE_USER,
      creatingUserUuid,
      (creatingUserData) => {
        creatingUserData["completed"] = true;
        requestUpdateEntry(
          DB_TABLE_USER,
          creatingUserUuid,
          creatingUserData,
          (updatedCreatingUserData) => {
            userSessionBegin(creatingUserUuid)
              .then((userSessionData) => {
                userSessionSet(
                  userSessionData,
                  setAuthenticated,
                  setPresentSessionUuid,
                  setPresentUserUuid
                );
                setTimeout(() => {
                  navigate("/calendar", { replace: true });
                }, REDIRECT_DELAY_DURATION_MS);
              })
              .catch((error) => {
                // TODO: display error message in the profile create super pane
              });
          },
          (error) => {
            // TODO: display error message in the profile create super pane
          }
        );
      },
      (error) => {
        // TODO: display error message in the profile create super pane
      }
    );
  };

  const handleClickSidebarItem = (itemId) => {
    if (lobbyPageView === LobbyPageView.CREATE_PROFILE) {
      switch (itemId) {
        case "cancel":
          handleCreateProfileCancel();
          break;
        case "welcome":
          setLobbyProfileCreateView(LobbyProfileCreateView.WELCOME);
          break;
        case "privacy":
          setLobbyProfileCreateView(LobbyProfileCreateView.PRIVACY_POLICY);
          break;
        case "profile":
          setLobbyProfileCreateView(LobbyProfileCreateView.INFO);
          break;
      }
    }
  };

  const handlePreviousView = () => {
    const index = LOBBY_PROFILE_CREATE_VIEW_ORDER.indexOf(
      lobbyProfileCreateView
    );
    if (index === 0) {
      handleCreateProfileCancel();
    } else {
      const newView = LOBBY_PROFILE_CREATE_VIEW_ORDER[index - 1];
      setLobbyProfileCreateView(newView);
    }
  };

  const handleNextView = () => {
    const index = LOBBY_PROFILE_CREATE_VIEW_ORDER.indexOf(
      lobbyProfileCreateView
    );
    if (index === LOBBY_PROFILE_CREATE_VIEW_ORDER.length - 1) {
      handleCreateProfileFinish();
    } else {
      const newView = LOBBY_PROFILE_CREATE_VIEW_ORDER[index + 1];
      setLobbyProfileCreateView(newView);
    }
  };

  const handleSignInFailure = () => {
    setSignInError(true);
  };

  return (
    <ThemeProvider theme={lobbyPageTheme}>
      <SurroundingControls
        sidebarItemList={sidebarContents}
        sidebarOnClickItem={handleClickSidebarItem}
        sidebarNavigator={sidebarNavigatorEmpty}
        sidebarProfilePictureVisible={false}
        headingSubtitle={HEADING_SLOGAN}
      >
        {lobbyPageView === LobbyPageView.LANDING && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {signInError && (
              <Box
                sx={{
                  paddingTop: "24px",
                  maxWidth: "510px",
                }}
              >
                <StatusMessage
                  message="An error occurred when trying to sign you in. Please try again later."
                  type={StatusMessageType.ERROR}
                />
              </Box>
            )}
            <LobbyLandingSuperPane
              onNewUser={handleNewUser}
              onExistingUser={handleExistingUser}
              onFailure={handleSignInFailure}
            />
          </Box>
        )}
        {lobbyPageView === LobbyPageView.CREATE_PROFILE && (
          <LobbyProfileCreateSuperPane
            targetUserUuid={creatingUserUuid}
            view={lobbyProfileCreateView}
            onPreviousView={handlePreviousView}
            onNextView={handleNextView}
          />
        )}
      </SurroundingControls>
    </ThemeProvider>
  );
}
