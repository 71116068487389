// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext } from "react";
import SurroundingControls from "../../special/SurroundingControls";
import SettingsDisplaySuperPane from "../../super_pane/settings/SettingsDisplaySuperPane";
import { UserContext } from "../../../context/UserContext";

export const makeSettingsDisplayPageThemeDesignTokens = (mode) => ({});

export function makeSettingsDisplayPageTheme(mode) {
  return {
    ...makeSettingsDisplayPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function SettingsDisplayPage({}) {
  const settingsDisplayPageTheme = createTheme(
    makeSettingsDisplayPageTheme("light")
  );
  const { presentUserUuid } = useContext(UserContext);

  return (
    <ThemeProvider theme={settingsDisplayPageTheme}>
      <SurroundingControls>
        <SettingsDisplaySuperPane presentUserUuid={presentUserUuid} />
      </SurroundingControls>
    </ThemeProvider>
  );
}
