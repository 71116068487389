// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import DropDownBoxContainer from "../../component/shared/drop_down_box/DropDownBoxContainer";
import { CalendarMeetingInfoPopupBackground } from "../../component/calendar_meeting_info/CalendarMeetingInfoComponentCollection";
import { LocalizationProvider, StaticTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DATE_FRONTEND_FORMAT_TIME_ONLY, dateConvertToString } from "../../../service/browser/DateService";

export const makeCalendarMeetingInfoInputTimePaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputTimePaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputTimePaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarMeetingInfoInputTimePane({
  initialValue,
  minDateValue,
  maxDateValue,
  onChangeValue,
  modifiable = false,
  required = false,
}) {
  const calendarMeetingInfoInputTimePaneTheme = createTheme(
    makeCalendarMeetingInfoInputTimePaneTheme("light")
  );

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [chosenDateDayJs, setChosenDateDayJs] = useState(dayjs(initialValue));

  const chosenDateText = chosenDateDayJs
    ? dateConvertToString(
        chosenDateDayJs.toDate(),
        DATE_FRONTEND_FORMAT_TIME_ONLY
      )
    : "";

  const handleDropDownBoxClick = (event) => {
    if (modifiable) {
      setChosenDateDayJs(dayjs(initialValue));
      setPopoverAnchorEl(event.currentTarget);
    }
  };

  const handleTimePickerChange = (newDayJs) => {
    if (modifiable) {
      setChosenDateDayJs(newDayJs);
    }
  };

  const handlePopoverClose = () => {
    if (modifiable) {
      onChangeValue?.(chosenDateDayJs.toDate());
    }
    setPopoverAnchorEl(null);
  };

  return (
    <ThemeProvider theme={calendarMeetingInfoInputTimePaneTheme}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <DropDownBoxContainer
          required={required}
          fullWidth={true}
          open={popoverAnchorEl ? true : false}
          onClick={handleDropDownBoxClick}
        >
          <Typography sx={{ fontFamily: "PT Sans", fontSize: 16 }}>
            {chosenDateText}
          </Typography>
        </DropDownBoxContainer>
        <CalendarMeetingInfoPopupBackground
          anchorEl={popoverAnchorEl}
          onClose={handlePopoverClose}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticTimePicker
              sx={{
                backgroundColor: colorBg,
                ".MuiPickersLayout-actionBar": {
                  display: "none",
                },
              }}
              value={chosenDateDayJs}
              onChange={handleTimePickerChange}
              minTime={minDateValue ? dayjs(minDateValue) : undefined}
              maxTime={maxDateValue ? dayjs(maxDateValue) : undefined}
            />
          </LocalizationProvider>
        </CalendarMeetingInfoPopupBackground>
      </Box>
    </ThemeProvider>
  );
}
