// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import TextBodySmall from "../../component/shared/text/TextBodySmall";
import TextHeadingLarge from "../../component/shared/text/TextHeadingLarge";
import Separator from "../../component/shared/other/Separator";

export const makeHelpTutorialSuperPaneThemeDesignTokens = (mode) => ({
  typography: {
    sectionHeading: {
      fontFamily: "Source Serif Pro",
      color: purple[900],
      fontSize: 24,
      textTransform: "none",
    },
  },
});

export function makeHelpTutorialSuperPaneTheme(mode) {
  return {
    ...makeHelpTutorialSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

function VideoSection({ label, youtubeEmbedUrl }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "12px",
        backgroundColor: alpha(gray[200], 0.5),
        borderRadius: "6px",
        width: "75%",
        height: "100%",
      }}
    >
      <Typography variant="sectionHeading">{label}</Typography>
      <Separator />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          padding: "12px",
          width: "100%",
          height: "600px"
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={youtubeEmbedUrl}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Box>
    </Box>
  );
}

export default function HelpTutorialSuperPane({}) {
  const helpTutorialSuperPaneTheme = createTheme(
    makeHelpTutorialSuperPaneTheme("light")
  );

  const VIDEO_DATA = [
    {
      title: "Part #1: Creating Your First Account",
      link: "https://www.youtube.com/embed/IMdNkGfXa4k?si=i_p-EDsdXM7zbPOm",
    },
    {
      title: "Part #2: User Interaction (Friend, Reaction, Endorsement)",
      link: "https://www.youtube.com/embed/wBLdCLQaDjE?si=zpK4PJkUEhg4U5CZ",
    },
    {
      title: "Part #3: Meetings (Create, Edit, Delete)",
      link: "https://www.youtube.com/embed/Q0nnNHd5Eeg?si=TNkevucLTMeavRY5",
    },
  ];

  return (
    <ThemeProvider theme={helpTutorialSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "30px",
          padding: "20px",
          width: "100%",
        }}
      >
        <TextHeadingLarge label="Tutorial Videos" />
        <Box
          sx={{
            width: "400px",
          }}
        >
          <TextBodySmall label="A higher quality, more comprehensive video-based and written tutorial series coming soon!" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "30px",
            padding: "20px",
            width: "100%",
          }}
        >
          {VIDEO_DATA.map(({ title, link }) => (
            <VideoSection label={title} youtubeEmbedUrl={link} />
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
