// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext } from "react";
import {
  DB_TABLE_USER,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import {
  userSessionBegin,
  userSessionGet,
  userSessionSet,
} from "../../../service/user/UserService";
import LobbySignInButton from "../../component/lobby/LobbySignInButton";
import { UserContext } from "../../../context/UserContext";
import StatusMessage, {
  StatusMessageType,
} from "../../component/other/StatusMessage";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import { useNavigate } from "react-router";

export const makeLobbyLandingLoginPaneThemeDesignTokens = (mode) => ({});

export function makeLobbyLandingLoginPaneTheme(mode) {
  return {
    ...makeLobbyLandingLoginPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function LobbyLandingLoginPane({
  onNewUser,
  onExistingUser,
  onFailure,
}) {
  const lobbyLandingLoginPaneTheme = createTheme(
    makeLobbyLandingLoginPaneTheme("light")
  );

  const navigate = useNavigate();

  const {
    authenticated,
    setAuthenticated,
    presentSessionUuid,
    setPresentSessionUuid,
    presentUserUuid,
    setPresentUserUuid,
  } = useContext(UserContext);

  const continueExistingUser = async (userUuid) => {
    const userSessionData = await userSessionGet();

    if (userSessionData) {
      onExistingUser?.(userSessionData);
    } else {
      const newUserSessionData = await userSessionBegin(userUuid);
      userSessionSet(
        newUserSessionData,
        setAuthenticated,
        setPresentSessionUuid,
        setPresentUserUuid
      );
      onExistingUser?.(newUserSessionData);
    }
  };

  const continueNewUser = async (googleMatchedUserData) => {
    onNewUser?.(googleMatchedUserData);
  };

  const handleSignInSuccess = async (googleMatchedUserData) => {
    requestSearchEntry(
      DB_TABLE_USER,
      {
        google_id: googleMatchedUserData["google_id"],
        completed: true,
        soft_deleted: false,
      },
      (userResponseData) => {
        if (userResponseData && userResponseData["total_elements"] > 0) {
          const userUuid = userResponseData["content"][0]["uuid"];
          continueExistingUser(userUuid).catch((error) => {
            onFailure?.();
          });
        } else {
          continueNewUser(googleMatchedUserData).catch((error) => {
            onFailure?.();
          });
        }
      },
      (error) => {
        onFailure?.();
      }
    );
  };

  const handleSignInFailure = () => {
    onFailure?.();
  };

  const handleClickAlreadySignedInButton = () => {
    navigate("/calendar", { replace: true });
  };

  return (
    <ThemeProvider theme={lobbyLandingLoginPaneTheme}>
      {authenticated && (
        <ButtonRectangleLabel
          variant="success"
          label="Go to Calendar"
          onClick={handleClickAlreadySignedInButton}
        />
      )}
      {!authenticated && (
        <LobbySignInButton
          onSuccess={handleSignInSuccess}
          onFailure={handleSignInFailure}
        />
      )}
    </ThemeProvider>
  );
}
