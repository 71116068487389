import Cookies from "js-cookie";

export const COOKIE_LENGTH_SESSION = 7;
export const COOKIE_KEY_SESSION_ID = "session";
export const COOKIE_KEY_MAINTENANCE_MODE_AUTH = "mma";

export const setCookie = (name, value, options) => {
  Cookies.set(name, value, options);
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};

export const removeAllCookies = () => {
  const cookies = Cookies.get();
  for (const cookie in cookies) {
    Cookies.remove(cookie);
  }
};
