// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProfileSuperPane from "../../super_pane/profile/ProfileSuperPane";
import { UserContext } from "../../../context/UserContext";
import SurroundingControls from "../../special/SurroundingControls";
import {
  DB_TABLE_USER,
  requestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import StatusMessage from "../../component/other/StatusMessage";

export const makeProfileAlternatePageThemeDesignTokens = (mode) => ({});

export function makeProfileAlternatePageTheme(mode) {
  return {
    ...makeProfileAlternatePageThemeDesignTokens(mode),
    components: {},
  };
}

export default function ProfileAlternatePage({}) {
  const profileAlternatePageTheme = createTheme(
    makeProfileAlternatePageTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [matchedUserData, setMatchedUserData] = useState({});

  const { presentUserUuid } = useContext(UserContext);

  const { userIdentifier } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const userNameCleaned = userIdentifier.toLowerCase();
    requestSearchEntry(
      DB_TABLE_USER,
      {
        user_name: userIdentifier,
        completed: true,
        soft_deleted: false
      },
      (searchResponseData) => {
        if (searchResponseData && searchResponseData["content"]) {
          const userDataList = searchResponseData["content"];

          if (
            userDataList.length === 1 &&
            userDataList[0]["user_name"].toLowerCase() === userNameCleaned
          ) {
            const userData = userDataList[0];
            if (userData["uuid"] === presentUserUuid) {
              navigate("/settings/my-profile", { replace: true });
            } else {
              setMatchedUserData(userData);
              setAssetLoading(false);
            }
          } else {
            setAssetLoadingError(true);
          }
        } else {
          setAssetLoadingError(true);
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  }, [userIdentifier]);

  return (
    <ThemeProvider theme={profileAlternatePageTheme}>
      <SurroundingControls>
        {assetLoadingError && (
          <StatusMessageComponentLoadError
            message={`User Not Found: ${userIdentifier}`}
          />
        )}
        {!assetLoading && !assetLoadingError && (
          <ProfileSuperPane
            presentUserUuid={presentUserUuid}
            targetUserUuid={matchedUserData["uuid"]}
          />
        )}
      </SurroundingControls>
    </ThemeProvider>
  );
}
