// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import {
  CalendarMeetingInfoLabelRow,
  CalendarMeetingInfoRowItemWrapper,
  CalendarMeetingInfoRowWrapper,
} from "../../component/calendar_meeting_info/CalendarMeetingInfoComponentCollection";
import CalendarMeetingInfoInputDatePane from "./CalendarMeetingInfoInputDatePane";
import CalendarMeetingInfoInputTimePane from "./CalendarMeetingInfoInputTimePane";
import { CalendarMeetingInfoValidationStatus } from "../../component/calendar_meeting_info/CalendarMeetingInfoRowComponentCollection";

export const makeCalendarMeetingInfoInputDateTimeSuperPaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputDateTimeSuperPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputDateTimeSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

// initialValue is list of two Date() objects
// onChangeValue handles two Date() objects
export default function CalendarMeetingInfoInputDateTimeSuperPane({
  initialValue,
  onChangeValue,
  valueValidator,
  onError,
  modifiable,
  required,
}) {
  const calendarMeetingInfoInputDateTimeSuperPaneTheme = createTheme(
    makeCalendarMeetingInfoInputDateTimeSuperPaneTheme("light")
  );

  const [pairDateTime, setPairDateTime] = useState(initialValue);
  const [startDateTime, endDateTime] = pairDateTime;

  const MEETING_MIN_DATE = new Date();
  const MEETING_MAX_DATE = new Date(2024, 11, 20);

  const validateChange = (givenPairDateTime) => {
    const valueValidationResult = valueValidator
      ? valueValidator(givenPairDateTime)
      : undefined;

    const valueValid = valueValidationResult
      ? valueValidationResult.status ===
        CalendarMeetingInfoValidationStatus.VALID
      : true;

    if (
      valueValidationResult &&
      valueValidationResult.status !== CalendarMeetingInfoValidationStatus.VALID
    ) {
      onError?.(valueValidationResult);
    }

    return valueValid;
  };

  const handleDayChange = (newValue) => {
    const [newStart, newEnd] = [...pairDateTime];

    newStart.setFullYear(
      newValue.getFullYear(),
      newValue.getMonth(),
      newValue.getDate()
    );

    newEnd.setFullYear(
      newValue.getFullYear(),
      newValue.getMonth(),
      newValue.getDate()
    );

    setPairDateTime([newStart, newEnd]);

    if (validateChange([newStart, newEnd])) {
      onChangeValue?.([newStart, newEnd]);
    }
  };
  const handleStartChange = (newValue) => {
    const [currentStart, currentEnd] = pairDateTime;
    const newPair = [newValue, currentEnd];
    setPairDateTime(newPair);

    if (validateChange(newPair)) {
      onChangeValue?.(newPair);
    }
  };
  const handleEndChange = (newValue) => {
    const [currentStart, currentEnd] = pairDateTime;
    const newPair = [currentStart, newValue];
    setPairDateTime(newPair);

    if (validateChange(newPair)) {
      onChangeValue?.(newPair);
    }
  };

  return (
    <ThemeProvider theme={calendarMeetingInfoInputDateTimeSuperPaneTheme}>
      <CalendarMeetingInfoRowWrapper
        rowLabel={"Date:"}
        rowLabelCounterBalanced={true}
        rowLabelCentered={true}
      >
        <CalendarMeetingInfoRowItemWrapper flexGrow={2}>
          <CalendarMeetingInfoInputDatePane
            initialValue={startDateTime}
            onChangeValue={handleDayChange}
            minDateValue={MEETING_MIN_DATE}
            maxDateValue={MEETING_MAX_DATE}
            modifiable={modifiable}
            required={required}
          />
        </CalendarMeetingInfoRowItemWrapper>
        <CalendarMeetingInfoRowItemWrapper flexGrow={1}>
          <CalendarMeetingInfoLabelRow label="Start:" />
          <CalendarMeetingInfoInputTimePane
            initialValue={startDateTime}
            maxDateValue={endDateTime}
            onChangeValue={handleStartChange}
            modifiable={modifiable}
            required={required}
          />
        </CalendarMeetingInfoRowItemWrapper>
        <CalendarMeetingInfoRowItemWrapper flexGrow={1}>
          <CalendarMeetingInfoLabelRow label="End:" />
          <CalendarMeetingInfoInputTimePane
            initialValue={endDateTime}
            minDateValue={startDateTime}
            onChangeValue={handleEndChange}
            modifiable={modifiable}
            required={required}
          />
        </CalendarMeetingInfoRowItemWrapper>
      </CalendarMeetingInfoRowWrapper>
    </ThemeProvider>
  );
}
