// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import InputText from "../../component/shared/input/InputText";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";

export const makeCalendarSearchPaneThemeDesignTokens = (mode) => ({});

export function makeCalendarSearchPaneTheme(mode) {
  return {
    ...makeCalendarSearchPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarSearchPane({}) {
  const calendarSearchPaneTheme = createTheme(
    makeCalendarSearchPaneTheme("light")
  );

  const [searchBarText, setSearchBarText] = useState("");

  // NOTE (8/26/2024):
  // The content of this component is empty right now.
  // Search bar and create button will be implemented later in the pilot.

  return (
    <ThemeProvider theme={calendarSearchPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {/* <InputText
            placeholderValue={"Search..."}
            value={searchBarText}
            setValue={setSearchBarText}
          /> */}
        </Box>
        {/* <ButtonRectangleNarrowLabel label="Create" fullWidth={false} /> */}
      </Box>
    </ThemeProvider>
  );
}
