import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function IconDeny(props) {
  const { onClick, size, fill, opacity, onHoverOpacity, ...other } = props;

  return (
    <SvgIcon
      {...other}
      sx={{
        fontSize: size,
        width: size,
        height: size,
        "&:hover path": {
          opacity: onHoverOpacity,
        },
      }}
      onClick={onClick}
    >
      <path
        d="M10.5427 0.0268583C4.75243 0.0120325 0.0411944 4.64998 0.0265243 10.3794C0.0118542 16.1089 4.69928 20.7709 10.4895 20.7857C16.2798 20.8005 20.991 16.1626 21.0057 10.4331C21.0203 4.7037 16.3329 0.0416841 10.5427 0.0268583ZM15.7582 11.4577L5.26865 11.4308L5.27397 9.35491L15.7635 9.38177L15.7582 11.4577Z"
        fill={fill}
        opacity={opacity}
        // The transform-translate is required to center the <path/> in the center of <SvgIcon/>.
        transform="translate(1.6, 1.6)"
      />
    </SvgIcon>
  );
}

export default IconDeny;
