// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import CalendarMeetingInfoInputControlsPane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputControlsPane";
import {
  CalendarMeetingInfoLabelRow,
  CalendarMeetingInfoRowItemWrapper,
  CalendarMeetingInfoRowWrapper,
  CalendarMeetingInfoStatusMessageWrapper,
} from "./CalendarMeetingInfoComponentCollection";
import CalendarMeetingInfoInputParticipantCountPane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputParticipantCountPane";
import CalendarMeetingInfoInputParticipantListPane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputParticipantListPane";
import CalendarMeetingInfoInputTextPane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputTextPane";
import { StatusMessageType } from "../other/StatusMessage";
import CalendarMeetingInfoInputCoursePane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputCoursePane";
import CalendarMeetingInfoInputDateTimeSuperPane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputDateTimeSuperPane";
import {
  DATE_BACKEND_FORMAT,
  dateConvertToObject,
  dateConvertToString,
} from "../../../service/browser/DateService";
import { CalendarMeetingInfoPreset } from "../../super_pane/calendar_meeting_info/CalendarMeetingInfoSuperPane";
import CalendarMeetingInfoInputCheckBoxPane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputCheckBoxPane";
import TextBodySmall from "../shared/text/TextBodySmall";
import LobbyProfilePortraitPane from "../../pane/lobby/LobbyProfilePortraitPane";
import TextHeadingMediumA from "../shared/text/TextHeadingMediumA";
import ButtonRectangleNarrowLabel from "../shared/button/ButtonRectangleNarrowLabel";
import CalendarMeetingInfoInputControlsMinorPane from "../../pane/calendar_meeting_info/CalendarMeetingInfoInputControlsMinorPane";

export const CalendarMeetingInfoValidationStatus = {
  VALID: "VALID",
  INVALID: "INVALID",
};

export const CalendarMeetingInfoRowKey = {
  NAME: "NAME",
  DATE: "DATE",
  CLASS: "CLASS",
  LOCATION: "LOCATION",
  DESCRIPTION: "DESCRIPTION",
  EXTRA: "EXTRA",
  INVITE: "INVITE",
};

// VALIDATORS FOR THE ROWS:
export const validatorMeetingName = (value) => {
  if (value.length <= 0) {
    return {
      status: CalendarMeetingInfoValidationStatus.INVALID,
      message: "The meeting name cannot be empty.",
    };
  }
  return {
    status: CalendarMeetingInfoValidationStatus.VALID,
    message: "",
  };
};

export const validatorMeetingLocation = (value) => {
  if (value.length <= 0) {
    return {
      status: CalendarMeetingInfoValidationStatus.INVALID,
      message: "The meeting location cannot be empty.",
    };
  }
  return {
    status: CalendarMeetingInfoValidationStatus.VALID,
    message: "",
  };
};

export const validatorMeetingTime = (pairValue) => {
  const [startValue, endValue] = pairValue;

  if (endValue < startValue) {
    return {
      status: CalendarMeetingInfoValidationStatus.INVALID,
      message: "The end time cannot be before the start time.",
    };
  }
  const durationMin = (endValue - startValue) / (1000 * 60);

  if (durationMin < 15) {
    return {
      status: CalendarMeetingInfoValidationStatus.INVALID,
      message: "The meeting must be at least 15 minutes long.",
    };
  }
  if (durationMin > 300) {
    return {
      status: CalendarMeetingInfoValidationStatus.INVALID,
      message: "The meeting cannot be longer than 5 hours.",
    };
  }
  return {
    status: CalendarMeetingInfoValidationStatus.VALID,
    message: "",
  };
};

const CHECK_BOX_BUTTON_WIDTH = 40;
const MEETING_MIN_PARTICIPANT_COUNT = 1;
const MEETING_MAX_PARTICIPANT_COUNT = 6;

export function CalendarMeetingInfoRowControlsMinor({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  onControlsMinorAction,
  meetingLinkCopiedClipboard
}) {
  return (
    <CalendarMeetingInfoInputControlsMinorPane
      meetingInfoPreset={meetingInfoPreset}
      onControlsMinorAction={onControlsMinorAction}
      meetingLinkCopiedClipboard={meetingLinkCopiedClipboard}
    />
  );
}

export function CalendarMeetingInfoRowName({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  errorMessageByRowKeyTable,
  onUpdateMeetingData,
  onSetErrorMessage,
  onRemoveErrorMessage,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;
  const required = modifiable;

  return (
    <CalendarMeetingInfoStatusMessageWrapper
      statusType={StatusMessageType.ERROR}
      statusMessage={errorMessageByRowKeyTable[CalendarMeetingInfoRowKey.NAME]}
    >
      <CalendarMeetingInfoRowWrapper
        rowLabel={"Name:"}
        rowLabelCounterBalanced={true}
        rowLabelCentered={true}
      >
        <CalendarMeetingInfoRowItemWrapper>
          <CalendarMeetingInfoInputTextPane
            initialValue={meetingDataInitial["name"]}
            onChangeValue={(newValue) => {
              onUpdateMeetingData?.({ name: newValue });
              onRemoveErrorMessage?.(CalendarMeetingInfoRowKey.NAME);
            }}
            onError={(validationResult) =>
              onSetErrorMessage?.(
                CalendarMeetingInfoRowKey.NAME,
                validationResult.message
              )
            }
            placeholderValue={"Example: Exam Study Session"}
            maxCharCount={32}
            valueValidator={validatorMeetingName}
            modifiable={modifiable}
            required={required}
          />
        </CalendarMeetingInfoRowItemWrapper>
      </CalendarMeetingInfoRowWrapper>
    </CalendarMeetingInfoStatusMessageWrapper>
  );
}

export function CalendarMeetingInfoRowTime({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  errorMessageByRowKeyTable,
  onUpdateMeetingData,
  onSetErrorMessage,
  onRemoveErrorMessage,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;
  const required = modifiable;

  return (
    <CalendarMeetingInfoStatusMessageWrapper
      statusType={StatusMessageType.ERROR}
      statusMessage={errorMessageByRowKeyTable[CalendarMeetingInfoRowKey.DATE]}
    >
      <CalendarMeetingInfoInputDateTimeSuperPane
        initialValue={[
          dateConvertToObject(
            meetingDataInitial["start_date_time"],
            DATE_BACKEND_FORMAT
          ),
          dateConvertToObject(
            meetingDataInitial["end_date_time"],
            DATE_BACKEND_FORMAT
          ),
        ]}
        valueValidator={validatorMeetingTime}
        onChangeValue={([newStart, newEnd]) => {
          onUpdateMeetingData?.({
            start_date_time: dateConvertToString(newStart, DATE_BACKEND_FORMAT),
            end_date_time: dateConvertToString(newEnd, DATE_BACKEND_FORMAT),
          });
          onRemoveErrorMessage?.(CalendarMeetingInfoRowKey.DATE);
        }}
        onError={(validationResult) =>
          onSetErrorMessage?.(
            CalendarMeetingInfoRowKey.DATE,
            validationResult.message
          )
        }
        modifiable={modifiable}
        required={required}
      />
    </CalendarMeetingInfoStatusMessageWrapper>
  );
}

export function CalendarMeetingInfoRowClassAndRecurring({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  presentUserUuid,
  onUpdateMeetingData,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;
  const required = modifiable;

  return (
    <CalendarMeetingInfoStatusMessageWrapper
      noteMessage={
        modifiable
          ? "Selecting a new class will remove all currently invited/attending users since they may not be in the selected class."
          : undefined
      }
    >
      <CalendarMeetingInfoRowWrapper
        rowLabel={"Class:"}
        rowLabelCounterBalanced={true}
        rowLabelCentered={true}
      >
        <CalendarMeetingInfoRowItemWrapper>
          <CalendarMeetingInfoInputCoursePane
            initialValue={meetingDataInitial["course_uuid"]}
            onChangeValue={(newValue) =>
              onUpdateMeetingData?.({
                course_uuid: newValue,
              })
            }
            targetUserUuid={presentUserUuid}
            modifiable={modifiable}
            required={required}
          />
        </CalendarMeetingInfoRowItemWrapper>
      </CalendarMeetingInfoRowWrapper>
    </CalendarMeetingInfoStatusMessageWrapper>
  );
}

export function CalendarMeetingInfoRowLocation({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  errorMessageByRowKeyTable,
  onUpdateMeetingData,
  onSetErrorMessage,
  onRemoveErrorMessage,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;
  const required = modifiable;

  return (
    <CalendarMeetingInfoStatusMessageWrapper
      statusType={StatusMessageType.ERROR}
      statusMessage={
        errorMessageByRowKeyTable[CalendarMeetingInfoRowKey.LOCATION]
      }
    >
      <CalendarMeetingInfoRowWrapper
        rowLabel={"Location:"}
        rowLabelCounterBalanced={true}
        rowLabelCentered={false}
      >
        <CalendarMeetingInfoRowItemWrapper>
          <CalendarMeetingInfoInputTextPane
            initialValue={meetingDataInitial["address"]}
            onChangeValue={(newValue) => {
              onUpdateMeetingData?.({ address: newValue });
              onRemoveErrorMessage?.(CalendarMeetingInfoRowKey.LOCATION);
            }}
            onError={(validationResult) =>
              onSetErrorMessage?.(
                CalendarMeetingInfoRowKey.LOCATION,
                validationResult.message
              )
            }
            placeholderValue={
              "Example: UA Main Library | 444 E University Blvd, Tucson, AZ 85705 | ..."
            }
            maxCharCount={72}
            valueValidator={validatorMeetingLocation}
            modifiable={modifiable}
            required={required}
          />
        </CalendarMeetingInfoRowItemWrapper>
      </CalendarMeetingInfoRowWrapper>
    </CalendarMeetingInfoStatusMessageWrapper>
  );
}

export function CalendarMeetingInfoRowDescription({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  onUpdateMeetingData,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;

  return (
    <CalendarMeetingInfoRowWrapper
      rowLabel={"Description:"}
      rowLabelCounterBalanced={true}
      rowLabelCentered={false}
    >
      <CalendarMeetingInfoRowItemWrapper>
        <CalendarMeetingInfoInputTextPane
          initialValue={meetingDataInitial["description"]}
          onChangeValue={(newValue) =>
            onUpdateMeetingData?.({ description: newValue })
          }
          placeholderValue={
            "Explain what the study group will be like, what to expect, what the goals are, and so on..."
          }
          maxCharCount={340}
          lineCount={5}
          modifiable={modifiable}
          required={false}
        />
      </CalendarMeetingInfoRowItemWrapper>
    </CalendarMeetingInfoRowWrapper>
  );
}

export function CalendarMeetingInfoRowExtraInfo({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  onUpdateMeetingData,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;

  return (
    <CalendarMeetingInfoRowWrapper>
      <CalendarMeetingInfoRowItemWrapper>
        <CalendarMeetingInfoInputCheckBoxPane
          initialValue={meetingDataInitial["mode_in_person"]}
          onChangeValue={(newValue) =>
            onUpdateMeetingData?.({ mode_in_person: newValue })
          }
          modifiable={modifiable}
          width={CHECK_BOX_BUTTON_WIDTH}
        />
        <CalendarMeetingInfoLabelRow label={"In Person"} />
      </CalendarMeetingInfoRowItemWrapper>
      <CalendarMeetingInfoRowItemWrapper>
        <CalendarMeetingInfoInputCheckBoxPane
          initialValue={meetingDataInitial["mode_online"]}
          onChangeValue={(newValue) =>
            onUpdateMeetingData?.({ mode_online: newValue })
          }
          modifiable={modifiable}
          width={CHECK_BOX_BUTTON_WIDTH}
        />
        <CalendarMeetingInfoLabelRow label={"Online"} />
      </CalendarMeetingInfoRowItemWrapper>
      <CalendarMeetingInfoRowItemWrapper flexGrow={6}>
        <TextBodySmall label="Private meetings require permission to join." />
      </CalendarMeetingInfoRowItemWrapper>
      <CalendarMeetingInfoRowItemWrapper>
        <CalendarMeetingInfoInputCheckBoxPane
          initialValue={meetingDataInitial["mode_public"]}
          onChangeValue={(newValue) =>
            onUpdateMeetingData?.({ mode_public: newValue })
          }
          modifiable={modifiable}
          width={CHECK_BOX_BUTTON_WIDTH}
        />
        <CalendarMeetingInfoLabelRow label={"Public"} />
      </CalendarMeetingInfoRowItemWrapper>
    </CalendarMeetingInfoRowWrapper>
  );
}

export function CalendarMeetingInfoRowInviteFriends({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  presentUserUuid,
  targetMeetingUuid,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;

  return (
    <CalendarMeetingInfoRowWrapper
      rowLabel={"Invited Friends:"}
      rowLabelCounterBalanced={true}
      rowLabelCentered={true}
    >
      <CalendarMeetingInfoRowItemWrapper>
        <CalendarMeetingInfoInputParticipantListPane
          meetingInfoPreset={meetingInfoPreset}
          presentUserUuid={presentUserUuid}
          targetMeetingUuid={targetMeetingUuid}
          targetMeetingCreatorUuid={meetingData["creator_user_uuid"]}
          targetCourseUuid={meetingData["course_uuid"]}
          maxInvitedParticipantCount={meetingData["user_limit"] - 1}
          modifiable={modifiable}
          required={false}
        />
      </CalendarMeetingInfoRowItemWrapper>
    </CalendarMeetingInfoRowWrapper>
  );
}

export function CalendarMeetingInfoRowParticipantCount({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  onUpdateMeetingData,
}) {
  const modifiable =
    meetingInfoPreset === CalendarMeetingInfoPreset.CREATE ||
    meetingInfoPreset === CalendarMeetingInfoPreset.EDIT;

  return (
    <CalendarMeetingInfoRowWrapper
      rowLabel={"Participant Limit:"}
      rowLabelCounterBalanced={true}
      rowLabelCentered={true}
    >
      <CalendarMeetingInfoRowItemWrapper>
        <Box
          sx={{
            width: "300px",
          }}
        >
          <CalendarMeetingInfoInputParticipantCountPane
            initialValue={meetingDataInitial["user_limit"]}
            minValue={MEETING_MIN_PARTICIPANT_COUNT}
            maxValue={MEETING_MAX_PARTICIPANT_COUNT}
            onChange={(newValue) =>
              onUpdateMeetingData?.({ user_limit: newValue })
            }
            modifiable={modifiable}
          />
        </Box>
      </CalendarMeetingInfoRowItemWrapper>
    </CalendarMeetingInfoRowWrapper>
  );
}

export function CalendarMeetingInfoRowControls({
  meetingInfoPreset,
  meetingData,
  meetingDataInitial,
  onControlsAction,
  presentUserAttendingMeeting,
  presentUserRequestedJoin,
  presentUserInvited,
  meetingSubmissionReady,
}) {
  return (
    <Box>
      <CalendarMeetingInfoInputControlsPane
        meetingInfoPreset={meetingInfoPreset}
        meetingSubmissionReady={meetingSubmissionReady}
        meetingModePublic={meetingData["mode_public"]}
        presentUserAttendingMeeting={presentUserAttendingMeeting}
        presentUserRequestedJoin={presentUserRequestedJoin}
        presentUserInvited={presentUserInvited}
        onControlsAction={onControlsAction}
      />
    </Box>
  );
}
