// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import SliderDiscreteNumerical from "../../component/shared/slider/SliderDiscreteNumerical";

export const makeCalendarMeetingInfoInputParticipantCountPaneThemeDesignTokens =
  (mode) => ({});

export function makeCalendarMeetingInfoInputParticipantCountPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputParticipantCountPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarMeetingInfoInputParticipantCountPane({
  initialValue,
  minValue,
  maxValue,
  onChange,
  modifiable,
}) {
  const calendarMeetingInfoInputParticipantCountPaneTheme = createTheme(
    makeCalendarMeetingInfoInputParticipantCountPaneTheme("light")
  );

  const [participantCount, setParticipantCount] = useState(initialValue);

  const handleSliderChange = (newValue) => {
    setParticipantCount(newValue);
    onChange?.(newValue);
  };

  return (
    <ThemeProvider theme={calendarMeetingInfoInputParticipantCountPaneTheme}>
      <SliderDiscreteNumerical
        minValue={minValue}
        maxValue={maxValue}
        stepValue={1}
        value={participantCount}
        onChange={handleSliderChange}
        showCounter={true}
        modifiable={modifiable}
      />
    </ThemeProvider>
  );
}
