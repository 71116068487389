// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";

// Interactive Assets
import { Chip, Badge, Box } from "@mui/material";

// Custom Theme and Style
import { red, gray, blue } from "../../style/AppTheme";

export const makeProfileButtonReactionDesignTokens = (mode) => ({});

export function makeProfileButtonReactionTheme(mode) {
  return {
    ...makeProfileButtonReactionDesignTokens(mode),
    components: {
      MuiBadge: {
        styleOverrides: {
          badge: {
            fontFamily: "PT Sans",
            fontWeight: 900,
            fontSize: 11,
            color: gray[50],
            backgroundColor: red[900],
            boxShadow: `0 0 3px ${gray[600]}`,
          },
        },
      },
      MuiChip: {
        defaultProps: {
          // Uncomment to disable the default Google-like ripple
          // disableTouchRipple: true,
          // disableRipple: true,
        },
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            fontFamily: "PT Sans",
            fontWeight: 900,
            fontSize: "18px",
            border: "1px solid black",
            boxShadow: `0 0 3px ${gray[600]}`,
            paddingInline: "0px",
            paddingBlock: "0px",
            "&:hover": {
              backgroundColor: gray[200],
            },
            ...(ownerState.variant === "regular" && {
              backgroundColor: gray[100],
            }),
            ...(ownerState.variant === "highlighted" && {
              backgroundColor: blue[100],
            }),
          }),
        },
      },
    },
  };
}

export default function ProfileButtonReaction({
  label = "Reaction",
  count = 0,
  highlighted = false,
  clickable = false,
  onClick,
}) {
  const profileButtonReactionTheme = createTheme(
    makeProfileButtonReactionTheme("light")
  );

  const handleClick = () => {
    onClick?.();
  };

  const getChip = () => {
    const variant = highlighted ? "highlighted" : "regular";
    if (clickable) {
      return <Chip variant={variant} label={label} onClick={handleClick} />;
    } else {
      return <Chip variant={variant} label={label} />;
    }
  };

  if (count > 0) {
    return (
      <ThemeProvider theme={profileButtonReactionTheme}>
        <Box
          sx={{
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          <Badge badgeContent={count} variant="reaction">
            {getChip()}
          </Badge>
        </Box>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={profileButtonReactionTheme}>
        <Box
          sx={{
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          {getChip()}
        </Box>
      </ThemeProvider>
    );
  }
}
