// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import InputText from "../../component/shared/input/InputText";
import { CalendarMeetingInfoValidationStatus } from "../../component/calendar_meeting_info/CalendarMeetingInfoRowComponentCollection";

export const makeCalendarMeetingInfoInputTextPaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputTextPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputTextPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function CalendarMeetingInfoInputTextPane({
  initialValue,
  onChangeValue,
  onError,
  placeholderValue,
  maxCharCount,
  lineCount = 1,
  valueValidator,
  valueFilter,
  modifiable = false,
  required = false,
}) {
  const calendarMeetingInfoInputTextPaneTheme = createTheme(
    makeCalendarMeetingInfoInputTextPaneTheme("light")
  );

  const [textValue, setTextValue] = useState(initialValue);
  const [textValueValid, setTextValueValid] = useState(true);

  const handleUnfocusName = () => {
    if (textValueValid) {
      onChangeValue?.(textValue);
    }
  };

  const handleSetValue = (newValue) => {
    const updateValue = valueFilter ? valueFilter(newValue) : newValue;

    const updateValueValidationResult = valueValidator
      ? valueValidator(updateValue)
      : undefined;

    const updateValueValid = updateValueValidationResult
      ? updateValueValidationResult.status ===
        CalendarMeetingInfoValidationStatus.VALID
      : true;

    if (updateValueValid) {
      onChangeValue?.(updateValue);
    } else {
      onError?.(updateValueValidationResult);
    }

    setTextValue(updateValue);
    setTextValueValid(updateValueValid);
  };

  return (
    <ThemeProvider theme={calendarMeetingInfoInputTextPaneTheme}>
      <InputText
        required={required}
        numLines={lineCount}
        value={textValue}
        setValue={modifiable ? handleSetValue : undefined}
        error={!textValueValid}
        maxCharCount={modifiable ? maxCharCount : undefined}
        placeholderValue={placeholderValue}
      />
    </ThemeProvider>
  );
}
