import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function IconAccept(props) {
  const { onClick, size, fill, opacity, onHoverOpacity, ...other } = props;

  return (
    <SvgIcon
      {...other}
      sx={{
        fontSize: size, 
        width: size,
        height: size,
        "&:hover path": {
          opacity: onHoverOpacity,
        },
      }}
      onClick={onClick}
    >
      <path
        d="M10.4167 0C4.66667 0 0 4.66667 0 10.4167C0 16.1667 4.66667 20.8333 10.4167 20.8333C16.1667 20.8333 20.8333 16.1667 20.8333 10.4167C20.8333 4.66667 16.1667 0 10.4167 0ZM8.33333 15.625L3.125 10.4167L4.59375 8.94792L8.33333 12.6771L16.2396 4.77083L17.7083 6.25L8.33333 15.625Z"
        fill={fill}
        opacity={opacity}
        // The transform-translate is required to center the <path/> in the center of <SvgIcon/>.
        transform="translate(1.6, 1.6)"
      />
    </SvgIcon>
  );
}

export default IconAccept;
