// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import HelpFaqSuperPane from "../../super_pane/help/HelpFaqSuperPane";
import SurroundingControls from "../../special/SurroundingControls";

export const makeHelpFaqPageThemeDesignTokens = (mode) => ({});

export function makeHelpFaqPageTheme(mode) {
  return {
    ...makeHelpFaqPageThemeDesignTokens(mode),
    components: {},
  };
}

export default function HelpFaqPage({}) {
  const helpFaqPageTheme = createTheme(makeHelpFaqPageTheme("light"));

  return (
    <ThemeProvider theme={helpFaqPageTheme}>
      <SurroundingControls>
        <HelpFaqSuperPane />
      </SurroundingControls>
    </ThemeProvider>
  );
}
