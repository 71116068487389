// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography, Avatar } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import ImageEditable from "../../component/shared/image/ImageEditable";
import tempProfilePictureA from "../../asset/image/placeholder/profile_picture/sample-pfp-amir.png";
import {
  DATE_BACKEND_FORMAT,
  DATE_FRONTEND_FORMAT_LONG,
  dateConvertToObject,
  dateConvertToString,
} from "../../../service/browser/DateService";
import {
  DB_TABLE_USER,
  requestGetEntry,
} from "../../../service/browser/BackendRequestService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";

export const makeProfilePortraitPaneThemeDesignTokens = (mode) => ({
  typography: {
    bigUserName: {
      fontFamily: "Source Serif Pro",
      color: purple[900],
      fontWeight: 600,
      fontSize: 40,
      textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
      textTransform: "none",
      lineHeight: 0.9,
    },
    userJoinDate: {
      fontFamily: "PT Sans",
      color: purple[300],
      fontWeight: 600,
      fontSize: 20,
    },
  },
});

export function makeProfilePortraitPaneTheme(mode) {
  return {
    ...makeProfilePortraitPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function ProfilePortraitPane({
  targetUserUuid,
  onProfilePictureClick,
}) {
  const profilePortraitPaneTheme = createTheme(
    makeProfilePortraitPaneTheme("light")
  );

  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [targetUserData, setTargetUserData] = useState({});

  useEffect(() => {
    requestGetEntry(
      DB_TABLE_USER,
      targetUserUuid,
      (userData) => {
        setTargetUserData(userData);
        setAssetLoading(false);

        setTimeout(() => {
          setAssetVisible(true);
        }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  }, []);

  const handleProfilePictureClick = () => {
    onProfilePictureClick?.();
  };

  const dateBackend = targetUserData["creation_date_time"];
  const date =
    dateBackend && dateConvertToObject(dateBackend, DATE_BACKEND_FORMAT);
  const dateText = date && dateConvertToString(date, DATE_FRONTEND_FORMAT_LONG);

  return (
    <ThemeProvider theme={profilePortraitPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name={"ProfilePortraitPane"} />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "400px",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          <Box
            sx={{
              width: "180px",
              height: "180px",
            }}
          >
            {/* <ImageEditable
            pictureUrl={profilePictureUrl}
            onClick={handleProfilePictureClick}
          /> */}
            {/* <Avatar
              alt={targetUserData["user_name"]}
              src={tempProfilePictureA}
              sx={{
                width: "100%",
                height: "100%",
              }}
            /> */}
            <Avatar
              src={targetUserData["profile_picture_url"]}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="bigUserName">
              {targetUserData["user_name"]}
            </Typography>
            <Typography variant="userJoinDate">Joined {dateText}</Typography>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}
