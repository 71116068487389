// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import { userSessionEnd } from "../../../service/user/UserService";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import { useNavigate } from "react-router";
import {
  DB_TABLE_USER,
  DB_TABLE_USER_FRIEND,
  DB_TABLE_USER_NOTIFICATION,
  improvedRequestDeleteEntry,
  improvedRequestDeleteEntryAssociation,
  improvedRequestSearchEntry,
  requestDeleteEntry,
} from "../../../service/browser/BackendRequestService";
import {
  notificationCreate,
  NotificationType,
} from "../../../service/user/NotificationService";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import { notificationCreationTemplateUserFriendRequest } from "../../../service/user/NotificationCreationTemplateService";

export const makeProfileCriticalControlPaneThemeDesignTokens = (mode) => ({});

export function makeProfileCriticalControlPaneTheme(mode) {
  return {
    ...makeProfileCriticalControlPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function ProfileCriticalControlPane({
  presentUserUuid,
  targetUserUuid,
}) {
  const profileCriticalControlPaneTheme = createTheme(
    makeProfileCriticalControlPaneTheme("light")
  );

  const viewingOwnProfile = presentUserUuid === targetUserUuid;

  const navigate = useNavigate();
  const { setAuthenticated, setPresentSessionUuid, setPresentUserUuid } =
    useContext(UserContext);

  const REDIRECT_DELAY_DURATION_MS = 200;
  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 400;

  const [targetUserFriended, setTargetUserFriended] = useState(false);
  const [targetUserFriendRequestSent, setTargetUserFriendRequestSent] =
    useState(false);
  const [
    presentUserFriendRequestReceived,
    setPresentUserFriendRequestReceived,
  ] = useState(false);

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [assetBusy, setAssetBusy] = useState(false);

  // NOTE: debounce mechanism does not seem necessary for this component, will avoid making it for now.

  // Pure async function, can throw errors.
  const initAlternateProfileData = async () => {
    const [
      notificationForTargetResponse,
      notificationForPresentResponse,
      friendToTargetUserResponse,
      friendToPresentUserResponse,
    ] = await Promise.all([
      // NOTE: need action_completed=false in the search because the user may have sent multiple friend requests in the past.
      improvedRequestSearchEntry(DB_TABLE_USER_NOTIFICATION, {
        user_uuid: targetUserUuid,
        first_resource_uuid: presentUserUuid,
        type: NotificationType.USER_FRIEND_REQUEST,
        action_completed: false,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_NOTIFICATION, {
        user_uuid: presentUserUuid,
        first_resource_uuid: targetUserUuid,
        type: NotificationType.USER_FRIEND_REQUEST,
        action_completed: false,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: presentUserUuid,
        friended_user_uuid: targetUserUuid,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: targetUserUuid,
        friended_user_uuid: presentUserUuid,
      }),
    ]);

    if (
      notificationForPresentResponse.data &&
      notificationForTargetResponse.data &&
      friendToTargetUserResponse.data &&
      friendToPresentUserResponse.data
    ) {
      const userNotificationDataListPresentUser =
        notificationForPresentResponse.data["content"];
      const userNotificationDataListTargetUser =
        notificationForTargetResponse.data["content"];
      const userFriendDataListTargetUser =
        friendToTargetUserResponse.data["content"];
      const userFriendDataListPresentUser =
        friendToPresentUserResponse.data["content"];

      const newPresentUserFriendRequestReceived =
        userNotificationDataListPresentUser.length > 0;
      const newTargetUserFriendRequestSent =
        userNotificationDataListTargetUser.length > 0;
      const newTargetUserFriended =
        userFriendDataListTargetUser.length > 0 ||
        userFriendDataListPresentUser.length > 0;

      setPresentUserFriendRequestReceived(newPresentUserFriendRequestReceived);
      setTargetUserFriendRequestSent(newTargetUserFriendRequestSent);
      setTargetUserFriended(newTargetUserFriended);
    }
  };

  useEffect(() => {
    if (viewingOwnProfile) {
      setAssetLoading(false);

      setTimeout(() => {
        setAssetVisible(true);
      }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
    } else {
      initAlternateProfileData()
        .then(() => {
          setAssetLoading(false);

          setTimeout(() => {
            setAssetVisible(true);
          }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
        })
        .catch((error) => {
          setAssetLoadingError(true);
        });
    }
  }, []);

  const presentUserSignOut = () => {
    setAssetBusy(true);

    userSessionEnd(
      setAuthenticated,
      setPresentSessionUuid,
      setPresentSessionUuid
    )
      .then(() => {
        setTimeout(() => {
          navigate("/", { replace: true });
        }, REDIRECT_DELAY_DURATION_MS);
      })
      .catch((error) => {});
  };

  // Pure async function, can throw errors.
  const processTargetUserRemoveFriend = async () => {
    if (!targetUserFriended) {
      return;
    }

    const [friendToTargetUserResponse, friendToPresentUserResponse] =
      await Promise.all([
        improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
          user_uuid: presentUserUuid,
          friended_user_uuid: targetUserUuid,
        }),
        improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
          user_uuid: targetUserUuid,
          friended_user_uuid: presentUserUuid,
        }),
      ]);

    if (friendToTargetUserResponse.data && friendToPresentUserResponse.data) {
      const userFriendDataListTargetUser =
        friendToTargetUserResponse.data["content"];
      const userFriendDataListPresentUser =
        friendToPresentUserResponse.data["content"];

      // NOTE: even though both responses have a list of friends, in case there was a bug with the criteria and the search yielded
      // multiple user-friend entires that belong to other users, this function will ONLY remove the FIRST relationship found in each.
      // Also, it will remove BOTH if BOTH exist (even though only one or the other should).

      const attemptDeleteTarget = async () => {
        if (userFriendDataListTargetUser.length > 0) {
          await improvedRequestDeleteEntry(
            DB_TABLE_USER_FRIEND,
            userFriendDataListTargetUser[0]["uuid"]
          );
        }
      };
      const attemptDeletePresent = async () => {
        if (userFriendDataListPresentUser.length > 0) {
          await improvedRequestDeleteEntry(
            DB_TABLE_USER_FRIEND,
            userFriendDataListPresentUser[0]["uuid"]
          );
        }
      };

      await Promise.all([attemptDeleteTarget(), attemptDeletePresent()]);
      setTargetUserFriended(false);
    }
  };

  const handleTargetUserRemoveFriend = () => {
    if (!assetBusy) {
      setAssetBusy(true);

      processTargetUserRemoveFriend()
        .then(() => {
          setAssetBusy(false);
        })
        .catch((error) => {
          // Due to complexity of this component, error-out the entire component if this fails.
          setAssetLoadingError(true);
        });
    }
  };

  // Pure async function, can throw errors.
  const processTargetUserSendFriendRequest = async () => {
    if (targetUserFriendRequestSent) {
      return;
    }
    const [
      notificationForTargetResponse,
      notificationForPresentResponse,
      friendToTargetUserResponse,
      friendToPresentUserResponse,
    ] = await Promise.all([
      // NOTE: need action_completed=false in the search because the user may have sent multiple friend requests in the past.
      improvedRequestSearchEntry(DB_TABLE_USER_NOTIFICATION, {
        user_uuid: targetUserUuid,
        first_resource_uuid: presentUserUuid,
        type: NotificationType.USER_FRIEND_REQUEST,
        action_completed: false,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_NOTIFICATION, {
        user_uuid: presentUserUuid,
        first_resource_uuid: targetUserUuid,
        type: NotificationType.USER_FRIEND_REQUEST,
        action_completed: false,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: presentUserUuid,
        friended_user_uuid: targetUserUuid,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: targetUserUuid,
        friended_user_uuid: presentUserUuid,
      }),
    ]);

    if (
      notificationForPresentResponse.data &&
      notificationForTargetResponse.data &&
      friendToTargetUserResponse.data &&
      friendToPresentUserResponse.data
    ) {
      const userNotificationDataListPresentUser =
        notificationForPresentResponse.data["content"];
      const userNotificationDataListTargetUser =
        notificationForTargetResponse.data["content"];
      const userFriendDataListTargetUser =
        friendToTargetUserResponse.data["content"];
      const userFriendDataListPresentUser =
        friendToPresentUserResponse.data["content"];

      const newPresentUserFriendRequestReceived =
        userNotificationDataListPresentUser.length > 0;
      const newTargetUserFriendRequestSent =
        userNotificationDataListTargetUser.length > 0;
      const newTargetUserFriended =
        userFriendDataListTargetUser.length > 0 ||
        userFriendDataListPresentUser.length > 0;

      setPresentUserFriendRequestReceived(newPresentUserFriendRequestReceived);
      setTargetUserFriendRequestSent(newTargetUserFriendRequestSent);
      setTargetUserFriended(newTargetUserFriended);

      if (
        !newPresentUserFriendRequestReceived &&
        !newTargetUserFriendRequestSent &&
        !newTargetUserFriended
      ) {
        await notificationCreate(
          notificationCreationTemplateUserFriendRequest(
            presentUserUuid,
            targetUserUuid,
            ""
          )
        );
        setTargetUserFriendRequestSent(true);
      }
    }
  };

  const handleTargetUserSendFriendRequest = () => {
    if (!assetBusy) {
      setAssetBusy(true);

      processTargetUserSendFriendRequest()
        .then(() => {
          setAssetBusy(false);
        })
        .catch((error) => {
          // Due to complexity of this component, error-out the entire component if this fails.
          setAssetLoadingError(true);
        });
    }
  };

  const viewInbox = () => {
    if (!assetBusy && presentUserFriendRequestReceived) {
      setAssetBusy(true);
      navigate("/inbox", { replace: true });
    }
  };

  // (AKA "Primary Profile")
  const viewingOwnProfileButtons = () => {
    return (
      <ButtonRectangleLabel
        variant="neutral"
        label="Sign Out"
        onClick={() => presentUserSignOut()}
      />
    );
  };

  // (AKA "Alternate Profile")
  const viewingOtherProfileButtons = () => {
    if (targetUserFriended) {
      return (
        <ButtonRectangleLabel
          variant="critical"
          label="Remove Friend"
          disabled={!targetUserFriended}
          onClick={() => handleTargetUserRemoveFriend()}
        />
      );
    } else if (presentUserFriendRequestReceived) {
      return (
        <ButtonRectangleLabel
          variant="success"
          label={"See Friend Request in Inbox"}
          disabled={!presentUserFriendRequestReceived}
          onClick={() => viewInbox()}
        />
      );
    } else {
      return (
        <ButtonRectangleLabel
          variant="success"
          label={
            targetUserFriendRequestSent ? "Friend Request Sent" : "Add Friend"
          }
          disabled={targetUserFriendRequestSent}
          onClick={() => handleTargetUserSendFriendRequest()}
        />
      );
    }
  };

  return (
    <ThemeProvider theme={profileCriticalControlPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name="ProfileCriticalControlPane" />
      )}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          {viewingOwnProfile
            ? viewingOwnProfileButtons()
            : viewingOtherProfileButtons()}
        </Box>
      )}
    </ThemeProvider>
  );
}
