import {
  parse,
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isSameWeek,
  isBefore,
  subWeeks,
  differenceInDays,
} from "date-fns";

// export const DATE_BACKEND_FORMAT = "yyyy-MM-dd'T'HH:mm:ssX"
export const DATE_BACKEND_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_FRONTEND_FORMAT_LONG = "MMMM d, yyyy";
export const DATE_FRONTEND_FORMAT_SHORT = "MM/dd/yyyy";
export const DATE_FRONTEND_FORMAT_TIME_ONLY = "hh:mm a";
export const DATE_FRONTEND_FORMAT_MONTH_ONLY = "MMMM";
export const DATE_FRONTEND_FORMAT_YEAR_ONLY = "yyyy";

export const dateCheckTimeEqual = (dateA, dateB) => {
  // Extract hours, minutes, and seconds from both dates
  const hoursA = dateA.getHours();
  const minutesA = dateA.getMinutes();
  const secondsA = dateA.getSeconds();

  const hoursB = dateB.getHours();
  const minutesB = dateB.getMinutes();
  const secondsB = dateB.getSeconds();

  // Compare the components
  return hoursA === hoursB && minutesA === minutesB && secondsA === secondsB;
};

export const dateCheckDayEqual = (dateA, dateB) => {
  // Extract year, month, and day from both dates
  const yearA = dateA.getFullYear();
  const monthA = dateA.getMonth();
  const dayA = dateA.getDate();

  const yearB = dateB.getFullYear();
  const monthB = dateB.getMonth();
  const dayB = dateB.getDate();

  // Compare the components
  return yearA === yearB && monthA === monthB && dayA === dayB;
};

export const dateConvertToObject = (dateString) => {
  return parse(dateString, DATE_BACKEND_FORMAT, new Date());
};

export const dateConvertToString = (dateObject, dateFormatString) => {
  return format(dateObject, dateFormatString);
};

export const dateConvertToStringRelative = (dateData) => {
  const now = new Date();

  if (isToday(dateData)) {
    return "Today";
  } else if (isYesterday(dateData)) {
    return "Yesterday";
  } else if (isBefore(dateData, now) && differenceInDays(now, dateData) < 6) {
    return `Last ${format(dateData, "EEEE")}`;
  }

  return format(dateData, "MMM d, yyyy");
};

export const dateShiftDay = (date, offset) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + offset);
  return newDate;
};

export const dateShiftWeek = (date, offset) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + offset * 7);
  return newDate;
};

export const dateShiftMonth = (date, offset) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + offset);
  return newDate;
};
