// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";

// Interactive Assets
import { Typography } from "@mui/material";

// Custom Theme and Style
import { purple } from "../../../style/AppTheme"

export const makeTextHeadingMediumADesignTokens = (mode) => ({
  typography: {
    sectionHeadingMediumA: {
      fontFamily: "PT Sans",
      fontSize: 26,
      fontStyle: "italic",
      fontWeight: 400,
      color: purple[900],
    },
  },
});

export function makeTextHeadingMediumATheme(mode) {
  return {
    ...makeTextHeadingMediumADesignTokens(mode),
  };
}

export default function TextHeadingMediumA({ label }) {
  const textHeadingMediumATheme = createTheme(
    makeTextHeadingMediumATheme("light")
  );

  return (
    <ThemeProvider theme={textHeadingMediumATheme}>
      <Typography variant="sectionHeadingMediumA">{label}</Typography>
    </ThemeProvider>
  );
}
