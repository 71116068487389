// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import LobbySignInButton from "../../component/lobby/LobbySignInButton";
import Separator from "../../component/shared/other/Separator";
import StatusMessage, {
  StatusMessageType,
} from "../../component/other/StatusMessage";
import LobbyLandingLoginPane from "../../pane/lobby/LobbyLandingLoginPane";
import TextBodySmall from "../../component/shared/text/TextBodySmall";

export const makeLobbyLandingSuperPaneThemeDesignTokens = (mode) => ({
  typography: {
    sectionHeading: {
      fontFamily: "Source Serif Pro",
      color: purple[900],
      fontSize: 24,
      textTransform: "none",
    },
    bigHeading: {
      fontFamily: "PT Sans",
      color: purple[900],
      fontWeight: 600,
      fontSize: 32,
      textTransform: "none",
    },
    noteBody: {
      fontFamily: "PT Sans",
      color: purple[900],
      fontWeight: 600,
      fontStyle: "italic",
      fontSize: 16,
      textTransform: "none",
    },
  },
});

export function makeLobbyLandingSuperPaneTheme(mode) {
  return {
    ...makeLobbyLandingSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function LobbyLandingSuperPane({
  onNewUser,
  onExistingUser,
  onFailure,
}) {
  const lobbyLandingSuperPaneTheme = createTheme(
    makeLobbyLandingSuperPaneTheme("light")
  );

  return (
    <ThemeProvider theme={lobbyLandingSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            padding: "12px",
            textAlign: "center",
          }}
        >
          <Typography variant="bigHeading">
            Start Your Studious Journey Today!
          </Typography>
          <LobbyLandingLoginPane
            onNewUser={onNewUser}
            onExistingUser={onExistingUser}
            onFailure={onFailure}
          />
          <Box
            sx={{
              maxWidth: "300px",
            }}
          >
            <Typography variant="noteBody">
              NOTE: only Google Accounts from the University of Arizona can be
              used to log in.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "12px",
            backgroundColor: alpha(gray[200], 0.5),
            borderRadius: "6px",
            width: "70%",
            height: "800px",
          }}
        >
          <Typography variant="sectionHeading">About Us</Typography>
          <Separator />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
              padding: "12px",
              width: "100%",
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/xrDThsPZWDY?si=ebhEixaziA8SEqM_"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
