import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function IconNavigationArrowThin(props) {
  const {
    flippedRight = false,
    onClick,
    width,
    height,
    stroke,
    strokeWidth,
    opacity,
    onHoverOpacity,
    ...other
  } = props;

  return (
    <SvgIcon
      {...other}
      sx={{
        fontSize: height,
        width: width,
        height: height,
        "&:hover path": {
          opacity: onHoverOpacity,
        },
        rotate: `${flippedRight ? "180deg" : "0deg"}`,
        
      }}
      onClick={onClick}
    >
      <path
        d="M5 1L1 10L5 19"
        fill="none"
        stroke={stroke}
        opacity={opacity}
        strokeWidth={strokeWidth}
        // The transform-translate is required to center the <path/> in the center of <SvgIcon/>.
        // transform="translate(7.5, 0) scale(1.2, 1.2)" (width and height the same)
        transform="translate(2, -27) scale(3.9, 3.9)"
      />
    </SvgIcon>
  );
}

export default IconNavigationArrowThin;
