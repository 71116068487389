import {
  DB_TABLE_USER,
  DB_TABLE_USER_ENDORSEMENT,
  DB_TABLE_USER_FRIEND,
  DB_TABLE_USER_SESSION,
  improvedRequestCreateEntry,
  improvedRequestGetEntry,
  improvedRequestSearchEntry,
  improvedRequestUpdateEntry,
  requestCreateEntry,
  requestDeleteEntry,
  requestGetEntry,
  requestSearchEntry,
} from "../browser/BackendRequestService";
import {
  COOKIE_KEY_SESSION_ID,
  COOKIE_LENGTH_SESSION,
  getCookie,
  removeCookie,
  setCookie,
} from "../browser/CookieService";
import {
  notificationCreationTemplateUserEndorsementAccept,
  notificationCreationTemplateUserEndorsementDeny,
  notificationCreationTemplateUserFriendAccept,
  notificationCreationTemplateUserFriendDeny,
} from "./NotificationCreationTemplateService";
import { notificationCreate } from "./NotificationService";

// onSuccess: (MySQL session table as JSON) => Void
// onError: (error) => Void
export const userSessionBegin = async (userUuid) => {
  return new Promise((resolve, reject) => {
    const sessionData = {
      user_uuid: userUuid,
    };

    requestCreateEntry(
      DB_TABLE_USER_SESSION,
      sessionData,
      (backendResponseData) => {
        if (backendResponseData && backendResponseData["uuid"]) {
          setCookie(COOKIE_KEY_SESSION_ID, backendResponseData["uuid"], {
            expires: COOKIE_LENGTH_SESSION,
          });
          resolve(backendResponseData);
        } else {
          reject();
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};

// onFound: (MySQL session table as JSON) => Void
// onNotFound: () => Void
// onError: (error) => Void

// NOTE: for the requestGetEntry, will interpret any error as resolve(undefined) not reject()
export const userSessionGet = async () => {
  return new Promise((resolve, reject) => {
    const cookieSessionId = getCookie(COOKIE_KEY_SESSION_ID);

    if (!cookieSessionId) {
      resolve(undefined);
    }

    requestGetEntry(
      DB_TABLE_USER_SESSION,
      cookieSessionId,
      (backendUserSessionResponseData) => {
        if (
          backendUserSessionResponseData &&
          backendUserSessionResponseData["uuid"]
        ) {
          const userUuid = backendUserSessionResponseData["user_uuid"];

          requestGetEntry(
            DB_TABLE_USER,
            userUuid,
            (backendUserResponseData) => {
              if (backendUserResponseData && backendUserResponseData["uuid"]) {
                resolve(backendUserSessionResponseData);
              } else {
                resolve(undefined);
              }
            },
            (error) => {
              // reject(error);
              resolve(undefined);
            }
          );
        } else {
          resolve(undefined);
        }
      },
      (error) => {
        // reject(error);
        resolve(undefined);
      }
    );
  });
};

export const userSessionSet = (
  userSessionData,
  setAuthenticated,
  setSessionUuid,
  setUserUuid
) => {
  setCookie(userSessionData["uuid"]);
  setAuthenticated(true);
  setSessionUuid(userSessionData["uuid"]);
  setUserUuid(userSessionData["user_uuid"]);
};

export const userSessionEnd = async (
  setAuthenticated,
  setSessionUuid,
  setUserUuid
) => {
  return new Promise((resolve, reject) => {
    const cookieSessionId = getCookie(COOKIE_KEY_SESSION_ID);

    if (!cookieSessionId) {
      resolve();
    }

    removeCookie(COOKIE_KEY_SESSION_ID);
    setAuthenticated(null);
    setSessionUuid(null);
    setUserUuid(null);

    requestDeleteEntry(
      DB_TABLE_USER_SESSION,
      cookieSessionId,
      (userSessionResponseData) => {
        resolve();
      },
      (error) => {
        // Since the cookie was deleted, even if the session deletion fails, the website will create a
        // new session when the user logs in again.
        resolve();
      }
    );
  });
};

// Pure async function, can throw errors.
// NOTE (9/1/2024): based on code originally from NotificationActionService
export const userFriendRequestAccept = async (
  friendingUserUuid,
  friendedUserUuid
) => {
  // NOTE: create a new user-friend if no user-friend exists either you-to-them or them-to-you (either direction).
  const [friendToPendingUserResponse, friendToPresentUserResponse] =
    await Promise.all([
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: friendedUserUuid,
        friended_user_uuid: friendingUserUuid,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: friendingUserUuid,
        friended_user_uuid: friendedUserUuid,
      }),
    ]);

  if (friendToPendingUserResponse.data && friendToPresentUserResponse.data) {
    const userFriendDataListPendingUser =
      friendToPendingUserResponse.data["content"];
    const userFriendDataListPresentUser =
      friendToPresentUserResponse.data["content"];

    if (
      userFriendDataListPendingUser.length == 0 &&
      userFriendDataListPresentUser.length == 0
    ) {
      // NOTE:
      // The "user_uuid" is the user who initiated the invite (here, the pending user).
      // The "friended_user_uuid" is the user receiving the invite (here, the present user).

      await improvedRequestCreateEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: friendingUserUuid,
        friended_user_uuid: friendedUserUuid,
      });
      await notificationCreate(
        notificationCreationTemplateUserFriendAccept(
          friendingUserUuid,
          friendedUserUuid,
          ""
        )
      );
    }
  }
};

// Pure async function, can throw errors.
// NOTE (9/1/2024): based on code originally from NotificationActionService
export const userFriendRequestDeny = async (
  friendingUserUuid,
  friendedUserUuid
) => {
  await notificationCreate(
    notificationCreationTemplateUserFriendDeny(
      friendingUserUuid,
      friendedUserUuid,
      ""
    )
  );
};

// Pure async function, can throw errors.
// NOTE (9/1/2024): based on code originally from NotificationActionService
export const userEndorsementRequestAccept = async (
  endorsingUserUuid,
  endorsedUserUuid,
  userEndorsementUuid
) => {
  const getResponse = await improvedRequestGetEntry(
    DB_TABLE_USER_ENDORSEMENT,
    userEndorsementUuid
  );
  const receivedUserEndorsementData = getResponse.data;

  if (
    receivedUserEndorsementData &&
    receivedUserEndorsementData["visible"] == false &&
    receivedUserEndorsementData["soft_deleted"] == false
  ) {
    // NOTE: Update the endorsement ot be visible ONLY IF it is not currently visible AND it was not soft-deleted.
    const newUserEndorsementData = { ...receivedUserEndorsementData };
    newUserEndorsementData["visible"] = true;

    await improvedRequestUpdateEntry(
      DB_TABLE_USER_ENDORSEMENT,
      userEndorsementUuid,
      newUserEndorsementData
    );
    await notificationCreate(
      notificationCreationTemplateUserEndorsementAccept(
        endorsingUserUuid,
        endorsedUserUuid,
        userEndorsementUuid
      )
    );
  }
};

// Pure async function, can throw errors.
// NOTE (9/1/2024): based on code originally from NotificationActionService
export const userEndorsementRequestDeny = async (
  endorsingUserUuid,
  endorsedUserUuid,
  userEndorsementUuid
) => {
  await notificationCreate(
    notificationCreationTemplateUserEndorsementDeny(
      endorsingUserUuid,
      endorsedUserUuid,
      userEndorsementUuid
    )
  );
};
