// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";

// Interactive Assets
import {
  Avatar,
  Box,
  ListSubheader,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";

// Custom Theme and Style
import { colorBg, blue, purple, red, green, gray } from "../style/AppTheme";

// Extra
import { ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import {
  DB_TABLE_USER,
  requestGetEntry,
} from "../../service/browser/BackendRequestService";

export const makeSidebarThemeDesignTokens = (mode) => ({});

export function makeSidebarTheme(mode) {
  return {
    ...makeSidebarThemeDesignTokens(mode),
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            justifyContent: "flex-start",
            textAlign: "left",
            paddingInline: "20px",
            paddingBlock: "2px",
            borderBottomLeftRadius: "0px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            backgroundColor: alpha(purple[100], 0),
          }),
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: ({ theme, ownerState }) => ({
            ...(ownerState.textStyle === "normal" && {
              fontFamily: "Source Serif Pro",
              color: purple[900],
              fontSize: 28,
              textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
              textTransform: "none",
            }),
            ...(ownerState.textStyle === "normal-disabled" && {
              fontFamily: "Source Serif Pro",
              color: gray[300],
              fontSize: 28,
              textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
              textTransform: "none",
            }),
            ...(ownerState.textStyle === "small" && {
              fontFamily: "Source Serif Pro",
              color: purple[900],
              fontSize: 20,
              textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
              textTransform: "none",
            }),
            ...(ownerState.textStyle === "small-disabled" && {
              fontFamily: "Source Serif Pro",
              color: gray[300],
              fontSize: 20,
              textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
              textTransform: "none",
            }),
          }),
        },
      },
    },
  };
}

// 8/27/2024
// NOTE: navigator is a function that:
// (1) if not undefined, takes an id string and returns a string url to navigate it (or return an empty string, which will not result in any navigation).
// (2) if undefined, nothing will happen.
// (3) will not do anything for parent itemList (only child itemList), because it does not make sense to navigate to a page when clicking a parent, drop-down-like item.

export default function Sidebar({
  itemList,
  onClickItem,
  navigator,
  profilePictureVisible,
}) {
  const sidebarTheme = createTheme(makeSidebarTheme("light"));

  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;

  const { authenticated, presentUserUuid } = useContext(UserContext);
  const navigate = useNavigate();

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [userProfilePictureUrl, setUserProfilePictureUrl] = useState("");
  const [openItems, setOpenItems] = useState({});

  useEffect(() => {
    if (authenticated && presentUserUuid && presentUserUuid !== "") {
      requestGetEntry(DB_TABLE_USER, presentUserUuid, (userData) => {
        if (userData && userData["profile_picture_url"]) {
          setUserProfilePictureUrl(userData["profile_picture_url"]);
          setAssetLoading(false);
        }
      });
    } else {
      setAssetLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!assetLoading) {
      setTimeout(() => {
        setAssetVisible(true);
      }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
    }
  }, [assetLoading]);

  const handleProfileAvatarClick = () => {
    if (authenticated) {
      navigate("/settings/my-profile", { replace: true });
    }
  };

  const handleNavigate = (id) => {
    if (navigator && navigator !== undefined) {
      const navigationUrl = navigator(id);
      if (navigationUrl !== undefined && navigationUrl !== "") {
        navigate(navigationUrl, { replace: true });
      }
    }
  };

  const handleChildClick = (id) => {
    onClickItem?.(id);
    handleNavigate(id);
  };

  const handleParentClick = (hasChildren, id) => {
    onClickItem?.(id);
    if (hasChildren) {
      setOpenItems((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    } else {
      handleNavigate(id);
    }
  };

  const checkItemDisabled = (item) => {
    return !(item.disabled === undefined || item.disabled == false);
  };

  const renderItem = (item) => {
    const hasChildren = itemList.some((child) => child.parent === item.id);

    return (
      <div key={item.id}>
        <ListItemButton
          onClick={
            checkItemDisabled(item)
              ? undefined
              : () => handleParentClick(hasChildren, item.id)
          }
        >
          <ListItemText
            primary={item.label}
            textStyle={checkItemDisabled(item) ? "normal-disabled" : "normal"}
          />
          {hasChildren ? (
            openItems[item.id] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </ListItemButton>
        {hasChildren && (
          <Collapse in={openItems[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {itemList
                .filter((child) => child.parent === item.id)
                .map((child) => (
                  <ListItemButton
                    key={child.id}
                    sx={{ pl: 4 }}
                    onClick={
                      checkItemDisabled(child)
                        ? undefined
                        : () => handleChildClick(child.id)
                    }
                  >
                    <ListItemText
                      primary={child.label}
                      textStyle={
                        checkItemDisabled(child) ? "small-disabled" : "small"
                      }
                    />
                  </ListItemButton>
                ))}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <ThemeProvider theme={sidebarTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "200px",
          borderRight: "2px solid",
          borderColor: alpha(gray[200], 1),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "160px",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          {profilePictureVisible && (
            <Avatar
              alt="Profile"
              src={userProfilePictureUrl}
              onClick={() => handleProfileAvatarClick()}
              sx={{
                width: "120px",
                height: "120px",
                transition: "filter 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                "&:hover": {
                  filter: "brightness(70%)",
                },
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {itemList.filter((item) => item.parent === "root").map(renderItem)}
          </List>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
