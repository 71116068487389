export const componentHeightCalculate = (ref, offset, minimum) => {
  if (ref.current) {
    const refRect = ref.current.getBoundingClientRect();
    const newHeight = Math.max(
      window.innerHeight - refRect.top + offset,
      minimum
    );
    return newHeight;
  } else {
    return minimum;
  }
};

export const componentHeightSet = (setHeight, heightPixels) => {
  setHeight(`${heightPixels}px`);
};

export const componentWidthCalculate = (ref, offset, minimum) => {
  if (ref.current) {
    const refRect = ref.current.getBoundingClientRect();
    const newWidth = Math.max(
      window.innerWidth - refRect.left + offset,
      minimum
    );
    return newWidth;
  } else {
    return minimum;
  }
};

export const componentWidthSet = (setWidth, widthPixels) => {
  setWidth(`${widthPixels}px`);
};
