// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";

// Interactive Assets
import { Typography } from "@mui/material";

// Custom Theme and Style
import { purple } from "../../../style/AppTheme"

export const makeTextBodySmallThemeDesignTokens = (mode) => ({
  typography: {
    sectionBodySmall: {
      fontFamily: "PT Sans",
      fontSize: 16,
      color: purple[900],
    },
  },
});

export function makeTextBodySmallTheme(mode) {
  return {
    ...makeTextBodySmallThemeDesignTokens(mode),
    components: {},
  };
}

export default function TextBodySmall({ variant, label }) {
  const textBodySmallTheme = createTheme(
    makeTextBodySmallTheme("light")
  );

  return (
    <ThemeProvider theme={textBodySmallTheme}>
      <Typography variant="sectionBodySmall">{label}</Typography>
    </ThemeProvider>
  );
}
