// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, blue, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import ProfileUserListingPane, {
  ProfileUserListingGroupType,
} from "../../pane/profile/ProfileUserListingPane";
import InputText from "../../component/shared/input/InputText";
import ButtonCheckBox from "../../component/shared/button/ButtonCheckBox";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";

export const makeProfileUserListingSuperPaneThemeDesignTokens = (mode) => ({});

export function makeProfileUserListingSuperPaneTheme(mode) {
  return {
    ...makeProfileUserListingSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function ProfileUserListingSuperPane({
  targetUserUuid,
  selectedUserUuidList,
  dateBySelectedUserUuidTable,
  dateTextPrefix,
  listMaxHeight,
}) {
  const profileUserListingSuperPaneTheme = createTheme(
    makeProfileUserListingSuperPaneTheme("light")
  );
  const [searchBarText, setSearchBarText] = useState("");
  const [groupByCourseChecked, setGroupByCourseChecked] = useState(false);

  const handleCourseCheckBoxClick = () => {
    setGroupByCourseChecked(!groupByCourseChecked);
  };

  return (
    <ThemeProvider theme={profileUserListingSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          paddingTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <InputText
            placeholderValue={"Search..."}
            value={searchBarText}
            setValue={setSearchBarText}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: alpha(blue[800], 0.1),
            }}
          >
            <ButtonCheckBox
              checked={groupByCourseChecked}
              onClick={() => handleCourseCheckBoxClick()}
              outlineEnabled={true}
              fillEnabled={false}
              boxWidth={50}
            />
            <TextHeadingMediumA label="Group By Course" />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            ...(listMaxHeight && { maxHeight: listMaxHeight }),
            overflowY: "auto",
          }}
        >
          <ProfileUserListingPane
            groupType={groupByCourseChecked ? ProfileUserListingGroupType.COURSE : ProfileUserListingGroupType.NONE}
            targetUserUuid={targetUserUuid}
            selectedUserUuidList={selectedUserUuidList}
            dateBySelectedUserUuidTable={dateBySelectedUserUuidTable}
            dateTextPrefix={dateTextPrefix}
            userFilterText={searchBarText}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
