// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme"

// Extra
import * as React from "react";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";

// StudiousCat Components
import ButtonRectangleLabel from "../shared/button/ButtonRectangleLabel"; 
import {
  COOKIE_KEY_SESSION_ID,
  removeAllCookies,
  removeCookie,
} from "../../../service/browser/CookieService";

export const makeNetDebugControlsThemeDesignTokens = (mode) => ({});

export function makeNetDebugControlsTheme(mode) {
  return {
    ...makeNetDebugControlsThemeDesignTokens(mode),
    components: {},
  };
}

export default function NetDebugControls({ variant, label, onClick }) {
  const netDebugControlsTheme = createTheme(makeNetDebugControlsTheme("light"));

  const {
    authenticated,
    setAuthenticated,
    presentSessionUuid,
    setPresentSessionUuid,
    presentUserUuid,
    setPresentUserUuid,
  } = useContext(UserContext);

  const handleClickButtonClearCookies = () => {
    removeAllCookies();
  };

  return (
    <ThemeProvider theme={netDebugControlsTheme}>
      <Box
        sx={{
          padding: "10px",
        }}
      >
        <Grid
          container
          columnGap={2}
          sx={{
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: purple[100],
          }}
        >
          <Grid
            container
            item
            xs
            direction="column"
            rowSpacing={1}
            sx={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: purple[200],
            }}
          >
            <Grid item>
              <Typography>
                Authenticated: {authenticated ? "true" : "false"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>Session UUID: {presentSessionUuid}</Typography>
            </Grid>
            <Grid item>
              <Typography>User UUID: {presentUserUuid}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs
            direction="column"
            rowSpacing={1}
            sx={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: purple[200],
            }}
          >
            <ButtonRectangleLabel
              label="CLEAR COOKIES"
              onClick={handleClickButtonClearCookies}
            />
          </Grid>
          <Grid
            container
            item
            xs
            direction="column"
            rowSpacing={1}
            sx={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: purple[200],
            }}
          ></Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
