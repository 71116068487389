// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import {
  CalendarMeetingInfoRowItemWrapper,
  CalendarMeetingInfoRowWrapper,
} from "../../component/calendar_meeting_info/CalendarMeetingInfoComponentCollection";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";
import { CalendarMeetingInfoPreset } from "../../super_pane/calendar_meeting_info/CalendarMeetingInfoSuperPane";

export const makeCalendarMeetingInfoInputControlsPaneThemeDesignTokens = (
  mode
) => ({});

export function makeCalendarMeetingInfoInputControlsPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputControlsPaneThemeDesignTokens(mode),
    components: {},
  };
}

const DEBOUNCE_ACTION_TIMEOUT_DURATION_MS = 400;

export const CalendarMeetingInfoControlsActionType = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  DIRECTLY_JOIN: "DIRECTLY_JOIN",
  REQUEST_JOIN: "REQUEST_JOIN",
  ACCEPT_INVITE: "ACCEPT_INVITE",
  DENY_INVITE: "DENY_INVITE",
  LEAVE: "LEAVE",
};

export default function CalendarMeetingInfoInputControlsPane({
  meetingInfoPreset = CalendarMeetingInfoPreset.VIEW,
  meetingSubmissionReady,
  meetingModePublic,
  presentUserAttendingMeeting,
  presentUserRequestedJoin,
  presentUserInvited,
  onControlsAction,
}) {
  const calendarMeetingInfoInputControlsPaneTheme = createTheme(
    makeCalendarMeetingInfoInputControlsPaneTheme("light")
  );

  const [awaitingPropChange, setAwaitingPropChange] = useState(false);

  // No Reaction for Create/Delete because the expectation is that the entire CalendarMeetingInfoPane will be closed upon creation/deletion
  useEffect(() => {
    setAwaitingPropChange(false);
  }, [presentUserAttendingMeeting, presentUserRequestedJoin, presentUserInvited]);

  const processAction = (actionType) => {
    onControlsAction?.(actionType);
    setAwaitingPropChange(true);
  };

  const debounceAction = useCallback(
    debounce((actionType) => {
      processAction(actionType);
    }, DEBOUNCE_ACTION_TIMEOUT_DURATION_MS),
    [processAction]
  );

  const handleAction = useCallback(
    (actionType) => {
      if (!awaitingPropChange) {
        debounceAction(actionType);
      }
    },
    [debounceAction]
  );

  const generateCreateComponent = () => {
    return (
      <CalendarMeetingInfoRowItemWrapper>
        <ButtonRectangleLabel
          variant="success"
          label="Create"
          disabled={awaitingPropChange || !meetingSubmissionReady}
          onClick={() =>
            handleAction(CalendarMeetingInfoControlsActionType.CREATE)
          }
        />
      </CalendarMeetingInfoRowItemWrapper>
    );
  };

  const generateEditComponent = () => {
    return (
      <CalendarMeetingInfoRowItemWrapper>
        <ButtonRectangleLabel
          variant="critical"
          label="Delete"
          disabled={awaitingPropChange}
          onClick={() =>
            handleAction(CalendarMeetingInfoControlsActionType.DELETE)
          }
        />
      </CalendarMeetingInfoRowItemWrapper>
    );
  };

  const generateViewComponent = () => {
    if (presentUserAttendingMeeting) {
      return (
        <CalendarMeetingInfoRowItemWrapper>
          <ButtonRectangleLabel
            variant="critical"
            label="Leave"
            // Sanity Check for Disabled Parameter
            disabled={awaitingPropChange || !presentUserAttendingMeeting}
            onClick={() =>
              handleAction(CalendarMeetingInfoControlsActionType.LEAVE)
            }
          />
        </CalendarMeetingInfoRowItemWrapper>
      );
    } else if (presentUserInvited) {
      return (
        <CalendarMeetingInfoRowItemWrapper>
          <ButtonRectangleLabel
            variant="success"
            label="Accept Invite"
            // Sanity Check for Disabled Parameter
            disabled={
              awaitingPropChange ||
              presentUserAttendingMeeting ||
              !presentUserInvited
            }
            onClick={() =>
              handleAction(CalendarMeetingInfoControlsActionType.ACCEPT_INVITE)
            }
          />
          <ButtonRectangleLabel
            variant="critical"
            label="Decline Invite"
            // Sanity Check for Disabled Parameter
            disabled={
              awaitingPropChange ||
              presentUserAttendingMeeting ||
              !presentUserInvited
            }
            onClick={() =>
              handleAction(CalendarMeetingInfoControlsActionType.DENY_INVITE)
            }
          />
        </CalendarMeetingInfoRowItemWrapper>
      );
    } else if (meetingModePublic) {
      return (
        <CalendarMeetingInfoRowItemWrapper>
          <ButtonRectangleLabel
            variant="success"
            label="Join"
            // Sanity Check for Disabled Parameter
            disabled={
              awaitingPropChange ||
              !meetingModePublic ||
              presentUserAttendingMeeting
            }
            onClick={() =>
              handleAction(CalendarMeetingInfoControlsActionType.DIRECTLY_JOIN)
            }
          />
        </CalendarMeetingInfoRowItemWrapper>
      );
    } else {
      return (
        <CalendarMeetingInfoRowItemWrapper>
          <ButtonRectangleLabel
            variant="success"
            label="Request to Join"
            disabled={awaitingPropChange || presentUserRequestedJoin}
            onClick={() =>
              handleAction(CalendarMeetingInfoControlsActionType.REQUEST_JOIN)
            }
          />
        </CalendarMeetingInfoRowItemWrapper>
      );
    }
  };

  const generateMainComponent = () => {
    switch (meetingInfoPreset) {
      case CalendarMeetingInfoPreset.CREATE:
        return generateCreateComponent();
      case CalendarMeetingInfoPreset.EDIT:
        return generateEditComponent();
      case CalendarMeetingInfoPreset.VIEW:
        return generateViewComponent();
      default:
        return;
    }
  };

  // Seemingly redundant to have lambda function, but may have assetLoading and assetLoadingError in future.
  return (
    <ThemeProvider theme={calendarMeetingInfoInputControlsPaneTheme}>
      <CalendarMeetingInfoRowWrapper>
        {generateMainComponent()}
      </CalendarMeetingInfoRowWrapper>
    </ThemeProvider>
  );
}
