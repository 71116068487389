import axios from "axios";

export const DB_TABLE_USER = "user";
export const DB_TABLE_USER_SESSION = "user_session";
export const DB_TABLE_USER_REACTION = "user_reaction";
export const DB_TABLE_USER_ENDORSEMENT = "user_endorsement";
export const DB_TABLE_USER_NOTIFICATION = "user_notification";
export const DB_TABLE_USER_FRIEND = "user_friend";
export const DB_TABLE_USER_BLOCK = "user_block";
export const DB_TABLE_SCHOOL = "school";
export const DB_TABLE_MEETING = "meeting";
export const DB_TABLE_MEETING_AND_USER = "meeting_and_user";
export const DB_TABLE_COURSE = "course";
export const DB_TABLE_COURSE_AND_USER = "course_and_user";
export const DB_TABLE_MAJOR = "major";
export const DB_TABLE_MAJOR_AND_USER = "major_and_user";

export const PAGE_DEFAULT_NUMBER = 0;
export const PAGE_DEFAULT_SIZE = 100000;

export const BackendTableType = Object.freeze({
  IDENTIFIABLE: "identifiable",
  ASSOCIATION: "association",
});

export const TABLE_BY_TYPE = {
  DB_TABLE_USER: BackendTableType.IDENTIFIABLE,
  DB_TABLE_USER_SESSION: BackendTableType.IDENTIFIABLE,
  DB_TABLE_USER_REACTION: BackendTableType.IDENTIFIABLE,
  DB_TABLE_USER_ENDORSEMENT: BackendTableType.IDENTIFIABLE,
  DB_TABLE_USER_NOTIFICATION: BackendTableType.IDENTIFIABLE,
  // DB_TABLE_USER_FRIEND: BackendTableType.ASSOCIATION,
  // DB_TABLE_USER_BLOCK: BackendTableType.ASSOCIATION,
  DB_TABLE_USER_FRIEND: BackendTableType.IDENTIFIABLE,
  DB_TABLE_USER_BLOCK: BackendTableType.IDENTIFIABLE,
  DB_TABLE_SCHOOL: BackendTableType.IDENTIFIABLE,
  DB_TABLE_MEETING: BackendTableType.IDENTIFIABLE,
  DB_TABLE_MEETING_AND_USER: BackendTableType.IDENTIFIABLE,
  DB_TABLE_COURSE: BackendTableType.IDENTIFIABLE,
  DB_TABLE_COURSE_AND_USER: BackendTableType.IDENTIFIABLE,
  DB_TABLE_MAJOR: BackendTableType.IDENTIFIABLE,
  DB_TABLE_MAJOR_AND_USER: BackendTableType.IDENTIFIABLE,
};

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  timeout: process.env.REACT_APP_BACKEND_REQUEST_TIMEOUT_MS,
  withCredentials: true,
  auth: {
    username: process.env.REACT_APP_BACKEND_ADMIN_USERNAME,
    password: process.env.REACT_APP_BACKEND_ADMIN_PASSWORD,
  },
});

export const requestCreateEntry = async (table, data, onSuccess, onFailure) => {
  await apiClient
    .post(`/${table}`, data)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestCreateEntryList = async (
  table,
  createDataList,
  onSuccess,
  onFailure
) => {
  await apiClient
    .post(`/${table}/list/post`, createDataList)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestUpdateEntry = async (
  table,
  id,
  data,
  onSuccess,
  onFailure
) => {
  await apiClient
    .put(`/${table}/${id}`, data)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestUpdateEntryAssociation = async (
  table,
  idA,
  idB,
  data,
  onSuccess,
  onFailure
) => {
  await apiClient
    .put(`/${table}/${idA}/${idB}`, data)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestUpdateEntryList = async (
  table,
  updateDataList,
  onSuccess,
  onFailure
) => {
  await apiClient
    .post(`/${table}/list/put`, updateDataList)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestGetEntry = async (table, id, onSuccess, onFailure) => {
  await apiClient
    .get(`/${table}/${id}`)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestGetEntryAssociation = async (
  table,
  idA,
  idB,
  onSuccess,
  onFailure
) => {
  await apiClient
    .get(`/${table}/${idA}/${idB}`)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestGetEntryList = async (
  table,
  idList,
  onSuccess,
  onFailure
) => {
  await apiClient
    .post(`/${table}/list/get`, idList)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestGetEntryListAll = async (table, onSuccess, onFailure) => {
  await apiClient
    .get(`/${table}/all`, {
      params: {
        page: PAGE_DEFAULT_NUMBER,
        size: PAGE_DEFAULT_SIZE,
      },
    })
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestGetEntryListAllPaged = async (
  table,
  pageNumber,
  pageSize,
  pageSortField,
  onSuccess,
  onFailure
) => {
  await apiClient
    .get(`/${table}/all`, {
      params: {
        page: pageNumber,
        size: pageSize,
        sort: pageSortField,
      },
    })
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestDeleteEntry = async (table, id, onSuccess, onFailure) => {
  await apiClient
    .delete(`/${table}/${id}`)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestDeleteEntryAssociation = async (
  table,
  idA,
  idB,
  onSuccess,
  onFailure
) => {
  await apiClient
    .delete(`/${table}/${idA}/${idB}`)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestDeleteEntryList = async (
  table,
  idList,
  onSuccess,
  onFailure
) => {
  await apiClient
    .post(`/${table}/list/delete`, idList)
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestSearchEntry = async (
  table,
  criteria,
  onSuccess,
  onFailure
) => {
  await apiClient
    .post(`/${table}/search`, criteria, {
      params: {
        page: PAGE_DEFAULT_NUMBER,
        size: PAGE_DEFAULT_SIZE,
      },
    })
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

export const requestSearchEntryPaged = async (
  table,
  criteria,
  pageNumber,
  pageSize,
  pageSortField,
  onSuccess,
  onFailure
) => {
  await apiClient
    .post(`/${table}/search`, criteria, {
      params: {
        page: pageNumber,
        size: pageSize,
        sort: pageSortField,
      },
    })
    .then((response) => {
      onSuccess?.(response.data);
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

// onSuccess:
// responseDataList: list of all of the retrieved database entries
// uuidToData: uuid to the retrieved database entry
export const requestIdentityMapping = async (
  dataList,
  uuidKey,
  table,
  onSuccess,
  onFailure
) => {
  const uuidList = dataList.map((entry) => entry[uuidKey]);
  const mappedDataList = [];
  const response = await apiClient
    .post(`/${table}/list/get`, uuidList)
    .then((response) => {
      if (response.data) {
        const responseDataList = response.data;
        const uuidToData = {};
        responseDataList.forEach((data) => {
          if (!(data["uuid"] in uuidToData)) {
            uuidToData[data["uuid"]] = data;
          }
        });
        onSuccess?.(responseDataList, uuidToData);
      } else {
        onFailure?.(undefined);
      }
    })
    .catch((error) => {
      onFailure?.(error);
    });
};

// === ===
// IMPROVED FUNCTIONS
// === ===

export const improvedRequestCreateEntry = async (table, data) => {
  return apiClient.post(`/${table}`, data);
};

export const improvedRequestCreateEntryList = async (table, createDataList) => {
  return apiClient.post(`/${table}/list/post`, createDataList);
};

export const improvedRequestUpdateEntry = async (table, id, data) => {
  return apiClient.put(`/${table}/${id}`, data);
};

export const improvedRequestUpdateEntryAssociation = async (table, idA, idB, data) => {
  return apiClient.put(`/${table}/${idA}/${idB}`, data);
}

export const improvedRequestUpdateEntryList = async (table, updateDataList) => {
  return apiClient.post(`/${table}/list/put`, updateDataList);
};

export const improvedRequestGetEntry = async (table, id) => {
  return apiClient.get(`/${table}/${id}`);
};

export const improvedRequestGetEntryAssociation = async (table, idA, idB) => {
  return apiClient.get(`/${table}/${idA}/${idB}`);
};

export const improvedRequestGetEntryList = async (table, idList) => {
  return apiClient.post(`/${table}/list/get`, idList);
};

export const improvedRequestGetEntryListAll = async (table) => {
  return apiClient.get(`/${table}/all`, {
    params: {
      page: PAGE_DEFAULT_NUMBER,
      size: PAGE_DEFAULT_SIZE,
    },
  });
};

export const improvedRequestGetEntryListAllPaged = async (
  table,
  pageNumber,
  pageSize,
  pageSortField
) => {
  return apiClient.get(`/${table}/all`, {
    params: {
      page: pageNumber,
      size: pageSize,
      sort: pageSortField,
    },
  });
};

export const improvedRequestDeleteEntry = async (table, id) => {
  return apiClient.delete(`/${table}/${id}`);
};

export const improvedRequestDeleteEntryAssociation = async (
  table,
  idA,
  idB
) => {
  return apiClient.delete(`/${table}/${idA}/${idB}`);
};

export const improvedRequestDeleteEntryList = async (table, idList) => {
  return apiClient.post(`/${table}/list/delete`, idList);
};

export const improvedRequestSearchEntry = async (table, criteria) => {
  return apiClient.post(`/${table}/search`, criteria, {
    params: {
      page: PAGE_DEFAULT_NUMBER,
      size: PAGE_DEFAULT_SIZE,
    },
  });
};

export const improvedRequestSearchEntryPaged = async (
  table,
  criteria,
  pageNumber,
  pageSize,
  pageSortField
) => {
  return apiClient.post(`/${table}/search`, criteria, {
    params: {
      page: pageNumber,
      size: pageSize,
      sort: pageSortField,
    },
  });
};
