// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Box, Typography } from "@mui/material";

// Custom Theme and Style
import { gray, blue } from "../style/AppTheme";

// Extra
import scLogo from "../asset/image/sc_logo.png";

export const makeHeadingThemeDesignTokens = (mode) => ({
  typography: {
    subtitle: {
      fontSize: "40px",
      fontFamily: "Source Serif Pro",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
      letterSpacing: "-0.4px",
      paddingTop: "15px"
      }
    }
});

export function makeHeadingTheme(mode) {
  return {
    ...makeHeadingThemeDesignTokens(mode),
  };
}

export default function Heading({ subtitle }) {
  const headingTheme = createTheme(makeHeadingTheme("light"));

  return (
    <ThemeProvider theme={headingTheme}>
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "2px solid",
        borderColor: alpha(gray[200], 1),
        padding: "30px",
        height: subtitle ? "140px" : "100px"
      }}
    >
      <img src={scLogo} />
       { subtitle ?
        <Typography variant="subtitle" sx={{
          color: blue[800]
        }}>{subtitle}</Typography>
       : null }
    </Box>
    </ThemeProvider>
  );
}
