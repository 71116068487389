// Format phone number as (XXX) XXX-XXXX
export const formatPhoneNumber = (input) => {
  if (!input || input === undefined) {
    return "";
  }

  // Remove all non-numeric characters
  const digits = input.replace(/\D/g, "");
  // Format the number
  if (digits.length == 0) {
    return "";
  } else if (digits.length <= 3) {
    return `(${digits}`;
  } else if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
  } else {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
      6,
      10
    )}`;
  }
};

export const validatePhoneNumber = (input, emptyAllowed) => {
  if (!input || input === undefined) {
    return false;
  }
  const phoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/;
  return (input.length == 0 && emptyAllowed) || phoneNumberPattern.test(input);
};

export const formatPhoneNumberDigitsOnly = (input) => {
  if (!input || input === undefined) {
    return "";
  }
  return input.match(/\d+/g).join("");
};
