// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import ButtonRectangleNarrowLabel from "../../component/shared/button/ButtonRectangleNarrowLabel";
import TextHeadingLarge from "../../component/shared/text/TextHeadingLarge";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";

export const makeHelpSupportAndFeedbackSuperPaneThemeDesignTokens = (
  mode
) => ({});

export function makeHelpSupportAndFeedbackSuperPaneTheme(mode) {
  return {
    ...makeHelpSupportAndFeedbackSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function HelpSupportAndFeedbackSuperPane({}) {
  const helpSupportAndFeedbackSuperPaneTheme = createTheme(
    makeHelpSupportAndFeedbackSuperPaneTheme("light")
  );

  const BUG_REPORT_URL = "https://forms.gle/3iHvctCsy3ptNHr37";
  const SUGGEST_FEATURE_URL = "https://forms.gle/C1mGWTc9XqXmbibQ9";
  const EMAIL_ADDRESS = "help.studiouscat@gmail.com";

  const handleReportBugButtonClick = () => {
    window.open(BUG_REPORT_URL, "_blank");
  };

  const handleSuggestFeatureButtonClick = () => {
    window.open(SUGGEST_FEATURE_URL, "_blank");
  };

  return (
    <ThemeProvider theme={helpSupportAndFeedbackSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "30px",
          padding: "20px",
          width: "100%",
        }}
      >
        <TextHeadingLarge label="Support & Feedback" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextHeadingMediumA label="Technical Support Email" />
          <Typography
            sx={{
              fontFamily: "PT Sans",
              fontWeight: 600,
              fontSize: 20,
              color: purple[900],
            }}
          >
            {EMAIL_ADDRESS}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
          }}
        >
          <ButtonRectangleNarrowLabel
            label="Report a Bug or Issue"
            variant="inverted"
            showNumber={false}
            selected={false}
            onClick={() => handleReportBugButtonClick()}
            labelFontSize={"14px"}
            fullWidth={false}
          />
          <ButtonRectangleNarrowLabel
            label="Suggest a Feature"
            variant="inverted"
            showNumber={false}
            selected={false}
            onClick={() => handleSuggestFeatureButtonClick()}
            labelFontSize={"14px"}
            fullWidth={false}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
