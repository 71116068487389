import { Box } from "@mui/material";

export function truncateString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
}

export function shapeRectangleFilled(width, height, borderRadius, color) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg width={width} height={height}>
        <rect
          width={width}
          height={height}
          rx={borderRadius}
          ry={borderRadius}
          style={{ fill: `${color}` }}
        />
      </svg>
    </Box>
  );
};

export function shapeCircleFilled(radius, color) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg width={radius * 2} height={radius * 2}>
        <circle
          cx={radius}
          cy={radius}
          r={radius}
          style={{ fill: `${color}` }}
        />
      </svg>
    </Box>
  );
};