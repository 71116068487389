// Theme and Style
import {
  createTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
} from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";

export const makeDropDownMenuProfileReactionOptionThemeDesignTokens = (
  mode
) => ({});

export function makeDropDownMenuProfileReactionOptionTheme(mode) {
  return {
    ...makeDropDownMenuProfileReactionOptionThemeDesignTokens(mode),
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            height: "45px",
          }),
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: ({ theme, ownerState }) => ({
            fontFamily: "PT Sans",
            fontWeight: 900,
            fontSize: "22px",
            textTransform: "none",
          }),
        },
      },
    },
  };
}

export default function DropDownMenuProfileReactionOption({
  itemList,
  onClickItem,
}) {
  const dropDownMenuProfileReactionOptionTheme = createTheme(
    makeDropDownMenuProfileReactionOptionTheme("light")
  );

  const handleClickListItem = (id) => {
    onClickItem?.(id);
  };

  return (
    <ThemeProvider theme={dropDownMenuProfileReactionOptionTheme}>
      {itemList && (
        <List sx={{
          maxHeight: "200px",
          overflowY: "auto"
        }}>
          {itemList.map(({ label, id }) => (
            <ListItem disablePadding key={id}>
              <ListItemButton onClick={() => handleClickListItem(id)}>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </ThemeProvider>
  );
}
