// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import LobbyProfilePrivacyPolicySuperPane from "./LobbyProfilePrivacyPolicySuperPane";
import LobbyProfileWelcomeSuperPane from "./LobbyProfileWelcomeSuperPane";
import LobbyProfileInfoSuperPane from "./LobbyProfileInfoSuperPane";

export const makeLobbyProfileCreateSuperPaneThemeDesignTokens = (mode) => ({});

export function makeLobbyProfileCreateSuperPaneTheme(mode) {
  return {
    ...makeLobbyProfileCreateSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

export const LobbyProfileCreateView = {
  WELCOME: "WELCOME",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  INFO: "INFO",
};

export const LOBBY_PROFILE_CREATE_VIEW_ORDER = [
  LobbyProfileCreateView.WELCOME,
  LobbyProfileCreateView.PRIVACY_POLICY,
  LobbyProfileCreateView.INFO,
];

export default function LobbyProfileCreateSuperPane({
  view,
  targetUserUuid,
  onPreviousView,
  onNextView,
}) {
  const lobbyProfileCreateSuperPaneTheme = createTheme(
    makeLobbyProfileCreateSuperPaneTheme("light")
  );

  const handleWelcomeNext = () => {
    onNextView?.();
  };

  const handlePrivacyPolicyDecline = () => {
    onPreviousView?.();
  };

  const handlePrivacyPolicyAccept = () => {
    onNextView?.();
  };

  const handleInfoBack = () => {
    onPreviousView?.();
  };

  const handleInfoCreate = () => {
    onNextView?.();
  };

  return (
    <ThemeProvider theme={lobbyProfileCreateSuperPaneTheme}>
      {view === LobbyProfileCreateView.WELCOME && (
        <LobbyProfileWelcomeSuperPane
          targetUserUuid={targetUserUuid}
          onNext={handleWelcomeNext}
        />
      )}
      {view === LobbyProfileCreateView.PRIVACY_POLICY && (
        <LobbyProfilePrivacyPolicySuperPane
          targetUserUuid={targetUserUuid}
          onDecline={handlePrivacyPolicyDecline}
          onAccept={handlePrivacyPolicyAccept}
        />
      )}
      {view === LobbyProfileCreateView.INFO && (
        <LobbyProfileInfoSuperPane
          targetUserUuid={targetUserUuid}
          onBack={handleInfoBack}
          onCreate={handleInfoCreate}
        />
      )}
    </ThemeProvider>
  );
}
