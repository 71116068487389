// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Avatar, Typography, Link } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";
import {
  DATE_FRONTEND_FORMAT_LONG,
  dateConvertToString,
} from "../../../service/browser/DateService";

export const makeProfileUserFriendCollectionItemThemeDesignTokens = (mode) => ({
  typography: {
    userName: {
      fontFamily: "Source Serif Pro",
      fontSize: 32,
      fontWeight: 600,
      lineHeight: "32px",
      color: purple[900],
    },
    detailBold: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 600,
      color: purple[900],
    },
    detailItalics: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 400,
      fontStyle: "italic",
      color: alpha(purple[900], 0.7),
    },
    detail: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 400,
      color: purple[900],
    },
    date: {
      fontFamily: "PT Sans",
      fontSize: 20,
      fontWeight: 800,
      lineHeight: "32px",
      color: alpha(purple[900], 0.4),
    },
  },
});

export function makeProfileUserFriendCollectionItemTheme(mode) {
  return {
    ...makeProfileUserFriendCollectionItemThemeDesignTokens(mode),
    components: {},
  };
}

// NOTE (9/2/2024): passing in dateText (string, not Date instance) due to needed customizing flexibility of text

export default function ProfileUserFriendCollectionItem({
  pictureUrl,
  userName,
  fullName,
  websiteUrl,
  majorNameList,
  mutualUserFullNameList,
  dateText,
}) {
  const profileUserFriendCollectionItemTheme = createTheme(
    makeProfileUserFriendCollectionItemTheme("light")
  );

  const FIELD_SEPARATION_NAME = "|";
  const FIELD_SEPARATION_MAJOR = "•";

  const getMutualsText = () => {
    if (!mutualUserFullNameList || mutualUserFullNameList.length == 0) {
      return "";
    } else if (mutualUserFullNameList.length == 1) {
      return `Mutual: ${mutualUserFullNameList[0]}`;
    } else {
      return `Mutuals: ${mutualUserFullNameList[0]} + ${
        mutualUserFullNameList.length - 1
      } more!`;
    }
  };

  const dateTextFinal = dateText ? dateText : "";

  return (
    <ThemeProvider theme={profileUserFriendCollectionItemTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: "5px",
          width: "100%",
        }}
      >
        <Avatar
          alt={userName}
          src={pictureUrl}
          sx={{
            width: "80px",
            height: "80px",
          }}
        />
        {/* <Avatar
          sx={{
            width: "80px",
            height: "80px",
          }}
        >
          {userName.charAt(0)}
        </Avatar> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link href={`/user/${userName}`} underline="always" color="inherit">
              <Typography variant="userName">{userName}</Typography>
            </Link>
            <Typography variant="date">{dateTextFinal}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <Typography variant="detailBold">{fullName}</Typography>
            {websiteUrl && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                }}
              >
                <Typography variant="detail">
                  {FIELD_SEPARATION_NAME}
                </Typography>
                <Link href={websiteUrl} underline="always" color="inherit">
                  <Typography variant="detail">LinkedIn</Typography>
                </Link>
              </Box>
            )}
          </Box>
          {majorNameList && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              {majorNameList.map((majorName, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <Typography variant="detail">{majorName}</Typography>
                  {index < majorNameList.length - 1 && (
                    <Typography variant="detail">
                      {FIELD_SEPARATION_MAJOR}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <Typography variant="detailItalics">
              {mutualUserFullNameList && getMutualsText()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
