// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Box, Slider } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../../style/AppTheme";

// Extra
import * as React from "react";

// StudiousCat Components
// ...

export const makeDiscreteSliderNumericalThemeDesignTokens = (mode) => ({
  typography: {
    sliderNumberText: {
      fontFamily: "PT Sans",
      fontSize: 22,
      fontWeight: 400,
      color: purple[900],
    },
  },
});

export function makeDiscreteSliderNumericalTheme(mode) {
  return {
    ...makeDiscreteSliderNumericalThemeDesignTokens(mode),
    components: {
      MuiSlider: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            "& .MuiSlider-thumb": {
              backgroundColor: alpha(purple[100], 1),
              width: "12px",
              height: "12px",
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0 0 0 6px ${alpha(purple[300], 0.2)}`,
              },
              "&.Mui-active": {
                boxShadow: `0 0 0 8px ${alpha(purple[400], 0.2)}`,
                width: "16px",
                height: "16px",
              },
            },
            "& .MuiSlider-rail": {
              backgroundColor: alpha(gray[600], 1),
              height: "2px",
              borderRadius: "2px",
            },
            "& .MuiSlider-mark": {
              backgroundColor: alpha(gray[600], 1),
              color: alpha(gray[600], 1),
              height: "24px",
              width: "2px",
              "&.MuiSlider-markActive": {
                opacity: 1,
                backgroundColor: "currentColor",
              },
            },
          }),
        },
      },
    },
  };
}

// NOTE:
// By default the slider is fullWidth.
// So if useFixedWidth=false, then the slider will expand to the width of its parent.

export default function SliderDiscreteNumerical({
  minValue = 1,
  maxValue = 6,
  stepValue = 1,
  value = 1,
  onChange,
  fixedWidth,
  showCounter = false,
  counterLabel = "",
  modifiable = true,
}) {
  const sliderDiscreteNumericalTheme = createTheme(
    makeDiscreteSliderNumericalTheme("light")
  );

  const handleSliderChange = (event, newValue) => {
    if (modifiable) {
      onChange?.(newValue);
    }
  };

  const getCounterText = () => {
    if (counterLabel) {
      return `${value}/${maxValue} ${counterLabel}`;
    }
    return `${value}/${maxValue}`;
  };

  return (
    <ThemeProvider theme={sliderDiscreteNumericalTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Slider
          min={minValue}
          max={maxValue}
          step={stepValue}
          value={value}
          onChange={handleSliderChange}
          defaultValue={minValue}
          marks
          track={false}
          sx={{
            ...(fixedWidth && { width: fixedWidth }),
          }}
        />
        {showCounter && (
          <Typography variant="sliderNumberText">{getCounterText()}</Typography>
        )}
      </Box>
    </ThemeProvider>
  );
}
