// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Avatar } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, blue, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState } from "react";

import eizaPictureUrl from "../../asset/image/about_portrait/about_eiza.png";
import valeriePictureUrl from "../../asset/image/about_portrait/about_val.png";
import masonPictureUrl from "../../asset/image/about_portrait/about_mason.png";
import yonaPictureUrl from "../../asset/image/about_portrait/about_yona.png";
import TextHeadingLarge from "../../component/shared/text/TextHeadingLarge";
import TextHeadingMediumA from "../../component/shared/text/TextHeadingMediumA";
import TextHeadingMediumB from "../../component/shared/text/TextHeadingMediumB";

export const makeAboutSuperPaneThemeDesignTokens = (mode) => ({
  typography: {
    headingText: {
      fontFamily: "PT Sans",
      fontWeight: 600,
      fontSize: 32,
      color: purple[900],
    },
    subjectTopText: {
      fontFamily: "PT Sans",
      fontWeight: 600,
      fontSize: 20,
      color: purple[200],
    },
    subjectBottomText: {
      fontFamily: "PT Sans",
      fontWeight: 400,
      fontSize: 20,
      color: purple[200],
    },
    bodyText: {
      fontFamily: "Sue Ellen Francisco",
      fontSize: 26,
    },
  },
});

export function makeAboutSuperPaneTheme(mode) {
  return {
    ...makeAboutSuperPaneThemeDesignTokens(mode),
    components: {},
  };
}

function AboutPersonInfo({
  portraitPictureUrl,
  heading,
  subjectTop,
  subjectBottom,
  bodyParagraphList,
  bodyColor,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        gap: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Avatar
          src={portraitPictureUrl}
          variant="rounded"
          sx={{
            width: "200px",
            height: "200px",
          }}
        />
        <Typography variant="headingText">{heading}</Typography>
        <Typography variant="subjectTopText">{subjectTop}</Typography>
        <Typography variant="subjectBottomText">{subjectBottom}</Typography>
      </Box>
      {bodyParagraphList && bodyParagraphList.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
            padding: "10px",
            width: "90%",
            border: `3px solid ${bodyColor}`,
            borderRadius: "5px",
          }}
        >
          {bodyParagraphList.map((paragraph, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                paddingBottom: "15px",
              }}
            >
              <Typography
                variant="bodyText"
                sx={{
                  color: bodyColor,
                }}
              >
                {paragraph}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default function AboutSuperPane({}) {
  const aboutSuperPaneTheme = createTheme(makeAboutSuperPaneTheme("light"));

  const ABOUT_PRIMARY_DATA = [
    {
      portraitUrl: yonaPictureUrl,
      fullName: "Yona Kleinerman",
      year: "Junior",
      majorList: ["Biomedical Engineering"],
      role: "Founder",
      origin: "Austin, TX",
      bodyColor: blue[800],
      bodyParagraphList: [
        "I aspire to become a surgeon in a specialized field and a leader in the biomedical tech industry. ",
        "I love bringing people together, forging long lasting connections and developing new skills.",
        "Outside of work and school, I enjoy watching soccer (Real Madrid of course), Kendama, drawing, and reading non-fiction.",
      ],
    },
    {
      portraitUrl: eizaPictureUrl,
      fullName: "Eiza Stanford",
      year: "Junior",
      majorList: ["Computer Science", "Mathematics"],
      role: "Lead Software Developer",
      origin: "Gilbert, AZ",
      bodyColor: red[800],
      bodyParagraphList: [
        "Hey, Eiza here! I'm super passionate about all things computers, especially full-stack development with databases, websites, and mobile apps!",
        "It's energizing to lift people up and ensure that everyone has an opportunity to succeed and build incredible things.",
        "In my leisure, I relax by hiking, playing DND, and doing game dev on the side! I also love hanging out with friends.",
      ],
    },
  ];

  const ABOUT_SOFTWARE_DEVELOPER_DATA = [
    {
      portraitUrl: masonPictureUrl,
      fullName: "Mason Mariani",
      year: "Graduate Student",
      majorList: ["Computer Science"],
      role: "Software Developer",
    },
    {
      portraitUrl: valeriePictureUrl,
      fullName: "Valerie Militeeva",
      year: "Junior",
      majorList: ["Computer Science"],
      role: "Software Developer",
    },
    {
      portraitUrl: undefined,
      fullName: "Sebastian Pojman-Malo",
      year: "Junior",
      majorList: ["Electrical and Computer Engineering"],
      role: "Software Developer",
    },
  ];

  return (
    <ThemeProvider theme={aboutSuperPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          padding: "20px",
          width: "100%",
        }}
      >
        <TextHeadingLarge label="Meet the Team:" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "20px",
            width: "100%",
          }}
        >
          {ABOUT_PRIMARY_DATA.map((personData, index) => (
            <Box
              key={index}
              sx={{
                flexGrow: 1,
              }}
            >
              <AboutPersonInfo
                portraitPictureUrl={personData.portraitUrl}
                heading={personData.fullName}
                subjectTop={`${personData.year} | ${personData.majorList.join(
                  ", "
                )}`}
                subjectBottom={personData.role}
                bodyParagraphList={[
                  `From: ${personData.origin}`,
                  ...personData.bodyParagraphList,
                ]}
                bodyColor={personData.bodyColor}
              />
            </Box>
          ))}
        </Box>
        <TextHeadingLarge label="With Special Thanks To:" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "20px",
            width: "100%",
          }}
        >
          {ABOUT_SOFTWARE_DEVELOPER_DATA.map((personData, index) => (
            <Box
              key={index}
              sx={{
                width: "400px",
              }}
            >
              <AboutPersonInfo
                portraitPictureUrl={personData.portraitUrl}
                heading={personData.fullName}
                subjectTop={`${personData.year} | ${personData.majorList.join(
                  ", "
                )}`}
                subjectBottom={personData.role}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
