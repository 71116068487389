// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// React
import * as React from "react";
import { useState } from "react";
import StatusMessage, { StatusMessageType } from "./StatusMessage";

export const makeStatusMessageComponentLoadErrorThemeDesignTokens = (
  mode
) => ({});

export function makeStatusMessageComponentLoadErrorTheme(mode) {
  return {
    ...makeStatusMessageComponentLoadErrorThemeDesignTokens(mode),
    components: {},
  };
}

export default function StatusMessageComponentLoadError({ name, message }) {
  const statusMessageComponentLoadErrorTheme = createTheme(
    makeStatusMessageComponentLoadErrorTheme("light")
  );

  return (
    <ThemeProvider theme={statusMessageComponentLoadErrorTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          padding: "12px",
        }}
      >
        <StatusMessage
          width={"240px"}
          message={message ? message : `Component Failed to Load: ${name}`}
          type={StatusMessageType.ERROR}
        />
      </Box>
    </ThemeProvider>
  );
}
