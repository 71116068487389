// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green, blue } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import {
  DB_TABLE_COURSE,
  DB_TABLE_COURSE_AND_USER,
  DB_TABLE_MAJOR,
  DB_TABLE_MAJOR_AND_USER,
  DB_TABLE_USER,
  DB_TABLE_USER_FRIEND,
  improvedRequestGetEntryList,
  improvedRequestGetEntryListAll,
  improvedRequestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import {
  listToObjectByKey,
  objectToListConcatenateByKeyList,
} from "../../../service/browser/ObjectService";
import {
  DATE_FRONTEND_FORMAT_LONG,
  dateConvertToObject,
  dateConvertToString,
} from "../../../service/browser/DateService";
import ProfileUserFriendCollectionItem from "../../component/profile/ProfileUserFriendCollectionItem";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import Separator from "../../component/shared/other/Separator";
import StatusComponentLoad from "../../component/other/StatusComponentLoad";

export const makeProfileUserListingPaneThemeDesignTokens = (mode) => ({
  typography: {
    groupHeading: {
      fontFamily: "PT Sans",
      fontSize: 24,
      fontWeight: 600,
      color: purple[900],
    },
  },
});

export function makeProfileUserListingPaneTheme(mode) {
  return {
    ...makeProfileUserListingPaneThemeDesignTokens(mode),
    components: {},
  };
}

export const ProfileUserListingGroupType = {
  COURSE: "COURSE",
  NONE: "NONE",
};

const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;

export default function ProfileUserListingPane({
  groupType = ProfileUserListingGroupType.NONE,
  targetUserUuid,
  selectedUserUuidList = [],
  dateBySelectedUserUuidTable = {},
  dateTextPrefix = "",
  userFilterText = "",
}) {
  const profileUserListingPaneTheme = createTheme(
    makeProfileUserListingPaneTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetGeneralStageLoading, setAssetGeneralStageLoading] =
    useState(true);
  const [assetSelectedStageLoading, setAssetSelectedStageLoading] =
    useState(true);
  const [assetTargetUserStageLoading, setAssetTargetUserStageLoading] =
    useState(true);
  const [assetGroupStageLoading, setAssetGroupStageLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);
  const [assetFirstInit, setAssetFirstInit] = useState(true);

  const [sortedSelectedUserUuidList, setSortedSelectedUserUuidList] = useState(
    {}
  );

  const [userDataBySelectedUserUuidTable, setUserDataBySelectedUserUuidTable] =
    useState({});
  const [
    mutualUserUuidListBySelectedUserUuidTable,
    setMutualUserUuidListBySelectedUserUuidTable,
  ] = useState({});
  const [
    majorUuidListBySelectedUserUuidTable,
    setMajorUuidListBySelectedUserUuidTable,
  ] = useState({});

  // GROUPING RELATED
  const [
    selectedUserUuidListByGroupLabel,
    setSelectedUserUuidListByGroupLabel,
  ] = useState({});
  // NOTE: approach with group filters - the goal is to pre-load any assets that may potentially be used for filters (even if never ultimately used)
  const [
    courseUuidListBySelectedUserUuidTable,
    setCourseUuidListBySelectedUserUuidTable,
  ] = useState({});
  // "Label" refers to the human-readable version, not the course uuid
  const [
    selectedUserUuidListByCourseLabel,
    setSelectedUserUuidListByCourseLabel,
  ] = useState({});

  // NOTE: all mutuals can simply refer to the complete list of the friends of the target user.
  const [
    friendedUserDataWithTargetUserByUserUuidTable,
    setFriendedUserDataWithTargetUserByUserUuidTable,
  ] = useState({});
  const [userFriendDataCompleteList, setUserFriendDataCompleteList] = useState(
    []
  );
  const [courseAndUserDataCompleteList, setCourseAndUserDataCompleteList] =
    useState([]);
  const [majorAndUserDataCompleteList, setMajorAndUserDataCompleteList] =
    useState([]);
  const [majorDataByMajorUuidTable, setMajorDataByMajorUuidTable] = useState(
    {}
  );
  const [courseDataByCourseUuidTable, setCourseDataByCourseUuidTable] =
    useState({});

  const SORT_USER_BY_KEY = "full_name";
  const FILTER_USER_BY_KEY_LIST = ["full_name", "user_name"];

  const utilSortUserUuidListByKey = (
    userUuidList,
    userDataByUserUuidTable,
    key
  ) => {
    return userUuidList.sort((userUuidA, userUuidB) => {
      if (
        !(
          userUuidA in userDataByUserUuidTable &&
          userUuidB in userDataByUserUuidTable
        )
      ) {
        return 0;
      }
      const valueA = userDataByUserUuidTable[userUuidA][key];
      const valueB = userDataByUserUuidTable[userUuidB][key];
      if (valueA > valueB) {
        return 1;
      }
      if (valueA < valueB) {
        return -1;
      }
      return 0;
    });
  };

  const utilSortAlphabetical = (valueList) => {
    return valueList.sort((valueA, valueB) => {
      if (valueA > valueB) {
        return 1;
      }
      if (valueA < valueB) {
        return -1;
      }
      return 0;
    });
  };

  const utilFilterUserUuidListByKey = (
    userUuidList,
    userDataByUserUuid,
    keyList,
    value
  ) => {
    const valueLower = value.toLowerCase();
    return userUuidList.filter(
      (userUuid) =>
        userUuid in userDataByUserUuid &&
        objectToListConcatenateByKeyList(
          userDataByUserUuid[userUuid],
          keyList,
          " "
        )
          .toLowerCase()
          .includes(valueLower)
    );
  };

  // === === === === === === === === === === === === === === ==
  // Filter Functions: generic, decoupled, designed to work with parameters
  // === === === === === === === === === === === === === === ===

  const filterFriendedUserUuidList = (providedUserUuid) => {
    const userFriendDataListFromProvided = userFriendDataCompleteList.filter(
      (userFriendData) => userFriendData["user_uuid"] === providedUserUuid
    );
    const userFriendDataListToProvided = userFriendDataCompleteList.filter(
      (userFriendData) =>
        userFriendData["friended_user_uuid"] === providedUserUuid
    );

    const friendedUserUuidListFromProvided = userFriendDataListFromProvided.map(
      (userFriendData) => userFriendData["friended_user_uuid"]
    );
    const friendedUserUuidListToProvided = userFriendDataListToProvided.map(
      (userFriendData) => userFriendData["user_uuid"]
    );
    const finalUserUuidList = friendedUserUuidListFromProvided
      .concat(friendedUserUuidListToProvided)
      .filter((userUuid) => userUuid !== providedUserUuid);
    return finalUserUuidList;
  };

  const filterCourseUuidListByProvidedUserUuidList = (providedUserUuidList) => {
    const courseUuidListByProvidedUserTable = {};
    providedUserUuidList.forEach((userUuid) => {
      const courseAndUserDataList = courseAndUserDataCompleteList.filter(
        (courseAndUserData) =>
          courseAndUserData["course_and_user_id"]["user_uuid"] === userUuid
      );
      courseUuidListByProvidedUserTable[userUuid] = courseAndUserDataList.map(
        (courseAndUserData) =>
          courseAndUserData["course_and_user_id"]["course_uuid"]
      );
    });
    return courseUuidListByProvidedUserTable;
  };

  const filterMajorUuidListByProvidedUserUuidList = (providedUserUuidList) => {
    const majorUuidListByProvidedUserTable = {};
    providedUserUuidList.forEach((userUuid) => {
      const majorAndUserDataList = majorAndUserDataCompleteList.filter(
        (majorAndUserData) =>
          majorAndUserData["major_and_user_id"]["user_uuid"] === userUuid
      );
      majorUuidListByProvidedUserTable[userUuid] = majorAndUserDataList.map(
        (majorAndUserData) =>
          majorAndUserData["major_and_user_id"]["major_uuid"]
      );
    });
    return majorUuidListByProvidedUserTable;
  };

  // If overlappingUserUuidList is not empty, then only friends that overlap with overlappingUserUuidList will be saved.
  const filterFriendedUserUuidListByProvidedUserUuidList = (
    providedUserUuidList,
    overlappingUserUuidList = []
  ) => {
    const friendedUserUuidListByProvidedUserUuidTable = {};
    providedUserUuidList.forEach((userUuid) => {
      const friendedUserUuidList = filterFriendedUserUuidList(userUuid);
      friendedUserUuidListByProvidedUserUuidTable[userUuid] =
        overlappingUserUuidList && overlappingUserUuidList.length > 0
          ? friendedUserUuidList.filter((userUuid) =>
              overlappingUserUuidList.includes(userUuid)
            )
          : [];
    });
    return friendedUserUuidListByProvidedUserUuidTable;
  };

  // === === === === === === === === === === === === === === ==
  // Init Functions: dedicated for updating react state
  // May use retrieval functions as tools
  // === === === === === === === === === === === === === === ===

  // NOTE: the selected user init functions will still be marked as async,
  // even if they use non-async or non-IO/network functions.

  const initMutualUserUuidListBySelectedUserUuidTable = async () => {
    const newMutualUserUuidListBySelectedUserUuidTable =
      filterFriendedUserUuidListByProvidedUserUuidList(
        selectedUserUuidList,
        Object.keys(friendedUserDataWithTargetUserByUserUuidTable)
      );
    setMutualUserUuidListBySelectedUserUuidTable(
      newMutualUserUuidListBySelectedUserUuidTable
    );
  };

  const initCourseUuidListBySelectedUserUuidTable = async () => {
    const newCourseUuidListBySelectedUserUuidTable =
      filterCourseUuidListByProvidedUserUuidList(selectedUserUuidList);
    setCourseUuidListBySelectedUserUuidTable(
      newCourseUuidListBySelectedUserUuidTable
    );
  };

  const initMajorUuidListBySelectedUserUuidTable = async () => {
    const newMajorUuidListBySelectedUserUuidTable =
      filterMajorUuidListByProvidedUserUuidList(selectedUserUuidList);
    setMajorUuidListBySelectedUserUuidTable(
      newMajorUuidListBySelectedUserUuidTable
    );
  };

  const initFriendedUserDataWithTargetUserByUserUuidTable = async () => {
    const friendedUserUuidListForTargetUser =
      filterFriendedUserUuidList(targetUserUuid);
    const getFriendedUserDataListForTargetUserResponse =
      await improvedRequestGetEntryList(
        DB_TABLE_USER,
        friendedUserUuidListForTargetUser
      );
    const friendedUserDataListForTargetUser =
      getFriendedUserDataListForTargetUserResponse.data;
    const friendedUserDataForTargetUserByUserUuidTable = listToObjectByKey(
      friendedUserDataListForTargetUser,
      "uuid"
    );
    setFriendedUserDataWithTargetUserByUserUuidTable(
      friendedUserDataForTargetUserByUserUuidTable
    );
  };

  const initUserDataBySelectedUserUuidTable = async () => {
    const getUserResponse = await improvedRequestGetEntryList(
      DB_TABLE_USER,
      selectedUserUuidList
    );
    const newUserDataBySelectedUserUuidTable = listToObjectByKey(
      getUserResponse.data,
      "uuid"
    );
    const newSortedSelectedUserUuidList = utilSortUserUuidListByKey(
      selectedUserUuidList,
      newUserDataBySelectedUserUuidTable,
      SORT_USER_BY_KEY
    );

    setUserDataBySelectedUserUuidTable(newUserDataBySelectedUserUuidTable);
    setSortedSelectedUserUuidList(newSortedSelectedUserUuidList);
  };

  const initUserFriendDataCompleteList = async () => {
    const getAllUserFriendResponse = await improvedRequestGetEntryListAll(
      DB_TABLE_USER_FRIEND
    );
    const newUserFriendDataCompleteList =
      getAllUserFriendResponse &&
      getAllUserFriendResponse.data &&
      getAllUserFriendResponse.data["content"]
        ? getAllUserFriendResponse.data["content"]
        : [];
    setUserFriendDataCompleteList(newUserFriendDataCompleteList);
  };

  const initCourseAndUserDataCompleteList = async () => {
    const getAllCourseAndUserResponse = await improvedRequestGetEntryListAll(
      DB_TABLE_COURSE_AND_USER
    );
    const newCourseAndUserDataCompleteList =
      getAllCourseAndUserResponse &&
      getAllCourseAndUserResponse.data &&
      getAllCourseAndUserResponse.data["content"]
        ? getAllCourseAndUserResponse.data["content"]
        : [];
    setCourseAndUserDataCompleteList(newCourseAndUserDataCompleteList);
  };

  const initMajorAndUserDataCompleteList = async () => {
    const getAllMajorAndUserResponse = await improvedRequestGetEntryListAll(
      DB_TABLE_MAJOR_AND_USER
    );
    const newMajorAndUserDataCompleteList =
      getAllMajorAndUserResponse &&
      getAllMajorAndUserResponse.data &&
      getAllMajorAndUserResponse.data["content"]
        ? getAllMajorAndUserResponse.data["content"]
        : [];
    setMajorAndUserDataCompleteList(newMajorAndUserDataCompleteList);
  };

  const initCourseDataByCourseUuidTable = async () => {
    const getAllCourseResponse = await improvedRequestGetEntryListAll(
      DB_TABLE_COURSE
    );
    const courseDataList =
      getAllCourseResponse &&
      getAllCourseResponse.data &&
      getAllCourseResponse.data["content"]
        ? getAllCourseResponse.data["content"]
        : [];
    const newCourseDataByCourseUuidTable = listToObjectByKey(
      courseDataList,
      "uuid"
    );
    setCourseDataByCourseUuidTable(newCourseDataByCourseUuidTable);
  };

  const initMajorDataByMajorUuidTable = async () => {
    const getAllMajorResponse = await improvedRequestGetEntryListAll(
      DB_TABLE_MAJOR
    );
    const majorDataList =
      getAllMajorResponse &&
      getAllMajorResponse.data &&
      getAllMajorResponse.data["content"]
        ? getAllMajorResponse.data["content"]
        : [];
    const newMajorDataByMajorUuidTable = listToObjectByKey(
      majorDataList,
      "uuid"
    );
    setMajorDataByMajorUuidTable(newMajorDataByMajorUuidTable);
  };

  // === === === === === === === === === === === === === === ===

  const initGroupCourse = () => {
    const selectedUserUuidListByCourseUuid = {};

    Object.keys(courseDataByCourseUuidTable).forEach((courseUuid) => {
      selectedUserUuidListByCourseUuid[courseUuid] = [];
    });

    sortedSelectedUserUuidList.forEach((selectedUserUuid) => {
      const courseUuidList =
        courseUuidListBySelectedUserUuidTable[selectedUserUuid];
      courseUuidList.forEach((courseUuid) => {
        selectedUserUuidListByCourseUuid[courseUuid].push(selectedUserUuid);
      });
    });

    const newSelectedUserUuidListByCourseLabel = {};
    Object.entries(selectedUserUuidListByCourseUuid).forEach(
      ([courseUuid, userUuidList]) => {
        const courseData = courseDataByCourseUuidTable[courseUuid];
        const courseLabel = `${courseData["code"]} ${courseData["number"]} [${courseData["section_number"]}]`;
        newSelectedUserUuidListByCourseLabel[courseLabel] = userUuidList;
      }
    );
    setSelectedUserUuidListByCourseLabel(newSelectedUserUuidListByCourseLabel);
  };

  // === === === === === === === === === === === === === === ===
  // Broad init functions (broken up into stages)
  // === === === === === === === === === === === === === === ===

  // WARNING: groups require state variables
  const initGroupStageData = async () => {
    await Promise.all([
      // GROUP INIT
      initGroupCourse(),
    ]);

    setAssetGroupStageLoading(false);
  };

  const initSelectedStageData = async () => {
    await Promise.all([
      // SELECTED-USER INIT
      initMutualUserUuidListBySelectedUserUuidTable(),
      initCourseUuidListBySelectedUserUuidTable(),
      initMajorUuidListBySelectedUserUuidTable(),
      initUserDataBySelectedUserUuidTable(),
    ]);

    setAssetSelectedStageLoading(false);
  };

  const initTargetUserStageData = async () => {
    await Promise.all([initFriendedUserDataWithTargetUserByUserUuidTable()]);

    setAssetTargetUserStageLoading(false);
  };

  const initGeneralStageData = async () => {
    await Promise.all([
      // GET-ALL INIT
      initUserFriendDataCompleteList(),
      initCourseAndUserDataCompleteList(),
      initMajorAndUserDataCompleteList(),
      initCourseDataByCourseUuidTable(),
      initMajorDataByMajorUuidTable(),
    ]);

    setAssetGeneralStageLoading(false);
  };

  // NOTE: for now, this component will NOT be flexible to changing parameters.
  // HOWEVER, because the stages are broken up, this flexibility can be easily added later.
  // For now, this component will ONLY have its first init.

  const initFirstLoop = async () => {
    if (assetLoading && assetFirstInit) {
      if (
        assetGeneralStageLoading &&
        assetTargetUserStageLoading &&
        assetSelectedStageLoading &&
        assetGroupStageLoading
      ) {
        await initGeneralStageData();
      }

      if (
        !assetGeneralStageLoading &&
        assetTargetUserStageLoading &&
        assetSelectedStageLoading &&
        assetGroupStageLoading
      ) {
        await initTargetUserStageData();
      }

      if (
        !assetGeneralStageLoading &&
        !assetTargetUserStageLoading &&
        assetSelectedStageLoading &&
        assetGroupStageLoading
      ) {
        await initSelectedStageData();
      }

      if (
        !assetGeneralStageLoading &&
        !assetTargetUserStageLoading &&
        !assetSelectedStageLoading &&
        assetGroupStageLoading
      ) {
        await initGroupStageData();
      }

      if (
        !assetGeneralStageLoading &&
        !assetTargetUserStageLoading &&
        !assetSelectedStageLoading &&
        !assetGroupStageLoading
      ) {
        setAssetLoading(false);
        // No longer the first initialization; any other initialization of individual stages will not be the first.
        setAssetFirstInit(false);

        setTimeout(() => {
          setAssetVisible(true);
        }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
      }
    }
  };

  useEffect(() => {
    if (!assetLoadingError) {
      initFirstLoop().catch((error) => {
        setAssetLoadingError(true);
      });
    }
  }, [
    assetGeneralStageLoading,
    assetTargetUserStageLoading,
    assetSelectedStageLoading,
    assetGroupStageLoading,
  ]);

  useEffect(() => {
    if (!assetLoading) {
      switch (groupType) {
        case ProfileUserListingGroupType.COURSE:
          setSelectedUserUuidListByGroupLabel(
            selectedUserUuidListByCourseLabel
          );
          break;
        default: // Also applies to ProfileUserListingGroupType.NONE
          break;
      }
    }
  }, [assetLoading, groupType]);

  const generateSelectedUserComponent = (selectedUserUuid) => {
    const selectedUserData = userDataBySelectedUserUuidTable[selectedUserUuid];

    const majorUuidList =
      majorUuidListBySelectedUserUuidTable[selectedUserUuid];
    const majorNameList = majorUuidList.map(
      (majorUuid) => majorDataByMajorUuidTable[majorUuid]["name"]
    );

    const mutualUserUuidList =
      mutualUserUuidListBySelectedUserUuidTable[selectedUserUuid];
    const mutualUserFullNameList = mutualUserUuidList.map(
      (mutualUserUuid) =>
        friendedUserDataWithTargetUserByUserUuidTable[mutualUserUuid][
          "full_name"
        ]
    );

    const date = dateBySelectedUserUuidTable
      ? dateBySelectedUserUuidTable[selectedUserUuid]
      : undefined;
    const dateText = date
      ? `${dateTextPrefix} ${dateConvertToString(
          date,
          DATE_FRONTEND_FORMAT_LONG
        )}`
      : undefined;

    return (
      <ProfileUserFriendCollectionItem
        pictureUrl={selectedUserData["profile_picture_url"]}
        userName={selectedUserData["user_name"]}
        fullName={selectedUserData["full_name"]}
        websiteUrl={selectedUserData["website_url"]}
        majorNameList={majorNameList}
        mutualUserFullNameList={mutualUserFullNameList}
        dateText={dateText}
      />
    );
  };

  const generateProvidedSelectedUserList = (selectedUserUuidList) => {
    const filteredSelectedUserUuidList = utilFilterUserUuidListByKey(
      selectedUserUuidList,
      userDataBySelectedUserUuidTable,
      FILTER_USER_BY_KEY_LIST,
      userFilterText
    );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {filteredSelectedUserUuidList.map((selectedUserUuid) => (
          <Box
            key={selectedUserUuid}
            sx={{
              paddingTop: "10px",
            }}
          >
            <Box
              sx={{
                paddingBottom: "10px",
              }}
            >
              {generateSelectedUserComponent(selectedUserUuid)}
            </Box>
            <Separator />
          </Box>
        ))}
      </Box>
    );
  };

  const generateChosenGroupList = () => {
    const sortedGroupLabelList = utilSortAlphabetical(
      Object.keys(selectedUserUuidListByGroupLabel)
    );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "10px",
        }}
      >
        {sortedGroupLabelList &&
          sortedGroupLabelList.length > 0 &&
          sortedGroupLabelList.map((groupLabel) => (
            <Box
              key={groupLabel}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: alpha(blue[800], 0.1),
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Typography variant="groupHeading">{groupLabel}</Typography>
                </Box>
                <Separator />
              </Box>
              {generateProvidedSelectedUserList(
                selectedUserUuidListByGroupLabel[groupLabel]
              )}
            </Box>
          ))}
      </Box>
    );
  };

  const generateMainComponent = () => {
    if (groupType == ProfileUserListingGroupType.NONE) {
      return generateProvidedSelectedUserList(selectedUserUuidList);
    } else {
      return generateChosenGroupList();
    }
  };

  // === === === === === === === === === === === === === === ===

  return (
    <ThemeProvider theme={profileUserListingPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name={"ProfileUserListingPane"} />
      )}
      {assetLoading && !assetLoadingError && <StatusComponentLoad />}
      {!assetLoading && !assetLoadingError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            opacity: assetVisible ? 1 : 0,
          }}
        >
          {generateMainComponent()}
        </Box>
      )}
    </ThemeProvider>
  );
}
