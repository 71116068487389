// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Typography } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";
import {
  DB_TABLE_COURSE,
  DB_TABLE_COURSE_AND_USER,
  requestGetEntryList,
  requestSearchEntry,
  requestUpdateEntry,
  requestUpdateEntryAssociation,
  requestUpdateEntryList,
} from "../../../service/browser/BackendRequestService";
import InputColorSelector from "../../component/shared/input/InputColorSelector";
import {
  listToObjectByKey,
  stateObjectSetItem,
} from "../../../service/browser/ObjectService";
import { debounce } from "lodash";
import StatusMessageComponentLoadError from "../../component/other/StatusMessageComponentLoadError";
import ButtonRectangleLabel from "../../component/shared/button/ButtonRectangleLabel";

export const makeSettingsDisplayColorPickerPaneThemeDesignTokens = (mode) => ({
  typography: {
    courseNameText: {
      fontFamily: "PT Sans",
      fontSize: 24,
      fontColor: purple[900],
    },
  },
});

export function makeSettingsDisplayColorPickerPaneTheme(mode) {
  return {
    ...makeSettingsDisplayColorPickerPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function SettingsDisplayColorPickerPane({ targetUserUuid }) {
  const settingsDisplayColorPickerPaneTheme = createTheme(
    makeSettingsDisplayColorPickerPaneTheme("light")
  );
  const [courseDataByCourseUuidTable, setCourseDataByCourseUuidTable] =
    useState({});
  const [
    courseAndUserDataByCourseUuidTable,
    setCourseAndUserDataByCourseUuidTable,
  ] = useState([]);
  const [lastModifiedCourseUuid, setLastModifiedCourseUuid] = useState(null);
  const [presetColorIndexOffset, setPresetColorIndexOffset] = useState(0);
  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetVisible, setAssetVisible] = useState(false);

  const DEBOUNCE_TIMEOUT_DURATION_MS = 500;
  const ASSET_VISIBLE_TIMEOUT_DURATION_MS = 200;
  const PRESET_COLOR_LIST = [
    "f6777b", // red
    "779bf6", // blue
    "8ad68e", // green
    "f6d277", // yellow
  ];

  useEffect(() => {
    requestSearchEntry(
      DB_TABLE_COURSE_AND_USER,
      {
        user_uuid: targetUserUuid,
      },
      (courseAndUserResponseData) => {
        if (courseAndUserResponseData) {
          const courseAndUserDataList = courseAndUserResponseData["content"];
          const newCourseAndUserDataByCourseUuidTable = {};
          courseAndUserDataList.forEach((courseAndUserData) => {
            newCourseAndUserDataByCourseUuidTable[
              courseAndUserData["course_and_user_id"]["course_uuid"]
            ] = courseAndUserData;
          });
          setCourseAndUserDataByCourseUuidTable(
            newCourseAndUserDataByCourseUuidTable
          );

          const courseUuidList = courseAndUserDataList.map(
            (courseAndUserData) =>
              courseAndUserData["course_and_user_id"]["course_uuid"]
          );

          requestGetEntryList(
            DB_TABLE_COURSE,
            courseUuidList,
            (courseDataList) => {
              if (courseDataList) {
                const newCourseDataByCourseUuidTable = listToObjectByKey(
                  courseDataList,
                  "uuid"
                );
                setCourseDataByCourseUuidTable(newCourseDataByCourseUuidTable);
                setAssetLoading(false);
                setTimeout(() => {
                  setAssetVisible(true);
                }, ASSET_VISIBLE_TIMEOUT_DURATION_MS);
              } else {
                setAssetLoadingError(true);
              }
            },
            (error) => {
              setAssetLoadingError(true);
            }
          );
        } else {
          setAssetLoadingError(true);
        }
      },
      (error) => {
        setAssetLoadingError(true);
      }
    );
  }, []);

  const updateAllColorsRequest = (givenCourseAndUserDataByCourseUuidTable) => {
    const updateDataList = Object.values(
      givenCourseAndUserDataByCourseUuidTable
    ).map((courseAndUserData) => ({
      id: courseAndUserData["course_and_user_id"],
      content: {
        course_uuid: courseAndUserData["course_and_user_id"]["course_uuid"],
        user_uuid: courseAndUserData["course_and_user_id"]["user_uuid"],
        color: courseAndUserData["color"],
      },
    }));
    requestUpdateEntryList(DB_TABLE_COURSE_AND_USER, updateDataList);
  };

  const setAllColorsPreset = () => {
    const newCourseAndUserDataByCourseUuidTable = {};

    var presetIndex = presetColorIndexOffset;
    Object.entries(courseAndUserDataByCourseUuidTable).forEach(
      ([courseUuid, courseAndUserData]) => {
        const newCourseAndUserData = { ...courseAndUserData };
        newCourseAndUserData["color"] = PRESET_COLOR_LIST[presetIndex];
        newCourseAndUserDataByCourseUuidTable[courseUuid] =
          newCourseAndUserData;
        presetIndex++;
        if (presetIndex >= PRESET_COLOR_LIST.length) {
          presetIndex = 0;
        }
      }
    );

    var newPresetColorIndexOffset = presetColorIndexOffset + 1;
    if (newPresetColorIndexOffset >= PRESET_COLOR_LIST.length) {
      newPresetColorIndexOffset = 0;
    }
    setPresetColorIndexOffset(newPresetColorIndexOffset);

    setCourseAndUserDataByCourseUuidTable(
      newCourseAndUserDataByCourseUuidTable
    );
    updateAllColorsRequest(newCourseAndUserDataByCourseUuidTable);
  };

  const processChangeColorValueRequest = useCallback(() => {
    if (!lastModifiedCourseUuid) {
      return;
    }
    const courseAndUserData =
      courseAndUserDataByCourseUuidTable[lastModifiedCourseUuid];
    requestUpdateEntryAssociation(
      DB_TABLE_COURSE_AND_USER,
      lastModifiedCourseUuid,
      targetUserUuid,
      {
        user_uuid: courseAndUserData["course_and_user_id"]["user_uuid"],
        course_uuid: courseAndUserData["course_and_user_id"]["course_uuid"],
        color: courseAndUserData["color"],
      }
    );
  }, [courseAndUserDataByCourseUuidTable]); // Add dependencies

  const debounceChangeColorValueRequest = useMemo(
    () =>
      debounce(processChangeColorValueRequest, DEBOUNCE_TIMEOUT_DURATION_MS),
    [processChangeColorValueRequest]
  );

  useEffect(() => {
    debounceChangeColorValueRequest();

    // Cleanup function to cancel the debounce on unmount
    return () => {
      debounceChangeColorValueRequest.cancel();
    };
  }, [debounceChangeColorValueRequest]);

  const handleChangeColorValue = (courseUuid, newColorValue) => {
    setLastModifiedCourseUuid(courseUuid);

    const newCourseAndUserData = courseAndUserDataByCourseUuidTable[courseUuid];

    // Remove the "#" that InputColorSelector adds before the hex value in its color values
    newCourseAndUserData["color"] = newColorValue.slice(1);
    stateObjectSetItem(
      courseUuid,
      newCourseAndUserData,
      courseAndUserDataByCourseUuidTable,
      setCourseAndUserDataByCourseUuidTable
    );
  };

  const generateCheckBoxComponent = (courseUuid) => {
    const courseAndUserData = courseAndUserDataByCourseUuidTable[courseUuid];
    const courseData = courseDataByCourseUuidTable[courseUuid];

    const color = `#${courseAndUserData["color"]}`;
    const courseLabel = `${courseData["code"]} ${courseData["number"]} ${courseData["section_number"]}`;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <InputColorSelector
          colorValue={color}
          onChangeColorValue={(newColorValue) =>
            handleChangeColorValue(courseUuid, newColorValue)
          }
          boxWidth={40}
        />
        <Typography variant="courseNameText">{courseLabel}</Typography>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={settingsDisplayColorPickerPaneTheme}>
      {assetLoadingError && (
        <StatusMessageComponentLoadError name="SettingsDisplayColorPickerPane" />
      )}
      {!assetLoading &&
        !assetLoadingError &&
        Object.keys(courseAndUserDataByCourseUuidTable).length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
              opacity: assetVisible ? 1 : 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                gap: "10px",
                padding: "10px",
              }}
            >
              {Object.keys(courseAndUserDataByCourseUuidTable).map(
                (courseUuid, index) => (
                  <Box key={index}>{generateCheckBoxComponent(courseUuid)}</Box>
                )
              )}
            </Box>
            <ButtonRectangleLabel
              variant="success"
              label="Set Preset Colors"
              onClick={() => setAllColorsPreset()}
            />
          </Box>
        )}
    </ThemeProvider>
  );
}
