// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Avatar, Typography, Box, Link } from "@mui/material";

// Custom Theme and Style
import { purple } from "../../style/AppTheme";
import {
  DATE_FRONTEND_FORMAT_LONG,
  DATE_FRONTEND_FORMAT_SHORT,
  dateConvertToString,
} from "../../../service/browser/DateService";

export const makeProfileEndorsementCollectionItemDesignTokens = (mode) => ({
  typography: {
    userNameText: {
      fontFamily: "Source Serif Pro",
      fontSize: 18,
      fontWeight: 600,
      color: purple[900],
    },
    dateText: {
      fontFamily: "PT Sans",
      fontSize: 18,
      fontWeight: 800,
      color: alpha(purple[900], 0.4),
    },
    bodyText: {
      fontFamily: "PT Sans",
      fontSize: 16,
      fontWeight: 400,
      color: purple[900],
    },
  },
});

export function makeProfileEndorsementCollectionItemTheme(mode) {
  return {
    ...makeProfileEndorsementCollectionItemDesignTokens(mode),
    components: {},
  };
}

export default function ProfileEndorsementCollectionItem({
  profilePictureUrl,
  userName,
  body,
  date,
}) {
  const profileEndorsementCollectionItemTheme = createTheme(
    makeProfileEndorsementCollectionItemTheme("light")
  );

  const dateText = dateConvertToString(date, DATE_FRONTEND_FORMAT_SHORT);
  const USER_NAME_MAX_LENGTH = 18;
  const userNameFiltered =
    userName && userName.length > USER_NAME_MAX_LENGTH
      ? userName.slice(0, USER_NAME_MAX_LENGTH - 3) + "..."
      : userName;

  return (
    <ThemeProvider theme={profileEndorsementCollectionItemTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: "10px",
          width: "100%",
        }}
      >
        {/* column with the profile picture */}
        <Box>
          <Avatar
            alt={userName}
            src={profilePictureUrl}
            sx={{
              width: "48px",
              height: "48px",
            }}
          />
        </Box>
        {/* everything else */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* row: name, date */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Link href={`/user/${userName}`} underline="always" color="inherit">
              <Typography
                gutterBottom
                variant="userNameText"
                sx={{
                  lineHeight: "18px",
                }}
              >
                {userNameFiltered}
              </Typography>
            </Link>
            <Typography
              gutterBottom
              variant="dateText"
              sx={{
                lineHeight: "18px",
              }}
            >
              {dateText}
            </Typography>
          </Box>
          {/* row: body */}
          <Box>
            <Typography
              variant="bodyText"
              sx={{
                lineHeight: 0.75,
              }}
            >
              {body}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
