// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import DropDownComboSearch from "../../component/shared/drop_down_combo/DropDownComboSearch";
import {
  DB_TABLE_COURSE_AND_USER,
  DB_TABLE_MEETING_AND_USER,
  DB_TABLE_USER,
  DB_TABLE_USER_FRIEND,
  improvedRequestCreateEntry,
  improvedRequestDeleteEntryAssociation,
  improvedRequestDeleteEntryList,
  improvedRequestGetEntryList,
  improvedRequestSearchEntry,
} from "../../../service/browser/BackendRequestService";
import {
  listToObjectByKey,
  stateObjectDeleteItem,
  stateObjectSetItem,
  varListAddItem,
  varListRemoveItemByValue,
} from "../../../service/browser/ObjectService";
import {
  notificationCreate,
  notificationSetActionCompleted,
  NotificationType,
} from "../../../service/user/NotificationService";
import {
  notificationCreationTemplateMeetingParticipantLeaderInviteCancel,
  notificationCreationTemplateMeetingParticipantLeaderInviteRequest,
  notificationCreationTemplateMeetingParticipantLeaderRemoveUser,
} from "../../../service/user/NotificationCreationTemplateService";
import { CalendarMeetingInfoPreset } from "../../super_pane/calendar_meeting_info/CalendarMeetingInfoSuperPane";

export const makeCalendarMeetingInfoInputParticipantListPaneThemeDesignTokens =
  (mode) => ({});

export function makeCalendarMeetingInfoInputParticipantListPaneTheme(mode) {
  return {
    ...makeCalendarMeetingInfoInputParticipantListPaneThemeDesignTokens(mode),
    components: {},
  };
}

/*
 * NOTES:
 * Regardless if the viewer is the meeting creator or not, the participant list will NEVER include the meeting creator.
 */
export default function CalendarMeetingInfoInputParticipantListPane({
  meetingInfoPreset = CalendarMeetingInfoPreset.VIEW,
  onChangeValue,
  presentUserUuid,
  targetMeetingCreatorUuid,
  targetMeetingUuid,
  targetCourseUuid,
  maxInvitedParticipantCount,
  modifiable,
  required,
}) {
  const calendarMeetingInfoInputParticipantListPaneTheme = createTheme(
    makeCalendarMeetingInfoInputParticipantListPaneTheme("light")
  );

  const [assetLoading, setAssetLoading] = useState(true);
  const [assetLoadingError, setAssetLoadingError] = useState(false);
  const [assetBusy, setAssetBusy] = useState(false);
  const [selectedUserUuidList, setSelectedUserUuidList] = useState([]);
  const [friendedUserDataByUserUuidTable, setFriendedUserDataByUserUuidTable] =
    useState({});
  const [
    meetingAndUserDataByUserUuidTable,
    setMeetingAndUserDataByUserUuidTable,
  ] = useState({});
  const [
    initialParticipantUserDataByUserUuidTable,
    setInitialParticipantUserDataByUserUuidTable,
  ] = useState([]);
  const [userAttendingCourseUserUuidList, setUserAttendingCourseUserUuidList] =
    useState([]);
  const [
    searchableUserDataByUserUuidTable,
    setSearchableUserDataByUserUuidTable,
  ] = useState({});

  // Pure async function: can throw errors.
  // NOTE: this will EXCLUDE the meeting creator.
  const operationGetParticipantUserUuidList = async (meetingUuid) => {
    const meetingAndUserResponse = await improvedRequestSearchEntry(
      DB_TABLE_MEETING_AND_USER,
      {
        meeting_uuid: meetingUuid,
      }
    );
    const meetingAndUserDataList = meetingAndUserResponse.data["content"];
    const meetingAndUserDataByUserUuid = {};

    meetingAndUserDataList.forEach((meetingAndUserData) => {
      const userUuid = meetingAndUserData["meeting_and_user_id"]["user_uuid"];
      if (userUuid !== targetMeetingCreatorUuid) {
        meetingAndUserDataByUserUuid[userUuid] = meetingAndUserData;
      }
    });

    const userResponse = await improvedRequestGetEntryList(
      DB_TABLE_USER,
      Object.keys(meetingAndUserDataByUserUuid)
    );
    const userDataList = userResponse.data;
    const filteredParticipantUserDataList = userDataList.filter(
      (userData) =>
        userData["uuid"] !== targetMeetingCreatorUuid &&
        userData["completed"] == true &&
        userData["soft_deleted"] == false
    );
    const filteredParticipantUserUuidList = filteredParticipantUserDataList
      ? filteredParticipantUserDataList.map((userData) => userData["uuid"])
      : [];
    const filteredMeetingAndUserDataByUserUuidTable = {};

    filteredParticipantUserUuidList.forEach((userUuid) => {
      filteredMeetingAndUserDataByUserUuidTable[userUuid] =
        meetingAndUserDataByUserUuid[userUuid];
    });

    return {
      filteredParticipantUserUuidList: filteredParticipantUserUuidList,
      filteredMeetingAndUserDataByUserUuidTable:
        filteredMeetingAndUserDataByUserUuidTable,
    };
  };

  // Pure async function: can throw errors.
  // NOTE: this will INCLUDE the meeting creator.
  const operationGetUserAttendingCourseUserUuidList = async (userUuidList) => {
    const courseAndUserResponse = await improvedRequestSearchEntry(
      DB_TABLE_COURSE_AND_USER,
      {
        course_uuid: targetCourseUuid,
      }
    );
    const newUserAttendingCourseUserUuidList = [];
    const courseAndUserDataList = courseAndUserResponse.data["content"];
    courseAndUserDataList.forEach((courseAndUserData) => {
      const userUuid = courseAndUserData["course_and_user_id"]["user_uuid"];
      if (
        userUuidList.includes(userUuid) &&
        !newUserAttendingCourseUserUuidList.includes(userUuid)
      ) {
        newUserAttendingCourseUserUuidList.push(userUuid);
      }
    });
    return newUserAttendingCourseUserUuidList;
  };

  // Pure async function: can throw errors.
  const operationGetFriendUserUuidList = async (userUuid) => {
    const [resultA, resultB] = await Promise.all([
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        friended_user_uuid: userUuid,
      }),
      improvedRequestSearchEntry(DB_TABLE_USER_FRIEND, {
        user_uuid: userUuid,
      }),
    ]);

    const friendedUserUuidList = [];

    const friendedUserUuidListA = resultA.data["content"].map(
      (userFriendData) => userFriendData["user_uuid"]
    );
    const friendedUserUuidListB = resultB.data["content"].map(
      (userFriendData) => userFriendData["friended_user_uuid"]
    );
    const friendedUserDataListCombined = friendedUserUuidListA.concat(
      friendedUserUuidListB
    );
    friendedUserDataListCombined.forEach((friendedUserUuid) => {
      if (friendedUserUuid != userUuid) {
        friendedUserUuidList.push(friendedUserUuid);
      }
    });

    return friendedUserUuidList;
  };

  // Pure async function: can throw errors.
  // NOTE: this will INCLUDE the meeting creator.
  const operationGetUserDataGivenUserUuidList = async (userUuidList) => {
    const userResponse = await improvedRequestGetEntryList(
      DB_TABLE_USER,
      userUuidList
    );
    const userDataList = userResponse.data.filter(
      (userData) => userData["completed"] && !userData["soft_deleted"]
    );
    const newUserDataByUserUuidTable = listToObjectByKey(userDataList, "uuid");
    return newUserDataByUserUuidTable;
  };

  // Pure async function: can throw errors.
  const sendNotificationUserInvite = async (userUuid) => {
    await notificationSetActionCompleted({
      user_uuid: userUuid,
      second_resource_uuid: targetMeetingUuid,
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
    });
    await notificationCreate(
      notificationCreationTemplateMeetingParticipantLeaderInviteRequest(
        [userUuid],
        targetMeetingCreatorUuid,
        targetMeetingUuid,
        ""
      )
    );
  };

  // Pure async function: can throw errors.
  const sendNotificationUserInviteCancel = async (userUuid) => {
    await notificationSetActionCompleted({
      user_uuid: userUuid,
      second_resource_uuid: targetMeetingUuid,
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
    });
    await notificationCreate(
      notificationCreationTemplateMeetingParticipantLeaderInviteCancel(
        [userUuid],
        targetMeetingCreatorUuid,
        targetMeetingUuid,
        ""
      )
    );
  };

  // Pure async function: can throw errors.
  const sendNotificationUserRemove = async (userUuid) => {
    await notificationSetActionCompleted({
      user_uuid: userUuid,
      second_resource_uuid: targetMeetingUuid,
      type: NotificationType.MEETING_PARTICIPANT_LEADER_INVITE_REQUEST,
    });
    await notificationCreate(
      notificationCreationTemplateMeetingParticipantLeaderRemoveUser(
        userUuid,
        targetMeetingCreatorUuid,
        targetMeetingUuid,
        ""
      )
    );
  };

  const operationNotifyDeletedMeetingAndUserDataList = async (
    deletedMeetingAndUserDataList
  ) => {
    for (const meetingAndUserData of deletedMeetingAndUserDataList) {
      const userUuid = meetingAndUserData["meeting_and_user_id"]["user_uuid"];
      if (meetingAndUserData["attending"] == true) {
        await sendNotificationUserRemove(userUuid);
      } else {
        await sendNotificationUserInviteCancel(userUuid);
      }
    }
  };

  // Pure async function, can throw errors.
  // NOTE: this will EXCLUDE the meeting creator.
  const handleChangeCourseUuid = async () => {
    const meetingAndUserIdList = selectedUserUuidList
      .map((userUuid) => ({
        user_uuid: userUuid,
        meeting_uuid: targetMeetingUuid,
      }))
      .filter((userUuid) => userUuid !== targetMeetingCreatorUuid);
    const deletedMeetingAndUserDataListResponse =
      await improvedRequestDeleteEntryList(
        DB_TABLE_MEETING_AND_USER,
        meetingAndUserIdList
      );
    const deletedMeetingAndUserDataList =
      deletedMeetingAndUserDataListResponse.data;
    if (meetingInfoPreset === CalendarMeetingInfoPreset.EDIT) {
      operationNotifyDeletedMeetingAndUserDataList(
        deletedMeetingAndUserDataList
      );
    }
    setSelectedUserUuidList([]);

    const friendUserUuidList = Object.keys(friendedUserDataByUserUuidTable);
    const newUserAttendingCourseUserUuidList =
      await operationGetUserAttendingCourseUserUuidList(friendUserUuidList);
    setUserAttendingCourseUserUuidList(newUserAttendingCourseUserUuidList);

    processSetSearchableUsers(
      newUserAttendingCourseUserUuidList,
      friendedUserDataByUserUuidTable,
      {}
    );
  };

  useEffect(() => {
    if (!assetLoading && friendedUserDataByUserUuidTable) {
      handleChangeCourseUuid().catch((error) => {});
    }
  }, [targetCourseUuid]);

  // Pure async function, can throw errors.
  const handleChangeMaxInvitedCount = async (newMaxCount) => {
    if (selectedUserUuidList.length > newMaxCount) {
      const removedUserUuidList = selectedUserUuidList.slice(newMaxCount);
      const newSelectedUserUuidList = selectedUserUuidList.slice(
        0,
        newMaxCount
      );

      const meetingAndUserIdList = removedUserUuidList.map((userUuid) => ({
        user_uuid: userUuid,
        meeting_uuid: targetMeetingUuid,
      }));

      const deletedMeetingAndUserDataListResponse =
        await improvedRequestDeleteEntryList(
          DB_TABLE_MEETING_AND_USER,
          meetingAndUserIdList
        );
      const deletedMeetingAndUserDataList =
        deletedMeetingAndUserDataListResponse.data;
      if (meetingInfoPreset === CalendarMeetingInfoPreset.EDIT) {
        operationNotifyDeletedMeetingAndUserDataList(
          deletedMeetingAndUserDataList
        );
      }
      setSelectedUserUuidList(newSelectedUserUuidList);
    }
  };

  useEffect(() => {
    handleChangeMaxInvitedCount(maxInvitedParticipantCount).catch(
      (error) => {}
    );
  }, [maxInvitedParticipantCount]);

  const processSetSearchableUsers = (
    givenUserAttendingCourseUserUuidList,
    givenFriendedUserDataByUserUuidTable,
    givenParticipantUserDataByUserUuidTable
  ) => {
    const newSearchableUserDataByUserUuidTable = {};
    Object.entries(givenParticipantUserDataByUserUuidTable).forEach(
      ([userUuid, userData]) => {
        newSearchableUserDataByUserUuidTable[userUuid] = userData;
      }
    );

    givenUserAttendingCourseUserUuidList.forEach((userUuid) => {
      if (!(userUuid in newSearchableUserDataByUserUuidTable)) {
        newSearchableUserDataByUserUuidTable[userUuid] =
          givenFriendedUserDataByUserUuidTable[userUuid];
      }
    });
    setSearchableUserDataByUserUuidTable(newSearchableUserDataByUserUuidTable);
  };

  // Pure async function, can throw errors.
  const processInit = async () => {
    const [
      unfilteredFriendUserUuidList,
      {
        filteredParticipantUserUuidList,
        filteredMeetingAndUserDataByUserUuidTable,
      },
    ] = await Promise.all([
      operationGetFriendUserUuidList(targetMeetingCreatorUuid),
      operationGetParticipantUserUuidList(targetMeetingUuid),
    ]);

    setSelectedUserUuidList(filteredParticipantUserUuidList);
    setMeetingAndUserDataByUserUuidTable(
      filteredMeetingAndUserDataByUserUuidTable
    );

    const newInitialParticipantUserDataByUserUuidTable =
      await operationGetUserDataGivenUserUuidList(
        filteredParticipantUserUuidList
      );

    const newFriendedUserDataByUserUuidTable =
      await operationGetUserDataGivenUserUuidList(unfilteredFriendUserUuidList);
    setFriendedUserDataByUserUuidTable(newFriendedUserDataByUserUuidTable);

    const newUserAttendingCourseUserUuidList =
      await operationGetUserAttendingCourseUserUuidList(
        Object.keys(newFriendedUserDataByUserUuidTable)
      );
    setUserAttendingCourseUserUuidList(newUserAttendingCourseUserUuidList);

    processSetSearchableUsers(
      newUserAttendingCourseUserUuidList,
      newFriendedUserDataByUserUuidTable,
      newInitialParticipantUserDataByUserUuidTable
    );
  };

  useEffect(() => {
    if (
      assetLoading &&
      presentUserUuid &&
      targetMeetingCreatorUuid &&
      targetMeetingUuid &&
      targetCourseUuid
    ) {
      processInit()
        .then(() => {
          setAssetLoading(false);
        })
        .catch((error) => {
          setAssetLoadingError(true);
        });
    }
  }, [
    presentUserUuid,
    targetMeetingCreatorUuid,
    targetMeetingUuid,
    targetCourseUuid,
  ]);

  // Pure async function: can throw errors.
  // NOTE: this will EXCLUDE the meeting creator.
  const processUserDropDownAddItem = async (userUuid) => {
    if (userUuid === targetMeetingCreatorUuid) {
      return;
    }
    const createResponse = await improvedRequestCreateEntry(
      DB_TABLE_MEETING_AND_USER,
      {
        user_uuid: userUuid,
        meeting_uuid: targetMeetingUuid,
        attending: false,
      }
    );
    const createdMeetingAndUserData = createResponse.data;

    if (createdMeetingAndUserData) {
      stateObjectSetItem(
        userUuid,
        createdMeetingAndUserData,
        meetingAndUserDataByUserUuidTable,
        setMeetingAndUserDataByUserUuidTable
      );

      const newSelectedUserUuidList = varListAddItem(
        userUuid,
        selectedUserUuidList
      );
      onChangeValue?.(newSelectedUserUuidList);
      setSelectedUserUuidList(newSelectedUserUuidList);

      // If EDIT, then immediately send an invite to the user.
      // Do NOT do this with CREATE, because the meeting does not exist yet.
      if (meetingInfoPreset === CalendarMeetingInfoPreset.EDIT) {
        await sendNotificationUserInvite(userUuid);
      }
    }
  };

  const handleUserDropDownAddItem = (userUuid) => {
    if (!assetBusy) {
      setAssetBusy(true);
      processUserDropDownAddItem(userUuid)
        .then(() => {
          setAssetBusy(false);
        })
        .catch((error) => {
          setAssetBusy(false);
        });
    }
  };

  // Pure async function: can throw errors.
  // NOTE: this will EXCLUDE the meeting creator.
  const processUserDropDownRemoveItem = async (userUuid) => {
    if (userUuid === targetMeetingCreatorUuid) {
      return;
    }
    const deleteResponse = await improvedRequestDeleteEntryAssociation(
      DB_TABLE_MEETING_AND_USER,
      targetMeetingUuid,
      userUuid
    );
    const deletedMeetingAndUserData = deleteResponse.data;

    if (deletedMeetingAndUserData) {
      stateObjectDeleteItem(
        userUuid,
        meetingAndUserDataByUserUuidTable,
        setMeetingAndUserDataByUserUuidTable
      );
      const newSelectedUserUuidList = varListRemoveItemByValue(
        userUuid,
        selectedUserUuidList
      );
      onChangeValue?.(newSelectedUserUuidList);
      setSelectedUserUuidList(newSelectedUserUuidList);

      // If EDIT, then immediately send an invite to the user.
      // Do NOT do this with CREATE, because the meeting does not exist yet.
      if (meetingInfoPreset === CalendarMeetingInfoPreset.EDIT) {
        if (deletedMeetingAndUserData["attending"] == true) {
          await sendNotificationUserRemove(userUuid);
        } else {
          await sendNotificationUserInviteCancel(userUuid);
        }
      }
    }
  };

  const handleUserDropDownRemoveItem = (userUuid) => {
    if (!assetBusy) {
      setAssetBusy(true);
      processUserDropDownRemoveItem(userUuid)
        .then(() => {
          setAssetBusy(false);
        })
        .catch((error) => {
          setAssetBusy(false);
        });
    }
  };

  return (
    <ThemeProvider theme={calendarMeetingInfoInputParticipantListPaneTheme}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {assetLoading || assetLoadingError ? (
          <DropDownComboSearch
            required={required}
            modifiable={false}
            fullWidth={true}
          />
        ) : (
          <DropDownComboSearch
            required={required}
            modifiable={
              meetingInfoPreset !== CalendarMeetingInfoPreset.VIEW && modifiable
            }
            removable={true}
            searchable={
              selectedUserUuidList.length < maxInvitedParticipantCount
            }
            fullWidth={true}
            itemKeyId="uuid"
            itemKeySortBy="user_name"
            itemKeyPictureUrl="profile_picture_url"
            itemKeyListFilterBy={["user_name", "full_name"]}
            itemKeyListHeading={["user_name"]}
            itemKeyListDescription={["full_name"]}
            searchableItemById={searchableUserDataByUserUuidTable}
            selectedItemIdList={selectedUserUuidList}
            onAddItemById={handleUserDropDownAddItem}
            onRemoveItemById={handleUserDropDownRemoveItem}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}
