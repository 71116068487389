// Theme and Style
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useContext } from "react";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import {
  backendBeginUserSession,
  backendCreateUser,
  backendMatchGoogleIdToUser,
  localSetUserSession,
} from "../../../service/user/UserService";
import { UserContext } from "../../../context/UserContext";
import { requestSearchEntry } from "../../../service/browser/BackendRequestService";
import ButtonRectangleLabel from "../shared/button/ButtonRectangleLabel";

const PLANNED_MOCK_DATA = [
  {
    google_id: "111111111111111111111",
    user_name: "wilbur",
    full_name: "Wilbur Wildcat",
    school_email: "wilbur@arizona.edu",
    profile_picture_url:
      "https://quickstart.arizona.edu/sites/default/files/2021-06/Wilbur.jpg",
  },
  {
    google_id: "222222222222222222222",
    user_name: "wilma",
    full_name: "Wilma Wildcat",
    school_email: "wilma@arizona.edu",
    profile_picture_url:
      "https://quickstart.arizona.edu/sites/default/files/styles/az_medium/public/2021-06/Wilma1.jpg?itok=5kL18yWs",
  },
];

const GENERATED_MOCK_NAME_LIST = [
  "Emma",
  "Olivia",
  "Ava",
  "Sophia",
  "Isabella",
  "Mia",
  "Charlotte",
  "Amelia",
  "Harper",
  "Evelyn",
  "Liam",
  "Noah",
  "Oliver",
  "Elijah",
  "James",
  "William",
  "Benjamin",
  "Lucas",
  "Henry",
  "Alexander",
  "Jackson",
  "Sebastian",
  "Aiden",
  "Matthew",
  "Samuel",
  "David",
  "Joseph",
  "Carter",
  "Wyatt",
  "John",
];

const GENERATED_MOCK_DATA = GENERATED_MOCK_NAME_LIST.map((userName) => ({
  google_id: userName.toUpperCase(),
  user_name: userName.toLowerCase(),
  full_name: userName,
  school_email: `${userName.toLowerCase()}@arizona.edu`,
  profile_picture_url:
    "https://i.pinimg.com/originals/6f/f2/c0/6ff2c059ead1410a99c77ec20d1025bf.jpg",
}));

export const makeLobbySignInButtonThemeDesignTokens = (mode) => ({});

export function makeLobbySignInButtonTheme(mode) {
  return {
    ...makeLobbySignInButtonThemeDesignTokens(mode),
    components: {},
  };
}

function GoogleLoginPlaceholder({
  mockDataLabel,
  mockData,
  onSuccess,
  onFailure,
}) {
  return (
    <Box>
      <ButtonRectangleLabel
        label={`Mock Google Login (${mockDataLabel})`}
        variant="success"
        disabled={!mockData}
        onClick={() => onSuccess?.(mockData)}
      />
    </Box>
  );
}

// onSuccess(googleMatchedUserData)
export default function LobbySignInButton({ onSuccess, onFailure }) {
  const lobbySignInButtonTheme = createTheme(
    makeLobbySignInButtonTheme("light")
  );

  const handleGoogleSignInSuccess = (googleResponse) => {
    const responseParts = googleResponse.credential.split(".");
    const decodedPayload = atob(responseParts[1]);
    const payload = JSON.parse(decodedPayload);

    if (payload.email.includes("@arizona.edu")) {
      const googleMatchedUserData = {
        google_id: payload.sub,
        user_name: payload.email.split("@")[0],
        full_name: payload.name,
        school_email: payload.email,
        profile_picture_url: payload.picture,
      };
      onSuccess?.(googleMatchedUserData);
    } else {
      onFailure?.();
    }
  };

  const handleGoogleSignInSuccessPlaceholder = (googleMatchedUserData) => {
    onSuccess?.(googleMatchedUserData);
  };

  const handleGoogleSignInFailure = () => {
    onFailure?.();
  };

  const generateLoginPlaceholderComponentList = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {GENERATED_MOCK_DATA.map((mockData, index) => (
          <Box key={index}>
            <GoogleLoginPlaceholder
              mockDataLabel={mockData["user_name"]}
              mockData={mockData}
              onSuccess={handleGoogleSignInSuccessPlaceholder}
              onFailure={handleGoogleSignInFailure}
            />
          </Box>
        ))}
      </Box>
    );
  };

  const generateLoginComponent = () => {
    switch (process.env.REACT_APP_LOGIN_MODE) {
      case "placeholder":
        return generateLoginPlaceholderComponentList();
      case "real":
        return (
          <GoogleLogin
            onSuccess={handleGoogleSignInSuccess}
            onFailure={handleGoogleSignInFailure}
          />
        );
      default:
        return <Box></Box>;
    }
  };

  return (
    <ThemeProvider theme={lobbySignInButtonTheme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {generateLoginComponent()}
        </Box>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}
