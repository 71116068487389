// Theme and Style
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import { alpha } from "@mui/material";

// Interactive Assets
import { Grid, Box, Avatar } from "@mui/material";

// Custom Theme and Style
import { colorBg, purple, red, gray, green } from "../../style/AppTheme";

// React
import * as React from "react";
import { useState, useEffect } from "react";
import {
  DB_TABLE_USER,
  requestGetEntry,
} from "../../../service/browser/BackendRequestService";
import Separator from "../../component/shared/other/Separator";
import privacyPolicyFile from "../../asset/document/sc-privacy-policy-aug-2024.pdf";
import LobbyProfilePortraitPane from "../lobby/LobbyProfilePortraitPane";

export const makeSettingsPrivacyPolicyPaneThemeDesignTokens = (mode) => ({
  typography: {
    sectionHeading: {
      fontFamily: "Source Serif Pro",
      color: purple[900],
      fontSize: 24,
      textTransform: "none",
    },
  },
});

export function makeSettingsPrivacyPolicyPaneTheme(mode) {
  return {
    ...makeSettingsPrivacyPolicyPaneThemeDesignTokens(mode),
    components: {},
  };
}

export default function SettingsPrivacyPolicyPane({ targetUserUuid }) {
  const settingsPrivacyPolicyPaneTheme = createTheme(
    makeSettingsPrivacyPolicyPaneTheme("light")
  );

  return (
    <ThemeProvider theme={settingsPrivacyPolicyPaneTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
          width: "100%",
          height: "100%",
        }}
      >
        <LobbyProfilePortraitPane
          targetUserUuid={targetUserUuid}
          scale={0.75}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "12px",
            backgroundColor: alpha(gray[200], 0.5),
            borderRadius: "6px",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography variant="sectionHeading">Privacy Policy</Typography>
          <Separator />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
              padding: "12px",
              width: "100%",
            }}
          >
            <iframe src={privacyPolicyFile} width="100%" height="100%"></iframe>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
